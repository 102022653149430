import React, { useState } from "react";
import Util from "../../helpers/util";
import Language from "../../helpers/language";

import { ConfirmModal } from "../../modals/confirm_modal";
import { Drip7OriginalModal } from "../../modals/drip7_original_modal";
import { InputModal } from "../../modals/input_modal";

export const CategoriesTab = (props) => {
    const { categories, tenant_uid, tenants, onRefreshCategories, showToast } = props
    const [state, setState] = useState({
        search: "",
        show_add_category: false,
        show_edit_category: false,
        confirm: null
    })
    const { search, show_add_category, show_edit_category, confirm } = state

    const filterCategories = (categories, search) => {
        const s = search.toLowerCase();
        return categories.filter( x =>
            search == "" ||
            x.name.toLowerCase().indexOf(s) >= 0 ||
            x.tenant_name.toLowerCase().indexOf(s) >= 0
        )
    }

    const handleExportCsv = () => {
        const { questions } = buildContent();

        //Export a member list, and then redirect to it
        const question_uids = questions.map( x => x.uid )
        Util.fetch_js( "/question/export/", { question_uids },
            js => {
                window.location.href = js.url;
            },
            (reason, code) => {
                showToast(reason, 'failure');
            }
        );
    }

    const handleCreateCategory = (name) => {
        //Reset the show add
        setState(prev => ({ ...prev, show_add_category: false }))

        //If there was nothing, then we aren't actually trying to add a category
        if (name == null || name == undefined || name == "") {
            return;
        }

        //Add a category to the server
        Util.fetch_js( "/category/create/", { name, tenant_uid },
            js => {
                onRefreshCategories();
            },
            (err, code) => {
                showToast(err, 'failure');
            }
        );
    }

    const handleEditCategory = (name, category_uid) => {
        //Reset the show add
        setState(prev => ({ ...prev, show_edit_category: false }))

        //If there was nothing, then we aren't actually trying to add a category
        if (name == null || name == undefined || name == "") {
            return;
        }

        //Add a category to the server
        Util.fetch_js( "/category/modify/", { category_uid, name },
            js => {
                onRefreshCategories();
            },
            (err, code) => {
                showToast(err, 'failure');
            }
        );
    }

    const handleRemoveCategory = (category_uid) => {
        //Reset the show add
        setState(prev => ({ ...prev, confirm: null }))

        //Add a category to the server
        Util.fetch_js( "/category/remove/", { category_uid },
            js => {
                onRefreshCategories();
            },
            (err, code) => {
                showToast(err, 'failure');
            }
        );
    }

    const confirmAction = (action, args) => {
        let yes = null;
        let msg = null;
        if (action == handleRemoveCategory) {
            yes = Language.getTitleCase('Remove category?');
            msg = Language.getSentenceCase('Are you sure?');
        }
        else {
            throw Language.getSentenceCase('Program error, Unknown action');
        }

        //Setting a confirm action, will load message box, and if accepted saves
        const new_confirm = { action, args, msg, yes, no: Language.getTitleCase('cancel') };
        setState(prev => ({ ...prev, confirm: new_confirm }))
    };

    const filtered_categories = filterCategories(categories, search);

    return (
        <div className='central__main central__main--questions'>
            <div className='central__head'>
                <div className='central__head-container'>
                    <div className='central__head-search-wrapper'>
                        <svg
                            width='15'
                            height='16'
                            viewBox='0 0 15 16'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'>
                            <path
                                d='M14.8828 14.6152L11.3379 11.0703C11.25 11.0117 11.1621 10.9531 11.0742 10.9531H10.6934C11.6016 9.89844 12.1875 8.49219 12.1875 6.96875C12.1875 3.62891 9.43359 0.875 6.09375 0.875C2.72461 0.875 0 3.62891 0 6.96875C0 10.3379 2.72461 13.0625 6.09375 13.0625C7.61719 13.0625 8.99414 12.5059 10.0781 11.5977V11.9785C10.0781 12.0664 10.1074 12.1543 10.166 12.2422L13.7109 15.7871C13.8574 15.9336 14.0918 15.9336 14.209 15.7871L14.8828 15.1133C15.0293 14.9961 15.0293 14.7617 14.8828 14.6152ZM6.09375 11.6562C3.48633 11.6562 1.40625 9.57617 1.40625 6.96875C1.40625 4.39062 3.48633 2.28125 6.09375 2.28125C8.67188 2.28125 10.7812 4.39062 10.7812 6.96875C10.7812 9.57617 8.67188 11.6562 6.09375 11.6562Z'
                                fill='currentColor'></path>
                        </svg>
                        <input
                            type='text'
                            className='central__head-search rounded-md border-gray-200 bg-white text-gray-900 placeholder-text-gray-400 focus:border-drip7 focus:ring-0 sm:text-sm sm:leading-6'
                            placeholder={Language.getSentenceCase('search by category, tenant') + '...'}
                            value={search}
                            onChange={e => setState(prev => ({ ...prev, search: e.target.value })) }
                        />
                    </div>
                </div>

                <div className='central__head-button-container'>
                    <button
                        className='central__head-button central__head-button--solid central__head-button--add'
                        id='addContent'
                        type='button'
                        onClick={() => setState(prev => ({...prev, show_add_category: true })) }>
                        {Language.getTitleCase('add category')}
                    </button>
                </div>
            </div>

            <table className='questions__table questions__table--categories'>
                <tbody>
                    <tr className='questions__table-row'>
                        <th className='questions__table-head questions__table-head--category'>
                            <h5 className='questions__table-heading'>
                                {Language.getTitleCase('category')}
                            </h5>
                        </th>
                        <th className='questions__table-head questions__table-head--tenant'>
                            <h5 className='questions__table-heading'>
                                {Language.getTitleCase('tenant')}
                            </h5>
                        </th>
                        <th className='questions__table-head questions__table-head--tenant'>
                            <h5 className='questions__table-heading'>
                                {Language.getTitleCase('Question count')}
                            </h5>
                        </th>
                        <th className='questions__table-head'>
                            <div style={{width: '52px'}}>     </div>
                        </th>
                    </tr>
                    {Object.entries(filtered_categories).map( ([key, category]) => {
                            return (
                                <tr className='questions__table-row'
                                    key={"category_key_" + key}>
                                    <td className='questions__table-data questions__table-data--category'>
                                        <h5 className='questions__table-text'
                                            onClick={() => setState(prev => ({...prev, show_edit_category: category }))}>
                                            {category.name}
                                        </h5>
                                    </td>
                                    <td className='questions__table-data questions__table-data--tenant'>
                                        <h5 className='questions__table-text'
                                            onClick={() => setState(prev => ({...prev, show_edit_category: category }))}>
                                            {category.tenant_name}
                                        </h5>
                                    </td>
                                    <td className='questions__table-data questions__table-data--tenant'>
                                        <h5 className='questions__table-text'
                                            onClick={() => setState(prev => ({...prev, show_edit_category: category }))}>
                                            {category.question_count + category.phish_count}
                                        </h5>
                                    </td>
                                    {category.access.write &&
                                        <td className='questions__table-data questions__table-data--edit'>
                                            <svg
                                                className='questions__edit-button'
                                                width='16'
                                                height='16'
                                                viewBox='0 0 16 16'
                                                fill='none'
                                                xmlns='http://www.w3.org/2000/svg'
                                                onClick={() => setState(prev => ({...prev, show_edit_category: category }))}>
                                                <path
                                                    d='M15.0605 5.06445C15.6172 4.50781 15.6172 3.59961 15.0605 3.07227L13.3027 1.31445C12.7754 0.757812 11.8672 0.757812 11.3105 1.31445L9.96289 2.66211C9.8457 2.7793 9.8457 3.01367 9.96289 3.16016L13.2148 6.41211C13.3613 6.5293 13.5957 6.5293 13.7129 6.41211L15.0605 5.06445ZM8.82031 3.80469L1.11523 11.5098L0.5 15.0547C0.412109 15.5527 0.822266 15.9629 1.32031 15.875L4.86523 15.2598L12.5703 7.55469C12.7168 7.4375 12.7168 7.20312 12.5703 7.05664L9.31836 3.80469C9.17188 3.6875 8.9375 3.6875 8.82031 3.80469ZM4.13281 10.8359C3.95703 10.6895 3.95703 10.4258 4.13281 10.2793L8.64453 5.76758C8.79102 5.5918 9.05469 5.5918 9.20117 5.76758C9.37695 5.91406 9.37695 6.17773 9.20117 6.32422L4.68945 10.8359C4.54297 11.0117 4.2793 11.0117 4.13281 10.8359ZM3.07812 13.2969H4.48438V14.3809L2.58008 14.7031L1.67188 13.7949L1.99414 11.8906H3.07812V13.2969Z'
                                                    fill='currentColor'></path>
                                            </svg>
                                            <svg
                                                className='questions__delete-button'
                                                width='14'
                                                height='16'
                                                viewBox='0 0 14 16'
                                                fill='none'
                                                xmlns='http://www.w3.org/2000/svg'
                                                onClick={() => confirmAction( handleRemoveCategory, category.uid )}>
                                                <path
                                                    d='M1.375 14.4688C1.375 15.2598 1.99023 15.875 2.78125 15.875H11.2188C11.9805 15.875 12.625 15.2598 12.625 14.4688V4.625H1.375V14.4688ZM9.34375 6.96875C9.34375 6.73438 9.54883 6.5 9.8125 6.5C10.0469 6.5 10.2812 6.73438 10.2812 6.96875V13.5312C10.2812 13.7949 10.0469 14 9.8125 14C9.54883 14 9.34375 13.7949 9.34375 13.5312V6.96875ZM6.53125 6.96875C6.53125 6.73438 6.73633 6.5 7 6.5C7.23438 6.5 7.46875 6.73438 7.46875 6.96875V13.5312C7.46875 13.7949 7.23438 14 7 14C6.73633 14 6.53125 13.7949 6.53125 13.5312V6.96875ZM3.71875 6.96875C3.71875 6.73438 3.92383 6.5 4.1875 6.5C4.42188 6.5 4.65625 6.73438 4.65625 6.96875V13.5312C4.65625 13.7949 4.42188 14 4.1875 14C3.92383 14 3.71875 13.7949 3.71875 13.5312V6.96875ZM13.0938 1.8125H9.57812L9.28516 1.28516C9.16797 1.05078 8.9043 0.875 8.66992 0.875H5.30078C5.06641 0.875 4.77344 1.05078 4.68555 1.28516L4.42188 1.8125H0.90625C0.642578 1.8125 0.4375 2.04688 0.4375 2.28125V3.21875C0.4375 3.48242 0.642578 3.6875 0.90625 3.6875H13.0938C13.3281 3.6875 13.5625 3.48242 13.5625 3.21875V2.28125C13.5625 2.04688 13.3281 1.8125 13.0938 1.8125Z'
                                                    fill='currentColor'></path>
                                            </svg>
                                        </td>
                                    }
                                    {!category.access.write &&
                                        <td className='questions__table-data questions__table-data--edit'></td>}
                                </tr>
                            )})
                        }
                </tbody>
            </table>

            {confirm != null && (
                <ConfirmModal
                    msg={confirm.msg}
                    yesTxt={confirm.yes}
                    noTxt={confirm.no}
                    onYes={() => confirm.action(confirm.args)}
                    onNo={() => setState(prev => ({ ...prev, confirm: null })) }
                />
            )}

            <Drip7OriginalModal>
                {show_add_category && (
                    <InputModal
                        title={Language.getTitleCase('create category')}
                        placeholder={Language.getTitleCase('add category')}
                        add_button={Language.getTitleCase('add category')}
                        onClose={handleCreateCategory}
                    />
                )}
                {show_edit_category && (
                    <InputModal
                        title={Language.getTitleCase('edit category')}
                        placeholder={Language.getTitleCase('edit category')}
                        add_button={Language.getTitleCase('update category')}
                        usr={show_edit_category.uid}
                        message={show_edit_category.name}
                        onClose={handleEditCategory}
                    />
                )}
            </Drip7OriginalModal>
        </div>
    );
}