import React, { useEffect, useRef, useState } from "react";
import upArrow from "../../assets/images/user-portal/up-arrow.svg";
import downArrow from "../../assets/images/user-portal/down-arrow.png";
import Language from "../../helpers/language";
import {TierBadge, TierNames, TierNumber} from "../../helpers/consts";
import {ArrowUpIcon} from "@heroicons/react/20/solid";
import Util from "../../helpers/util";
import BadgePlatinum from "../../assets/images/badges/badge_platinum.png";
import BadgeGold from "../../assets/images/badges/badge_gold.png";
import BadgeSilver from "../../assets/images/badges/badge_silver.png";
import BadgeBronze from "../../assets/images/badges/badge_bronze.png";
import BadgeSteel from "../../assets/images/badges/badge_steel.png";
import BadgeWood from "../../assets/images/badges/badge_wood.png";
import { ChartBarIcon } from "@heroicons/react/24/solid";


export const TierRank = (props) => {
    const { tier, columns, percentage, tier_pop, tier_percentage } = props;

    const [state, setState] = useState({
        historic_tiers: [],
    });
    const { historic_tiers } = state;

    useEffect(() => {
        Util.fetch_js('/client/tier/history/', { weeks: 4 },
            js => {
                setState(prev => ({...prev,
                    historic_tiers: js.tiers,
                }))
            })
    }, [])

    //there is a probably an algo that would be cleaner, but this was way easier...
    const mobile_tier_layout = {
        Diamond:    [1, 2, 3, 4, 5],
        Platinum:   [1, 2, 3, 4, 5],
        Gold:       [1, 2, 3, 4, 5],
        Silver:     [2, 3, 4, 5, 6],
        Bronze:     [3, 4, 5, 6, 7],
        Steel:      [3, 4, 5, 6, 7],
        Wood:       [3, 4, 5, 6, 7],
    }

    const chart = useRef(null);
    const chartWidth = useRef(null)

    const tier_perc = Math.min( Math.max( tier_percentage, 0.1 ), 99 )
    const ready_percentage = (tier_perc >= 2)? Math.round( tier_perc ): tier_perc.toFixed(1)

    const is_desktop = chartWidth.current !== null && chartWidth.current.clientWidth > 544

    const loadGraph = () => {
        if ( columns.length === 0 || percentage === null ) {
            return;
        }

        if (chart.current != null) {
            chart.current.destroy();
        }

        const options = {
            series: [{
                name: 'User Engagement',
                data: columns
            }],
            chart: {
                height: 350,
                width: (is_desktop)? 512: 256,
                type: 'bar',
                toolbar: {
                    show: false,
                },
                zoom: {
                    enabled: false
                },
            },
            plotOptions: {
                bar: {
                    borderRadius: 10,
                    dataLabels: {
                        position: 'top', // top, center, bottom
                    },
                }
            },
            dataLabels: {
                enabled: true,
                formatter: function (val) {
                    return val
                },
                offsetY: -20,
                style: {
                    fontSize: '12px',
                    colors: ["#304758"]
                }
            },

            xaxis: {
                categories: [0,1,2,3,4,5,6,7].map( x => (is_desktop)? `${x} of 7 ${x === 0 || x === 7? 'days': ''}`: x),
                position: 'top',
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false
                },
                crosshairs: {
                    fill: {
                        type: 'gradient',
                        gradient: {
                            colorFrom: '#D8E3F0',
                            colorTo: '#BED1E6',
                            stops: [0, 100],
                            opacityFrom: 0.4,
                            opacityTo: 0.5,
                        }
                    }
                },
                tooltip: {
                    enabled: true,
                }
            },
            yaxis: {
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false,
                },
                labels: {
                    show: false,
                    formatter: function (val) {
                        return val;
                    }
                }

            },
            title: {
                text: (is_desktop)? `${Language.getSentenceCase(TierNames[tier-1])} ${Language.getString("Tier Engagement")}`: '',
                floating: true,
                offsetY: 330,
                align: 'center',
                style: {
                    color: '#444'
                }
            },
            fill: {
                type: 'gradient',
                gradient: {
                    shade: 'light',
                    type: "vertical",
                    shadeIntensity: 0.1,
                    colorFrom: '#41c8ff',
                    colorTo: '#41c8ff',
                    gradientToColors: undefined,
                    inverseColors: true,
                    opacityFrom: 0.95,
                    opacityTo: 0.85,
                },
            },
        };

        chart.current = new ApexCharts(document.querySelector("#tier_chart"), options);
        chart.current.render();
    };

    useEffect(() => {
        loadGraph();
    }, [columns, percentage]);

    //tier-chart-indicator margin-left goes from 10% TO 87%, any more or less will move the indicator off the graph

    const convertPercentage = (tier, percentage) => {
        percentage = Math.min(1, Math.max(0, percentage / 100))

        //const perc = (tier + percentage) / 9
        const perc = percentage
        if ( is_desktop ) {
            return Math.round( (1 - perc) * 5 + perc * 93) + '%'
        }
        else {
            return Math.round( (1 - perc) * 9 + perc * 89) + '%'
        }
    }

    /*
    <ArrowUpIcon className="w-6 h-6 text-green-500" />
    <h3 className="tier-card-title">{Language.getSentenceCase('Tier 3') +' '+ Language.getSentenceCase('in top') +' '+ '48%'}</h3>
     */

    return (
        <div ref={chartWidth} className="card userdash-component tier-card shadowed mt-5 mx-2 sm:mx-6">
            <div className="flex flex-column justify-center items-center h-24 w-full">
                {tier > 0 && <p className="h-10 m-0 font-semibold text-lg">
                    {Language.getSentenceCase(TierNames[tier - 1])} {Language.getString("tier")}
                    <span className="hidden sm:inline">
                        {Language.getString(tier_percentage <= 50 ? ' top' : ' bottom')} {ready_percentage}%
                        of {Util.condenseNumber( tier_pop )} {Language.getString("users")}
                    </span>
                </p>}
                {/* Desktop */}
                <div className="m-0 flex-row justify-center items-center gap-2 hidden sm:flex">
                    {TierNames.map( ( n, idx ) => {
                        if ( tier !== idx + 1 ) {
                            return (<div key={`tier_names_${idx}`}
                                         className="w-10 h-10 relative">
                                <img src={TierBadge[n]} className="w-auto"
                                     alt={n}/>
                                <div
                                    className="absolute inset-0 flex items-center justify-center text-white z-10">
                                    <p className="text-lg">{idx + 1}</p>
                                </div>
                            </div>)
                        }
                        else {
                            return (<div key={`tier_names_${idx}`}
                                         className="w-16 h-16 relative">
                                    <img src={TierBadge[n]}
                                         className="mt-1 w-auto" alt={n}/>
                                    <div className="absolute inset-0 flex items-center justify-center text-white z-10">
                                        <p className="text-lg">{idx + 1}</p>
                                    </div>
                                </div>)
                        }
                    } ).reverse()}
                </div>
                {/* mobile */}
                <div className="m-0 flex flex-row justify-center items-center gap-2 sm:hidden">
                    {mobile_tier_layout[TierNames[tier-1]].map( ( n, idx ) => {
                        if ( tier !== n ) {
                            return (<div key={`tier_names_${idx}`}
                                         className="w-10 h-10 relative">
                                <img src={TierBadge[TierNames[n-1]]} className="w-auto" alt={n}/>
                                <div className="absolute inset-0 flex items-center justify-center text-white z-10">
                                    <p className="text-lg">{n}</p>
                                </div>
                            </div>)
                        }
                        else {
                            return (<div key={`tier_names_${idx}`}
                                         className="w-16 h-16 relative">
                                    <img src={TierBadge[TierNames[n-1]]}
                                         className="mt-1 w-auto" alt={n}/>
                                    <div
                                        className="absolute inset-0 flex items-center justify-center text-white z-10">
                                        <p className="text-lg">{n}</p>
                                    </div>
                                </div>)
                        }
                    } ).reverse()}
                </div>
            </div>
            <div className="mobile-collapse">
                <span className="font-semibold text-lg sm:hidden">
                    {Language.getSentenceCase("Engagement this week")}
                </span>
                <div className="tier-chart-container">
                    {columns.length > 0 && percentage !== null &&
                        <div className="tier-chart-indicator"
                                                style={{ marginLeft: convertPercentage( tier, percentage ) }}
                    />}
                    <div className="tier-chart-inner mt-8">
                        {columns.length > 0 && percentage !== null &&
                        <div className="tier-chart-background sm:border-r"></div>
                        }
                        <div id="tier_chart">
                            {columns.length === 0 || percentage === null &&
                            <ChartBarIcon className="w-64 h-64" style={{ color: 'var(--drip7-primary-color)'}} />
                            }
                        </div>
                    </div>
                </div>

                <div className="tier-card-col ml-5 pr-5">
                    {historic_tiers.map( ( hist, idx ) => (
                        <div key={`tier_history_${idx}`}
                             className={Util.classNames(
                                            "tier-card-container flex flex-row items-center border-t border-gray-300",
                                            (idx === 0 ? 'sm:border-t-0' : ''), )}>
                            <div className="tier-category">
                                {idx === 0 &&
                                    <div className="tier-category-date">
                                        {Language.getSentenceCase( 'Last Week' )}
                                    </div>}
                                {idx > 0 &&
                                    <div className="tier-category-date">
                                        {`${idx + 1} ${Language.getSentenceCase( 'Weeks ago' )}`}
                                    </div>}
                            {hist.tier_value > 0 && <>
                                <p className="h-10 m-0 hidden md:block">
                                    {Language.getSentenceCase( hist.tier )} {Language.getString("tier")}
                                    {/*of {Util.condenseNumber(hist.population)} users*/}
                                    {Language.getString(hist.percentage <= 50 ? ' top' : ' bottom')} {Util.boundedPercentage( hist.percentage ).ready_percentage}%
                                    {/*of {Util.condenseNumber(hist.population)} users*/}
                                </p>
                                <p className="h-10 m-0 sm:hidden">
                                    {Language.getSentenceCase( hist.tier )} {Language.getString("tier")}
                                    {Language.getString(hist.percentage <= 50 ? ' top' : ' bottom')} {Util.boundedPercentage( hist.percentage ).ready_percentage}%
                                    {/*of {Util.condenseNumber(hist.population)} users*/}
                                </p>
                            </>}
                        </div>
                        <div className="w-12 h-12 relative">
                            <img src={TierBadge[hist.tier]} className="w-auto"
                                 alt={`Tier badge ${hist.tier}`}/>
                            <div
                                className="-mt-1 absolute inset-0 flex items-center justify-center text-white z-10">
                                <p className="text-lg">{parseInt(hist.tier_value)}</p>
                            </div>
                        </div>
                    </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
