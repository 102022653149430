import Util from "../helpers/util";

import { ExclamationCircleIcon } from '@heroicons/react/20/solid'

export const Drip7Input = props => {
    const id = props.id || ""
    const name = props.name || ""
    const mode = props.mode || "input"
    const label = props.label || props.title || ""
    const type = props.type || "text"
    const placeholder = props.placeholder || ""
    const disabled = props.disabled || false
    const autoFocus = props.autoFocus || false
    const error = props.error || ""
    const value = props.value || ""
    const onChange = props.onChange || (() => {})
    const onFocus = props.onFocus || (() => {})
    const onBlur = props.onBlur || (() => {})
    const onKeyDown = props.onKeyDown || (() => {})
    const className = props.className || ""
    const children = props.children

    const isComponent = obj => {
        if (obj === undefined || obj === null) {
            return false
        }

        //Check if string
        if (typeof obj === 'string' || obj instanceof String) {
            return obj.toString().replace(/\s/g, '').length > 0
        }
        else if (Array.isArray(obj) && obj.length > 0) {
            return isComponent(obj[0])
        }
        else {
            //Return true if its an object
            return obj.constructor === Object
        }
    }

    const has_first_icon = isComponent( children )
    const has_second_icon = children && Array.isArray(children) && children.length > 1

    if (mode === "error") {
        return (
            <div>
                <label htmlFor={name}
                       className='basic-modal__label'>
                    {label}
                </label>
                <div className="relative mt-1 rounded-md">
                    <input
                        id={id}
                        name={name}
                        type={type}
                        placeholder={placeholder}
                        aria-invalid="true"
                        aria-describedby={`${name}-error`}
                        className={Util.classNames("h-10 block w-full rounded-md border-0 py-1.5 pr-10 text-red-900 ring-1 ring-inset ring-red-300 placeholder:text-red-300 focus:ring-inset focus:ring-red-500 sm:text-sm sm:leading-6", className)}
                        value={value}
                        onChange={onChange}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        onKeyDown={onKeyDown}
                    />
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                        <ExclamationCircleIcon aria-hidden="true"
                                               className="h-5 w-5 text-red-500"/>
                    </div>
                </div>
                <p id={`${name}-error`} className="mt-2 text-sm text-red-600">
                    {error}
                </p>
            </div>
        )
    }
    else if ( mode === "disabled" ) {
        return (
            <div className={className}>
                {label &&
                    <label htmlFor={name}
                        className='basic-modal__label'>
                        {label}
                    </label>
                }
                <div className="relative rounded-md shadow-sm">
                    {children && !has_second_icon &&
                        <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                            {children}
                        </div>
                    }
                    {has_second_icon &&
                        <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                            {children[0]}
                        </div>
                    }
                    <input
                        defaultValue={value}
                        id={id}
                        name={name}
                        type={type}
                        disabled
                        placeholder={placeholder}
                        className={Util.classNames(
                            "block w-full rounded-md border-0 py-2.5 text-gray-900 ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-inset focus:ring-drip7 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200 sm:text-sm sm:leading-6",
                            has_first_icon ? "pl-12" : "",
                            has_second_icon ? "pr-12": "",
                        )}
                    />
                    {has_second_icon &&
                        <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                            {children[1]}
                        </div>
                    }
                </div>
            </div>
        )
    }
    else { //Filled and catch all
        return (
            <div className={className}>
                {label &&
                <label htmlFor={name}
                    className='basic-modal__label'>
                    {label}
                </label>
                }
                <div className="relative rounded-md shadow-sm">
                    {children && !has_second_icon &&
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                        {children}
                    </div>
                    }
                    {has_second_icon &&
                        <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                            {children[0]}
                        </div>
                    }
                    <input
                        id={id}
                        name={name}
                        type={type}
                        placeholder={placeholder}
                        className={Util.classNames(
                            "block w-full rounded-md border-0 py-2.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-inset focus:ring-drip7 sm:leading-6 disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200",
                            has_first_icon ? "pl-12" : "",
                            has_second_icon ? "pr-12": "",
                        )}
                        value={value}
                        disabled={disabled}
                        autoFocus={autoFocus}
                        onChange={onChange}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        onKeyDown={onKeyDown}
                        />
                    {has_second_icon &&
                        <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                            {children[1]}
                        </div>
                    }
                </div>
            </div>
        )
    }
}

