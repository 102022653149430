import React, {useEffect, useRef, useState} from "react";
import {useStore} from "../../store";
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import  droplet  from "../../assets/images/shared/droplet.svg";
import step1 from "../../assets/images/shared/whatsnext/step1_admin.png";
import step2 from "../../assets/images/shared/whatsnext/step2_tenant.png";
import step3 from "../../assets/images/shared/whatsnext/step3_users.png";
import step4 from "../../assets/images/shared/whatsnext/step4_queue.png";

export const WhatsNext = (props) => {
    const { onPathChange, showToast } = props;

    const { usr_info } = useStore((x) => x);
    const [state, setState] = useState({});
    const { } = state;

    const colors = ['#FA5C3C','#1482E6','#87DCAA','#41C8FF','#1F4760']

    useEffect(() => {
        onPathChange("whats-next");
    }, [])

    const handleReady = (event) => {
        showToast('test', 'success');
    }
    const stepitems = [
        {
            img: step1,
            text: 'Click on your profile in the upper right and choose \'Switch to Admin\'.',
        },
        {
            img: step2,
            text: 'Click on Tenants on the left side bar, then check that your colors and icons are set appropriately. Also verify your Main Information.',
        },
        {
            img: step3,
            text: 'Click on Users, then add users using a CSV file or add them manually',
        },
        {
            img: step4,
            text: 'Finally, click on Queue, and set up a queue and a queue track to begin training your users.',
        },
    ]
    return (
        <div className='user-portal__main-container user-portal__main-container--badge-status'>
            <div className='badge-status__heading mobile-heading'>
                <h1>Welcome to Drip7</h1>
                <h2>Here are the steps needed to start training your organization</h2>
            </div>
            <div className='whatsnext-top'>
                <Splide
                hasTrack={ false } 
                options={ {
                    drag:'free',
                    rewind: true,
                    arrows: true,
                    pagination: true,
                    snap: true,
                    start:0,
                } }>
                    <SplideTrack>
                        {stepitems.map((elem,idx)=>{
                            return(<SplideSlide className="slide-step" key={idx}>                
                                <div className='card desktop-card' >
                                    <span className='card-question'>Step {idx+1}. { elem.text }</span> 
                                    <img src={ elem.img }/>
                                </div>
                            </SplideSlide>  
                        )})}
                    </SplideTrack>
                </Splide>
            </div>
        </div>
    );
}
