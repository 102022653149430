import React, { useEffect, useState } from "react";

import { useStore } from "../../store";
import { ToggleWidget } from "../../components/admin/toggle_widget";
import { LeaderBoardCard } from "../../cards/leaderboard_card";
import { UserProgressCard } from "../../cards/user_progress_card";
import { ContentCard } from "../../cards/content_card";
import { PhishCard } from "../../cards/phish_card";
import { QuestionActivityCard } from "../../cards/question_activity_card";
import { UserActivityCard } from "../../cards/user_activity_card";
import { ActiveQueueCard } from "../../cards/active_queue_card";
import { Languages } from "../../helpers/consts";
import Language from "../../helpers/language";
import SETTINGS from "../../assets/images/shared/settings.svg"


export const Dashboard = (props) => {
    const { tenant_uid, tenants, showToast } = props

    const { usr_info } = useStore(x => x)

    const [state, setState] = useState({
        show_widget_settings: false,
        show_single: true,
    })
    const { show_widget_settings, show_single } = state

    useEffect(() => {
        props.onPathChange("dashboard");
    }, [])

    const handleChange = (e) => {
        setState(prev => ({ ...prev,
            [e.target.id]: e.target.value
        }))
    }
    
    const { is_msp } = usr_info.tenant
    const tenant = tenants.find( x => x.uid == tenant_uid ) || {}
    const has_phishing = is_msp || tenant.has_phishing

    return (
        <>
            {/* 67 and 68 wrap and hide widget settings */}
            {false && (
                <div>
                    {show_widget_settings && (
                        <div className='widgets-settings'>
                            <div className='widgets-settings-wrap'>
                                <div className='save-widgets-buttons'>
                                    <button
                                        className='clear-button'
                                        onClick={this.handleToggleWidgetSettings}>
                                        {Language.getTitleCase('cancel')}
                                    </button>
                                    <button
                                        className='solid-button'
                                        onClick={this.handleSaveChanges}>
                                        {Language.getTitleCase('Save Changes')}
                                    </button>
                                </div>

                                <div className='widgets-wrap'>
                                    <div className='active-widgets widget-toggles'>
                                        <h3>{Language.getTitleCase('active widgets')} (x):</h3>

                                        <ToggleWidget
                                            name='Question Activity'
                                            checked={true}
                                        />
                                        <ToggleWidget
                                            name='Most active users'
                                            checked={true}
                                        />
                                        <ToggleWidget
                                            name='User progress'
                                            checked={true}
                                        />
                                        <ToggleWidget
                                            name='Complete user list'
                                            checked={true}
                                        />
                                    </div>

                                    <div className='available-widgets widget-toggles'>
                                        <h3>{Language.getTitleCase('available widgets')} (x):</h3>

                                        <ToggleWidget
                                            name='User Counter'
                                            checked={false}
                                        />
                                        <ToggleWidget
                                            name='Leaderboard'
                                            checked={false}
                                        />
                                        <ToggleWidget
                                            name='Inactive user list'
                                            checked={false}
                                        />
                                        <ToggleWidget
                                            name='Department Stats'
                                            checked={false}
                                        />
                                        <ToggleWidget
                                            name='Admin list'
                                            checked={false}
                                        />
                                        <ToggleWidget
                                            name='Available user'
                                            checked={false}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}

            {tenants.length > 1 && usr_info.tenant_uid == tenant_uid &&
            <div className='admin-header-wrap'>
                <div className='widgets-settings-button'
                    onClick={() => handleChange({ target: { id: 'show_single', value: !show_single }})}>
                    <img src={SETTINGS} alt='settings button' />
                    <h4>
                        {(show_single)? Language.getTitleCase('my tenant'): Language.getTitleCase('aggregate')}
                    </h4>
                </div>
            </div>
            }

            <div className='admin-card__container'>
                <UserActivityCard
                    usr_info={usr_info}
                    tenant_uid={tenant_uid}
                    tenants={tenants}
                    cascade={!show_single}
                    showToast={showToast}
                />
            </div>

            <div className='admin-card__container'>
                { has_phishing && 
                <PhishCard
                    usr_info={usr_info}
                    cascade={!show_single}
                    tenant_uid={tenant_uid}
                    showToast={showToast}
                />}

                <UserProgressCard
                    usr_info={usr_info}
                    cascade={!show_single}
                    tenant_uid={tenant_uid}
                    showToast={showToast}
                />
            </div>

            <div className='admin-card__container'>
                <QuestionActivityCard
                    usr_info={usr_info}
                    cascade={!show_single}
                    tenant_uid={tenant_uid}
                    tenants={tenants}
                />
            </div>

            <div className='admin-card__container'>
                {/* <ContentCard
                    usr_info={usr_info}
                    tenant_uid={tenant_uid}
                    tenants={tenants}
                    showToast={showToast}
                /> */}

                <ActiveQueueCard
                    cascade={!show_single}
                    tenant_uid={tenant_uid}
                    showToast={showToast}
                />
                {/* <LeaderBoardCard
                    usr_info={usr_info}
                    tenant_uid={tenant_uid}
                    tenants={tenants}
                    showToast={showToast}
                /> */}
            </div>

        </>
    )
}
