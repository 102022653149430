import React, { useEffect, useState } from "react";
import { Drip7TextArea } from "../drip7_textarea";

export const AnswerTypeInlineArea = (props) => {
    const { name, value, onChanged } = props;

    return (
        <Drip7TextArea
            className='daily-questions__fitb'
            placeholder='Type Answer Here'
            name={name}
            value={value}
            onChange={onChanged}
        />
    );
}
