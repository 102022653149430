import React, { useEffect, useState } from "react";
import Util from "../../helpers/util";

export const AnswerTypeButtons = React.forwardRef((props, ref) => {
    const { answer, value, onChanged } = props;

    const is_answered = (value != undefined && value != null && Util.xint(value, -1) != -1)

    React.useImperativeHandle(ref, () => ({
        reset: () => {
        },
        attemptAnswer: () => {
            return false;
        }
    }));

    const calcValues = (answer, value) => {
        const safe_value = Util.xint( value )

        //Is this answer now correct?
        const ans_correct = ('correct' in answer && answer.correct != undefined && answer.correct != null)? answer.correct: safe_value
        const ans_val = (('answers' in answer && ans_correct >= 0 && ans_correct < answer.answers.length)? answer.answers[ans_correct]: "") || ""
        const is_correct = (ans_correct == -1) || (ans_correct == safe_value)

        return {
            safe_value,
            ans_correct,
            ans_val: ans_val.replace(/[;].*$/, ''),
            is_correct
        }
    }

    const handleChange = (e) => {
        const { safe_value, ans_correct, ans_val, is_correct } = calcValues( answer, e.target.value )

        //Update my info
        onChanged( safe_value, is_correct, ans_val)
    }

    const klass = idx => {
        const { safe_value, ans_correct, ans_val, is_correct } = calcValues( answer, value )

        if ( !is_answered ) {
            return "daily-questions__answer--large";
        }

        if (safe_value == idx && !is_correct) {
            return "daily-questions__answer--large daily-questions__answer--incorrect";
        }
        if(ans_correct == idx || (is_correct && safe_value == idx) ) {
            return "daily-questions__answer--large daily-questions__answer--correct"
        }

        return "daily-questions__answer--large";
    };

    const answers = ('answers' in answer)? answer.answers: ["Correct"]
    const answer_split = answers.map( x => x.split(/\s*[;]+\s*/) )

    return (
        <div className='daily-questions__answers daily-questions__answers--large'>
            {answer_split.map((txt, idx) => (
                <div
                    key={'buttons_' + idx}
                    className={klass(idx)}
                    onClick={() => handleChange({ target: { value: idx }})}>
                    <div className='daily-questions__answer--text'>
                        <div>{txt[0]}</div>
                        <div>{txt[1]}</div>
                    </div>
                    <div className='daily-questions__answer--chevron'>
                        <i className='drop-chevron drop-chevron--answer' ></i>
                    </div>
                </div>
            ))}
        </div>
    );
});
