import React, { useEffect, useState } from "react";

/**
 * @author Giuliano Antonanzas
 * @param {{children:React.DetailedHTMLProps<React.LiHTMLAttributes<HTMLLIElement>, HTMLLIElement>[]}}
 */
const Categories = ({ children }) => {
    const [openStyle, setOpenStyle] = useState();
    useEffect(() => {
        setTimeout(() => {
            setOpenStyle("active");
        }, [100]);
    }, []);

    return (
        <div className={`categories ${openStyle || ""}`}>
            <div className='content'>
                <ul>{children}</ul>
            </div>
        </div>
    );
};

export default Categories;
