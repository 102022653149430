//import '../assets/scss/main.scss'
//import '../assets/css/tailwind.css'

import React from "react";
import {Routes, Route, useNavigate} from "react-router-dom";

import { Header } from "../components/admin/header";
import { Sidebar } from "../components/admin/sidebar";
import { Toast } from "../components/toast";

import Util from "../helpers/util";
import { useStore } from "../store";

import { Dashboard } from "./admin/dashboard";
import { Reports } from "./admin/reports";
import { Avatar } from "./admin/avatar";
import { UsersSetup } from "./admin/users_setup";
import { Tenants } from "./admin/tenants";
import { Styles } from "./admin/styles";
import { Content } from "./admin/content";
import { Phish } from "./admin/phish";
import { Badges } from "./admin/badges";
import { ManualPoints } from "./admin/manual_points";
import { Curriculum } from "./admin/curriculum";
import { Queue } from "./admin/queue";
import { Notification } from "./admin/notification";
import { setTenantColors } from "../helpers/content";
import {DocumentAcceptance} from "./admin/document_acceptance";

//State wrapper
const withStore = BaseComponent => props => {
    const { usr_info } = useStore(state => ({ usr_info: state.usr_info }));
    const navigate = useNavigate()
    return <BaseComponent {...props}
                          usr_info={usr_info}
                          navigate={navigate} />;
};

class Klass extends React.Component {
    constructor(props) {
        super(props);

        let tenant_uid = "";
        let initial_tenants = [];
        if ( "tenant" in props.usr_info && typeof props.usr_info.tenant != "undefined" && props.usr_info.tenant != null ) {
            initial_tenants.push(props.usr_info.tenant);
            if (tenant_uid == "") {
                tenant_uid = props.usr_info.tenant.uid;
            }
        }

        this.state = {
            toast: { msg: "", status: "" },
            path: "",
            tenants: initial_tenants,
            tenant_uid,
            is_sidebar_open: false,
            show_adv_sidebar: false,
        };

        this.handleContext = this.handleContext.bind(this);
        this.handleToast = this.handleToast.bind(this);
        this.handleToastComplete = this.handleToastComplete.bind(this);
        this.handleRefreshMspTenants = this.handleRefreshMspTenants.bind(this);
        this.handleToggleSidebar = this.handleToggleSidebar.bind(this)
        this.handleToggleAdvanced = this.handleToggleAdvanced.bind(this)
    }

    componentDidMount() {
        this.confirmAccess();

        this.handleRefreshMspTenants();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.confirmAccess();
    }

    confirmAccess() {
        const { navigate, usr_info } = this.props;
        if (!["Admin", "Super Admin"].includes(usr_info.role)) {
            navigate("/dashboard");
        }
    }

    handleRefreshMspTenants(callback=null) {
        //Grab all MSPs and Tenants
        Util.fetch_js( "/tenant/list/", {},
            js => {
                this.setState({ tenants: js.tenants });
                if (callback) {
                    callback();
                }
            },
            (reason, code) => {
                this.handleToast(reason, "failure");
            }
        );
    }

    handleContext(tenant_uid) {
        const { tenants } = this.state;

        const tenant = tenants.find(x => x.uid == tenant_uid);

        //Set the colors
        if (tenant) {
            setTenantColors( tenant )
        }

        this.setState({ tenant_uid });
    }

    handleToggleSidebar() {
        this.setState({ is_sidebar_open: !this.state.is_sidebar_open })
    }

    handleToggleAdvanced() {
        this.setState({ show_adv_sidebar: !this.state.show_adv_sidebar })
    }

    handleToast(msg, status) {
        const toast = { msg, status };
        this.setState({ toast });
    }

    handleToastComplete() {
        const toast = { msg: "", status: "" };
        this.setState({ toast });
    }

    render() {
        const { navigate, usr_info } = this.props;
        const { path, tenants, tenant_uid, toast, is_sidebar_open, show_adv_sidebar } = this.state;

        document.title = `Drip7 | ${Util.namify(path)}`;

        //No role info yet
        if (!["Admin", "Super Admin"].includes(usr_info.role)) {
            return <></>;
        }

        return (
            <div className={`admin-page`}>
                <Header
                    tenant_uid={tenant_uid}
                    tenants={tenants}
                    is_sidebar_open={is_sidebar_open}
                    showToast={this.handleToast}
                    onContext={this.handleContext}
                />
                <Sidebar
                    path={path}
                    tenant_uid={tenant_uid}
                    tenants={tenants}
                    is_sidebar_open={is_sidebar_open}
                    onToggleSidebar={this.handleToggleSidebar}
                    show_adv_sidebar={show_adv_sidebar}
                    onToggleAdvanced={this.handleToggleAdvanced}
                />
                <main className={`admin-main ${is_sidebar_open ? "active" : ""}`} id='main'>
                    <Routes>
                        <Route path='/users-setup' element={
                            <UsersSetup
                                tenant_uid={tenant_uid}
                                tenants={tenants}
                                showToast={this.handleToast}
                                onPathChange={path => this.setState({ path })}
                            />
                        }/>
                        <Route path='/reports' element={
                            <Reports
                              tenant_uid={tenant_uid}
                              tenants={tenants}
                              showToast={this.handleToast}
                              onRefreshMspsTenants={ this.handleRefreshMspTenants }
                              onPathChange={path => this.setState({ path })}
                            />
                        }/>
                        <Route path='/policies' element={
                            <DocumentAcceptance
                                tenant_uid={tenant_uid}
                                tenants={tenants}
                                showToast={this.handleToast}
                                onRefreshMspsTenants={ this.handleRefreshMspTenants }
                                onPathChange={path => this.setState({ path })}
                            />
                        }/>
                        <Route path='/styles' element={
                            <Styles
                                tenant_uid={tenant_uid}
                                tenants={tenants}
                                showToast={this.handleToast}
                                onRefreshMspsTenants={ this.handleRefreshMspTenants }
                                onPathChange={path => this.setState({ path })}
                                onContext={this.handleContext}
                            />
                        }/>
                        <Route path='/tenants' element={
                            <Tenants
                                tenant_uid={tenant_uid}
                                tenants={tenants}
                                showToast={this.handleToast}
                                onRefreshMspsTenants={ this.handleRefreshMspTenants }
                                onPathChange={path => this.setState({ path })}
                                onContext={this.handleContext}
                            />
                        }/>
                        <Route path='/notification' element={
                            <Notification
                                tenant_uid={tenant_uid}
                                tenants={tenants}
                                showToast={this.handleToast}
                                onPathChange={path => this.setState({ path })}
                            />
                        }/>
                        <Route path='/content' element={
                            <Content
                                tenant_uid={tenant_uid}
                                tenants={tenants}
                                showToast={this.handleToast}
                                onPathChange={path => this.setState({ path })}
                            />
                        }/>
                        <Route path='/avatar' element={
                            <Avatar
                                tenant_uid={tenant_uid}
                                tenants={tenants}
                                showToast={this.handleToast}
                                onPathChange={path => this.setState({ path })}
                            />
                        }/>
                        <Route path='/phish' element={
                            <Phish
                                tenant_uid={tenant_uid}
                                tenants={tenants}
                                showToast={this.handleToast}
                                onPathChange={path => this.setState({ path })}
                            />
                        }/>
                        <Route path='/curriculum' element={
                            <Curriculum
                                tenant_uid={tenant_uid}
                                tenants={tenants}
                                showToast={this.handleToast}
                                onPathChange={path => this.setState({ path })}
                            />
                        }/>
                        <Route path='/queue' element={
                            <Queue
                                tenant_uid={tenant_uid}
                                tenants={tenants}
                                showToast={this.handleToast}
                                onPathChange={path => this.setState({ path })}
                            />
                        }/>
                        <Route path='/badges' element={
                            <Badges
                                tenant_uid={tenant_uid}
                                tenants={tenants}
                                showToast={this.handleToast}
                                onPathChange={path => this.setState({ path })}
                            />
                        }/>
                        <Route path='/manual-points' element={
                            <ManualPoints
                                tenant_uid={tenant_uid}
                                tenants={tenants}
                                showToast={this.handleToast}
                                onPathChange={path => this.setState({ path })}
                            />
                        }/>
                        <Route path='*' element={
                            <Dashboard
                                tenant_uid={tenant_uid}
                                tenants={tenants}
                                showToast={this.handleToast}
                                onPathChange={path => this.setState({ path })}
                            />
                        }/>
                    </Routes>
                </main>

                <Toast
                    msg={toast.msg}
                    status={toast.status}
                    timeout='5000'
                    onComplete={this.handleToastComplete}
                />
            </div>
        );
    }
}

export const AdminMain = withStore(Klass);
