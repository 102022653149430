import React, { useState, useEffect, useCallback, forwardRef, useImperativeHandle } from 'react'

export const UserInteractionDetection = forwardRef((props, ref) => {
    const { onUserInteraction } = props
    const once = props.once || false

    const [lastPosition, setLastPosition] = useState({ x: 0, y: 0 })
    const [totalDistance, setTotalDistance] = useState(-1)
    const [touchStart, setTouchStart] = useState({ x: 0, y: 0 })
    const [scrollPosition, setScrollPosition] = useState({ x: 0, y: 0 })
    const [totalScroll, setTotalScroll] = useState(0)
    const occurred = React.createRef(false) //Required since state gets lost

    const MOVEMENT_THRESHOLD = 100 // pixels
    const SWIPE_THRESHOLD = 50 // pixels
    const SCROLL_THRESHOLD = 100 // pixels

    const calculateDistance = (x1, y1, x2, y2) => {
        return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2))
    }

    const handleMouseMove = useCallback((e) => {
        const distance = (totalDistance >= 0)?
            calculateDistance(
                lastPosition.x,
                lastPosition.y,
                e.clientX,
                e.clientY
            ) : -totalDistance

        setTotalDistance(prev => prev + distance)
        setLastPosition({ x: e.clientX, y: e.clientY })

        if (totalDistance > MOVEMENT_THRESHOLD) {
            if ( !once || !occurred.current ) {
                onUserInteraction( `Mouse moved ${Math.round( totalDistance )} pixels` )
            }
            occurred.current = true
        }
    }, [lastPosition, totalDistance])

    const handleScroll = useCallback((e) => {
        const newScrollX = window.scrollX
        const newScrollY = window.scrollY

        // Calculate scroll distance since last position
        const scrollDistance = calculateDistance(
            scrollPosition.x,
            scrollPosition.y,
            newScrollX,
            newScrollY
        )

        setTotalScroll(prev => prev + scrollDistance)
        setScrollPosition({ x: newScrollX, y: newScrollY })

        if (scrollDistance > SCROLL_THRESHOLD) {
            const direction = newScrollY > scrollPosition.y ? 'down' : 'up'
            if ( !once || !occurred.current ) {
                onUserInteraction(`Scrolled ${direction} (${Math.round(totalScroll)} pixels total)`)
            }
            occurred.current = true
        }
    }, [scrollPosition])

    const handleTouchStart = (e) => {
        const touch = e.touches[0]
        setTouchStart({ x: touch.clientX, y: touch.clientY })
    }

    const handleTouchEnd = (e) => {
        const touch = e.changedTouches[0]
        const distance = calculateDistance(
            touchStart.x,
            touchStart.y,
            touch.clientX,
            touch.clientY
        )

        if (distance > SWIPE_THRESHOLD) {
            const deltaX = touch.clientX - touchStart.x
            const deltaY = touch.clientY - touchStart.y

            if ( !once || !occurred.current ) {
                if (Math.abs(deltaX) > Math.abs(deltaY)) {
                    onUserInteraction(deltaX > 0 ? 'Swiped right' : 'Swiped left')
                } else {
                    onUserInteraction(deltaY > 0 ? 'Swiped down' : 'Swiped up')
                }
            }
            occurred.current = true
        }
    }

    const handleWheel = useCallback((e) => {
        const wheelDistance = Math.abs(e.deltaY)
        setTotalScroll(prev => prev + wheelDistance)

        if (wheelDistance > 0) {
            const direction = e.deltaY > 0 ? 'down' : 'up'
            if ( !once || !occurred.current ) {
                onUserInteraction(`Mouse wheel ${direction} (${Math.round(wheelDistance)} pixels)`)
            }
            occurred.current = true
        }
    }, [])

    useImperativeHandle(ref, () => ({
        handleTouchStart,
        handleTouchEnd,
        handleScroll,
        handleWheel,
        reset: () => {
            setLastPosition({ x: 0, y: 0 })
            setTotalDistance(0)
            setTouchStart({ x: 0, y: 0 })
            setScrollPosition({ x: 0, y: 0 })
            setTotalScroll(0)
        }
    }))

    useEffect(() => {
        window.addEventListener('mousemove', handleMouseMove)
        window.addEventListener('scroll', handleScroll)
        window.addEventListener('wheel', handleWheel)

        return () => {
            window.removeEventListener('mousemove', handleMouseMove)
            window.removeEventListener('scroll', handleScroll)
            window.removeEventListener('wheel', handleWheel)
        }
    }, [handleMouseMove, handleScroll, handleWheel])

    return (<> </>)
})
    /*
        <div className="p-4 space-y-4">
            <Alert>
                <AlertCircle className="h-4 w-4" />
                <AlertTitle>Movement Detection</AlertTitle>
                <AlertDescription>
                    {lastEvent}
                </AlertDescription>
            </Alert>

            <div
                className="w-64 h-64 bg-blue-100 rounded-lg flex items-center justify-center touch-none"
                onTouchStart={handleTouchStart}
                onTouchEnd={handleTouchEnd}
            >
                Touch area for swipe detection
            </div>
        </div>
    )
})
     */