import React, {useState, useEffect, useRef} from "react";
import Language from "../helpers/language";
import Util from "../helpers/util";
import Chart from "react-apexcharts";


export const UserProgressCard = props => {
    const { cascade, tenant_uid, showToast } = props;

    const chart = useRef(null)
    const [state, setState] = useState({
        local_tenant_uid: '',
        local_cascade: null,
    });
    const { local_tenant_uid, local_cascade } = state

    const loadGraph = (series, labels) => {
        if (chart.current != null) {
            chart.current.destroy();
        }

        const options = {
            series,
            chart: {
                width: 310,
                type: "pie"
            },
            plotOptions: {
                pie: {
                    dataLabels: {
                        offset: -6,
                        minAngleToShowLabel: 32
                    }
                }
            },
            colors: [
                "#87DCAA", "#1482E6", "#41C8FF", "#FD6E6E"
            ],
            labels,
            responsive: [
                {
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: "bottom"
                        }
                    }
                }
            ],

            tooltip: {
                y: {
                    formatter: function(val) {
                        return ''
                    },
                    title: {
                        formatter: function (seriesName) {
                            return seriesName
                        }
                    }
                }
            }
        };

        chart.current = new ApexCharts(
            document.querySelector("#usercard_chart"),
            options
        )
        chart.current.render()
    };

    useEffect(() => {
        //Bail
        if (tenant_uid == "" || (tenant_uid == local_tenant_uid && cascade == local_cascade)) {
            return;
        }

        //Query the stats
        Util.fetch_js( "/dashboard/user_status/", { tenant_uid, cascade },
            js => {
                const series = [
                    js.completed,
                    js.inprogress,
                    js.behind,
                    js.inactive
                ]
                const labels = [
                    Language.getTitleCase('completed') + `: ${js.completed}`,
                    Language.getTitleCase('In Progress') + `: ${js.inprogress}`,
                    Language.getTitleCase('behind') + `: ${js.behind}`,
                    Language.getTitleCase('inactive') + `: ${js.inactive}`
                ]
                loadGraph( series, labels )
            },
            (err, code) => {
                showToast(err, 'failure');
            }
        );

        setState(prev => ({...prev,
            local_tenant_uid: tenant_uid,
            local_cascade: cascade,
        }))
    }, [props]);

    //destroy card on unmounting - removes console errors
    useEffect(() => {
        return () => {
            if ( chart.current != null ) {
                chart.current.destroy()
            }
        }
    }, [])

    return (
        <div
            className='admin-card admin-card--user-count'
            style={{minWidth: 300, maxWidth: 350}}>
            <h3>{Language.getTitleCase('User Progress')}</h3>
            <div id='usercard_chart'></div>
        </div>
    );
};
