import React from "react";
import {TrainingSection} from './v3_training_section.js'
import  MultipleChoiceImg  from "../../assets/images/user-portal/multiple-choice-question.svg";
import Language from "../../helpers/language";


export const NextTraining = (props) => {
    const { duration, question, questionCount } = props;

    const content = 'content' in question ? question.content.content : { text: ""}

    //Remove http links
    const text = content.text.substring(0, 64).replace(/(https?:\/\/[^\s]+)/g, '')

    return (
        <div className="flex flex-col bg-white card userdash-component next-lesson-card shadow-md w-80 sm:w-full">
            <h3 className="text-gray-600 font-medium text-lg tracking-[0.2em]">
                {Language.getSentenceCase('NEXT TRAINING')}
            </h3>
            <div className="center-divider mt-3">
                <div className="card-divider"></div>
            </div>   
            <div className="centered-div">
                <img src={MultipleChoiceImg} alt='img'></img>
            </div>
            {questionCount === 0 &&
                <p className="card-question">
                    {Language.getSentenceCase("You're all caught up!")}
                </p>
            }
            {questionCount > 0 &&
            <p className="card-question">
                {text}
                ...
            </p>
            }
            <TrainingSection 
                totalTime={Math.round(duration / 60,1)}
                questionCount={questionCount}
            />
      </div>
    );
  };