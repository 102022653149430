import React, { useEffect, useState } from "react";

import { useStore } from "../store";
import Util from "../helpers/util";
import Language from "../helpers/language";

export const AvatarModal = props => {
    const [usr_info, setUsrInfo] = useStore(state => [
        state.usr_info,
        state.setUsrInfo
    ]);

    const { avatar_info, user_uid, showToast, onClose } = props;
    const [state, setState] = useState({
        profile_url: props.profile_url,
        avatars: avatar_info,
        avatar_uid: null,
        save_enabled: false,
        fileRef: React.useRef(),
        filename: "",
        raw_file: null
    });

    const { profile_url, avatars, save_enabled, fileRef } = state;

    //Load all the avatars
    useEffect(() => {
        if (avatars == null || avatars == undefined) {
            Util.fetch_js( "/avatar/list/", {},
                js => {
                    setState({ ...state, avatars: js.avatars });
                },
                (err, code) => {
                    showToast(err, 'failure');
                }
            );
        }
    }, []);

    const handleFileClick = () => {
        fileRef.current.click();
    };

    const handleFileChange = e => {
        const raw_file = e.target.files[0];
        setState({
            ...state,
            save_enabled: true,
            profile_url: URL.createObjectURL(raw_file),
            avatar_uid: null,
            filename: raw_file.name,
            raw_file
        });
    };

    const handleAvatar = idx => {
        const avatar = avatars[idx];

        setState({
            ...state,
            save_enabled: true,
            profile_url: avatar.image,
            avatar_uid: avatar.uid,
            filename: null
        });
    };

    const handleSave = e => {
        let payload = { user_uid };

        //Figure out what we're going to modify
        if (state.avatar_uid != null) {
            payload["avatar_uid"] = state.avatar_uid;
        }
        else if (state.filename != null) {
            payload["image"] = state.raw_file;
        }
        else {
            return;
        }

        //Upload the new profile
        Util.fetch_js( "/human/modify/", payload,
            js => {
                showToast(Language.getSentenceCase('Updated Profile'), 'successful');
                setUsrInfo({
                    ...usr_info,
                    profile_url: js.usr_info.profile_url
                });
                onClose();
            },
            (err, code) => {
                showToast(err, 'failure');
            }
        );
    };

    return (
        <div className='up-modal up-modal--change-avatar'>
            <header className='up-modal__header'>
                <h3 className='up-modal__heading'>{Language.getSentenceCase('Change Avatar')}</h3>
                <svg
                    className='up-modal__close'
                    width='12'
                    height='11'
                    viewBox='0 0 12 11'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                    onClick={onClose}>
                    <path
                        d='M7.93359 5.375L10.8633 2.44531C11.2441 2.09375 11.2441 1.50781 10.8633 1.15625L10.2188 0.511719C9.86719 0.130859 9.28125 0.130859 8.92969 0.511719L6 3.44141L3.04102 0.511719C2.68945 0.130859 2.10352 0.130859 1.75195 0.511719L1.10742 1.15625C0.726562 1.50781 0.726562 2.09375 1.10742 2.44531L4.03711 5.375L1.10742 8.33398C0.726562 8.68555 0.726562 9.27148 1.10742 9.62305L1.75195 10.2676C2.10352 10.6484 2.68945 10.6484 3.04102 10.2676L6 7.33789L8.92969 10.2676C9.28125 10.6484 9.86719 10.6484 10.2188 10.2676L10.8633 9.62305C11.2441 9.27148 11.2441 8.68555 10.8633 8.33398L7.93359 5.375Z'
                        fill='currentColor'></path>
                </svg>
            </header>

            <div className='up-modal__body'>
                <div className='up-modal__user-avatar-wrapper'>
                    <img
                        src={profile_url}
                        alt='profile image'
                        style={{ width: "120px" }}
                    />
                    {
                        //hide this for now
                        false && (
                            <div className='up-modal__user-avatar-upload'>
                                <a href='#' onClick={handleFileClick}>
                                    {Language.getTitleCase('updatephoto')}
                                </a>
                            </div>
                        )
                    }
                    <input
                        type='file'
                        ref={node => (fileRef.current = node)}
                        onChange={handleFileChange}
                        style={{ display: "none" }}
                    />
                </div>

                <div className='up-modal__default-avatars'>
                    {avatars != null &&
                        avatars != undefined &&
                        Object.entries(avatars).map(([key, avatar]) => (
                            <div
                                className='up-modal__default-avatar-wrapper'
                                key={"avatar_id_" + key}
                                onClick={() => handleAvatar(key)}>
                                <img
                                    className='up-modal__default-avatar'
                                    src={avatar.image}
                                    alt={avatar.name}
                                    style={{ width: "120px", height: "120px" }}
                                />
                            </div>
                        ))}
                </div>

                <div className='up-modal__buttons'>
                    <button
                        type='button'
                        className='up-modal__button up-modal__button--cancel'
                        onClick={onClose}>
                        {Language.getSentenceCase('cancel')}
                    </button>
                    <button
                        type='button'
                        className='up-modal__button up-modal__button--save'
                        disabled={!save_enabled}
                        onClick={handleSave}>
                        {Language.getTitleCase('Save Avatar')}
                    </button>
                </div>
            </div>
        </div>
    );
};
