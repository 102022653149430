import React from "react";
import Language from "../../helpers/language";
import { Drip7TextArea } from "../drip7_textarea";
import { Drip7Input } from "../drip7_input";

export const ContentPhishWidget = props => {
    const { access, question, content, showToast, onChange } = props;
    const question_uid = question.uid || "";
    const subject = content.subject || "";
    const body = content.body || "";

    const handleChange = e => {
        if ( !access.write ) {
            return
        }

        onChange('content', { ...content, [e.target.id]: e.target.value })
    };

    return (
        <div className="width-100 m-v-sm">
            <Drip7Input
                className='basic-modal__field width-100 m-b-sm'
                label={Language.getTitleCase('subject')}
                placeholder={Language.getSentenceCase('Phish subject')}
                id='subject'
                value={subject}
                readOnly={!access.write}
                onChange={handleChange}
            />
            <Drip7TextArea
                className='basic-modal__field width-100'
                styleTextarea={{ height: 400 }}
                placeholder={Language.getSentenceCase('Phish content here')}
                id='body'
                name='body'
                readOnly={!access.write}
                onChange={handleChange}
                value={body}
            >
                {Language.getSentenceCase('body')} ({" "} 
                    <a
                        href='/static/replacement_codes.pdf'
                        target='_blank'
                        rel='noopener'>
                        {Language.getTitleCase('View Codes')}
                    </a>{" "}
                    )
            </Drip7TextArea>
        </div>
    );
};
