import React, { useEffect, useState } from "react";
import { PhotoIcon, UserCircleIcon } from '@heroicons/react/24/solid'
import {SketchPicker} from "react-color";
import Language from "../../helpers/language";
import {Menu} from "@headlessui/react";
import {ChevronDownIcon, ExclamationCircleIcon} from "@heroicons/react/20/solid";
import Util from "../../helpers/util";
import {CheckCircleIcon} from "@heroicons/react/24/outline";
import { sha256 } from "js-sha256";
import { Behavior } from "../../helpers/consts";
import Drip7 from "../../assets/images/shared/drip7-logo.svg";


export const OnboardingModules = React.forwardRef((props, ref) => {
    const { frequency, modules, onContinue, showToast } = props

    const [state, setState] = useState(
        ('init' in props && props.init != null && 'module_uids' in props.init )? props.init: {
            module_uids: props.module_uids,
    })
    const {module_uids} = state

    React.useImperativeHandle(ref, () => ({
        attemptContinue: handleContinue
    }));

    useEffect(() => {
        window.scrollTo({ top: 0 });
    }, []);

    const handleContinue = () => {
        onContinue({
            module_uids,
            state
        })
    }

    const handleToggleContentModule = (uid) => {
        //Create a hash of the current module uids, forcing the package modules to always be selected
        const uids = {}
        props.module_uids.forEach( u => {
            uids[u] = true
        })

        //Figure out if we should add or remove the module
        if ( module_uids.indexOf(uid) >= 0 ) {
            module_uids.forEach( u => {
                if ( u != uid ) {
                    uids[u] = true
                }
            })
        }
        else {
            module_uids.forEach( u => {
                uids[u] = true
            })
            uids[uid] = true
        }

        //Store the keys
        setState( prev => ({ ...prev,
            module_uids: Object.keys(uids),
        }))
    }

    const discounts = modules.map( p =>
        Math.round( ((p['price_1month'] > 0)? Math.abs((p[frequency] - p.price_1month) / p.price_1month): 0) * 100 )
    )

    return (
        <div className="bg-white p-10 space-y-10 divide-y divide-gray-900/10">
            {modules.map((module, idx) => (
            <div key={`module_${idx}`} className="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3 pt-10">
                <div className="px-4 sm:px-0">
                    <h2 className="flex justify-between text-base font-semibold leading-7 text-gray-900">
                        {module.name}
                        {discounts[idx] > 0 &&
                        <p className="rounded-full bg-green-600/10 px-2.5 py-1 text-xs font-semibold text-green-600 flex items-center">
                            {`Save ${discounts[idx]}%`}
                        </p>
                        }
                    </h2>
                    <p className="mt-1 text-sm leading-6 text-gray-600">
                        {module.description}
                    </p>
                </div>

                <div className="bg-white sm:rounded-xl md:col-span-2">
                    <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <div className="sm:col-span-4">
                            <div className={Util.classNames(
                                    "overflow-hidden rounded-xl",
                                    module_uids.indexOf(module.uid) >= 0? "ring-2 ring-drip7 border-drip7": "ring-1 ring-gray-200",
                                )}
                                onClick={() => handleToggleContentModule(module.uid)}>
                                <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
                                    <img src={(module.compact_logo === '')? Drip7: module.compact_logo}
                                         alt={module.name}
                                         className="h-auto w-20 flex-none rounded-lg bg-white object-cover ring-1 ring-gray-900/10" />
                                    <div className="text-sm font-medium leading-6 text-gray-900">
                                        {module.name}
                                    </div>
                                </div>
                                <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
                                    {module.info.map((x, idx) => (
                                        <div key={`${module.uid}_${idx}`} className="flex justify-between gap-x-4 py-3">
                                            <dt className="text-gray-500">{x.key}</dt>
                                            <dd className="text-gray-700">
                                                <div className="font-medium text-gray-900">{x.value}</div>
                                            </dd>
                                        </div>
                                    ))}
                                    <div className="flex justify-between gap-x-4 py-3">
                                        <dt className="text-gray-500">Categories</dt>
                                        <dd className="text-gray-700">
                                            <div className="font-medium text-gray-900">{module.categories.length}</div>
                                        </dd>
                                    </div>
                                    <div className="flex justify-between gap-x-4 py-3">
                                        <dt className="text-gray-500">{(module.behavior == Behavior.PHISHING_MODULE)? "Phishes": "Questions"}</dt>
                                        <dd className="text-gray-700">
                                            <div className="font-medium text-gray-900">{module.total_question_count}</div>
                                        </dd>
                                    </div>
                                    <div className="flex justify-between gap-x-4 py-3">
                                        <dt className="text-gray-500">Price</dt>
                                        <dd className="text-gray-700">
                                            <div className="font-medium text-gray-900">
                                                {Util.toCurrency(module[frequency], false)}&nbsp;/month
                                            </div>
                                        </dd>
                                    </div>
                                </dl>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            ))}

            <div className="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3 lg:pt-10">
                <div className="px-4 sm:px-0">
                    <h2 className="text-base font-semibold leading-7 text-gray-900">
                    </h2>
                    <p className="mt-1 text-sm leading-6 text-gray-600">
                    </p>
                </div>

                <div className="bg-white sm:rounded-xl md:col-span-2">
                    <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <div className="sm:col-span-4">
                            <div className={Util.classNames(
                                "overflow-hidden",
                            )}
                                 onClick={() => handleToggleContentModule(module.uid)}>


                                <div className="flex items-center justify-end gap-x-6 mb-5">
                                    <button
                                        type="button"
                                        onClick={handleContinue}
                                        className={
                                            (true) ?
                                                "rounded-md bg-drip7 px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2":
                                                "rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                                        }>
                                        Continue
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})