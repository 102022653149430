import React, { useEffect, useRef, useState } from "react";
import { useStore } from "../../store";
import { AvatarCard } from "../../components/user/v3_avatar";
import { TierRank } from "../../components/user/v3_tier_rank";
import Categories from "../../components/user/categories";
import userLogo from "../../assets/images/user-portal/user_logo.png";
import Language from "../../helpers/language";
import Util from "../../helpers/util";
import { TierNumber } from "../../helpers/consts";


export const Tier = (props) => {
    const { onPathChange, showToast } = props;

    const { usr_info } = useStore((x) => x);
    const [state, setState] = useState({
        show_companies: false,
        tier: TierNumber.Wood,
        columns: [0,0,0,0,0,0,0,0],
        percentage: null,

        tier_pop: 0,
        tier_percentage: 100,
    });
    const { show_companies, tier, columns, percentage, tier_pop, tier_percentage } = state;

    useEffect(() => {
        onPathChange("tier");

        Util.fetch_js('/client/tier/list/', {},
            js => {
                const { tier, columns, percentage, tier_pop, tier_percentage } = js;

                setState(prev => ({...prev,
                    tier,
                    columns,
                    percentage,

                    tier_pop,
                    tier_percentage,
                }))
            }, showToast )
    }, [])

    const exampleCompanies = ["drip7", "drip8", "drip9", "drip4", "drip1"];
    const handleToggleShowcompanies = () => {
        setState({
            ...state,
            show_companies: !show_companies,
            show_lb: false,
        });
    };

    const now = new Date();

    return (
        <div className="tier-container">
            <div className="tier-top">
                <div className="tier-background">
                    <h1 className="tier-title">
                        {Util.toLongMonth(now.getMonth()) +' '+ now.getFullYear() +' ' + Language.getSentenceCase('Tier')}
                    </h1>

                    {false && <div
                        className="user-portal__filter-wrapper user-portal__filter-wrapper--leaderboard tier-chart-company"
                        role="button"
                        aria-expanded={show_companies}
                        tabIndex="1"
                        onClick={handleToggleShowcompanies}
                    >
                        <span
                            style={{ cursor: "pointer" }}
                            onClick={handleToggleShowcompanies}
                        >
                            {"My Company"}
                        </span>
                        <div
                            className={`user-portal__filter-button user-portal__filter--leaderboard user-portal__filter-button expand`}
                        >
                            <div
                                id="show_departmentFilter"
                                onClick={handleToggleShowcompanies}
                            >
                                <svg
                                    width="12"
                                    height="20"
                                    viewBox="0 0 10 6"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M1.5332 5.50977 C1.00586 5.50977 0.742188 4.86524 1.12305 4.48438 L4.87305 1.12305 C5.10742 0.5 5.48828 0.5 5.72266 1.12305 L9.47266 4.48438 C9.85352 4.86524 9.58984 5.50977 9.0625 5.50977 H1.5332 Z"
                                        fill="black"
                                    ></path>
                                </svg>
                            </div>

                            {show_companies && (
                                <Categories>
                                    <p className="hidden-heading" tabIndex={-1}>
                                        Clicking on the following checkboxes
                                        will update the content in main section.
                                    </p>
                                    {exampleCompanies?.map((company) => (
                                        <li key={company}
                                            className="user-portal__filter user-portal__filter--department" >
                                            <div className="user-portal__filter-checkbox">
                                                <input
                                                    type="checkbox"
                                                    aria-label="checkbox"
                                                    name={company.toLowerCase()}
                                                    id={
                                                        "department-filter--" +
                                                        company.toLowerCase()
                                                    }
                                                    // checked={company in company_filter}
                                                    onChange={() =>
                                                        handlecompanyToggle(
                                                            company
                                                        )
                                                    }
                                                />
                                                <label
                                                    htmlFor={`department-filter--${company.toLowerCase()}`}
                                                >
                                                    {company}
                                                </label>
                                            </div>
                                        </li>
                                    ))}
                                </Categories>
                            )}
                        </div>
                    </div>
                    }
                </div>
                <div className="tier-background">
                    <AvatarCard
                        name={usr_info.nickname || usr_info.name}
                        tenant={usr_info.tenant?.name}
                        tier={usr_info.tier}
                        img={usr_info.profile_url}
                    />
                    <div className="tier-scroll"></div>
                </div>
            </div>
            <TierRank
                tier={tier}
                columns={columns}
                percentage={percentage}
                tier_pop={tier_pop}
                tier_percentage={tier_percentage}
            />
        </div>
    );
};
