import React, { useRef, useState } from "react";

import Language from "../helpers/language";


//Define the admin header
export const SelectItems = props => {
    const { selected, items, title, searchTitle, hideSearch, hideAll, onChange } = props

    let st = Language.getTitleCase('search')
    if ( searchTitle != undefined && searchTitle != null ) {
        st = Language.getTitleCase(searchTitle)
    }

    const [state, setState] = useState({
        drag: useRef(),
        search: '',
    })
    const { search, drag } = state

    //set a default ref?
    if ( drag.current == null ) {
        drag.current = {src: null, dst: null}
    }

    const lc_search = search.toLowerCase()
    const entries = (search != "")? items.filter(x => x.name.toLowerCase().indexOf(lc_search) >= 0): items

    const handleChange = (e) => {
        setState(prev => ({...prev, [e.target.name]: e.target.value }))
    }

    const handleSelectAll = () => {
        onChange( entries.map( x => x.uid ))
    }

    const handleUnselectAll = () => {
        onChange( [] )
    }

    const handleAddSelected = (uid) => {
        onChange( selected.concat( [uid] ))
    }

    const handleRemoveSelected = (uid) => {
        onChange( selected.filter(x => x != uid) )
    }

    const handleDragStart = (e) => {
        drag.current.src = e.target.id
    }

    const handleDragAllow = (e) => {
        drag.current.dst = e.target.id
        e.preventDefault()
    }

    const handleDragDrop = (e) => {
        const { src, dst } = drag.current

        //Can we do a DnD?
        const s_idx = selected.indexOf( src )
        if ( dst != null && src != null && s_idx >= 0 ) {
            const uid = selected[s_idx]
            selected.splice( s_idx, 1 )

            //Find where the target lives, if not found, Append
            const t_idx = selected.indexOf( dst )
            if ( t_idx >= 0 ) {
                selected.splice(t_idx, 0, uid)
            }
            else {
                selected.push( uid )
            }
        }

        //Reset my DnD state
        drag.current = { src: null, dst: null }
        onChange( selected )
    }

    //Setup my lookups
    let lookup = {}
    entries.forEach( x => {
        lookup[x.uid] = x
    })

    let sel_lookup = {}
    selected.forEach( x => {
        sel_lookup[x] = true
    })

    //Filter down my dataset
    const sel = selected.filter(x => x in lookup).map( x => lookup[x] )
    const unsel = entries.filter(x => !(x.uid in sel_lookup))

    return (
        <div className='create-group'>
            <div className='central__filters'>
            {!hideSearch &&
                <div className='search-bar-container'>
                    <i className='icon search-icon'></i>
                    <input
                        className='search-input'
                        type='search'
                        placeholder={st}
                        name='search'
                        value={search}
                        onChange={handleChange}
                    />
                </div>
            }

                {props.children}
            </div>

            <div className='create-group__users-container'>
                <div className='create-group__users'>
                    <h5>
                        {Language.getTitleCase('selected')}
                        &nbsp;
                        {Language.getTitleCase(title)} {Object.keys(sel).length}
                        {!hideAll && <>
                            &nbsp; ( <a onClick={handleUnselectAll} href="#">{Language.getSentenceCase('all')}</a> )
                        </>}
                    </h5>
                    <ul 
                    className='create-group__users-list ' 
                    tabIndex='0'
                    >
                        {Object.entries(sel).map( ([key, item]) => (
                            <li
                                tabIndex='0'
                                className='create-group__users-item'
                                key={"selected_" + key}
                                id={item.uid}
                                draggable={search == ""}
                                onDragStart={handleDragStart}
                                onDragEnter={handleDragAllow}
                                onDragOver={handleDragAllow}
                                onDragEnd={handleDragDrop}
                                onClick={() => handleRemoveSelected( item.uid )}>
                                {item.name}
                            </li>
                        ))}
                        <li
                            className='create-group__users-item'
                            key={"selected_-1"}
                            id={""}
                            draggable={search == ""}
                            onDragEnter={handleDragAllow}
                            onDragOver={handleDragAllow}
                            onDragEnd={handleDragDrop}>
                            &nbsp;
                        </li>
                    </ul>
                </div>

                <svg
                    aria-hidden='true'
                    focusable='false'
                    data-prefix='fas'
                    data-icon='exchange-alt'
                    className='svg-inline--fa fa-exchange-alt fa-w-16'
                    role='img'
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 512 512'>
                    <path
                        fill='currentColor'
                        d='M0 168v-16c0-13.255 10.745-24 24-24h360V80c0-21.367 25.899-32.042 40.971-16.971l80 80c9.372 9.373 9.372 24.569 0 33.941l-80 80C409.956 271.982 384 261.456 384 240v-48H24c-13.255 0-24-10.745-24-24zm488 152H128v-48c0-21.314-25.862-32.08-40.971-16.971l-80 80c-9.372 9.373-9.372 24.569 0 33.941l80 80C102.057 463.997 128 453.437 128 432v-48h360c13.255 0 24-10.745 24-24v-16c0-13.255-10.745-24-24-24z'></path>
                </svg>

                <div className='create-group__users'>
                    <h5>
                        {Language.getTitleCase('unselected')}
                        &nbsp;
                        {Language.getTitleCase(title)}
                        {!hideAll && <>
                            &nbsp; ( <a onClick={handleSelectAll} href="#">{Language.getSentenceCase('all')}</a> )
                        </> }
                    </h5>
                    <ul 
                    className='create-group__users-list'
                     tabIndex='0'
                     >
                        {Object.entries(unsel).map( ([key, item]) => (
                            <li
                                tabIndex='0'
                                className='create-group__users-item'
                                key={"selected_" + key}
                                onClick={() => handleAddSelected( item.uid ) }>
                                {item.name}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};
