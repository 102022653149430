export const CrashTest = (props) => {
    const cause = undefined
    throw new Error("Sentry test error");
    return (
        <div className='crash-test'>
            <h1>Crash Test</h1>
            <p>Crash test</p>
            <div>{cause.crash}</div>
        </div>
    );
}
