import React, { useEffect } from 'react';
import { Fragment, useState } from 'react'
import { Transition } from '@headlessui/react'
import { CheckCircleIcon, InformationCircleIcon } from '@heroicons/react/24/outline'
import { XMarkIcon, ExclamationCircleIcon } from '@heroicons/react/20/solid'
import Util from "../helpers/util.js";

//Define my view compon
export const Toast = (props) => {
    const { msg, timeout } = props
    const onComplete = props.onComplete || (() => {})
    const status = (props.status !== Util.xint( props.status ))? Util.xstr(props.status).toLowerCase(): "failure"

    useEffect(() => {
        if ( msg == "" ) {
            return
        }

        setTimeout(() => { onComplete() }, timeout)
    }, [msg])

    //If there is nothing, then show nothing
    if ( msg == "" ) {
        return (
            <></>
        );
    }

    if ( status != "success" ) {
        console.log(status +": "+ msg );
    }

    //Define the mode to be used below
    let mode = "info"
    if ( status == "success" || status == "successful" ) {
        mode = "success"
    }
    else if ( status == "error" || status == "failed" || status == "failure" || status == "invalid" || status == "fail" ) {
        mode = "error"
    }

    return (
        <div aria-live="assertive"
            className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 z-50">
            <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
                {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
                <Transition
                    show={true}
                    as={Fragment}
                    enter="transform ease-out duration-300 transition"
                    enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                    enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                        <div className="p-4">
                            <div className="flex items-start">
                                {mode == "success" && <>
                                    <div className="flex-shrink-0">
                                        <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />
                                    </div>
                                    <div className="ml-3 w-0 flex-1 pt-0.5">
                                        <p className="text-sm font-medium text-gray-900">
                                            {Util.capitalize(status)}
                                        </p>
                                        <p className="mt-1 text-sm text-gray-500">
                                            {msg}
                                        </p>
                                    </div>
                                </>}
                                {mode == "error" && <>
                                    <div className="flex-shrink-0">
                                        <ExclamationCircleIcon className="h-6 w-6 text-red-400" aria-hidden="true" />
                                    </div>
                                    <div className="ml-3 w-0 flex-1 pt-0.5">
                                        <p className="text-sm font-medium text-gray-900">
                                            {Util.capitalize(status)}
                                        </p>
                                        <p className="mt-1 text-sm text-gray-500">
                                            {msg}
                                        </p>
                                    </div>
                                </>}
                                {mode == "info" && <>
                                    <div className="flex-shrink-0">
                                        <InformationCircleIcon className="h-6 w-6 text-indigo-400" aria-hidden="true" />
                                    </div>
                                    <div className="ml-3 w-0 flex-1 pt-0.5">
                                        <p className="text-sm font-medium text-gray-900">
                                            {Util.capitalize(status)}
                                        </p>
                                        <p className="mt-1 text-sm text-gray-500">
                                            {msg}
                                        </p>
                                    </div>
                                </>}
                                <div className="ml-4 flex flex-shrink-0">
                                    <button
                                        type="button"
                                        className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                        onClick={onComplete}>
                                        <span className="sr-only">Close</span>
                                        <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Transition>
            </div>
        </div>
    );
}
