// Flags found here: https://www.flaticon.com/packs/international-flags-3

import React from "react";
import Language from "../helpers/language";
import { ReactComponent as DeFlag } from "../assets/images/flags/de.svg";
import { ReactComponent as EnFlag } from "../assets/images/flags/en-us.svg";
import { ReactComponent as EsFlag } from "../assets/images/flags/es.svg";
import { ReactComponent as FiFlag } from "../assets/images/flags/fi.svg";
import { ReactComponent as FrCaFlag } from "../assets/images/flags/fr-ca.svg";
import { ReactComponent as FrFlag } from "../assets/images/flags/fr.svg";
import { ReactComponent as ItFlag } from "../assets/images/flags/it.svg";
import { ReactComponent as JaFlag } from "../assets/images/flags/ja.svg";
import { ReactComponent as NlFlag } from "../assets/images/flags/nl.svg";
import { ReactComponent as NoFlag } from "../assets/images/flags/no.svg";
import { ReactComponent as PtFlag } from "../assets/images/flags/pt.svg";
import { ReactComponent as SvFlag } from "../assets/images/flags/sv.svg";
import { ReactComponent as ZhCnFlag } from "../assets/images/flags/zh-cn.svg";
import { ReactComponent as ZhTwFlag } from "../assets/images/flags/zh-tw.svg";

export const LanguageFlag = props => {
    const flags = {
        'de': <DeFlag />,
        'en-us': <EnFlag />,
        'es': <EsFlag />,
        'fi': <FiFlag />,
        'fr-ca': <FrCaFlag />,
        'fr': <FrFlag />,
        'it': <ItFlag />,
        'ja': <JaFlag />,
        'nl': <NlFlag />,
        'no': <NoFlag />,
        'pt': <PtFlag />,
        'sv': <SvFlag />,
        'zh-cn': <ZhCnFlag />,
        'zh-tw': <ZhTwFlag />
    }

    return ( flags[Language.getLanguage()] )
}