import React, { useEffect, useState } from "react";
import Util from "../../helpers/util";
import Language from "../../helpers/language";
import { UserSelectionLogic } from "../../helpers/consts";

import { queueAudienceRaw } from "../../helpers/content";
import { SelectItems } from "../../components/select_items";
import {Drip7Dropdown} from "../../components/drip7_dropdown";
import { Drip7TextArea } from "../../components/drip7_textarea";

export const NotificationMessageModal = (props) => {
    const { tenant_uid, showToast, onClose } = props

    const [state, setState] = useState({
        message: (props.notification != true && 'uid' in props.notification)? props.notification.custom_message: '',
        selected_users: [],
        selected_groups: [],
        users: [],
        groups: [],
        queues: [],
        queue_uid: '',
        push: true,
        sms: false,
        email: true,
        show_audience: false,
        criteria: UserSelectionLogic.ALL,
        allow_send: true,
    })
    const { message, selected_users, selected_groups, users, groups, queues, queue_uid, show_audience, push, email, sms, criteria, allow_send } = state

    useEffect(() => {
        //Query details?
        if ( props.notification != true && 'uid' in props.notification ) {
            Util.fetch_js( "/notification/detail/", { notification_uid: props.notification.uid },
                js => {
                    const update = {}
                    const { delivery } = js.notification
                    Object.entries(['sms', 'push', 'email']).forEach( ([k,x]) => {
                        update[x] = ( x in delivery )? delivery[x]: state[x]
                    })

                    setState(prev => ({ ...prev,
                        ...update,
                        selected_users: js.user_uids,
                    }))

                    updateTenant( true )
                },
                (reason, code) => {
                    showToast(reason, 'failure');
                    updateTenant()
                }
            );
        }
        else {
            updateTenant()
        }

    }, [])

    const updateTenant = ( skip_update_selected = false ) => {
        //Get users and groups
        Util.fetch_js( "/human/list/", { tenant_uid },
            js => {
                if ( !skip_update_selected ) {
                    const user_uids = (selected_users.length != 0)? selected_users: js.users.map( x => x.uid )
                    setState(prev => ({ ...prev,
                        users: js.users,
                        selected_users: user_uids,
                        selected_groups: [],
                    }))
                }
                else {
                    setState(prev => ({ ...prev,
                        users: js.users,
                        selected_groups: [],
                    }))
                }
            },
            (reason, code) => {
                showToast(reason, 'failure');
            }
        );

        Util.fetch_js( "/group/list/", { tenant_uid },
            js => {
                setState(prev => ({ ...prev,
                    groups: js.groups,
                }))
            },
            (reason, code) => {
                showToast(reason, 'failure');
            }
        );

        Util.fetch_js( "/queue/list/", { tenant_uid },
            js => {
                const safe_queues = js.queues.filter( x => x.active )

                setState(prev => ({ ...prev,
                    queues: safe_queues,
                    queue_uid: "",
                }))
            }, showToast)
    }

    const handleChecked = (e) => {
        const {name, checked} = e.target

        setState(prev => ({ ...prev,
            [name]: checked
        }))
    }

    const handleChange = (e) => {
        const {name, value} = e.target

        //Are we changing user selection logic?
        if ( name == 'criteria' || name == 'queue_uid' ) {
            const criteria = (name == 'criteria')? value: state.criteria
            const queue_uid = (name == 'queue_uid')? value: state.queue_uid

            //Query for criteria
            if ( criteria != UserSelectionLogic.ALL ) {
                //Select the users
                Util.fetch_js( "/human/by_criteria/", { tenant_uid, queue_uid },
                    js => {
                        setState(prev => ({ ...prev,
                            selected_users: js[criteria]
                        }))
                    }, showToast )
            }
            else {
                setState(prev => ({ ...prev,
                    selected_users: users.map( x => x.uid )
                }))
            }
        }

        //update the state
        setState(prev => ({ ...prev,
            [name]: value
        }))
    }

    const handleSave = () => {
        if ( selected_users.length <= 0 ) {
            showToast(Language.getSentenceCase('Please select users'), 'failure');
            return;
        }

        // Create?
        const payload = {
            user_uids: selected_users,
            group_uids: selected_groups,
            message,
            delivery: {
                push,
                email,
                sms,
            }
        }
        Util.fetch_js( "/notification/send/", payload,
            js => {
                showToast(Language.getSentenceCase('Sent Message'), 'successful');
                onClose(true);
            },
            (err, code) => {
                showToast(err, 'failure');
            }
        );

        showToast(Language.getSentenceCase('Sending Message'), 'successful');
        setState(prev => ({ ...prev,
            allow_send: false,
        }))
    }

    const showStyle = (show) => {
        return ( !show ) ? { rotate: '-90deg' }: {}
    }

    const queue = ((queue_uid != null && queue_uid != "")? queues.filter( x => x.uid == queue_uid )[0]: queues[0]) || {'name': Language.getSentenceCase('Queue'), 'uid': null}

    return (
        <div className='basic-modal basic-modal--add-group'>
            <div className='basic-modal__head'>
                <h4>{Language.getTitleCase('Send Notification')}</h4>
                <svg
                    className='basic-modal__close-button'
                    width='12'
                    height='11'
                    viewBox='0 0 12 11'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                    onClick={onClose}>
                    <path
                        d='M7.93359 5.375L10.8633 2.44531C11.2441 2.09375 11.2441 1.50781 10.8633 1.15625L10.2188 0.511719C9.86719 0.130859 9.28125 0.130859 8.92969 0.511719L6 3.44141L3.04102 0.511719C2.68945 0.130859 2.10352 0.130859 1.75195 0.511719L1.10742 1.15625C0.726562 1.50781 0.726562 2.09375 1.10742 2.44531L4.03711 5.375L1.10742 8.33398C0.726562 8.68555 0.726562 9.27148 1.10742 9.62305L1.75195 10.2676C2.10352 10.6484 2.68945 10.6484 3.04102 10.2676L6 7.33789L8.92969 10.2676C9.28125 10.6484 9.86719 10.6484 10.2188 10.2676L10.8633 9.62305C11.2441 9.27148 11.2441 8.68555 10.8633 8.33398L7.93359 5.375Z'
                        fill='currentColor'></path>
                </svg>
            </div>

            <div className="queue-grid">
                <div className='basic-modal__field basic-modal__field--add-question grid-item'>
                    <label
                        className='basic-modal__label'
                        htmlFor='newQuestionCategory'>
                        {Language.getTitleCase('Selection Logic')}
                    </label>
                    <div className='dropdown dropdown--attached dropdown--add-question'>
                        <Drip7Dropdown
                            id='criteria'
                            name='criteria'
                            entries={Object.values(UserSelectionLogic)}
                            value={criteria}
                            onChange={handleChange}
                            display={x => Language.getTitleCase(x)}
                            />
                    </div>
                </div>

                {criteria != UserSelectionLogic.ALL &&
                <div className='basic-modal__field basic-modal__field--add-question grid-item'>
                    <label
                        className='basic-modal__label'
                        htmlFor='newQuestionCategory'>
                        {Language.getTitleCase('Queue')}
                    </label>
                    <div className='dropdown dropdown--attached dropdown--add-question'>
                        <Drip7Dropdown
                            id='queue_uid'
                            name='queue_uid'
                            entries={queues}
                            value={queue}
                            onChange={handleChange}
                            display={x => x.name}
                            changeValue={x => x.uid}
                            overflow='max-h-60'
                            />
                    </div>
                </div>
                }
            </div>

            <div className="queue-grid">
                <div className='basic-modal__field basic-modal__field--add-question grid-item-row' style={{ maxHeight: 'unset' }}>
                    <label className='basic-modal__label'>
                        {Language.getTitleCase("Delivery")}
                    </label>

                    <div className='customization-checkbox grid-item queue-grid--settings'>
                        <input
                            type='checkbox'
                            name='push'
                            aria-label="checkbox"
                            readOnly
                            checked={push}
                            onClick={handleChecked}
                        />
                        <span className='checkmark'></span>
                        <label htmlFor='users-can-edit'
                               onClick={x => handleChecked({ target: { name: 'push', checked: !push } })}>
                            {Language.getSentenceCase("Push")}
                        </label>
                    </div>

                    <div className='customization-checkbox grid-item queue-grid--settings'>
                        <input
                            type='checkbox'
                            name='sms'
                            aria-label="checkbox"
                            readOnly
                            checked={sms}
                            onClick={handleChecked}
                        />
                        <span className='checkmark'></span>
                        <label htmlFor='users-can-edit'
                               onClick={x => handleChecked({ target: { name: 'sms', checked: !sms } })}>
                            {Language.getSentenceCase("Sms")}
                        </label>
                    </div>

                    <div className='customization-checkbox grid-item queue-grid--settings'>
                        <input
                            type='checkbox'
                            name='email'
                            aria-label="checkbox"
                            readOnly
                            checked={email}
                            onClick={handleChecked}
                        />
                        <span className='checkmark'></span>
                        <label htmlFor='users-can-edit'
                               onClick={x => handleChecked({ target: { name: 'email', checked: !email } })}>
                            {Language.getSentenceCase('Email')}
                        </label>
                    </div>
                </div>
            </div>

            <div className='queue-grid'>
                <div className='basic-modal__field basic-modal__field--add-question grid-item--large'
                    onClick={() => handleChange({ target: { name: 'show_audience', value: !show_audience }})}>

                    <label className='basic-modal__label'>
                        {`${Language.getSentenceCase('current audience')}: ${queueAudienceRaw(selected_users.length, users.length, groups, selected_groups, false)}`}
                        &nbsp;
                        <i className='drop-chevron' style={ showStyle(show_audience) }></i>
                    </label>
                </div>
            </div>

            {show_audience && <>
                <SelectItems
                    selected={selected_users}
                    items={users}
                    title={"users"}
                    searchTitle={"search users by name"}
                    onChange={value => handleChange({ target: { name: 'selected_users', value }})}
                />

                <SelectItems
                    selected={selected_groups}
                    items={groups}
                    title={"groups"}
                    hideSearch={true}
                    hideAll={true}
                    onChange={value => handleChange({ target: { name: 'selected_groups', value }})}
                />
            </>}

            <div className='queue-grid'>
                <Drip7TextArea
                    className='basic-modal__field--add-question width-100'
                    label={Language.getTitleCase('message')}
                    placeholder={Language.getSentenceCase('Custom message to users')+'...'}
                    name='message'
                    onChange={handleChange}
                    value={message}
                />
            </div>

            <div className='basic-modal__button-container'>
                <button
                    className='basic-modal__button basic-modal__button--clear'
                    onClick={onClose}>
                    {Language.getTitleCase('cancel')}
                </button>
                <button
                    className='basic-modal__button basic-modal__button--solid'
                    disabled={!allow_send || message == ''}
                    onClick={handleSave}>
                    {Language.getTitleCase('send')}
                </button>
            </div>
        </div>
    );
}
