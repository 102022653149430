import React, { useEffect, useRef, useState } from 'react'
import { RadioGroup } from '@headlessui/react'
import { CheckCircleIcon, TrashIcon } from '@heroicons/react/20/solid'
import Util from "../../helpers/util";
import {PackageFrequency} from "../../helpers/consts";
import {useStore} from "../../store";
import {Elements, PaymentElement, useStripe} from '@stripe/react-stripe-js';
import {Spinner} from "../../components/spinner";
import {Payment} from "../../components/onboarding/payment";
import { STRIPE_URL } from "../../settings";
import {ArrowTopRightOnSquareIcon} from "@heroicons/react/24/outline";


const paymentMethods = [
    { id: 'credit-card', title: 'Credit card' },
    { id: 'paypal', title: 'PayPal' },
    { id: 'etransfer', title: 'eTransfer' },
]


export const OnboardingPayment = React.forwardRef((props, ref) => {
    const { frequency, products, tenant_info, user_info, onContinue, showToast } = props

    const { stripePromise } = useStore(x=>x)

    const [state, setState] = useState({
        per_seat: 0,
        subtotal: 0,
        taxes: 0,
        total: 0,
        address: '',
        city: '',
        region: '',
        postal_code: '',
        country: 'US',
        payment_intent: null,
        client_secret: null,
        stripe: null,
        elements: null,
        sla: false,
        tos: false,
        ...('init' in props && props.init != null && 'seats' in props.init )? props.init: { seats: 10, },
    })
    const {per_seat, seats, subtotal, taxes, total, address, city, country, region, postal_code, payment_intent, client_secret, stripe, elements, sla, tos} = state

    const months_lookup = {
        [PackageFrequency.MONTHLY]: 1,
        [PackageFrequency.YEARLY]: 12,
        [PackageFrequency.YEARLY_3]: 36,
    }
    const months = months_lookup[frequency]
    const options = (client_secret)? { clientSecret: client_secret }: null

    const calcSubtotal = (seats_, months) => {
        const seats = ( !Util.isObject(seats_) )? seats_: state.seats
        const per_seat = products.reduce((acc, product) => acc + product.price, 0)

        return seats * per_seat * months
    }

    const formRef = useRef(null);

    const {setup_fee} = products.find( p => p.subtitle === 'Package') || { setup_fee: 0 }

    React.useImperativeHandle(ref, () => ({
        attemptContinue: handleContinue
    }));

    useEffect(() => {
        window.scrollTo({ top: 0 });

        handleTaxChange( seats )
    }, []);

    useEffect(() => {
        //If we have a secret, cancel it, we'll create a new one below
        if ( payment_intent ) {
            Util.fetch_js('/client/onboarding/cancel_intent/', {payment_intent} )
        }

        const subtotal = calcSubtotal( seats, months )
        const amount = Math.ceil( subtotal )

        //Get a payment intent
        Util.fetch_js('/client/onboarding/create_intent/', { amount },
            js => {
                setState(prev => ({ ...prev,
                    payment_intent: js.payment_intent,
                    client_secret: js.client_secret,
                }))
            }, showToast )

    }, [stripePromise])

    useEffect( () => {
        if ( payment_intent === null ) {
            return
        }

        const subtotal = calcSubtotal( seats, months )
        const amount = Math.ceil( subtotal )

        //Get a payment intent
        Util.fetch_js('/client/onboarding/modify_intent/', { payment_intent, amount },
            js => {
            }, showToast )

    }, [seats] );

    const handleContinue = () => {
        onContinue({
            seats,
            state
        })
    }

    const handleTaxChange = (seats_) => {
        const seats = ( !Util.isObject(seats_) )? seats_: state.seats
        const per_seat = products.reduce((acc, product) => acc + product.price, 0)
        const subtotal = per_seat * seats * months + setup_fee

        //Tax query if we have all the info
        if ( address !== '' && city !== '' && region !== '' && postal_code !== '' ) {
            const product_uids = products.map( p => p.uid )
            Util.fetch_js( '/client/onboarding/calculate_tax/', { price_months: frequency, seats, address, city, state: region, postal_code, country: 'US', product_uids },
                js => {
                    setState( prev => ({ ...prev,
                        taxes: js.taxes,
                        total: js.total,
                    }) )
                }, showToast )
        }

        setState(prev => ({ ...prev,
            seats,
            per_seat,
            subtotal,
            taxes: 0,
            total: subtotal,
        }))
    }

    const handleChange = (e) => {
        const {name, value} = e.target
        if ( name === 'seats') {
            let safe_number = (Math.round(value) != NaN)? Math.round(value): 1
            if ( safe_number < 0 ) {
                safe_number = 1
            }

            handleTaxChange(safe_number)
        }
        else {
            setState(prev => ({ ...prev,
                [name]: value,
            }))
        }
    }

    const handleSubmit = async (event) => {
        console.log("Submitting")
        //event.preventDefault();

        const {error} = await stripe.confirmPayment({
            //`Elements` instance that was used to create the Payment Element
            elements,
            clientSecret: client_secret,
            confirmParams: {
                return_url: STRIPE_URL
            },
        });

        if (error) {
            // This point will only be reached if there is an immediate error when
            // confirming the payment. Show error to your customer (for example, payment
            // details incomplete)
        } else {
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
        }
    };

    const handleSubmitClick = (e) => {
        e.preventDefault()
        elements.submit()

        const package_uid = products.find( p => p.subtitle === 'Package').uid
        const module_uids = products.filter( p => p.subtitle !== 'Package').map( p => p.uid )
        const address_info = {
            address,
            city,
            'state': region,
            postal_code,
            country,
        }

        if (formRef.current) {
            Util.fetch_js('/client/onboarding/store_onboard/', {
                payment_intent,
                package_uid,
                module_uids,
                seats,
                frequency,
                tenant_info,
                address_info,
                user_info,
            }, js => {
                handleSubmit()
            }, showToast )
        }
    }

    const handleStripe = (stripe, elements) => {
        setState(prev => ({ ...prev,
            stripe,
            elements,
        }))
    }

    const handleOpenDoc = (e, term) => {
        e.preventDefault()
        if ( term === "sla" ) {
            window.open('/static/Drip7%20SLA%20-%202024.pdf', '_blank');
        }
        else if ( term === "tos" ) {
            window.open("https://drip7.com/terms-of-service/", '_blank');
        }
    }

    return (
        <div className="bg-white">
            <div className="mx-auto max-w-2xl px-4 pb-24 pt-16 sm:px-6 lg:max-w-7xl lg:px-8">
                <h2 className="sr-only">Checkout</h2>

                <form className="lg:grid lg:grid-cols-2 lg:gap-x-12 xl:gap-x-16" ref={formRef} onSubmit={handleSubmit}>
                    <div>
                        <div>
                            <h2 className="text-lg font-medium text-gray-900">
                                License
                            </h2>

                            <div className="mt-4">
                                <label htmlFor="number"
                                       className="block text-sm font-medium text-gray-700">
                                    Seats
                                </label>
                                <div className="mt-1">
                                    <input
                                        type="number"
                                        id="seats"
                                        name="seats"
                                        autoComplete="number"
                                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-drip7 focus:ring-drip7 sm:text-sm"
                                        value={seats}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="mt-10 border-t border-gray-200 pt-10">
                            <h2 className="text-lg font-medium text-gray-900">
                                Billing information
                            </h2>

                            <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                                <div className="sm:col-span-2">
                                    <label htmlFor="address"
                                           className="block text-sm font-medium text-gray-700">
                                        Address
                                    </label>
                                    <div className="mt-1">
                                        <input
                                            type="text"
                                            name="address"
                                            id="address"
                                            autoComplete="street-address"
                                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-drip7 focus:ring-drip7 sm:text-sm"
                                            value={address}
                                            onChange={handleChange}
                                            onBlur={handleTaxChange}
                                        />
                                    </div>
                                </div>

                                <div>
                                    <label htmlFor="city"
                                           className="block text-sm font-medium text-gray-700">
                                        City
                                    </label>
                                    <div className="mt-1">
                                        <input
                                            type="text"
                                            name="city"
                                            id="city"
                                            autoComplete="address-level2"
                                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-drip7 focus:ring-drip7 sm:text-sm"
                                            value={city}
                                            onChange={handleChange}
                                            onBlur={handleTaxChange}
                                        />
                                    </div>
                                </div>

                                <div>
                                    <label htmlFor="country"
                                           className="block text-sm font-medium text-gray-700">
                                        Country
                                    </label>
                                    <div className="mt-1">
                                        <select
                                            id="country"
                                            name="country"
                                            autoComplete="country-name"
                                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-drip7 focus:ring-drip7 sm:text-sm"
                                        >
                                            <option>United States</option>
                                            <option>Canada</option>
                                            <option>Mexico</option>
                                        </select>
                                    </div>
                                </div>

                                <div>
                                    <label htmlFor="region"
                                           className="block text-sm font-medium text-gray-700">
                                        State / Province
                                    </label>
                                    <div className="mt-1">
                                        <input
                                            type="text"
                                            name="region"
                                            id="region"
                                            autoComplete="address-level1"
                                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-drip7 focus:ring-drip7 sm:text-sm"
                                            value={region}
                                            onChange={handleChange}
                                            onBlur={handleTaxChange}
                                        />
                                    </div>
                                </div>

                                <div>
                                    <label htmlFor="postal_code"
                                           className="block text-sm font-medium text-gray-700">
                                        Postal code
                                    </label>
                                    <div className="mt-1">
                                        <input
                                            type="text"
                                            name="postal_code"
                                            id="postal_code"
                                            autoComplete="postal-code"
                                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-drip7 focus:ring-drip7 sm:text-sm"
                                            value={postal_code}
                                            onChange={handleChange}
                                            onBlur={handleTaxChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {options &&
                        <Elements stripe={stripePromise} options={options}>
                            <Payment
                                client_secret={client_secret}
                                onStripe={handleStripe}
                            />
                        </Elements>
                        }
                    </div>

                    {/* Order summary */}
                    <div className="mt-10 lg:mt-0">
                        <h2 className="text-lg font-medium text-gray-900">Order
                            summary</h2>

                        <div
                            className="mt-4 rounded-lg border border-gray-200 bg-white shadow-sm">
                            <h3 className="sr-only">Cart</h3>
                            <ul role="list"
                                className="divide-y divide-gray-200">
                                {products.map( ( product, idx ) => (
                                    <li key={`product_${idx}`}
                                        className="flex px-4 py-6 sm:px-6">
                                        <div className="flex-shrink-0">
                                            <img src={product.img_src}
                                                 alt="Product logo"
                                                 className="h-auto w-20 rounded-md"/>
                                        </div>

                                        <div
                                            className="ml-6 flex flex-1 flex-col">
                                            <div className="flex">
                                                <div className="min-w-0 flex-1">
                                                    <h4 className="text-sm">
                                                        <a href={product.href}
                                                           className="font-medium text-gray-700 hover:text-gray-800">
                                                            {product.title}
                                                        </a>
                                                    </h4>
                                                    <p className="mt-1 text-sm text-gray-500">
                                                        {product.subtitle}
                                                    </p>
                                                </div>

                                                {false && <div
                                                    className="ml-4 flow-root flex-shrink-0">
                                                    <button
                                                        type="button"
                                                        className="-m-2.5 flex items-center justify-center bg-white p-2.5 text-gray-400 hover:text-gray-500"
                                                    >
                                                        <span
                                                            className="sr-only">Remove</span>
                                                        <TrashIcon
                                                            className="h-5 w-5"
                                                            aria-hidden="true"/>
                                                    </button>
                                                </div>}
                                            </div>

                                            <div
                                                className="flex flex-1 items-end justify-between pt-2">
                                                <p className="mt-1 text-sm font-medium text-gray-900">
                                                    {Util.toCurrency( product.price )}
                                                </p>

                                                <div className="w-24">
                                                    <label htmlFor="seats"
                                                           className="sr-only">
                                                        Quantity
                                                    </label>
                                                    <input
                                                        type="number"
                                                        id="seats"
                                                        name="seats"
                                                        className="w-24 rounded-md border border-gray-300 text-left text-base font-medium text-gray-700 shadow-sm focus:border-drip7 focus:outline-none focus:ring-1 focus:ring-drip7 sm:text-sm"
                                                        value={seats}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </li>) )}
                            </ul>
                            <dl className="space-y-6 border-t border-gray-200 px-4 py-6 sm:px-6">
                                <div
                                    className="flex items-center justify-between">
                                    <dt className="text-sm">Subscription</dt>
                                    <dd className="text-sm font-medium text-gray-900">
                                        {months} {months > 1 ? 'months' : 'month'}
                                    </dd>
                                </div>
                                <div
                                    className="flex items-center justify-between">
                                    <dt className="text-sm">Per seat</dt>
                                    <dd className="text-sm font-medium text-gray-900">
                                        {Util.toCurrency(per_seat)}
                                        &nbsp;
                                        x{seats.toLocaleString('en-US')}
                                    </dd>
                                </div>
                                {setup_fee > 0 &&
                                    <div
                                        className="flex items-center justify-between">
                                        <dt className="text-sm">One time setup fee</dt>
                                        <dd className="text-sm font-medium text-gray-900">
                                            {Util.toCurrency(setup_fee)}
                                        </dd>
                                    </div>
                                }
                                <div
                                    className="flex items-center justify-between">
                                    <dt className="text-sm">Subtotal</dt>
                                    <dd className="text-sm font-medium text-gray-900">{Util.toCurrency(subtotal)}</dd>
                                </div>
                                <div
                                    className="flex items-center justify-between">
                                    <dt className="text-sm">Taxes</dt>
                                    <dd className="text-sm font-medium text-gray-900">{Util.toCurrency(taxes)}</dd>
                                </div>
                                <div
                                    className="flex items-center justify-between border-t border-gray-200 pt-6">
                                    <dt className="text-base font-medium">Total</dt>
                                    <dd className="text-base font-medium text-gray-900">{Util.toCurrency(total)}</dd>
                                </div>
                            </dl>

                            <div className="border-t border-gray-200 px-4 py-6 sm:px-6">
                                <fieldset>
                                    <legend className="sr-only">Notifications</legend>
                                    <div className="space-y-5">
                                        <div className="relative flex items-start">
                                            <div className="flex h-6 items-center">
                                                <input
                                                    id="sla"
                                                    aria-describedby="comments-description"
                                                    name="sla"
                                                    type="checkbox"
                                                    className="h-4 w-4 rounded border-gray-300 text-drip7 focus:ring-drip7"
                                                    checked={sla}
                                                    onChange={e => setState(prev => ({...prev, sla: e.target.checked}))}
                                                />
                                            </div>
                                            <div className="ml-3 text-sm leading-6">
                                                <label htmlFor="sla"
                                                       className={Util.classNames(
                                                           "inline-flex items-center cursor-pointer font-medium",
                                                           sla ? "text-gray-900" : "text-red-600 font-semibold"
                                                       )}>
                                                    Service Level Agreement (SLA)
                                                    <ArrowTopRightOnSquareIcon
                                                        className="flex-shrink-0 ml-4 h-6 w-6 text-drip7"
                                                        onClick={e => handleOpenDoc( e, 'sla' )}
                                                    />
                                                </label>
                                                <p id="comments-description"
                                                   onClick={e => setState(prev => ({...prev, sla: !sla}))}
                                                   className="text-gray-500 cursor-pointer">
                                                    Accept the Service Level Agreement for your account.
                                                </p>
                                            </div>
                                        </div>

                                        <div className="relative flex items-start">
                                            <div className="flex h-6 items-center">
                                                <input
                                                    id="tos"
                                                    aria-describedby="comments-description"
                                                    name="tos"
                                                    type="checkbox"
                                                    className="h-4 w-4 rounded border-gray-300 text-drip7 focus:ring-drip7"
                                                    checked={tos}
                                                    onChange={e => setState(prev => ({...prev, tos: e.target.checked}))}
                                                />
                                            </div>
                                            <div className="ml-3 text-sm leading-6">
                                                <label htmlFor="tos"
                                                       className={Util.classNames(
                                                           "inline-flex items-center cursor-pointer font-medium",
                                                           tos ? "text-gray-900" : "text-red-600 font-semibold"
                                                       )}>
                                                    Terms of Service (ToS)
                                                    <ArrowTopRightOnSquareIcon
                                                        className="flex-shrink-0 ml-4 h-6 w-6 text-drip7"
                                                        onClick={e => handleOpenDoc( e, 'tos' )}
                                                    />
                                                </label>
                                                <p id="comments-description"
                                                   onClick={e => setState(prev => ({...prev, tos: !tos}))}
                                                   className="text-gray-500 cursor-pointer">
                                                    Accept the Terms of Service for your account.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>

                                <button
                                    className={Util.classNames(
                                        "mt-12 w-full rounded-md border border-transparent bg-drip7 px-4 py-3 text-base font-medium text-white shadow-sm hover:bg-drip7 focus:outline-none focus:ring-2 focus:ring-drip7 focus:ring-offset-2 focus:ring-offset-gray-50",
                                        (sla && tos) ? "hover:bg-drip7" : "hover:bg-drip7 opacity-50 cursor-not-allowed"
                                    )}
                                    disabled={!sla || !tos}
                                    onClick={handleSubmitClick}
                                >
                                    Confirm order
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
})