import React, { useEffect, useState } from "react";

import Util from "../../helpers/util";
import Language from "../../helpers/language";

import {DocumentAcceptanceTab} from "./document_acceptance_tab";
import {DocumentCompletionTab } from "./document_completion_tab";
import { DocumentCheckIcon } from "@heroicons/react/24/solid";
import { Square3Stack3DIcon } from "@heroicons/react/24/outline";
import { Queue } from "./queue";

export const DocumentAcceptance = (props) => {
    const { tenant_uid, tenants, showToast } = props;

    const [state, setState] = useState({
        selected_idx: 0
    })
    const { selected_idx } = state

    useEffect(() => {
        props.onPathChange("policies")
    }, [])

    return (
        <div className='questions main-modal'>
            <nav className='central__nav'>
                <ul className='central__nav-list'>
                    <li className={"central__nav-item central__nav-item--small " + (selected_idx === 0 ? "central__nav-item--selected" : "")}
                        onClick={() => setState( prev => ({
                            ...prev,
                            selected_idx: 0
                        }) )}>
                        <svg width='15' height='12' viewBox='0 0 15 12'
                             fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path
                                d='M0.46875 2.625H12.6562C12.8906 2.625 13.125 2.41992 13.125 2.15625V1.21875C13.125 0.984375 12.8906 0.75 12.6562 0.75H0.46875C0.205078 0.75 0 0.984375 0 1.21875V2.15625C0 2.41992 0.205078 2.625 0.46875 2.625ZM14.5312 5.4375H2.34375C2.08008 5.4375 1.875 5.67188 1.875 5.90625V6.84375C1.875 7.10742 2.08008 7.3125 2.34375 7.3125H14.5312C14.7656 7.3125 15 7.10742 15 6.84375V5.90625C15 5.67188 14.7656 5.4375 14.5312 5.4375ZM12.6562 10.125H0.46875C0.205078 10.125 0 10.3594 0 10.5938V11.5312C0 11.7949 0.205078 12 0.46875 12H12.6562C12.8906 12 13.125 11.7949 13.125 11.5312V10.5938C13.125 10.3594 12.8906 10.125 12.6562 10.125Z'
                                fill='currentColor'></path>
                        </svg>
                        {Language.getTitleCase( 'Completion' )}
                    </li>
                    <li className={"central__nav-item central__nav-item--small " + (selected_idx === 1 ? "central__nav-item--selected" : "")}
                        onClick={() => setState( prev => ({
                            ...prev,
                            selected_idx: 1
                        }) )}>
                        <DocumentCheckIcon className='h-5 w-5'/>
                        {Language.getTitleCase( 'policies' )}
                    </li>
                    <li className={"central__nav-item central__nav-item--small " + (selected_idx === 2 ? "central__nav-item--selected" : "")}
                        onClick={() => setState( prev => ({
                            ...prev,
                            selected_idx: 2
                        }) )}>
                        <Square3Stack3DIcon className='h-5 w-5'/>
                        {Language.getTitleCase( 'Delivery' )}
                    </li>
                </ul>
            </nav>

            {selected_idx === 0 && (
                <DocumentCompletionTab
                    tenant_uid={tenant_uid}
                    tenants={tenants}
                    showToast={showToast}
                />)}
            {selected_idx === 1 && (
                <DocumentAcceptanceTab
                    tenant_uid={tenant_uid}
                    tenants={tenants}
                    showToast={showToast}
                />
            )}
            {selected_idx === 2 && (
                <Queue
                    tenant_uid={tenant_uid}
                    tenants={tenants}
                    showToast={showToast}
                    isPolicy={true}
                />
            )}
        </div>
    );
}
