import React from "react";

export const Loading = props => {
    const { msg } = props;
    return (
        <div className='login-wrap'>
            <figure className='logo'>
                <img
                    src='/static/images/shared/drip7-logo.svg'
                    alt='Drip7 logo'
                />
                <p>{msg}</p>
            </figure>
        </div>
    );
};
