import React, { useState } from "react";
import Language from "../../helpers/language";
import { Drip7TextArea } from "../drip7_textarea";

export const ExplanationTextWidget = props => {
    const { access, explanation, onChange } = props;
    const info = explanation.info || ""

    const handleChange = e => {
        onChange('explanation', { ...explanation, [e.target.id]: e.target.value })
    };

    return (
        <Drip7TextArea
            className='basic-modal__field--add-question width-100'
            label={Language.getTitleCase('Explanation')}
            placeholder={Language.getSentenceCase('type explanation here')+'...'}
            id='info'
            readOnly={!access.write}
            onChange={handleChange}
            value={info}
        />
    );
};
