import React, {useState} from "react";

import {BadgeCriteria} from "../../helpers/consts";
import {filterCategory} from "../../helpers/content";
import Language from "../../helpers/language";
import TrashImage from "../../assets/images/admin/trash.svg"
import {Drip7Dropdown} from "../drip7_dropdown";


export const BadgeCollectionWidget = props => {
    const { onChange, onRemove, categories, idx, collection } = props;

    let { criteria, params } = collection;

    //Get the cateogry info
    const { cat_title, question_count } = filterCategory(categories, params.category_uid)

    let spinner = null
    if ( criteria == BadgeCriteria.CORRECT_ANSWERS ||
        criteria == BadgeCriteria.TOTAL_ANSWERS ||
        criteria == BadgeCriteria.STREAK ||
        criteria == BadgeCriteria.CORRECT_STREAK ||
        criteria == BadgeCriteria.LOGIN_30_DAYS ) {
        spinner = 'count'
    }
    else if ( criteria == BadgeCriteria.TOTAL_POINTS ) {
        spinner = 'points'
    }

    const handleChange = e => {
        onChange(idx, e.target.id, e.target.value);
    };

    const handleParamsChange = e => {
        const params = {...collection.params};
        params[e.target.id] = e.target.value;
        onChange(idx, "params", params);
    };

    const handleInc = e => {
        /*
        if (params.count >= question_count) {
            return;
        }
         */

        params[spinner] += 1;
        onChange(idx, "params", params);
    };

    const handleDec = e => {
        if (params[spinner] <= 1) {
            return;
        }

        params[spinner] -= 1;
        onChange(idx, "params", params);
    };

    const show_cat = (criteria == BadgeCriteria.CORRECT_ANSWERS || criteria == BadgeCriteria.TOTAL_ANSWERS)

    return (
        <div className='fields basic-modal__question-collection' >
            <div className='basic-modal__head basic-modal__head--config' >
                <h4>
                {Language.getTitleCase('question collection')}</h4>
                <div onClick={onRemove}>
                    <img className='user-trash-icon' src={TrashImage} alt='trash' />
                </div>
            </div>
            <div className='width-100 inline-flex space-between m-b-sm'>
                <div className='basic-modal__field basic-modal__field--config'>
                    <label
                      className='basic-modal__label'
                      htmlFor='curriculumContent'>
                        {Language.getTitleCase('criteria')}
                    </label>
                    <div className='dropdown dropdown--attached dropdown--curriculum-config width-100'>
                        <Drip7Dropdown
                            id='criteria'
                            entries={Object.entries(BadgeCriteria).map(([_, bc]) => bc)}
                            onChange={handleChange}
                            value={criteria}
                            overflow='max-h-52'
                        />
                    </div>
                </div>

                {spinner && <div className='basic-modal__field basic-modal__field--config'>
                    <label
                    className='basic-modal__label'
                    htmlFor='curriculumQuantity'>
                        { Language.getSentenceCase( spinner ) }
                    </label>
                    <div className='basic-modal__input-wrapper dropdown--curriculum-config'>
                        <input
                        type='number'
                        className='basic-modal__input basic-modal__input--number'
                        id={spinner}
                        placeholder='00'
                        value={params[spinner]}
                        onChange={handleParamsChange}
                        />
                        <div className='number-button__container'>
                            <svg
                            className='number-button number-button--up'
                            width='10'
                            height='6'
                            viewBox='0 0 10 6'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                            onClick={handleInc}>
                                <path
                                d='M1.5332 0.5C1.00586 0.5 0.742188 1.14453 1.12305 1.52539L4.87305 5.27539C5.10742 5.50977 5.48828 5.50977 5.72266 5.27539L9.47266 1.52539C9.85352 1.14453 9.58984 0.5 9.0625 0.5H1.5332Z'
                                fill='#1F4760'></path>
                            </svg>
                            <svg
                            className='number-button number-button--down'
                            id='numBtnDown'
                            width='10'
                            height='6'
                            viewBox='0 0 10 6'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                            onClick={handleDec}>
                                <path
                                d='M1.5332 0.5C1.00586 0.5 0.742188 1.14453 1.12305 1.52539L4.87305 5.27539C5.10742 5.50977 5.48828 5.50977 5.72266 5.27539L9.47266 1.52539C9.85352 1.14453 9.58984 0.5 9.0625 0.5H1.5332Z'
                                  fill='#1F4760'></path>
                            </svg>
                        </div>
                    </div>
                </div>}
            </div>

            {show_cat && <div className='basic-modal__field basic-modal__field--config'>
                <label
                    className='basic-modal__label'
                    htmlFor='curriculumCategory'>
                    {Language.getTitleCase('category')}
                </label>
                <div className='dropdown dropdown--attached dropdown--curriculum-config width-100'>
                    <Drip7Dropdown
                        id='category_uid'
                        entries={['---'].concat(Object.entries(categories).map(([_, category]) => category.name))}
                        onChange={e => handleParamsChange({target:{id:'category_uid', value:(e.target.idx === 0? null: categories[e.target.idx-1].uid)}})}
                        value={cat_title}
                        overflow='max-h-52'
                    />
                </div>
            </div>}
        </div>
    );
};
