import React, {useEffect, useRef, useState} from "react"
import { useParams } from "react-router-dom";
import Util from "../../helpers/util"
import Language from "../../helpers/language"

import { DripQuestions } from "../../components/user/drip_questions"
import {useStore} from "../../store"
import {useNavigate} from "react-router-dom"
import {buildNavigation} from "../../helpers/content";

export const ReviewDrip = props => {
    const { usr_info } = useStore( x => x)
    const { title, queue_uid, showToast, onPathChange } = props

    const [state, setState] = useState({
        user_questions: []
    })
    const { user_questions } = state

    const navigate = useRef( useNavigate() )
    const navs = buildNavigation( usr_info.tenant.navigations )

    const nav_start = (navs.length >= 2)? navs[0]: "/dashboard"
    const nav_finish = (navs.length >= 2)? navs[1]: "/dashboard"

    const params = useParams()

    useEffect(() => {
        onPathChange("drip")

        const { queue_uid } = params
        Util.fetch_js( "/client/question/review/", { queue_uid },
            js => {
                const { user_questions } = js

                //console.log("Before")
                //console.log(user_questions)

                //Is there is no data, jump back to the dashboard
                if (user_questions.length <= 0) {
                    navigate.current(nav_start.path)
                }

                setState({ ...state, user_questions })
            },
            showToast )
    }, [])

    const handleCompleted = () => {
        navigate.current(nav_finish.path)
    }

    return (
        <div className='user-portal__main-container user-portal__main-container--daily-questions'>
            <div className='daily-questions__heading mobile-heading z-40'>
                <h1>{title}</h1>
            </div>

            <DripQuestions
                user_questions={user_questions}
                readOnly={true}
                showToast={showToast}
                onCompleted={handleCompleted}
            />
        </div>
    )
}
