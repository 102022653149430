import React, {useState} from "react";

import {useStore} from "../../store";
import {ProfileWidget} from "../profile_widget";

import Language from "../../helpers/language";
import {Drip7Combobox} from "../drip7_combobox";
import {Drip7Button} from "../drip7_button";
import * as Consts from "../../helpers/consts";
import {SwapTenantModal} from "../../modals/admin/swap_tenant_modal";


//Define the admin header
export const Header = props => {
    const { is_sidebar_open, tenant_uid, tenants, showToast } = props;
    const { onContext } = props;

    const { usr_info } = useStore(state => ({ usr_info: state.usr_info }));
    const { nickname, name, email, role } = usr_info;

    const [state, setState] = useState({
        profile_widget: React.createRef(),
        swap_tenant: false,
    });
    const { profile_widget, swap_tenant } = state;

    //Calculate the title
    let title = "Drip7";
    let expires = null
    for (let i = 0; i < tenants.length; i++) {
        if (tenants[i].uid == tenant_uid) {
            title = tenants[i].name;

            if (tenants[i].trial_remaining) {
                if (tenants[i].trial_remaining == 0) {
                    expires = Language.getSentenceCase('last day')
                }
                else {
                    expires = tenants[i].trial_remaining.toString() + ' ' + Language.getSentenceCase('days remaining')
                }
            }
        }
    }

    const tenant_only = tenants.length == 1;

    //Functions
    const showProfile = () => {
        if (profile_widget.current != null) {
            profile_widget.current.toggle();
        }
    };

    const handleContext = (idx) => {
        if (idx >= 0 && idx < tenants.length) {
            onContext(tenants[idx].uid)
        }
    }

    const handleSwapTenant = swap_tenant => {
        setState(prev => ({ ...prev, swap_tenant }))
    }

    return (
        <header className={`admin-header ${is_sidebar_open ? "active" : ""}`}>
            <div className='header-wrap'>
                {false && (<h2>{title}</h2>)}

                <div className='user-mini-profile' onClick={showProfile}>
                    <div className='user-mini-info'>
                        <h3 className='user-name font-semibold'>
                            {nickname || name || email}
                        </h3>
                        <p className='user-title font-semibold'>
                            {Language.getTitleCase(role)}
                        </p>
                    </div>
                    <i className='drop-chevron'></i>
                </div>

                <ProfileWidget
                    ref={profile_widget}
                    switch_to='client'
                    showToast={showToast}
                />

                {role === Consts.MemberRole.SUPER_ADMIN &&
                <Drip7Button
                    onClick={() => handleSwapTenant(true)}>
                    {Language.getSentenceCase('Transfer account')}
                </Drip7Button>
                }

                {!tenant_only &&
                    <div>
                        {/*}<label className="font-semibold">Sub Tenants</label>{*/}
                        <Drip7Combobox
                            entries={tenants.map(t => ({name: t.name, uid: t.uid, image_url: t.compact_logo}))}
                            selected_idx={tenants.findIndex(t => t.uid === tenant_uid)}
                            onSelected={handleContext}/>
                    </div>
                }
                {expires &&
                    <span>{Language.getSentenceCase('trial')}: {expires}</span>
                }
            </div>

            {role === Consts.MemberRole.SUPER_ADMIN &&
            <SwapTenantModal
                open={swap_tenant}
                tenant_uid={tenant_uid}
                onClose={() => handleSwapTenant(false)}
                showToast={showToast}
            />
            }
        </header>
    );
};
