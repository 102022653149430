import React, { useState } from "react";
import { useStore } from "../../store";
import Language from "../../helpers/language";
import Util from "../../helpers/util";

export const ContentTypeImage = props => {
    const {usr_info} = useStore(state => state)

    const { content } = props;

    let text = "text" in content ? content.text : "";
    text = Util.hyperlink( text )

    return (
        <>
            {content.image && <img
                className='daily-questions__image'
                src={content.image}
                alt={content.alt_text}
            />}

            {content.video && <video
                className='daily-questions__video'
                title={content.alt_text}
                controls
                controlsList='nodownload'
                autoPlay={usr_info.autoplay_videos}>
                <source src={content.video} />
                {Language.getSentenceCase('Your browser doesn\'t support video tags')}.
                <track label="English"
                       kind="subtitles"
                       srcLang="en"
                       src={content.closed_caption}
                       default/>
            </video>}
        </>
    );
};
