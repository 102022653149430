import React, {useEffect, useRef, useState} from "react"
import Util from "../../helpers/util"
import Language from "../../helpers/language"

import { DripQuestions } from "../../components/user/drip_questions"
import {useStore} from "../../store"
import {useNavigate} from "react-router-dom"
import {buildNavigation} from "../../helpers/content";

export const Drip = props => {
    const { usr_info } = useStore( x => x)
    const { title, showToast, onPathChange } = props

    const [state, setState] = useState({
        user_questions: [],
    })
    const { user_questions } = state

    const navigate = useRef( useNavigate() )
    const navs = buildNavigation( usr_info.tenant?.navigations )

    const nav_start = (navs.length >= 2)? ((navs[0].code != "DRIP")? navs[0]: navs[1]): {path: "/dashboard"}
    const nav_finish = (navs.length >= 2)? navs[1]: {path: "/dashboard"}

    useEffect(() => {
        onPathChange("drip")

        Util.fetch_js( "/client/question/list/", { answered: false },
            js => {
                const { user_questions } = js

                //Is there is no data, jump back to the dashboard
                if (user_questions.length <= 0) {
                    navigate.current(nav_start.path)
                }

                setState(prev => ({ ...prev,
                    user_questions,
                }))
            },
            (reason, code) => {
                showToast(reason, 'failure')
            }
        )
    }, [])

    const handleCompleted = () => {
        navigate.current(nav_finish.path)
    }

    const handleAddQuestions = (idx, questions) => {
        let { user_questions } = state

        console.log( questions )
        questions.forEach( x => {
            user_questions.splice(++idx, 0, x)
        })

        setState(prev => ({...prev,
            user_questions,
        }))
    }

    const handleUpdateQuestions = (idx, questions) => {
        let { user_questions } = state

        //Update the user questions, this is needed if we preloaded the questions, and need the UserQuestion.uid  from the server
        questions.forEach( x => {
            const i = ++idx
            if ( user_questions[i].question.uid == x.question.uid ) {
                user_questions[i] = x
            }
            else {
                console.log("Error: Question UID mismatch")
            }
        })

        setState(prev => ({...prev,
            user_questions
        }))
    }

    return (
        <div className='user-portal__main-container user-portal__main-container--daily-questions'>
            <div className='daily-questions__heading mobile-heading'>
                <h1>{title}</h1>
            </div>

            {user_questions.length > 0 &&
            <DripQuestions
                user_questions={user_questions}
                showToast={showToast}
                onAddQuestions={handleAddQuestions}
                onUpdateQuestions={handleUpdateQuestions}
                onCompleted={handleCompleted}
            />
            }
        </div>
    )
}
