import { useStore } from "../store";
import axios from "axios";
import Language from "./language";

// https://learn.microsoft.com/en-us/javascript/api/@azure/msal-react/?view=msal-js-latest
// https://learn.microsoft.com/en-us/graph/api/overview?view=graph-rest-1.0

// SSO
import { InteractionRequiredAuthError } from "@azure/msal-browser";

export default class SSO {
    static getAccessToken(instance, accounts, request, onError) {
        if (accounts.length > 0) {
            const acct = accounts[0];
            instance.setActiveAccount(acct);
            console.log('accounts: ' + accounts.length.toString())
            if (acct)
                console.log(acct.username)
        }
        else {
            console.log('no accounts in list')
        }
        return instance.acquireTokenSilent(request).then(tokenResponse => {
            return tokenResponse.accessToken;
        }).catch(async (error) => {
            // if (error instanceof InteractionRequiredAuthError) {
            //     // fallback to interaction when silent call fails
            //     return instance.acquireTokenPopup(request).then(tokenResponse => {
            //         return tokenResponse.accessToken;
            //     });
            // }
            // else {
                if (onError != null) 
                    onError(error);
                else
                    console.log(error);
            // }
            return Promise.resolve(null);
        })
    }

    // static getAccount(instance, accounts, email) {
    //     console.log(instance.getAccountByUsername(email))
    //     console.log(instance.getActiveAccount())
    //     console.log(accounts.find((a) => a.username == email))
    //     console.log(accounts[0])
    //     return instance.getAccountByUsername(email) || instance.getActiveAccount() || accounts.find((a) => a.username == email) || accounts[0]
    // }

    static async fetchGraphData(graphEndpoint, options, valueTransform, onData, onComplete, onError) {
        let url = graphEndpoint
        let err = false
        while (url != null) {
            const res = await fetch(url, options);
            const data = await res.json();
            if (Object.keys(data).indexOf('error') >= 0) {
                if (onError != null) 
                    onError(data['error']['code'] + ': ' + data['error']['message'])
                else
                    console.log('error (' + url + '):\ncode: ' + data['error']['code'] + '\nmessage: ' + data['error']['message'])
                url = null;
                err = true;
            }
            else {
                onData(valueTransform(data))
                url = Object.hasOwn(data, '@odata.nextLink') ? data['@odata.nextLink'] : null
            }
        }
        if (!err && onComplete != null) onComplete()
    }

    static async fetchDefaultData(defaultValue, onData, onComplete) {
        onData(defaultValue);
        if (onComplete != null) onComplete()
    }

    static getGroupMembership(instance, accounts, userPrincipalName, onData, onComplete, onError) {
        //Get an access token so we can get this user's groups
        //  GroupMember.Read.All gives us details on the groups (e.g. displayName)
        const request = {
            scopes: ["User.Read", "GroupMember.Read.All"],
        };

        const fetchSSOGroupMembership = (accessToken) => {
            var headers = new Headers();
            const bearer = "Bearer " + accessToken;
            headers.append("Authorization", bearer);
    
            const options = {
                    method: "GET",
                    headers: headers
            };
            // Are we getting groups for this user or for a specific user
            let graphEndpoint = userPrincipalName ? 
                `https://graph.microsoft.com/v1.0/users/${userPrincipalName}/memberOf` : 
                "https://graph.microsoft.com/v1.0/me/memberOf";
            graphEndpoint = graphEndpoint + '?$select=displayName'

            return this.fetchGraphData(graphEndpoint, options, 
                (gs => gs['value'].filter(v => v['@odata.type'] === '#microsoft.graph.group').map(g => g['displayName'])), 
                onData,
                onComplete,
                onError
            );
        }

        return this.getAccessToken(instance, accounts, request).then((token) => {
            return token != null ? fetchSSOGroupMembership(token) : this.fetchDefaultData([], onData, onComplete, onError);
        });
    }

    static getAllGroups(instance, accounts, onData, onComplete, onError) {
        //Get an access token so we can get this user's groups
        //  GroupMember.Read.All gives us details on the groups (e.g. displayName)
        const request = {
            scopes: ["GroupMember.Read.All"],
        };

        const fetchSSOGroups = (accessToken) => {
            var headers = new Headers();
            const bearer = "Bearer " + accessToken;
            headers.append("Authorization", bearer);
    
            const options = {
                    method: "GET",
                    headers: headers
            };
            const graphEndpoint = "https://graph.microsoft.com/v1.0/groups";

            return this.fetchGraphData(graphEndpoint, options, 
                gs => gs['value'].filter(v =>  v['displayName'] && v['displayName'].length > 0).map(g => g['displayName']),
                onData,
                onComplete,
                onError
            );
        }

        return this.getAccessToken(instance, accounts, request, onError).then((token) => {
            return token != null ? fetchSSOGroups(token) : this.fetchDefaultData([], onData, onComplete, onError);
        });
    }

    static getAllUserEmails(instance, accounts, onData, onComplete, onError) {
        //Get an access token so we can get this user's groups
        const request = {
            scopes: ["Directory.Read.All"],
        };

        const fetchSSOUsers = (accessToken) => {
            var headers = new Headers();
            const bearer = "Bearer " + accessToken;
            headers.append("Authorization", bearer);
    
            const options = {
                    method: "GET",
                    headers: headers
            };
            const graphEndpoint = "https://graph.microsoft.com/v1.0/users";

            return this.fetchGraphData(graphEndpoint, options,
                gs => gs['value'].filter(u => u['userPrincipalName'] || u['mail']).map(u => u['mail'] || u['userPrincipalName']),
                onData,
                onComplete,
                onError
            )
        }
        
        return this.getAccessToken(instance, accounts, request, onError).then((token) => {
            return token != null ? fetchSSOUsers(token) : this.fetchDefaultData([], onData, onComplete, onError);
        });
    }

    static getUserDetails(instance, accounts, onData, onComplete, onError) {
        //Get an access token so we can get this user's groups
        const request = {
            scopes: ["User.Read"],
        };

        const fetchSSOUserDetails = (accessToken) => {
            var headers = new Headers();
            const bearer = "Bearer " + accessToken;
            headers.append("Authorization", bearer);
    
            const options = {
                    method: "GET",
                    headers: headers
            };
            const graphEndpoint = "https://graph.microsoft.com/v1.0/me";

            return this.fetchGraphData(graphEndpoint, options,
                u => {
                    const name = u['givenName'] && u['surname'] ? u['givenName'] + ' ' + u['surname'] : u['displayName']
                    const nickname = u['givenName'] || u['displayName']
                    const email = accounts.length > 0 ? accounts[0]['username'] : ''
                    return { name: name, nickname: nickname, email: email } 
                },
                onData,
                onComplete,
                onError
            )
        }
        
        return this.getAccessToken(instance, accounts, request).then((token) => {
            const defaultData = (accounts.length > 0) ? 
                { name: accounts[0]['name'], nickname: accounts[0]['name'], email: accounts[0]['username'] } :
                { name: '', nickname: '', email: ''}
            return token != null ? fetchSSOUserDetails(token) : this.fetchDefaultData(defaultData, onData, onComplete, onError);
        });
    }

    //Find users created after a given date (date format: 2020-01-01T00:00:00z))
    static getUsersSince(instance, accounts, createdSince, onData, onComplete, onError) {
        //Get an access token so we can get this user's groups
        const request = {
            scopes: ["Directory.Read.All"],
        };

        const fetchSSOUsersSince = (accessToken) => {
            var headers = new Headers();
            const bearer = "Bearer " + accessToken;
            headers.append("Authorization", bearer);
    
            const options = {
                    method: "GET",
                    headers: headers
            };
            const graphEndpoint = "https://graph.microsoft.com/v1.0/users/?$filter=(createdDateTime ge " + createdSince + ")&$select=createdDateTime,userPrincipalName,givenName,surName,displayName,mail";

            return this.fetchGraphData(graphEndpoint, options,
                us => us['value'].filter(u => u['userPrincipalName'] != null).map( (u) => {
                    const name = u['givenName'] && u['surname'] ? u['givenName'] + ' ' + u['surname'] : u['displayName']
                    const nickname = u['givenName'] || u['displayName']
                    const email = u['mail'] || u['userPrincipalName']
                    return { name: name, nickname: nickname, email: email, role: 'normal', principalName: u['userPrincipalName'] } 
                }),
                onData,
                onComplete,
                onError
            )
        }
        
        return this.getAccessToken(instance, accounts, request).then((token) => {
            return token != null ? fetchSSOUsersSince(token) : this.fetchDefaultData([], onData, onComplete, onError);
        });
    }
}