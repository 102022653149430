import React, { useEffect, useState } from "react";

import { useStore } from "../../store";

import { Progress } from "../../components/progress";

import { CorrectAnswerPopup } from "../../components/user/correct_answer_popup";
import { IncorrectAnswerPopup } from "../../components/user/incorrect_answer_popup";
import { InformationPopup } from "../../components/user/information_popup";

import { ContentTypeText } from "../../components/user/content_type_text";
import { ContentTypeImage } from "../../components/user/content_type_image";
import {ContentTypeDocument} from "./content_type_document";
import { AnswerTypeFill } from "../../components/user/answer_type_fill";
import { AnswerTypeBool } from "../../components/user/answer_type_bool";
import { AnswerTypeMultiple } from "../../components/user/answer_type_multiple";
import { AnswerTypeButtons } from "../../components/user/answer_type_buttons";
import { AnswerTypeCheckbox } from "../../components/user/answer_type_checkbox";
import { AnswerTypeSlider } from "../../components/user/answer_type_slider";
import { AnswerTypeTextArea } from "../../components/user/answer_type_textarea";
import { AnswerTypeAiChat } from "../../components/user/answer_type_ai_chat";
import { AnswerTypeQRCode } from "./answer_type_qrcode";
import { AnswerTypeNone } from "./answer_type_none";
import {AnswerTypeInlineBool} from "./answer_type_inline_bool";
import {AnswerTypeInlineInput} from "./answer_type_inline_input";
import {AnswerTypeInlineArea} from "./answer_type_inline_area";


import Util from "../../helpers/util";
import Language from "../../helpers/language";
import { AnswerTypeAccept } from "./answer_type_accept";
import {RefuseAnswerPopup} from "./refuse_answer_popup";

export const DripQuestions = React.forwardRef((props, ref) => {
    const { user_questions, showToast } = props

    const onCompDef = () => {
        setState(prev => ({ ...prev,
            is_correct: false,
            allow_next: true,
            reset_content: true,
            show_answer: false,
            show_info: false,
            points_earned: false,
            inline_data: {},
            answered_questions: {},
        }))
    }

    const onIdx = (props.onIdx != undefined && props.onIdx != null)? props.onIdx: () => {}
    const onAddQuestions = (props.onAddQuestions != undefined && props.onAddQuestions != null)? props.onAddQuestions: () => {}
    const onUpdateQuestions = (props.onUpdateQuestions != undefined && props.onUpdateQuestions != null)? props.onUpdateQuestions: () => {}
    const onCompleted = (props.onCompleted != undefined && props.onCompleted != null)? props.onCompleted: onCompDef
    const dry_run = (props.dryRun == true)
    const read_only = (props.readOnly == true)

    const {usr_info, setUsrInfo} = useStore(x => x )

    const [state, setState] = useState({
        idx: 0,
        is_correct: false,
        reset_content: false,
        show_answer: false,
        show_info: false,
        points_earned: false,
        reviewed_policy: false,
        allow_next: read_only,
        follow_ups: {},
        inline_data: {},
        answered_questions: {},
        question_start: Date.now(),
        answered_uid: "",
    });
    const {
        idx,
        is_correct,
        correct_answer,
        allow_next,
        follow_ups,
        inline_data,
        answered_questions,
        show_answer,
        points_earned,
        reviewed_policy,
        show_info,
        reset_content,
        question_start,
        answered_uid,
    } = state;

    const [refs, setRefs] = useState({
        childBool: React.createRef(),
        childFill: React.createRef(),
        childMulti: React.createRef(),
        childSlider: React.createRef(),
        childCheck: React.createRef(),
        childAiChat: React.createRef(),
        childQRCode: React.createRef(),
        childNone: React.createRef(),
    });

    //Do we have a ref set? Load it up
    if ( ref != undefined && ref != null ) {
        React.useImperativeHandle(ref, () => ({
            setIdx: ( idx ) => {
                setState(prev => ({...prev,
                    idx,
                    reset_content: true,
                }))
            }
        }))
    }

    useEffect(() => {
        let answered_questions = state.answered_questions
        const updates = user_questions.filter( x => !(x.uid in answered_questions) && ('answer' in x && 'response' in x.answer))
        updates.forEach( x => {
            answered_questions[x.uid] = x.answer.response
        })

        //Update the state info?
        if (reset_content) {
            //Delete uid
            if ( 'uid' in answered_questions ) {
                delete answered_questions['uid']
            }

            setState(prev => ({ ...prev,
                answered_questions,
                is_correct: false,
                show_answer: false,
                points_earned: false,
                reset_content: false,
            }))
        }
        else if ( updates.length > 0 ) {
            setState(prev => ({ ...prev,
                reset_content: true,
                answered_questions,
            }))
        }
    })

    useEffect(() => {
        const uq = (idx >= 0 && idx < user_questions.length)? user_questions[idx]: null

        //If the user question doesn't have a uid, we're probably previewing an unsaved question
        if ( uq == null || !uq.question.uid ) {
            return
        }

        //Read only mode, just load the follow ups
        if ( read_only ) {
            setState( prev => ({ ...prev,
                allow_next: true,
                reviewed_policy: false,
            }))
            return
        }

        //Answer teh question, and update with the points
        const payload = {
            question_uid: uq.question.uid,
        }
        if ( 'uid' in uq && uq.uid != null && uq.uid != undefined && uq.uid != '' ) {
            payload['user_question_uid'] = uq.uid
        }

        //Grab the follow up
        Util.fetch_js( "/client/question/query_follow_up/", payload,
            js => {
                const follow_ups = state.follow_ups
                follow_ups[uq.question.uid] = js.follow_ups

                setState( prev => ({ ...prev,
                    follow_ups,
                    allow_next: true,
                    reviewed_policy: false,
                }))
            },
            (reason, code) => {
                showToast(reason, 'failure');
            }
        );
    }, [idx])

    const showInfo = () => {
        setState(prev => ({...prev,
            show_info: !show_info
        }))
    };

    const handlePrev = () => {
        if (idx >= 1) {
            onIdx( idx - 1 )
            setState(prev => ({...prev,
                idx: idx - 1,
                allow_next: false,
                show_answer: false,
                reset_content: true,
                question_start: Date.now(),
                answered_uid: "",
            }))
        }
    };

    const handleNext = answer_ref => {
        if ( !allow_next ) {
            console.log("Blocked allow next")
            return
        }

        //If we have a pending answer, submit it now
        if ( !read_only &&
            answer_ref &&
            answer_ref.current &&
            answer_ref.current.attemptAnswer() ) {
            return;
        }

        if (idx + 1 < user_questions.length) {
            onIdx( idx + 1 )

            setState(prev => ({...prev,
                allow_next: false,
                show_answer: false,
                idx: idx + 1,
                reset_content: true,
                question_start: Date.now(),
                answered_uid: "",
            }))
        }
        else {
            onCompleted();
        }
    };

    const handleInline = (e) => {
        if ( read_only ) {
            return
        }

        if ( idx < 0 || idx >= user_questions.length ) {
            console.log(`Error, invalid X ${idx} ${user_questions.length}`)
            return
        }

        const uq_uid = user_questions[idx].uid || "uid"

        //Add raw data
        const inline_data = {...state.inline_data}
        if ( !(uq_uid in inline_data) ) {
            inline_data[uq_uid] = {}
        }

        //Ensure data exists
        inline_data[uq_uid][e.target.name] = e.target.value
        setState( prev => ({ ...prev,
            inline_data,
        }))
    }

    const handleAnswer = (answer, correct, correct_answer) => {
        //console.log('---')
        //console.log( answer )
        //console.log( correct )
        //console.log( correct_answer )

        if ( read_only || !allow_next ) {
            console.log("Blocked, read-only or not allow next")
            return
        }

        if ( idx < 0 || idx >= user_questions.length ) {
            console.log(`Error, invalid X ${idx} ${user_questions.length}`)
            return
        }

        const question = user_questions[idx].question
        const uq_uid = user_questions[idx].uid || "uid"

        //Already answered?
        if ( answered_uid == uq_uid ) {
            console.log("Already answered")
            return
        }

        //Show the response right away
        setState( prev => ({ ...prev,
            is_correct: correct,
            correct_answer: correct_answer,
            answered_questions: { ...answered_questions, [uq_uid]: answer },
            show_answer: true,
            points_earned: (!dry_run)? false: "XXX", //Will not show text
            answered_uid: uq_uid,
        }))

        //Add content?
        const cur_idx = idx
        const follow_ups_q = (question.uid in follow_ups)? follow_ups[question.uid]: {}
        if ( answer in follow_ups_q && follow_ups_q[answer].length > 0 ) {
            //console.log( follow_ups )
            onAddQuestions( cur_idx, follow_ups_q[answer] )
        }

        //Don't actually run this
        if ( dry_run ) {
            return
        }

        //Answer teh question, and update with the points
        const payload = {
            user_question_uid: uq_uid,
            answer,
            correct,
            inline_data: (uq_uid in inline_data)? inline_data[uq_uid]: {},
            answer_ms: Date.now() - question_start
        };
        //console.log(payload)
        Util.fetch_js( "/client/question/answer/", payload,
          js => {
                usr_info.points = js.points;
                setUsrInfo(usr_info);

                //Add in new questions at the current index
                if ( js.new_questions.length > 0 ) {
                    onUpdateQuestions( cur_idx, js.new_questions )
                }

                setState( prev => ({ ...prev,
                    points_earned: js.points_earned,
                }))
            },
            (reason, code) => {
                showToast(reason, 'failure');
            }
        );
    };

    const handleReviewed = () => {
        console.log("Review set true")
        setState(prev => ({...prev,
            reviewed_policy: true
        }))
    }

    const null_uq = {
        question: { content_type: "", answer_type: "", uid: '' },
        question_content: { content: {}, answer: {}, explanation: {} }
    };

    //Total
    let total = props.total || user_questions.length;
    if ( total < user_questions.length ) {
        total = user_questions.length;
    }

    const has_next = idx + 1 < user_questions.length;
    const user_question = idx < user_questions.length ? user_questions[idx] : null_uq;

    const { question, question_content } = user_question;
    const { content_type, answer_type } = question;

    const disable_info_button = (idx >= user_questions.length) ||
                                !('question' in user_questions[idx]) ||
                                !(user_questions[idx].uid in answered_questions) ||     // disable if the user hasn't answered yet
                                (user_questions[idx].question.uid in answered_questions && !dry_run) ||
                                !Util.isObject(question_content.explanation) ||
                                !('info' in question_content.explanation) ||
                                (question_content.explanation.info == "")

    // Answer ref
    let answer_ref = null;
    if (answer_type.toLowerCase() == "fill") {
        answer_ref = refs.childFill;
    }
    else if (answer_type.toLowerCase() == "boolean" ||
             answer_type.toLowerCase() === "accept" ) {
        answer_ref = refs.childBool;
    }
    else if (answer_type.toLowerCase() == "multiple" ||
             answer_type.toLowerCase() == "buttons") {
        answer_ref = refs.childMulti;
    }
    else if (answer_type.toLowerCase() == 'slider') {
        answer_ref = refs.childSlider;
    }
    else if (answer_type.toLowerCase() == 'checkbox') {
        answer_ref = refs.childCheck
    }
    else if (answer_type.toLowerCase() == 'ai chat') {
        answer_ref = refs.childAiChat
    }
    else if (answer_type.toLowerCase() == 'qr code') {
        answer_ref = refs.childQRCode
    }
    else if (answer_type.toLowerCase() == 'none') {
        answer_ref = refs.childNone
    }
    else {
        if ( Util.xstr(answer_type) != "" ) {
            console.log( `Invalid type ${answer_type}` )
        }
    }

    //Unload everything, which forces a completely fresh load next time
    if (reset_content) {
        return (
            <div className='central-container daily-questions'>
                <div className='daily-questions__body'>
                </div>
            </div>
        )
    }

    const content = question_content.content.text || ""
    const is_document = 'document' in question_content.content

    //Pull out all my components
    const regex = /([\[{][^\]}]+[\]}])/g
    const components = [...content.matchAll( regex )].map( x => x[0] )
    const comp_objs = components.map( comp => {
        //Match failed, is it content? else quit
        const r = comp.match(/(\w+)\s+(\w+)/)
        if ( r == null ) {
            return (comp.match(/content/) != null) ? { type: 'content' }: null
        }

        return {
            type: `inline_${r[1]}`,
            name: r[2],
        }
    })

    //Remove the components out of the text and convert it into an array of text
    const content_split = content.replaceAll( regex, 'REPLACE_TEXT').split('REPLACE_TEXT')

    //Combine the two arrays together
    let contents = []
    let found_content = false
    for ( let i = 0; i < content_split.length || i < comp_objs.length; i++ ) {
        //Do we have content left? Add it
        if ( i < content_split.length ) {
            //Clean up whitespace
            const text = content_split[i].replace(/^\s+/, '').replace(/\s+$/, '')
            if (contents.length === 0 && is_document) {
                contents.push( {
                    ...question_content.content,
                    type: 'document',
                    text,
                })
            }
            else {
                contents.push( {
                    type: 'markdown',
                    text,
                })
            }
        }

        //Do we have components left? Add it
        if ( i < comp_objs.length && comp_objs[i] != null ) {
            found_content |= (comp_objs[i].type == 'content')
            contents.push( comp_objs[i] )
        }
    }
    if(!found_content && question.content_type.toLowerCase() != 'text') {
        contents.push({type: 'content'})
    }

    //Append answer component object
    // Hardcoded temporarily
    contents.push({
        type: answer_type.toLowerCase(),
        answer: question_content.answer,
    });
    console.log(contents)

    let has_answer = false
    if ( 'correct' in question_content.answer ) {
        if ( Array.isArray( question_content.answer.correct ) ) {
            has_answer = question_content.answer.correct.findIndex( x => x != '' && Util.xint(x, -917) != -1  ) >= 0
        }
        else {
            has_answer = Util.xint( question_content.answer.correct, -1 ) >= 0
        }
    }
    //console.log( question_content.answer, has_answer )

    return (
        <div className='central-container daily-questions'>
            {!dry_run &&
                <Progress label={Language.getTitleCase('questions')} idx={idx} total={total} />
            }
            <div className='daily-questions__body'>
                {show_answer && is_correct && (
                    <CorrectAnswerPopup
                        explanation={question_content.explanation}
                        points_earned={points_earned}
                        has_answer={has_answer}
                        onClose={handleNext}
                    />
                )}

                {show_answer && !is_correct && !reviewed_policy &&
                    <IncorrectAnswerPopup
                        question={question}
                        explanation={question_content.explanation}
                        points_earned={points_earned}
                        correct_answer={correct_answer}
                        has_answer={has_answer}
                        onClose={handleNext}
                    />
                }

                {show_answer && !is_correct && reviewed_policy &&
                    <RefuseAnswerPopup
                        question={question}
                        explanation={question_content.explanation}
                        points_earned={points_earned}
                        correct_answer={correct_answer}
                        has_answer={has_answer}
                        onClose={handleNext}
                    />
                }

                {show_info && (
                    <InformationPopup
                        explanation={question_content.explanation}
                        onClose={showInfo}
                    />
                )}
                {user_questions && (Object.entries(contents).map(([key, content]) => {
                    const {name, answer} = content
                    const type = content.type.toLowerCase()

                    const uq = ((idx >= 0 && idx < user_questions.length)? user_questions[idx]: null) || { uid: 'uid', answer: {} }
                    const uq_uid = uq.uid || "uid"

                    //Calculate inline values
                    let inline_value = ""
                    if ( uq_uid in inline_data && name in inline_data[uq_uid] ) {
                        inline_value = inline_data[uq_uid][name]
                    }
                    else if ( uq != null && 'answer' in uq && 'inline_data' in uq.answer && name in uq.answer.inline_data ) {
                        inline_value = uq.answer.inline_data[name]
                    }

                    //Build out the modules
                    if ( type == 'text') {
                        return <ContentTypeText key={key} content={content} />
                    }
                    else if ( type == 'markdown') {
                        return <ContentTypeText key={key} content={content} />
                    }
                    else if ( type == 'document') {
                        return <ContentTypeDocument key={key} content={content} onReviewedPolicy={handleReviewed} />
                    }
                    else if ( type == 'content') {
                        return <ContentTypeImage key={key} content={question_content.content} />
                    }
                    else if ( type == 'fill') {
                        return <AnswerTypeFill
                                    key={key}
                                    ref={refs.childFill}
                                    name={name}
                                    answer={answer}
                                    value={answered_questions[uq_uid]}
                                    readOnly={read_only}
                                    onChanged={handleAnswer}
                                    />
                    }
                    else if ( type == 'area') {
                        return <AnswerTypeTextArea
                                    key={key}
                                    ref={refs.childFill}
                                    answer={answer}
                                    value={answered_questions[uq_uid]}
                                    readOnly={read_only}
                                    onChanged={handleAnswer}
                                    />
                    }
                    else if ( type === 'accept') {
                        return <AnswerTypeAccept
                            key={key}
                            ref={refs.childBool}
                            answer={answer}
                            value={answered_questions[uq_uid]}
                            readOnly={read_only}
                            reviewedPolicy={reviewed_policy}
                            onChanged={handleAnswer}
                            showToast={showToast}
                        />
                    }
                    else if ( type == 'boolean') {
                        return <AnswerTypeBool
                                    key={key}
                                    ref={refs.childBool}
                                    answer={answer}
                                    value={answered_questions[uq_uid]}
                                    readOnly={read_only}
                                    onChanged={handleAnswer}
                                    />
                    }
                    else if ( type == 'multiple') {
                        return <AnswerTypeMultiple
                                    key={key}
                                    ref={refs.childMulti}
                                    answer={answer}
                                    value={answered_questions[uq_uid]}
                                    readOnly={read_only}
                                    onChanged={handleAnswer}
                                    />
                    }
                    else if ( type == 'buttons') {
                        return <AnswerTypeButtons
                                    key={key}
                                    ref={refs.childMulti}
                                    answer={answer}
                                    value={answered_questions[uq_uid]}
                                    readOnly={read_only}
                                    onChanged={handleAnswer}
                                    />
                    }
                    else if ( type == 'checkbox') {
                        return <AnswerTypeCheckbox
                                    key={key}
                                    ref={refs.childCheck}
                                    answer={answer}
                                    value={answered_questions[uq_uid]}
                                    readOnly={read_only}
                                    onChanged={handleAnswer}
                                    />
                    }
                    else if ( type == 'slider') {
                        return <AnswerTypeSlider
                                    key={key}
                                    ref={refs.childSlider}
                                    answer={answer}
                                    value={answered_questions[uq_uid]}
                                    readOnly={read_only}
                                    onChanged={handleAnswer}
                                    />
                    }
                    else if ( type == 'ai chat') {
                        return <AnswerTypeAiChat
                            key={key}
                            ref={refs.childAiChat}
                            answer={answer}
                            value={answered_questions[uq_uid]}
                            question_uid={question.uid}
                            readOnly={read_only}
                            onChanged={handleAnswer}
                            showToast={showToast}
                        />
                    }
                    else if ( type == 'qr code') {
                        return <AnswerTypeQRCode
                            key={key}
                            ref={refs.childQRCode}
                            showToast={showToast}
                            answer={answer}
                            value={answered_questions[uq_uid]}
                            readOnly={read_only}
                            onChanged={handleAnswer}
                        />
                    }
                    else if ( type == 'none') {
                        return <AnswerTypeNone
                            key={key}
                            ref={refs.childNone}
                            answer={answer}
                            value={answered_questions[uq_uid]}
                            readOnly={read_only}
                            onChanged={handleAnswer}
                        />
                    }
                    else if ( type == 'inline_boolean') {
                        return <AnswerTypeInlineBool
                            key={key}
                            name={name}
                            value={inline_value}
                            readOnly={read_only}
                            onChanged={handleInline}
                        />
                    }
                    else if ( type == 'inline_input') {
                        return <AnswerTypeInlineInput
                            key={key}
                            name={name}
                            value={inline_value}
                            readOnly={read_only}
                            onChanged={handleInline}
                        />
                    }
                    else if ( type == 'inline_area') {
                        return <AnswerTypeInlineArea
                            key={key}
                            name={name}
                            value={inline_value}
                            readOnly={read_only}
                            onChanged={handleInline}
                        />
                    }
                    else {
                        return <div key={key}></div>
                    }
                    })
                )}
            </div>

            <div className='daily-questions__nav'>
                <button
                    className='daily-questions__nav-button daily-questions__nav-button--previous flex justify-center items-center mr-2 sm:mr-0'
                    disabled={idx <= 0 || dry_run}
                    onClick={handlePrev}>
                    {Language.getSentenceCase('previous')}
                    <svg
                        width='15'
                        height='20'
                        viewBox='0 0 15 15'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'>
                        <path
                            d='M14.5714 6.92857V8.14286C14.5714 8.47805 14.4671 8.76581 14.2584 9.00614C14.056 9.24014 13.7904 9.35714 13.4615 9.35714H6.78292L9.5625 12.1462C9.80283 12.3739 9.92299 12.6585 9.92299 13C9.92299 13.3415 9.80283 13.6261 9.5625 13.8538L8.851 14.5748C8.617 14.8088 8.3324 14.9258 7.99721 14.9258C7.66834 14.9258 7.38058 14.8088 7.13393 14.5748L0.958147 8.38951C0.724144 8.15551 0.607143 7.87091 0.607143 7.53571C0.607143 7.20685 0.724144 6.91908 0.958147 6.67243L7.13393 0.506138C7.37426 0.26581 7.66202 0.145646 7.99721 0.145646C8.32608 0.145646 8.61068 0.26581 8.851 0.506138L9.5625 1.20815C9.80283 1.44847 9.92299 1.73623 9.92299 2.07143C9.92299 2.40662 9.80283 2.69438 9.5625 2.93471L6.78292 5.71429H13.4615C13.7904 5.71429 14.056 5.83445 14.2584 6.07478C14.4671 6.30878 14.5714 6.59338 14.5714 6.92857Z'
                            fill='currentColor'></path>
                    </svg>
                </button>
                {!disable_info_button && <button
                    className='daily-questions__nav-button daily-questions__nav-button--help'
                    disabled={disable_info_button}
                    onClick={showInfo}>
                    <svg
                        width='13'
                        height='18'
                        viewBox='0 0 13 18'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'>
                        <path
                            d='M6.72742 0.125C4.07117 0.125 2.3446 1.2207 0.983276 3.17969C0.750854 3.51172 0.817261 4.00977 1.1825 4.24219L2.61023 5.33789C2.94226 5.60352 3.44031 5.53711 3.70593 5.20508C4.53601 4.17578 5.16687 3.57812 6.46179 3.57812C7.49109 3.57812 8.75281 4.20898 8.75281 5.20508C8.75281 5.96875 8.12195 6.33398 7.12585 6.89844C5.93054 7.5625 4.37 8.39258 4.37 10.4512V10.75C4.37 11.2148 4.73523 11.5469 5.16687 11.5469H7.5907C8.02234 11.5469 8.38757 11.2148 8.38757 10.75V10.584C8.38757 9.15625 12.538 9.08984 12.538 5.23828C12.538 2.34961 9.51648 0.125 6.72742 0.125ZM6.39539 12.543C5.10046 12.543 4.07117 13.5723 4.07117 14.834C4.07117 16.0957 5.10046 17.125 6.39539 17.125C7.6571 17.125 8.6864 16.0957 8.6864 14.834C8.6864 13.5723 7.6571 12.543 6.39539 12.543Z'
                            fill='currentColor'></path>
                    </svg>
                </button> }

                <button
                    className='daily-questions__nav-button daily-questions__nav-button--next flex justify-center items-center ml-2 sm:ml-0'
                    disabled={!allow_next}
                    onClick={() => handleNext(answer_ref)}>
                    {has_next ? Language.getSentenceCase('next') : Language.getSentenceCase('done')}
                    <svg
                        width='15'
                        height='20'
                        viewBox='0 0 15 15'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'>
                        <path
                            d='M14.016 7.53571C14.016 7.87723 13.899 8.16499 13.665 8.399L7.48926 14.5748C7.24261 14.8088 6.95485 14.9258 6.62598 14.9258C6.30343 14.9258 6.01883 14.8088 5.77218 14.5748L5.06069 13.8633C4.82036 13.623 4.7002 13.3352 4.7002 13C4.7002 12.6648 4.82036 12.377 5.06069 12.1367L7.84026 9.35714H1.16169C0.832822 9.35714 0.564035 9.24014 0.355329 9.00614C0.152948 8.76581 0.0517578 8.47805 0.0517578 8.14286V6.92857C0.0517578 6.59338 0.152948 6.30878 0.355329 6.07478C0.564035 5.83445 0.832822 5.71429 1.16169 5.71429H7.84026L5.06069 2.92522C4.82036 2.69754 4.7002 2.41295 4.7002 2.07143C4.7002 1.72991 4.82036 1.44531 5.06069 1.21763L5.77218 0.506138C6.01251 0.26581 6.29711 0.145646 6.62598 0.145646C6.96117 0.145646 7.24893 0.26581 7.48926 0.506138L13.665 6.68192C13.899 6.90327 14.016 7.18787 14.016 7.53571Z'
                            fill='currentColor'></path>
                    </svg>
                </button>
            </div>
        </div>
    )
})
