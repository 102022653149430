import React, { useEffect, useState } from "react";
import Categories from "../../components/user/categories";
import InputWithIcon from "../../components/input_with_Icon";
import Util from "../../helpers/util";
import Language from "../../helpers/language";
import { useStore } from "../../store";
import ContentLoader from "react-content-loader";
import { UserPopup } from "../../modals/user/user_popup";
import {Drip7Input} from "../../components/drip7_input";
import {MagnifyingGlassIcon} from "@heroicons/react/24/outline";

export const Leaderboard = (props) => {
    const { showToast, onPathChange } = props

    const { usr_info, public_mode } = useStore(state => state)

    const INDIVIDUAL = 1;
    const GROUP_TOTAL = 2;
    const GROUP_AVG = 3;

    const [state, setState] = useState({
        show_groups: false,
        loading: false,
        show_date: false,
        show_lb: false,
        show_lbtype: false,
        lbtypes: [
            { id: INDIVIDUAL, name: Language.getTitleCase('individual') },
            { id: GROUP_AVG, name: Language.getTitleCase('group average') },
            { id: GROUP_TOTAL, name: Language.getTitleCase('group total') },
        ],
        lbtype: INDIVIDUAL,
        search: "",
        leaderboards: [],
        leaderboard_title: "",
        groups: [],
        group_filter: {},
        user_badges: {},
        show_user: null,
    });
    const { show_groups, search, leaderboards, leaderboard_title, groups, group_filter, show_date, loading, show_lb, user_badges, show_user, show_lbtype, lbtypes, lbtype } = state

    useEffect(() => {
        onPathChange("leaderboard")
        setState(prev => ({ ...prev, loading: true }))

        //Get a list of all members for tenant descending order.
        const ep = (!public_mode)? "complete": "public"
        Util.fetch_js(`/client/leaderboard/${ep}/`, { tenant_uid: usr_info.tenant.uid },
            js => {
                const { year, months, quarters, leaderboards, groups,
                    current_streak, best_streak } = js

                //Convert the data into one list of leaderboards
                let _leaderboards = []
                months.forEach(x => {
                    _leaderboards.push(x)
                })
                quarters.forEach(x => {
                    _leaderboards.push(x)
                })
                _leaderboards.push(year)
                //_leaderboards.push(current_streak)
                //_leaderboards.push(best_streak)

                //Add the leaderboard last since there is any number of these
                leaderboards.forEach(x => {
                    _leaderboards.push(x)
                })

                setState(prev => ({ ...prev,
                    leaderboard_title: _leaderboards[0].name,
                    leaderboards: _leaderboards,
                    loading: false,
                    groups,
                }))
            },
            (reason, code) => {
                showToast(reason, 'failure')
                setState(prev => ({ ...prev, loading: false }))
            }
        )
    }, [])

    useEffect(() => {
        setState(prev => ({...prev, loading: true}))
        Util.fetch_js('/client/leaderboard/badges/', { tenant_uid: usr_info.tenant.uid },
            js => {
                let { user_badges } = js
                setState(prev => ({...prev, user_badges: user_badges }))
            },
            (reason, code) => {
                showToast(reason, 'failure')
                setState(prev => ({...prev, loading: false}))
            }
        )
    }, [])

    const handleChange = (e) => {
        console.log('handleChange')
        setState({ ...state, [e.target.name]: e.target.value })
    }

    const handleToggleShowGroups = () => {
        setState({
            ...state,
            show_groups: !show_groups,
            show_lb: false,
            show_lbtype: false,
        })
    }

    const handleToggleShowLbType = () => {
        setState({
            ...state,
            show_lbtype: !show_lbtype,
            show_lb: false,
            show_groups: false,
        })
    }

    const handleToggleShowDate = () => {
        setState({
            ...state,
            show_lb: !show_lb,
            show_groups: false,
            show_lbtype: false,
        })
    }

    const handleGroupToggle = (grp) => {
        if (grp in group_filter) {
            delete group_filter[grp]
        }
        else {
            group_filter[grp] = true
        }

        setState({ ...state, group_filter })
    }

    const handleGroupClear = () => {
        let keys = Object.keys(group_filter)
        keys.forEach((k) => delete group_filter[k])
        setState({ ...state, group_filter, show_groups: false })
    }

    const handleLbTypeToggle = (type) => {
        // Clear the group filter if we're doing group leaderboards
        setState({...state, lbtype: type, group_filter: type == INDIVIDUAL ? group_filter : {}})
    }

    const handleDateClear = () => {
        setState({ ...state, group_filter: {} })
    }

    /*
    const handleReset = () => {
        setState({ ...state, filter: [], show_groups: false })
    }
     */

    const showActiveUser = (user) => {
        if ('uid' in usr_info && usr_info.uid != "" && usr_info.uid == user.uid) {
            return user.name + " (" + Language.getString('me') + ")";
        }
        else {
            return user.name;
        }
    }

    const filterLeaderboard = (entries) => {
        const search_lc = search.toLowerCase()

        //Search filter
        if (search_lc != "") {
            entries = entries.filter(u => u.name.toLowerCase().indexOf(search_lc) !== -1)
        }

        // Are we limiting by groups?
        if (Object.keys(group_filter).length > 0) {
            let user_filter = {}
            groups.forEach(x => {
                if (!(x.name in group_filter)) {
                    return
                }

                //Load all the valid names
                x.entries.forEach(user => {
                    user_filter[user.uid] = user
                })
            })

            //Now that we have a filter, run it
            entries = entries.filter(x => x.uid in user_filter).map(x => { return {...x, name: user_filter[x.uid].leaderboard_name}})
        }

        //Sort the response and return
        return entries
    }

    const buildGroupLeaderboard = (entries) => {
        let group_entries = {}
        groups.forEach((g) => {
            group_entries[g.uid] = {
                me: false,
                profile_url: '',
                uid: g.uid,
                name: g.name,
                points: 0
            }
        })

        entries.forEach((e) => {
            groups.forEach((g) => {
                if (g.entries.some((u) => u.uid == e.uid))
                    group_entries[g.uid].points += e.points
            })
        })

        if (lbtype == GROUP_AVG) {
            groups.forEach((g) => {
                group_entries[g.uid].points = (g.entries.length > 0)
                    ? (group_entries[g.uid].points / g.entries.length).toPrecision(3)
                    : '0.00';
            })
        }

        return Object.values(group_entries)
    }

    const rankify = (entries) => {
        let result = []

        let rank = 0
        let last_points = -1
        for (let i = 0; i < entries.length; i++) {
            //Calculate the rank, if we should have one at all
            if (entries[i].points <= 0) {
                rank = ""
            }
            else if (last_points != entries[i].points) {
                rank = i + 1
            }
            last_points = entries[i].points

            result.push({ ...entries[i], rank, trophy: rank })
        }

        return result
    }

    const rankGapify = (entries) => {
        if (entries.length <= 0) {
            return entries
        }

        //Create a gap for duplicated ranks
        let last_rank = entries[0].rank
        for (let i = 1; i < entries.length; i++) {
            const rank = entries[i].rank
            if (last_rank == rank) {
                entries[i].rank = ""
            }

            last_rank = rank
        }

        return entries
    }
    //Set the leaderboard we're going to use
    let entries = []
    leaderboards.forEach(x => {
        if (x.name == leaderboard_title) {
            entries = (lbtype == INDIVIDUAL) ? x.entries : buildGroupLeaderboard(x.entries)
            entries = entries.sort(function (a, b) { return b.points - a.points; })
            entries = rankify(entries) // Add Ranking
            entries = filterLeaderboard(entries) // Filter results
            entries = rankGapify(entries) // Remove ranking duplicates
        }
    })

    const addBadge = (user) => {
        let _badges = [];
        let recent = []
        // Iterate through each id with completed badges
        Object.entries(user_badges).map(([key, value ]) => {
            if(key == user) {
                // Assign badge collection to the user
                _badges = [...[value][0]];

                recent = _badges.slice(0, 4);
            }
        })
        return (
            <>
                {recent.map((badge, index) => (
                    <div key={index} className={`leaderboard__badge-icon leaderboard__badge-icon-${index}`}>
                        <img src={badge.image} alt='badge image'></img>
                    </div>
                ))}
                {_badges.length > 4 && <div className='leaderboard__text'>{`+${_badges.length - 4}`}</div>}
            </>
        )
    }

    const showUser = (user) => {
        setState({...state, show_user: user})
    }

    return (
        <div className='user-portal__main-container user-portal__main-container--leaderboard'>
            <div className='badge-status__heading mobile-heading z-40'>
                <h1>{leaderboard_title} {Language.getTitleCase('leaderboard')}</h1>
            </div>
            {false && (
                <div className='central-container__filter-button-container central-container__filter-button-container--mobile central-container__filter-button-container--leaderboard'>
                    <div className='central-container__filter-button central-container__filter-button--leaderboard central-container__filter-button--selected'>
                        <svg
                            width='16'
                            height='19'
                            viewBox='0 0 16 19'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'>
                            <path
                                d='M12.375 0.25L10.125 1.375L7.875 0.25L5.625 1.375L3.375 0.25V3.625H12.375V0.25ZM11.0039 10.9375H10.4062C9.63281 11.3242 8.78906 11.5 7.875 11.5C6.92578 11.5 6.08203 11.3242 5.30859 10.9375H4.71094C2.10938 10.9375 0 13.082 0 15.6836V16.5625C0 17.5117 0.738281 18.25 1.6875 18.25H14.0625C14.9766 18.25 15.75 17.5117 15.75 16.5625V15.6836C15.75 13.082 13.6055 10.9375 11.0039 10.9375ZM7.875 9.8125C10.3359 9.8125 12.375 7.80859 12.375 5.3125V4.75H3.375V5.3125C3.375 7.80859 5.37891 9.8125 7.875 9.8125Z'
                                fill='currentColor'></path>
                        </svg>
                        <h3 className='central-container__filter-button-name'>
                            {Language.getTitleCase('individual')}
                        </h3>
                    </div>

                    <div className='central-container__filter-button central-container__filter-button--leaderboard'>
                        <svg
                            width='23'
                            height='17'
                            viewBox='0 0 23 17'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'>
                            <path
                                d='M3.375 7.125C4.60547 7.125 5.625 6.14062 5.625 4.875C5.625 3.64453 4.60547 2.625 3.375 2.625C2.10938 2.625 1.125 3.64453 1.125 4.875C1.125 6.14062 2.10938 7.125 3.375 7.125ZM11.25 8.25C13.1133 8.25 14.625 6.77344 14.625 4.875V4.3125H7.875V4.875C7.875 6.77344 9.35156 8.25 11.25 8.25ZM20.25 8.25H18C17.3672 8.25 16.8047 8.53125 16.3828 8.91797C17.8242 9.69141 18.8086 11.0977 19.0547 12.75H21.375C21.9727 12.75 22.5 12.2578 22.5 11.625V10.5C22.5 9.26953 21.4805 8.25 20.25 8.25ZM6.08203 8.91797C5.66016 8.53125 5.09766 8.25 4.5 8.25H2.25C0.984375 8.25 0 9.26953 0 10.5V11.625C0 12.2578 0.492188 12.75 1.125 12.75H3.41016C3.65625 11.0977 4.64062 9.69141 6.08203 8.91797ZM19.125 7.125C20.3555 7.125 21.375 6.14062 21.375 4.875C21.375 3.64453 20.3555 2.625 19.125 2.625C17.8594 2.625 16.875 3.64453 16.875 4.875C16.875 6.14062 17.8594 7.125 19.125 7.125ZM13.9219 9.375H13.6406C12.9023 9.72656 12.0938 9.9375 11.25 9.9375C10.3711 9.9375 9.5625 9.72656 8.82422 9.375H8.54297C6.29297 9.375 4.5 11.2031 4.5 13.4531V14.4375C4.5 15.3867 5.23828 16.125 6.1875 16.125H16.3125C17.2266 16.125 18 15.3867 18 14.4375V13.4531C18 11.2031 16.1719 9.375 13.9219 9.375ZM14.625 0.375L12.9375 1.21875L11.25 0.375L9.5625 1.21875L7.875 0.375V3.1875H14.625V0.375Z'
                                fill='currentColor'></path>
                        </svg>
                        <h3 className='central-container__filter-button-name'>
                            {Language.getTitleCase('teams')}
                        </h3>
                    </div>
                </div>
            )}

            <nav className='user-portal__nav'>
                <div className='user-portal__filter-wrapper user-portal__filter-wrapper--leaderboard'
                     role='button'
                     aria-expanded={show_lb}
                     tabIndex='0'
                     onClick={handleToggleShowDate}>
                    <span
                        style={{ cursor: "pointer" }}
                        onClick={handleToggleShowDate}>
                        {Language.getTitleCase('leaderboards')}
                    </span>
                    <div className={`user-portal__filter-button user-portal__filter--leaderboard user-portal__filter-button expand ${show_lb ? "active" : ""}`}>
                        <div id='show_departmentFilter' onClick={handleToggleShowDate}>
                            <svg
                                width='12'
                                height='20'
                                viewBox='0 0 10 6'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'>
                                <path
                                    d='M1.5332 5.50977 C1.00586 5.50977 0.742188 4.86524 1.12305 4.48438 L4.87305 1.12305 C5.10742 0.5 5.48828 0.5 5.72266 1.12305 L9.47266 4.48438 C9.85352 4.86524 9.58984 5.50977 9.0625 5.50977 H1.5332 Z'
                                    fill='black'></path>
                            </svg>
                        </div>
                        {show_lb && <Categories>
                            <p
                                className='hidden-heading'
                                tabIndex={-1}>
                                Clicking on the following checkboxes will update the content in main section.
                            </p>
                            {leaderboards?.map(lb => (
                                <li key={lb.name} className='user-portal__filter user-portal__filter--department'>
                                    <div className='user-portal__filter-checkbox'>
                                        <input
                                            id={"department-filter--" + lb.name.toLowerCase()}
                                            type='checkbox'
                                            aria-label="checkbox"
                                            name={lb.name.toLowerCase()}
                                            checked={leaderboard_title == lb.name}
                                            onChange={() => setState({ ...state, leaderboard_title: lb.name, show_lb: false })}
                                        />
                                        <label htmlFor={`department-filter--${lb.name.toLowerCase()}`}>
                                            {lb.name}
                                        </label>
                                    </div>
                                </li>
                            ))}
                        </Categories>}

                    </div>
                </div>

                <div className='user-portal__filter-wrapper user-portal__filter-wrapper--leaderboard'
                     role='button'
                     aria-expanded={show_lbtype}
                     tabIndex='1'
                     onClick={handleToggleShowLbType}>
                    <span
                        style={{ cursor: "pointer" }}
                        onClick={handleToggleShowLbType}>
                        {Language.getTitleCase('Types')}
                    </span>
                    <div className={`user-portal__filter-button user-portal__filter--leaderboard user-portal__filter-button expand ${show_lbtype ? "active" : ""}`}>
                        <div id='show_departmentFilter' onClick={handleToggleShowLbType}>
                            <svg
                                width='12'
                                height='20'
                                viewBox='0 0 10 6'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'>
                                <path
                                    d='M1.5332 5.50977 C1.00586 5.50977 0.742188 4.86524 1.12305 4.48438 L4.87305 1.12305 C5.10742 0.5 5.48828 0.5 5.72266 1.12305 L9.47266 4.48438 C9.85352 4.86524 9.58984 5.50977 9.0625 5.50977 H1.5332 Z'
                                    fill='black'></path>
                            </svg>
                        </div>

                        {show_lbtype && (
                            <Categories>
                                <p
                                    className='hidden-heading'
                                    tabIndex={-1}>
                                    Clicking on the following checkboxes will update the content in main section.
                                </p>
                                {lbtypes?.map(type => (
                                    <li key={type.name} className='user-portal__filter user-portal__filter--department'>
                                        <div className='user-portal__filter-checkbox'>
                                            <input
                                                type='checkbox'
                                                aria-label="checkbox"
                                                name={type.name.toLowerCase()}
                                                id={"department-filter--" + type.name.toLowerCase()}
                                                checked={type.id == lbtype}
                                                onChange={() => handleLbTypeToggle(type.id)}
                                            />
                                            <label htmlFor={`department-filter--${type.name.toLowerCase()}`}>
                                                {type.name}
                                            </label>
                                        </div>
                                    </li>
                                ))}
                            </Categories>
                        )}
                    </div>
                </div>

                {lbtype == INDIVIDUAL && <div className='user-portal__filter-wrapper user-portal__filter-wrapper--leaderboard'
                     role='button'
                     aria-expanded={show_groups}
                     tabIndex='1'
                     onClick={handleToggleShowGroups}>
                    <span
                        style={{ cursor: "pointer" }}
                        onClick={handleToggleShowGroups}>
                        {Language.getTitleCase('groups')}
                    </span>
                    <div className={`user-portal__filter-button user-portal__filter--leaderboard user-portal__filter-button expand ${show_groups ? "active" : ""}`}>
                        <div id='show_departmentFilter' onClick={handleToggleShowGroups}>
                            <svg
                                width='12'
                                height='20'
                                viewBox='0 0 10 6'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'>
                                <path
                                    d='M1.5332 5.50977 C1.00586 5.50977 0.742188 4.86524 1.12305 4.48438 L4.87305 1.12305 C5.10742 0.5 5.48828 0.5 5.72266 1.12305 L9.47266 4.48438 C9.85352 4.86524 9.58984 5.50977 9.0625 5.50977 H1.5332 Z'
                                    fill='black'></path>
                            </svg>
                        </div>

                        {show_groups && (
                            <Categories>
                                <p
                                    className='hidden-heading'
                                    tabIndex={-1}>
                                    Clicking on the following checkboxes will update the content in main section.
                                </p>
                                {groups?.filter(group => group.leaderboard_filter).map(group => (
                                    <li key={group.name} className='user-portal__filter user-portal__filter--department'>
                                        <div className='user-portal__filter-checkbox'>
                                            <input
                                                type='checkbox'
                                                aria-label="checkbox"
                                                name={group.name.toLowerCase()}
                                                id={"department-filter--" + group.name.toLowerCase()}
                                                checked={group.name in group_filter}
                                                onChange={() => handleGroupToggle(group.name)}
                                            />
                                            <label htmlFor={`department-filter--${group.name.toLowerCase()}`}>
                                                {group.name}
                                            </label>
                                        </div>
                                    </li>
                                ))}
                                <a onClick={handleGroupClear} style={{ cursor: 'pointer' }}>{Language.getTitleCase('Clear All')}</a>
                            </Categories>
                        )}
                    </div>
                </div>}

                {false &&
                    <div
                        className={`user-portal__filter-wrapper user-portal__filter-wrapper--leaderboard expand ${sort_asc ? "active" : ""}`}>
                        <span
                            style={{ cursor: "pointer" }}
                            onClick={handleDirectionSort}>
                            {Language.getTitleCase('order')}:{" "}
                        </span>
                        <div
                            className='user-portal__filter-button'
                            onClick={handleDirectionSort}>
                            <h3>{sort_asc ? "Ascending" : "Descending"}</h3>
                            <svg
                                width='12'
                                height='20'
                                viewBox='0 0 10 6'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'>
                                <path
                                    d='M1.5332 0.5 C1.00586 0.5 0.742188 1.14453 1.12305 1.52539 L4.87305 5.27539 C5.10742 5.50977 5.48828 5.50977 5.72266 5.27539 L9.47266 1.52539 C9.85352 1.14453 9.58984 0.5 9.0625 0.5 H1.5332 Z'
                                    fill='currentColor'></path>
                            </svg>
                        </div>
                    </div>
                }

                <Drip7Input
                    name="search"
                    className='hidden sm:block'
                    placeholder={Language.getTitleCase('search')}
                    value={search}
                    onChange={handleChange}>
                    <MagnifyingGlassIcon className='w-6 h-6' />
                </Drip7Input>
                {/*}
                <InputWithIcon
                    inputProps={{
                        type: 'text',
                        placeholder: Language.getTitleCase('search')+'...',
                        name: "search",
                        value: search,
                        onChange: handleChange,
                        'aria-label': 'Search'
                    }}
                    icon='search-icon'
                />
                {*/}
            </nav>

            <div className='central-container leaderboard'>
                <div className='mx-6'>
                    <Drip7Input
                        name="search"
                        className='w-full block sm:hidden'
                        placeholder={Language.getTitleCase('search')}
                        value={search}
                        onChange={handleChange}>
                        <MagnifyingGlassIcon className='w-6 h-6' />
                    </Drip7Input>
                    {/*}
                    <InputWithIcon
                        fullWidth
                        inputProps={{
                            type: "text",
                            placeholder: Language.getTitleCase('search')+"...",
                            name: "search",
                            value: search,
                            onChange: handleChange
                        }}
                        icon='search-icon'
                    />
                    {*/}
                </div>
                {false && (
                    <div className='central-container__filter-button-container central-container__filter-button-container--leaderboard'>
                        <div className='central-container__filter-button central-container__filter-button--leaderboard central-container__filter-button--selected'>
                            <svg
                                width='16'
                                height='19'
                                viewBox='0 0 16 19'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'>
                                <path
                                    d='M12.375 0.25L10.125 1.375L7.875 0.25L5.625 1.375L3.375 0.25V3.625H12.375V0.25ZM11.0039 10.9375H10.4062C9.63281 11.3242 8.78906 11.5 7.875 11.5C6.92578 11.5 6.08203 11.3242 5.30859 10.9375H4.71094C2.10938 10.9375 0 13.082 0 15.6836V16.5625C0 17.5117 0.738281 18.25 1.6875 18.25H14.0625C14.9766 18.25 15.75 17.5117 15.75 16.5625V15.6836C15.75 13.082 13.6055 10.9375 11.0039 10.9375ZM7.875 9.8125C10.3359 9.8125 12.375 7.80859 12.375 5.3125V4.75H3.375V5.3125C3.375 7.80859 5.37891 9.8125 7.875 9.8125Z'
                                    fill='currentColor'></path>
                            </svg>
                            <h3 className='central-container__filter-button-name'>
                                {Language.getTitleCase('Individual Leaderboard')}
                            </h3>
                        </div>

                        <div className='central-container__filter-button central-container__filter-button--leaderboard'>
                            <svg
                                width='23'
                                height='17'
                                viewBox='0 0 23 17'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'>
                                <path
                                    d='M3.375 7.125C4.60547 7.125 5.625 6.14062 5.625 4.875C5.625 3.64453 4.60547 2.625 3.375 2.625C2.10938 2.625 1.125 3.64453 1.125 4.875C1.125 6.14062 2.10938 7.125 3.375 7.125ZM11.25 8.25C13.1133 8.25 14.625 6.77344 14.625 4.875V4.3125H7.875V4.875C7.875 6.77344 9.35156 8.25 11.25 8.25ZM20.25 8.25H18C17.3672 8.25 16.8047 8.53125 16.3828 8.91797C17.8242 9.69141 18.8086 11.0977 19.0547 12.75H21.375C21.9727 12.75 22.5 12.2578 22.5 11.625V10.5C22.5 9.26953 21.4805 8.25 20.25 8.25ZM6.08203 8.91797C5.66016 8.53125 5.09766 8.25 4.5 8.25H2.25C0.984375 8.25 0 9.26953 0 10.5V11.625C0 12.2578 0.492188 12.75 1.125 12.75H3.41016C3.65625 11.0977 4.64062 9.69141 6.08203 8.91797ZM19.125 7.125C20.3555 7.125 21.375 6.14062 21.375 4.875C21.375 3.64453 20.3555 2.625 19.125 2.625C17.8594 2.625 16.875 3.64453 16.875 4.875C16.875 6.14062 17.8594 7.125 19.125 7.125ZM13.9219 9.375H13.6406C12.9023 9.72656 12.0938 9.9375 11.25 9.9375C10.3711 9.9375 9.5625 9.72656 8.82422 9.375H8.54297C6.29297 9.375 4.5 11.2031 4.5 13.4531V14.4375C4.5 15.3867 5.23828 16.125 6.1875 16.125H16.3125C17.2266 16.125 18 15.3867 18 14.4375V13.4531C18 11.2031 16.1719 9.375 13.9219 9.375ZM14.625 0.375L12.9375 1.21875L11.25 0.375L9.5625 1.21875L7.875 0.375V3.1875H14.625V0.375Z'
                                    fill='currentColor'></path>
                            </svg>
                            <h3 className='central-container__filter-button-name'>
                                {Language.getTitleCase('Team Leaderboard')}
                            </h3>
                        </div>
                    </div>
            )}
                { loading && <div className='leaderboard__loader'>
                    {[...Array(10)].map((x, i) =>
                        <ContentLoader className='leaderboard__loader--desktop' width="573" height="65" key={'loading' + i} viewBox="0 0 573 65">
                            <circle cx="59" cy="16" r="16" />
                            <rect x="90" y="13" width="530" height="8" />
                        </ContentLoader>
                    )}
                    {[...Array(10)].map((x, i) =>
                        <ContentLoader className='leaderboard__loader--mobile' width="300" height="65" key={'loading' + i} viewBox="0 0 300 65">
                            <circle cx="85" cy="24" r="24" />
                            <rect x="116" y="13" width="220" height="10" />
                            <rect x="116" y="28" width="80" height="8" />
                        </ContentLoader>
                    )}
                    </div>
                }
                {show_user != null && show_user != undefined && (
                    <UserPopup
                        user={show_user}
                        badges={user_badges}
                        onClose={() => setState({...state, show_user: null})}
                    />
                )}
                <table className='leaderboard__table'>
                    <tbody>

                        {Object.entries(entries).map(([key, user]) => (
                            <tr key={key}
                                onClick={() => showUser(user)}
                                className={'leaderboard__user-info' + (user.me ? ' leaderboard__user-info--me' : '') + (user.rank && user.rank < 4 ? ' leaderboard__user-info--rank-' + user.rank : '')}>
                                <td className='leaderboard__rank'>
                                    <div className='leaderboard__text leaderboard__text--rank'>
                                        {user.rank}
                                    </div>
                                </td>
                                <td className='leaderboard__user-avatar'>
                                    {user.profile_url.length > 0 ? 
                                        <div className='user-avatar'>
                                            <img
                                                alt='user avatar'
                                                style={{ width: "100%" }}
                                                src={user.profile_url}
                                            />
                                        </div>
                                        : <span>&nbsp;</span>
                                    }
                                </td>
                                <td className='leaderboard__stats leaderboard__stats--user'>
                                    <div className='leaderboard__user-name'>
                                        <div className='leaderboard__text leaderboard__text--name'>
                                            {showActiveUser(user)}
                                        </div>
                                    </div>
                                    <div className='leaderboard__user-trophy'>
                                        <i className={`leaderboard__trophy-icon leaderboard__trophy-icon--rank-${user.trophy}`}></i>
                                    </div>
                                    <div className="leaderboard__badge">
                                        {addBadge(user.uid)}
                                    </div>
                                    <div className='leaderboard__user-points'>
                                        <div className='leaderboard__text leaderboard__text--points'>
                                            {user.points} {Language.getString('points')}
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}
