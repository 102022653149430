import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../store";
import { AvatarModal } from "../../modals/avatar_modal";
import { PasswordModal } from "../../modals/password_modal";
import { Notification } from "../../helpers/consts";
import Util from "../../helpers/util";
import Language from "../../helpers/language";
import { Drip7Input } from "../../components/drip7_input";
import {
    EnvelopeIcon, PhoneIcon, UserIcon, UsersIcon
} from "@heroicons/react/24/outline";
import { Drip7Checkbox } from "../../components/drip7_checkbox";
import { Drip7Dropdown } from "../../components/drip7_dropdown";
import { Drip7Button } from "../../components/drip7_button";
import { ConfirmModalV2 } from "../../modals/confirm_modal_v2";

export const ProfileSettings = props => {
    const {usr_info, setUsrInfo} = useStore(state => state)
    const [state, setState] = useState({
        ...usr_info,
        save_enabled: false,
        show_avatar: false,
        show_password: false,
        confirm: null,
        avatars: null,
        language: null
    });

    const { showToast } = props;
    const {
        uid,
        name,
        nickname,
        email,
        phone,
        leaderboard,
        autoplay_videos,
        notify,
        avatars,
        confirm,
        save_enabled,
        show_avatar,
        show_password,
        language
    } = state;
    const tenant = usr_info.tenant || {}

    const supported_langs = Language.getSupportedLanguages();
    const curr_language = language ?? Language.getLanguage();

    const navigate = useRef( useNavigate() )

    //Load all the avatars
    useEffect(() => {
        Util.fetch_js( "/avatar/list/", {},
            js => {
                setState({ ...state, avatars: js.avatars });
            },
            (err, code) => {
                showToast(err, 'failure');
            }
        );

        //Run some state updates
        props.onPathChange("profile-settings");
        setState({ ...state, loaded: true });
    }, []);

    const handleChange = e => {
        setState({
            ...state,
            save_enabled: true,
            [e.target.name]: e.target.value
        });
    };

    const handleChecked = e => {
        console.log(e)
        setState({
            ...state,
            save_enabled: true,
            [e.target.name]: e.target.checked
        });
    };

    const handleDropdown = e => {
        console.log(e)
        setState(prev => ({...prev,
            save_enabled: true,
            [e.target.id]: e.target.value
        }))
    };

    const handleShowAvatar = show_avatar => {
        setState({ ...state, show_avatar });
    };

    const handleShowPassword = show_password => {
        setState({ ...state, show_password });
    };

    const handleResetPwd = () => {
        Util.fetch_js( "/human/resetpwd/", { email },
            js => {
                navigate.current("/login");
            },
            (err, code) => {
                showToast(err, 'failure');
            }
        );
    };

    const handleConfirm = () => {
        const confirm = {
            action: handleDelete,
            title: Language.getTitleCase('Are you sure you want to delete your account?'),
            desc: Language.getSentenceCase('This action is not reversible.'),
            yes: Language.getTitleCase('delete'),
            no: Language.getTitleCase('cancel')
        };
        setState({ ...state, confirm });
    };

    const handleChangeLanguage = (loc) => {
        setState({ ...state, language: loc, save_enabled: true });
    }

    const handleDelete = e => {
        Util.fetch_js( "/human/permanent_remove_user/", {},
            js => {
                showToast(Language.getSentenceCase('updated'), 'successful');
                setState({...state, confirm: null})
                Util.handleLogout(navigate.current, null, tenant.auth_type);
            },
            (err, code) => {
                showToast(err, 'failure');
            }
        );
    }

    const handleSave = e => {
        const payload = {
            user_uid: uid,
            name,
            nickname,
            email,
            phone,
            leaderboard,
            autoplay_videos,
            notify,
            language
        };
        Util.fetch_js( "/human/modify/", payload,
            js => {
                setUsrInfo(js.usr_info);
                showToast(Language.getSentenceCase('updated'), 'successful');
            },
            (err, code) => {
                showToast(err, 'failure');
            }
        );
        if (language) {
            Language.setLanguage(language);
        }
        setState({ ...state, language:null, save_enabled: false });
    };

    return (
        <div className='user-portal__main-container user-portal__main-container--profile-settings'>
            <div className='profile-settings__heading mobile-heading z-40'>
                <h1>{Language.getTitleCase('Profile Settings')}</h1>
            </div>

            <div className='central-container profile-settings'>
                <div className='profile-settings__user-avatar-wrapper'>
                    <img
                        className='profile-settings__user-avatar'
                        src={usr_info.profile_url}
                        onClick={() => handleShowAvatar(true)}
                        alt={`Avatar image of ${name}`}
                    />
                    <a
                        href='#'
                        className='profile-settings__link hover:text-drip7'
                        onClick={() => handleShowAvatar(true)}>
                        {Language.getTitleCase('Change Avatar')}
                    </a>
                    {false && (
                        <a
                            href='#'
                            className='profile-settings__link'
                            onClick={() => navigate.current("/resetpwd")}>
                            {Language.getTitleCase('Reset Password')}
                        </a>
                    )}
                </div>

                <div className='profile-settings__main-fields'>
                    <div className='profile-settings__personal-information'>
                        <h2 className='profile-settings__menu-heading'>
                            {Language.getTitleCase('Personal Information')}
                        </h2>

                        <div className="user-info-content">
                            <Drip7Input
                                title={Language.getTitleCase('name')}
                                name='name'
                                value={name}
                                onChange={handleChange}>
                                <UserIcon className='h-6 w-6 text-gray-600 stroke-2'/>
                            </Drip7Input>

                            <Drip7Input
                                title={Language.getTitleCase('nickname')}
                                name='nickname'
                                value={nickname}
                                onChange={handleChange}>
                                <UsersIcon className='h-6 w-6 text-gray-600 stroke-2'/>
                            </Drip7Input>

                            <Drip7Input
                                title={Language.getTitleCase('email')}
                                name='email'
                                type='email'
                                value={email}
                                onChange={handleChange}>
                                <EnvelopeIcon className='h-6 w-6 text-gray-600 stroke-2'/>
                            </Drip7Input>

                            <Drip7Input
                                title={Language.getTitleCase('phone')}
                                name='phone'
                                value={phone}
                                onChange={handleChange}>
                                <PhoneIcon className='h-6 w-6 text-gray-600 stroke-2'/>
                            </Drip7Input>
                        </div>
                    </div>

                    {/* ****Does this need to be deleted?****

                    <div className="change-password-mobile" onClick={() => handleShowPassword(true)}>

                        <div className="lock-icon">
                            <svg width="14" height="16" viewBox="0 0 14 16" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M12.1562 7.90625H4.1875V5.15234C4.1875 3.59961 5.41797 2.31055 6.9707 2.28125C8.52344 2.28125 9.8125 3.54102 9.8125 5.09375V5.79688C9.8125 6.20703 10.1055 6.5 10.5156 6.5C10.8965 6.5 11.2188 6.20703 11.2188 5.79688V5.15234C11.2188 2.80859 9.34375 0.904297 7 0.875C4.65625 0.875 2.78125 2.7793 2.78125 5.09375V7.90625H1.84375C1.05273 7.90625 0.4375 8.55078 0.4375 9.3125V14.4688C0.4375 15.2598 1.05273 15.875 1.84375 15.875H12.1562C12.918 15.875 13.5625 15.2598 13.5625 14.4688V9.3125C13.5625 8.55078 12.918 7.90625 12.1562 7.90625ZM12.1562 14.4688H1.84375V9.3125H12.1562V14.4688ZM7 13.5312C7.43945 13.5312 7.82031 13.1797 7.82031 12.7109V11.0703C7.82031 10.6309 7.43945 10.25 7 10.25C6.53125 10.25 6.17969 10.6309 6.17969 11.0703V12.7109C6.17969 13.1797 6.53125 13.5312 7 13.5312Z"
                                    fill="var(--drip7-primary-color)" />
                            </svg>
                        </div>

                        <span>Change Password</span>

                        <div className="chevron-icon"><svg width="8" height="14" viewBox="0 0 8 14" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M7.66211 7.87305C7.92578 7.60938 7.92578 7.16992 7.66211 6.90625L1.97852 1.19336C1.68555 0.929688 1.24609 0.929688 0.982422 1.19336L0.308594 1.86719C0.0449219 2.13086 0.0449219 2.57031 0.308594 2.86328L4.82031 7.375L0.308594 11.916C0.0449219 12.209 0.0449219 12.6484 0.308594 12.9121L0.982422 13.5859C1.24609 13.8496 1.68555 13.8496 1.97852 13.5859L7.66211 7.87305Z"
                                fill="var(--drip7-primary-color)" />
                        </svg>
                        </div>

                    </div>*/}

                    <div className='profile-settings__toggles'>
                        {tenant.allow_anonymity &&
                            <div className='profile-settings__toggle profile-settings__toggle--multicare'>
                                <label className="text-md font-normal text-gray-700">
                                    {Language.getTitleCase( 'Leaderboard Visibility' )}
                                </label>
                                <Drip7Checkbox
                                    label={Language.getTitleCase('Leaderboard Visibility')}
                                    checked={leaderboard}
                                    name='leaderboard'
                                    onChange={handleChecked}
                                    />
                                {/*}
                                <label className='toggle'>
                                    <input
                                        type='checkbox'
                                        aria-label="checkbox"
                                        checked={leaderboard}
                                        name='leaderboard'
                                        onChange={handleChecked}
                                    />
                                    <span className='slider'></span>
                                </label>
                                {*/}
                            </div>
                        }

                        <div className='profile-settings__toggle profile-settings__toggle--multicare'>
                            <label className="text-md font-normal text-gray-700">
                                {Language.getTitleCase('Autoplay Videos')}
                            </label>
                            <Drip7Checkbox
                                label={Language.getTitleCase('Autoplay Videos')}
                                checked={autoplay_videos}
                                name='autoplay_videos'
                                onChange={handleChecked}
                            />
                                {/*}
                            <label className='toggle'>
                                <input
                                    type='checkbox'
                                    aria-label="checkbox"
                                    checked={autoplay_videos}
                                    name='autoplay_videos'
                                    onChange={handleChecked}
                                />
                                <span className='slider'></span>
                            </label>
                            {*/}
                        </div>

                        {/* {tenant.notify == Notification.NONE && */}
                        <div className='flex flex-col align-items-start'>
                            <label className="text-md font-normal text-gray-700">
                                {Language.getTitleCase( 'Notifications' )}
                            </label>

                            <div>
                                <Drip7Dropdown
                                    id='notify'
                                    name='notify'
                                    className="my-2"
                                    title={Language.getTitleCase( notify )}
                                    entries={Object.entries( Notification ).map( ( [key, value] ) => value )}
                                    onChange={handleDropdown}
                                    />
                            </div>
                        </div>
                        {/* } */}

                        {/* {tenant.notify != Notification.NONE &&
                            <div className='profile-settings__edit-field'>
                                <label className='profile-settings__label'>
                                    {Language.getSentenceCase( 'Notifications set by' )}
                                    &nbsp;
                                    {tenant.name}
                                </label>

                                <div className='dropdown'>
                                    <DropdownButton
                                        title={Language.getTitleCase( tenant.notify )}
                                        name='notify'
                                        disabled={true}>
                                    </DropdownButton>
                                </div>
                            </div>
                        } */}

                        {curr_language &&
                        <div className='mt-2 flex flex-col align-items-start'>
                            <label className='text-md font-normal text-gray-700'>
                                {Language.getTitleCase('language')}
                            </label>

                            <div>
                                <Drip7Dropdown
                                    id='language'
                                    name='language'
                                    className="my-2"
                                    title={Language.getTitleCase( curr_language )}
                                    entries={supported_langs}
                                    onChange={handleDropdown}
                                />
                            </div>

                            {/*}
                            <div className='dropdown'>
                                <DropdownButton title={Language.getTitleCase(curr_language)} name='access'>
                                    {supported_langs.map((key) => {
                                    return <Dropdown.Item
                                        id={'language-'+key.toLowerCase()}
                                        key={'language-'+key.toLowerCase()}
                                        as='button'
                                        onClick={() => handleChangeLanguage(key)}>
                                        {Language.getTitleCase(key)}
                                    </Dropdown.Item>
                                    })}
                                </DropdownButton>
                            </div>
                            {*/}
                        </div>
                        }
                    </div>

                    <div className="inline-flex flex-row flex-wrap items-end w-full">
                        <Drip7Button
                            disabled={!save_enabled}
                            className="w-full mt-6 sm:w-auto sm:mt-6"
                            onClick={handleSave}>
                            {Language.getTitleCase('Save Changes')}
                        </Drip7Button>
                        {false && tenant.allow_delete &&
                            /* Hide the Delete Account button */
                            <Drip7Button
                                color="red-500"
                                mode="outlined"
                                className="w-full mt-6 sm:ml-6 sm:mt-0 sm:w-auto"
                                onClick={handleConfirm}>
                                {Language.getTitleCase('Delete My Account')}
                            </Drip7Button>
                        }
                    </div>
                </div>
            </div>

            <ConfirmModalV2
                open={confirm !== null}
                danger={true}
                title={confirm?.title}
                desc={confirm?.desc}
                yes={confirm?.yes}
                no={confirm?.no}
                onClose={x => (x)? confirm.action(true): setState({ ...state, confirm: null })}
            />

            {show_avatar && (
                <AvatarModal
                    user_uid={uid}
                    profile_url={usr_info.profile_url}
                    avatar_info={avatars}
                    showToast={showToast}
                    onChange={handleChange}
                    onClose={() => handleShowAvatar(false)}
                />
            )}

            {show_password && (
                <PasswordModal
                    user_uid={uid}
                    onClose={() => handleShowPassword(false)}
                />
            )}
        </div>
    );
};
