import React, { useEffect, useState } from "react";

export const AnswerTypeNone = React.forwardRef((props, ref) => {
    const { onChanged } = props;

    React.useImperativeHandle(ref, () => ({
        reset: () => {
        },
        attemptAnswer: () => {
            onChanged( true, true, true )
            return true;
        }
    }));

    return (
        <div className='daily-questions__answers daily-questions__answers--slider'>
        </div>
    );
});
