import React from "react";

export const Drip7OriginalModal = props => {
    const { children } = props;

    //If we have no children included, then hide everything
    if (
        !children ||
        children.length <= 0 ||
        ("filter" in children && children.filter(x => x != false).length <= 0)
    ) {
        return <></>;
    }

    return <div className='popup-modal z-50'>{children}</div>;
};
