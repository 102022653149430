import React, {useEffect, useState} from "react";

import { NavigationPages } from "../../helpers/consts";
import Language from "../../helpers/language";
import { buildNavigation } from "../../helpers/content";
import Util from "../../helpers/util";
import { Drip7Button } from "../../components/drip7_button";
import {Drip7Dropdown} from "../../components/drip7_dropdown";
import {Drip7Input} from "../../components/drip7_input";
import {TrashIcon} from "@heroicons/react/20/solid";


export const TenantNavigationTab = (props) => {
    const {tenant_uid, tenants, reload, changed, onChanged, onRevert, onSave} = props

    const tenant = tenants.find( x => x.uid == tenant_uid )

    const [state, setState] = useState({
        navs: buildNavigation( tenant.navigations ),
    })
    const { navs } = state

    useEffect(() => {
        //If is the same id, and the reload hasn't been changed, we don't want to change anything
        if ( !reload ) {
            return
        }

        setState(prev => ({...prev,
            navs: buildNavigation( tenant.navigations )
        }))
    }, [reload])

    const handleChange = (idx, e) => {
        let { navs } = state
        navs[idx][e.target.id] = e.target.value

        console.log( navs )

        //Store the value
        setState(prev => ({...prev,
            navs
        }))

        onChanged({ ...props.initial_info, navigations: navs })
    }

    const handleDropdown = (idx, e) => {
        let { navs } = state
        if ( idx < 0 || idx >= navs.length ) {
            return
        }

        //Overwrite the codes
        navs[idx] = {
            code: e.target.value,
            name: Util.namify(e.target.value),
            path: NavigationPages[e.target.value],
        }

        setState(prev => ({...prev,
            navs
        }))

        onChanged({ ...props.initial_info, navigations: navs })
    }

    const handleAdd = () => {
        let {navs} = state

        const def = buildNavigation([])
        if ( navs.length < def.length ) {
            navs.push( def[navs.length] )
        }
        else {
            navs.push({
                code: 'CUSTOM',
                name: 'Custom',
                path: '',
            })
        }

        setState(prev => ({...prev,
            navs
        }))

        onChanged({ ...props.initial_info, navigations: navs })
    }

    const handleSave = () => {
        const info = { ...props.initial_info, navigations: navs }
        onSave( info )
    }

    const handleRemove = (idx) => {
        const navs = [...state.navs]
        navs.splice( idx, 1 )

        setState(prev => ({...prev,
            navs
        }))

        onChanged({ ...props.initial_info, navigations: navs })
    }

    return (
        <div className="px-4 sm:px-6 lg:px-8 mt-8 overflow-hidden">
            <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                    <h1 className="text-base font-semibold leading-6 text-gray-900">
                        {Language.getTitleCase("Navigation")}
                    </h1>
                    <p className="mt-2 text-sm text-gray-700">
                        {Language.getSentenceCase("Configure the navigation bar for your end users.")}
                    </p>
                </div>
                <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                    <Drip7Button
                        onClick={handleAdd}>
                        {Language.getTitleCase("Add Slot")}
                    </Drip7Button>
                </div>
            </div>
            <div className="mt-8 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div
                        className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <table className="min-w-full divide-y divide-gray-300">
                            <thead>
                            <tr>
                                <th scope="col"
                                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    {Language.getTitleCase("Page")}
                                </th>
                                <th scope="col"
                                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                    {Language.getTitleCase("Name")}
                                </th>
                                <th scope="col"
                                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    {Language.getTitleCase("Path")}
                                </th>
                                <th scope="col"
                                    className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                                    <span className="sr-only">Remove</span>
                                </th>
                            </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200">
                            {navs.map((nav, idx) => (
                                <tr key={`nav_${idx}`}>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                        <Drip7Dropdown
                                            id='code'
                                            name='code'
                                            entries={Object.keys(NavigationPages)}
                                            value={nav.code}
                                            onChange={e => handleDropdown(idx, e)}
                                            display={x => Util.namify(x)}
                                            overflow='max-h-60'
                                        />
                                    </td>
                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                                        <Drip7Input
                                            id='name'
                                            placeholder={Language.getTitleCase('Navigation name')}
                                            onChange={e => handleChange(idx, e)}
                                            value={nav.name}
                                        />
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                        <Drip7Input
                                            id='path'
                                            placeholder={Language.getTitleCase('/leaderboard')}
                                            onChange={e => handleChange(idx, e)}
                                            value={nav.path}
                                        />
                                    </td>
                                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                                        <a href="#"
                                           className="text-indigo-600 hover:text-indigo-900">
                                            <TrashIcon
                                                className="h-5 w-5 text-red-600"
                                                aria-hidden="true"
                                                onClick={() => handleRemove(idx)}/>
                                            <span
                                                className="sr-only">Remove {nav.name}</span>
                                        </a>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                        {changed &&
                            <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
                                <Drip7Button
                                    id='discard_changes'
                                    mode="outlined"
                                    onClick={onRevert}>
                                    {Language.getTitleCase( 'discard changes' )}
                                </Drip7Button>
                                <Drip7Button
                                    id='save_changes'
                                    onClick={handleSave}>
                                    {Language.getTitleCase( 'save changes' )}
                                </Drip7Button>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}