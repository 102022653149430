import React, {useState, useEffect, useRef} from "react";
import Util from "../helpers/util";
import Language from "../helpers/language";

import Chart from "react-apexcharts";


export const PhishCard = props => {
    const { cascade, tenant_uid, showToast } = props;

    const chart = useRef(null)
    const [state, setState] = useState({
        local_tenant_uid: '',
        local_cascade: null,
    });
    const { local_tenant_uid, local_cascade } = state

    const loadGraph = (series, categories) => {
        if (chart.current != null) {
            chart.current.destroy();
        }

        const options = {
            series,
            chart: {
                type: "bar",
                height: 320,
                stacked: true,
                toolbar: {
                    offsetY: -24,
                    tools: {
                         download: '<svg fill="#000000" height="20px" width="20px" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 212.973 212.973" xml:space="preserve"><g stroke-width="0"></g><g stroke-linecap="round" stroke-linejoin="round"></g><g> <g> <path d="M205.473,76.146c-4.143,0-7.5,3.358-7.5,7.5v103.32H15V83.646c0-4.142-3.357-7.5-7.5-7.5S0,79.503,0,83.646v110.82 c0,4.142,3.357,7.5,7.5,7.5h197.973c4.143,0,7.5-3.358,7.5-7.5V83.646C212.973,79.503,209.615,76.146,205.473,76.146z"></path> <path d="M101.171,154.746c1.407,1.407,3.314,2.197,5.304,2.197c1.989,0,3.896-0.79,5.304-2.197l32.373-32.374 c2.929-2.929,2.929-7.678,0-10.606c-2.93-2.93-7.678-2.929-10.607,0l-19.569,19.569l0.004-112.828c0-4.142-3.357-7.5-7.5-7.5 c-4.142,0-7.5,3.358-7.5,7.5l-0.004,112.829l-19.546-19.547c-2.929-2.929-7.677-2.93-10.607,0c-2.929,2.929-2.929,7.677,0,10.606 L101.171,154.746z"></path> </g> </g></svg>'
                      },
                    show: false, // Hide the toolbar

                }
            },
            plotOptions: {
                bar: {
                    horizontal: true
                }
            },
            stroke: {
                width: 1,
                colors: ["#fff"]
            },
            xaxis: {
                categories,
                labels: {
                    show: false
                }
                /*
                labels: {
                    formatter: function (val) {
                        return val + "K"
                    }
                }
                 */
            },
            yaxis: {
                title: {
                    text: undefined
                }
            },
            /*
            tooltip: {
                y: {
                    formatter: function (val) {
                        return val + "K"
                    }
                }
            },
             */
            fill: {
                opacity: 1
            },
            legend: {
                position: "top",
                horizontalAlign: "left",
                offsetX: 40
            }
        };

        chart.current = new ApexCharts(
            document.querySelector("#phishcard_chart"),
            options
        );
        chart.current.render();
    };

    useEffect(() => {
        //Bail
        if (tenant_uid == "" || (tenant_uid == local_tenant_uid && cascade == local_cascade)) {
            return;
        }

        //Query the stats
        Util.fetch_js( "/dashboard/phish_status/", { tenant_uid, cascade },
            js => {
                const categories = js.days.map(x =>
                    Util.toShortMonth(Util.dayToDate(x).getMonth())
                );
                const series = [
                    {
                        name: Language.getTitleCase('no action'),
                        data: js.no_action
                    },
                    {
                        name: Language.getTitleCase('reported'),
                        data: js.reported
                    },
                    {
                        name: Language.getTitleCase('opened'),
                        data: js.opened
                    },
                    {
                        name: Language.getTitleCase('failed'),
                        data: js.failed
                    }
                ];

                //Load up the graph with our data
                loadGraph(series, categories);
            },
            (err, code) => {
                showToast(err, 'failure');
            }
        )

        setState(prev => ({...prev,
            local_tenant_uid: tenant_uid,
            local_cascade: cascade,
        }))
    }, [props])

    //destroy card on unmounting - removes console errors
    useEffect(() => {
        return () => {
            if ( chart.current != null ) {
                chart.current.destroy()
            }
        }
    }, [])

    return (
        <div
            className='admin-card admin-card__large'
            style={{ minWidth: "600px" }}>
            <h3>{Language.getTitleCase('phishing')}</h3>
            <div id='phishcard_chart'></div>
        </div>
    );
};
