import React, { useEffect, useState } from "react";
import Util from "../../helpers/util";
import Language from "../../helpers/language"
import {
    ContentPhishWidget
} from "../../components/admin/content_phish_widget";
import {
    ContentPhishPreview
} from "../../components/admin/content_phish_preview";
import { ModalTabsWidget } from "../../components/modal_tabs_widget";

export const EmailNotificationModal = props => {
    const { tenant_uid, showToast, onClose } = props;

    const props_access = { read: true, write: true, exec: true }

    const [state, setState] = useState({
        access: props_access,
        template: props.template,
        preview: props.template,
        selected_idx: 0,
    });
    const { access, template, preview, selected_idx } = state;

    const tabs = [
        { name: Language.getTitleCase('preview'), icon: <svg width="15" height="15" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M5 10.4286C5 7.45982 7.375 5 10.4286 5H37.5714C40.5402 5 43 7.45982 43 10.4286V37.5714C43 40.625 40.5402 43 37.5714 43H10.4286C7.375 43 5 40.625 5 37.5714V10.4286ZM27.2232 16.5357C26.4598 17.3839 26.5446 18.6562 27.308 19.4196L32.4821 24L27.308 28.6652C26.5446 29.3437 26.4598 30.7009 27.2232 31.5491C27.9866 32.3125 29.2589 32.3973 30.1071 31.6339L36.8929 25.5268C37.317 25.1875 37.5714 24.5937 37.5714 24C37.5714 23.4911 37.317 22.8973 36.8929 22.558L30.1071 16.4509C29.2589 15.6875 27.9866 15.7723 27.2232 16.5357ZM20.6071 19.4196C21.3705 18.6562 21.4554 17.3839 20.692 16.5357C19.9286 15.7723 18.6562 15.6875 17.808 16.4509L11.0223 22.558C10.5982 22.8973 10.4286 23.4911 10.4286 24C10.4286 24.5937 10.5982 25.1875 11.0223 25.5268L17.808 31.6339C18.6562 32.3973 19.9286 32.3125 20.692 31.5491C21.4554 30.7009 21.3705 29.3437 20.6071 28.6652L15.433 24L20.6071 19.4196Z" fill='currentColor' /> </svg> },
        { name: Language.getTitleCase('template'), icon: <svg width='15' height='12' viewBox='0 0 15 12' fill='none' xmlns='http://www.w3.org/2000/svg'> <path d='M0.46875 2.625H12.6562C12.8906 2.625 13.125 2.41992 13.125 2.15625V1.21875C13.125 0.984375 12.8906 0.75 12.6562 0.75H0.46875C0.205078 0.75 0 0.984375 0 1.21875V2.15625C0 2.41992 0.205078 2.625 0.46875 2.625ZM14.5312 5.4375H2.34375C2.08008 5.4375 1.875 5.67188 1.875 5.90625V6.84375C1.875 7.10742 2.08008 7.3125 2.34375 7.3125H14.5312C14.7656 7.3125 15 7.10742 15 6.84375V5.90625C15 5.67188 14.7656 5.4375 14.5312 5.4375ZM12.6562 10.125H0.46875C0.205078 10.125 0 10.3594 0 10.5938V11.5312C0 11.7949 0.205078 12 0.46875 12H12.6562C12.8906 12 13.125 11.7949 13.125 11.5312V10.5938C13.125 10.3594 12.8906 10.125 12.6562 10.125Z' fill='currentColor'></path> </svg> },
    ]

    useEffect(() => {
        //Update the preview
        Util.fetch_js('/notification/preview/', { tenant_uid, ...template },
            js => {
                const { subject, template } = js
                setState(prev => ({ ...prev,
                    preview: { subject, template }
                }))
            },
            (err, code) => {
                showToast(err, 'failure');
            })
    }, []);

    //Save before exiting
    const handleSave = () => {
        if ( !access.write ) {
            return
        }

        let payload = {
            ...template,
            template_uid: template.uid,
            tenant_uid,
        };

        Util.fetch_js( "/notification/modify_template/", payload,
            js => {
                showToast(Language.getSentenceCase('updated'), 'successful');
                onClose(false);
            },
            (err, code) => {
                showToast(err, 'failure');
            }
        );
    };

    const handleContentChange = (_, content) => {
        if ( !access.write ) {
            return
        }

        const template = { ...props.template }
        if ( 'subject' in content ) {
            template.subject = content.subject
        }
        if ( 'body' in content ) {
            template.template = content.body
        }

        //Update the preview
        Util.fetch_js('/notification/preview/', { tenant_uid, ...template },
            js => {
                const { subject, template } = js
                setState(prev => ({ ...prev,
                    preview: { subject, template }
                }))
            },
            (err, code) => {
                showToast(err, 'failure');
            })

        console.log( template )
        setState(prev => ({ ...prev,
            template,
            preview: template
        }))
    }

    const handleSendTest = () => {
        // Create?
        const payload = {
            user_uids: [],
            group_uids: [],
            message: template.template,
            delivery: {
                push: false,
                email: true,
                sms: false,
            },
            test: true
        }
        Util.fetch_js( "/notification/send/", payload,
            js => {
                showToast(Language.getSentenceCase('Sent Message'), 'successful');
                onClose(true);
            },
            (err, code) => {
                showToast(err, 'failure');
            }
        );

        showToast(Language.getSentenceCase('Sending Message'), 'successful');
        setState(prev => ({ ...prev,
            allow_send: false,
        }))
    }

    const question = { uid: template.uid }
    const content = { subject: template.subject, body: template.template }
    const preview_content = { subject: preview.subject, body: preview.template }

    const hide_content = (selected_idx == 0)? {}: { display: 'none' }

    return (
        <div className='basic-modal basic-modal--create-curriculum'
             tabIndex={-1}
             role='dialog'
             aria-labelledby='phish_templates_modal'>
            <p className='hidden-heading'>{Language.getTitleCase('dialog content starts')}</p>
            <div className='basic-modal__head'>
                <h4>
                    {Language.getTitleCase('Email template')}
                    {!access.write ? ` ( ${Language.getTitleCase("read only")} )`: ''}
                </h4>
                <svg
                    className='basic-modal__close-button'
                    width='12'
                    height='11'
                    viewBox='0 0 12 11'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                    onClick={onClose}>
                    <path
                        d='M7.93359 5.375L10.8633 2.44531C11.2441 2.09375 11.2441 1.50781 10.8633 1.15625L10.2188 0.511719C9.86719 0.130859 9.28125 0.130859 8.92969 0.511719L6 3.44141L3.04102 0.511719C2.68945 0.130859 2.10352 0.130859 1.75195 0.511719L1.10742 1.15625C0.726562 1.50781 0.726562 2.09375 1.10742 2.44531L4.03711 5.375L1.10742 8.33398C0.726562 8.68555 0.726562 9.27148 1.10742 9.62305L1.75195 10.2676C2.10352 10.6484 2.68945 10.6484 3.04102 10.2676L6 7.33789L8.92969 10.2676C9.28125 10.6484 9.86719 10.6484 10.2188 10.2676L10.8633 9.62305C11.2441 9.27148 11.2441 8.68555 10.8633 8.33398L7.93359 5.375Z'
                        fill='currentColor'></path>
                </svg>
            </div>

            <button
                className='basic-modal__button basic-modal__button--solid mt-2'
                onClick={handleSendTest}>
                {Language.getTitleCase('Send Test')}
            </button>

            <ModalTabsWidget
                tabs={tabs}
                selected_idx={selected_idx}
                onChange={selected_idx => setState(prev => ({...prev, selected_idx }))}/>

                <div className='basic-modal__field-container' style={hide_content}>
                    <ContentPhishPreview
                        question={question}
                        content={preview_content}
                        showToast={showToast}
                    />
                </div>

            {selected_idx == 1 &&
                <div className='basic-modal__field-container'>
                    <div className='basic-modal__add-question basic-modal__add-question--text'>
                        <ContentPhishWidget
                            access={access}
                            question={question}
                            content={content}
                            showToast={showToast}
                            onChange={handleContentChange}
                        />
                    </div>
                </div>
            }

            <div className='basic-modal__button-container--save-bottom'>
                <button
                    className='basic-modal__button basic-modal__button--clear'
                    onClick={onClose}>
                    {Language.getTitleCase('cancel')}
                </button>
                <button
                    className='basic-modal__button basic-modal__button--solid'
                    disabled={!access.write}
                    onClick={handleSave}>
                    {Language.getTitleCase('save')}
                </button>
            </div>
            <p className='hidden-heading'>{Language.getTitleCase('dialog content ends')}</p>
        </div>
    );
};
