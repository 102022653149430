import React, { useEffect, useState } from "react";

import Util from "../../helpers/util";
import Language from "../../helpers/language";
import { ContentType } from "../../helpers/consts";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { Drip7Button } from "../../components/drip7_button";
import { Drip7Combobox } from "../../components/drip7_combobox";


export const DocumentCompletionTab = (props) => {
    const { tenant_uid, showToast } = props

    const [state, setState] = useState({
        search: "",
        sort_by: '',
        reverse: false,
        selected: {},
        delivered: 0,

        user_uid: null,
        user_questions: [],

        users: [],
        documents: [],
        document_uid: null,
    })
    const { search, sort_by, reverse, selected, delivered, user_uid, user_questions, users, documents, document_uid } = state

    useEffect(() => {
        Util.fetch_js( "/question/list/", { tenant_uid, include_types: [ContentType.Document], exclude_empty: true, detailed: true },
            js => {
                const documents = js.categories.map(cat => cat.questions ).flat()
                const document_uid = documents[0]?.uid

                updateUsers( document_uid )

                setState(prev => ({ ...prev,
                    documents,
                    document_uid,
                }))
            }, (reason, code) => {
                showToast(reason, 'failure');
            })
    }, [tenant_uid])

    const updateUsers = ( question_uid ) => {
        if ( question_uid == null ) {
            return
        }

        Util.fetch_js( "/question/document_summary/", { tenant_uid, question_uid },
            js => {
                console.log(js)
                setState(prev => ({ ...prev,
                    users: js.users,
                    user_uid: null,
                    user_questions: [],
                }))
            },
            (reason, code) => {
                showToast(reason, 'failure');
            }
        );
    }

    const handleUserView = (user_uid) => {
        if ( user_uid !== null ) {
            Util.fetch_js("/question/document_user_summary/", {user_uid},
                js => {
                    console.log(js)
                    setState(prev => ({
                        ...prev,
                        user_questions: js.user_questions,
                    }))
                },
                (reason, code) => {
                    showToast(reason, 'failure');
                }
            )
        }

        setState(prev => ({ ...prev,
            user_uid,
        }))
    }

    const handleChanged = (e) => {
        if ( e.target.id == "document_uid" ) {
            updateUsers( e.target.value )
        }
        setState(prev => ({...prev, [e.target.id]: e.target.value }))
    }

    const handleSortBy = (sort_by) => {
        const reverse = (state.sort_by == sort_by)
        if ( state.sort_by == sort_by && state.reverse ) {
            setState(prev => ({...prev,
                sort_by,
                reverse: false,
            }))
        }
        else {
            setState(prev => ({...prev,
                sort_by,
                reverse,
            }))
        }
    }

    const handleExportCsv = () => {
        //Export a member list, and then redirect to it
        if ( user_uid === null ) {
            Util.postDataAndOpenNewTab( "/report/policy_report/", { question_uid: document_uid } )
        }
        else {
            Util.postDataAndOpenNewTab( "/report/policy_user_report/", { user_uid } )
        }
    }

    const filterUsers = (users, search, sort_by, reverse) => {
        let filtered_users = users.filter(u => u.name.toLowerCase().includes(search.toLowerCase()) || u.email.toLowerCase().includes(search.toLowerCase()))
        if ( sort_by == 'name' ) {
            filtered_users = filtered_users.sort((a, b) => {
                return (!reverse)? a[sort_by].localeCompare(b[sort_by]): b[sort_by].localeCompare(a[sort_by])
            })
        }
        else if ( sort_by == 'start_day' ) {
            let convDate = d => {
                const ps = d.split('-');
                return Date.parse(ps[2] + '-' + (ps[0].length == 1 ? '0' + ps[0] : ps[0]) + '-' + (ps[1].length == 1 ? '0' + ps[1] : ps[1]))
            }
            filtered_users = filtered_users.sort((a, b) => {
                let conva = convDate(a[sort_by]);
                let convb = convDate(b[sort_by]);
                return (!reverse)? conva - convb: convb - conva;
            })
        }
        else if ( sort_by == 'progress' || sort_by == 'completion' ) {
            filtered_users = filtered_users.sort((a, b) => {
                if ( a[sort_by] == b[sort_by] ) {
                    return (!reverse)? (a.grade - b.grade): (b.grade - a.grade)
                }

                return (!reverse)? (a[sort_by] - b[sort_by]): (b[sort_by] - a[sort_by])
            })
        }
        else if ( sort_by != '' ) {
            filtered_users = filtered_users.sort((a, b) => {
                return (!reverse)? (a[sort_by] - b[sort_by]): (b[sort_by] - a[sort_by])
            })
        }

        return filtered_users
    }

    const filtered_users = (user_uid === null)? filterUsers( users, search, sort_by, reverse ): user_questions.map(x => ({ user_question: x, name: x.question.name, uid: x.question.uid}))
    const document = (document_uid != null)? documents.find(d => d.uid === document_uid) : { name: "Queue" }

    return (
        <div className='curriculum main-modal'>
            <div className='central__main central__main--management'>
                <div className='central__head'>
                    <div className='central__head-search-wrapper'>
                        <svg
                            width='15'
                            height='16'
                            viewBox='0 0 15 16'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'>
                            <path
                                d='M14.8828 14.6152L11.3379 11.0703C11.25 11.0117 11.1621 10.9531 11.0742 10.9531H10.6934C11.6016 9.89844 12.1875 8.49219 12.1875 6.96875C12.1875 3.62891 9.43359 0.875 6.09375 0.875C2.72461 0.875 0 3.62891 0 6.96875C0 10.3379 2.72461 13.0625 6.09375 13.0625C7.61719 13.0625 8.99414 12.5059 10.0781 11.5977V11.9785C10.0781 12.0664 10.1074 12.1543 10.166 12.2422L13.7109 15.7871C13.8574 15.9336 14.0918 15.9336 14.209 15.7871L14.8828 15.1133C15.0293 14.9961 15.0293 14.7617 14.8828 14.6152ZM6.09375 11.6562C3.48633 11.6562 1.40625 9.57617 1.40625 6.96875C1.40625 4.39062 3.48633 2.28125 6.09375 2.28125C8.67188 2.28125 10.7812 4.39062 10.7812 6.96875C10.7812 9.57617 8.67188 11.6562 6.09375 11.6562Z'
                                fill='currentColor'></path>
                        </svg>
                        <input
                            type='text'
                            className='central__head-search rounded-md border-gray-200 bg-white text-gray-900 placeholder-text-gray-400 focus:border-drip7 focus:ring-0 sm:text-sm sm:leading-6'
                            placeholder={Language.getSentenceCase('Search by name') + '...'}
                            id='search'
                            value={search}
                            onChange={handleChanged}
                        />
                    </div>

                    <div className='central__head-button-container'>
                        <Drip7Button
                            onClick={handleExportCsv}>
                            {Language.getTitleCase('Export')}
                        </Drip7Button>
                        {user_uid === null &&
                        <Drip7Combobox
                            entries={documents.map(t => ({name: t.name, uid: t.uid}))}
                            selected_idx={documents.findIndex(t => t.uid === document.uid)}
                            onEntry={e => handleChanged({target: {id: 'document_uid', value: e.uid }})}/>
                        }
                        {user_uid !== null &&
                        <Drip7Button
                            mode="outlined"
                            onClick={() => handleUserView(null)}>
                            {Language.getTitleCase('Back')}
                        </Drip7Button>
                        }
                    </div>
                </div>

                <table className='management__table'>
                    <tbody>
                    <tr className='management__table-row management__table-row--head'>
                        <th className='management__table-head management__table-head--input'>
                            <div className='d7__checkbox-container'>
                            </div>
                        </th>
                        <th className='management__table-head management__table-head--name'
                            onClick={() => handleSortBy( 'name' )}>
                            <h5 className='management__table-heading'>
                                {Language.getTitleCase( 'name' )}
                            </h5>
                        </th>
                        <th className='management__table-head management__table-head--questions'
                            onClick={() => handleSortBy( 'start_day' )}>
                            <h5 className='management__table-heading'>
                                {Language.getTitleCase( 'Assigned on' )}
                            </h5>
                        </th>
                        <th className='management__table-head management__table-head--questions'
                            onClick={() => handleSortBy( 'start_day' )}>
                            <h5 className='management__table-heading'>
                                {Language.getTitleCase( 'Answered on' )}
                            </h5>
                        </th>
                        <th className='management__table-head management__table-head--questions'
                            onClick={() => handleSortBy( 'completion' )}>
                            <h5 className='management__table-heading'>
                                {Language.getTitleCase( 'accepted' )}
                            </h5>
                        </th>
                    </tr>
                    {Object.entries( filtered_users ).map( ( [key, user] ) => (
                        <tr
                            className='management__table-row management__table-row--body'
                            key={"report_row_" + key}>
                            <td className='management__table-data management__table-data--input'>
                                <div className='d7__checkbox-container'>
                                </div>
                            </td>
                            <td className='management__table-data management__table-data--name'
                                onClick={() => setState( prev => ({ ...prev }) )}>
                                {user_uid === null &&
                                <h5 className='management__table-text cursor-pointer text-drip7'
                                    onClick={e => handleUserView(user.uid)}>
                                    {user.name}
                                </h5>
                                }
                                {user_uid !== null &&
                                    <h5 className='management__table-text cursor-pointer text-drip7'
                                        onClick={e => handleChanged({target: {id: 'document_uid', value: user.uid }})}>
                                        {user.name}
                                    </h5>
                                }
                            </td>
                            <td className='management__table-data'
                                onClick={() => setState( prev => ({ ...prev }) )}>
                                <h5 className='management__table-text'>
                                    {user?.user_question?.assigned_on !== undefined && user?.user_question?.assigned_on !== null && user?.user_question?.assigned_on !== 0?
                                        <div>
                                            {Util.friendlyDate( Util.unixToDate(user?.user_question?.assigned_on) )}
                                            &nbsp;({Math.floor(Math.abs(Util.epoch() - user?.user_question?.assigned_on) / (24 * 3600000))}d)
                                        </div> : '-'
                                    }
                                </h5>
                            </td>
                            <td className='management__table-data'
                                onClick={() => setState( prev => ({ ...prev }) )}>
                                <h5 className='management__table-text'>
                                    {(user?.user_question?.answered_on !== null && user?.user_question?.answered_on !== 0)?
                                        Util.friendlyDate( Util.unixToDate(user?.user_question?.answered_on) ) :
                                        '-'}
                                </h5>
                            </td>
                            <td className='management__table-data'
                                onClick={() => setState( prev => ({ ...prev }) )}>
                                {user?.user_question?.assigned_on &&
                                <h5 className='management__table-text'>
                                    {(user?.user_question?.answered_on !== null && user?.user_question?.answered_on !== 0)?
                                        (user?.user_question?.correct ? <CheckCircleIcon
                                            className="w-5 h-5 text-green-500"/> :
                                        <XCircleIcon
                                            className="w-5 h-5 text-red-500"/>) : '-'
                                    }
                                </h5>
                                }
                            </td>
                        </tr>) )}
                    </tbody>
                </table>
            </div>
        </div>);
};
