//*******************************
//  TODO: 1. use the actual phrase in en-us for keys
//        2. add {0} parameters (similar to C#) to support parameters
//        3. check in everything, including the translation json files
//***************

import Util from './util'
import { Languages } from './consts';

export default class Language {
    static _strings;
    static _language;

    static getSupportedLanguages() { 
        return Object.values(Languages).map((l) => l.toLowerCase())
    }

    static getLanguageFriendlyName(language) {
        //Every localized language file has the __name__ key for the name to display for that language
        return Util.titleCase(require('../assets/languages/'+language+'.json')['__name__']);
    }

    static getLanguage() {
        if (!Language._language) {
            Language.setBrowserDefaultLanguage()
        }
        return Language._language;
    }

    static setBrowserDefaultLanguage() {
        navigator.languages.every((l) => {
            if (Language.getSupportedLanguages().includes(l.toLowerCase())) {
                Language.setLanguage(l);
                return false;
            }
            return true;
        });
        // If we don't support the browser languages, default to en-US
        if (!Language._language) {
            Language.setLanguage('en-us');
        }
    }

    static setLanguage(language) {
        
        language = language.toString().toLowerCase()
        if (Language.getSupportedLanguages().includes(language)) {
            Language._language = language;
            Language._strings = require('../assets/languages/'+language+'.json')
        }
    }

    static getString(key, clean_err) {
        if (!Language._language) {
            Language._language = Language.getLanguage();
        }
        if (!Language._strings) {
            Language._strings = require('../assets/languages/'+Language._language+'.json')
        }

        //Clean up the key
        key = Util.xstr(key).toLowerCase()

        //Remove leading white space so we can match
        let leading = ''
        const ws_match = key.match(/^(\s*)/)
        if ( ws_match ) {
            leading = ws_match[1]
            key = key.replace(/^(\s*)/, '')
        }

        //If there's nothing to translate, return the empty string
        if (key.length == 0)
            return ''

        //Pull my key, and we hit
        if ( key in Language._strings ) {
            return leading + Language.cleanAcronyms(Language._strings[key].toLocaleLowerCase(Language._language))
        }

        //Export an error
        key = leading + key
        return (clean_err == true)? key : 'locale key not found: ' + key
    }

    static getSentenceCase(key, clean_err) {
        const s = Language.getString(key, clean_err)
        return Util.capitalize(s)
    }
    static getLowerCase(key, clean_err) {
        const s = Language.getString(key, clean_err)
        return Util.lowercase(s)
    }

    //Return the string in title case
    static getTitleCase(key, clean_err) {
        const s = Language.getString(key, clean_err)
        return Util.titleCase(s)
    }

    static cleanAcronyms(s) {
        return s.replace(/CSV|PDF|SSO|URI/gi, (w) => { return w.toLocaleUpperCase(Language._language) })
    }
}