import { CheckIcon } from '@heroicons/react/24/solid'
import Util from "../../helpers/util";

export const Steps = props => {
    const { step_idx, onChange } = props
    const step_names = props.step_names || ['Code error', 'Please add', 'Some step names']

    return (
        <nav aria-label="Progress" className="bg-white">
            <ol role="list" className="divide-y divide-gray-300 rounded-md border border-gray-300 md:flex md:divide-y-0">
                {step_names.map((name, idx) => (
                    <li key={name} className="relative md:flex md:flex-1">
                        {(idx < step_idx) &&
                            <div className="group flex w-full items-center"
                                 onClick={() => onChange( idx )}>
                                <span className="flex items-center px-6 py-4 text-sm font-medium">
                                    <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-drip7 group-hover:brightness-95">
                                        <CheckIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                    </span>
                                    <span className="ml-4 text-sm font-medium text-gray-600">{name}</span>
                                </span>
                            </div>
                        }
                        {(idx === step_idx) &&
                            <div className="flex items-center px-6 py-4 text-sm font-medium"
                                 aria-current="step"
                                 onClick={() => onChange( idx )}>
                                <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-drip7">
                                    <span className="text-drip7">{Util.lpad( idx + 1, 2, '0' )}</span>
                                </span>
                                <span className="ml-4 text-sm font-medium text-gray-900">{name}</span>
                            </div>
                        }
                        {(idx > step_idx) &&
                            <div className="group flex items-center"
                                 onClick={() => onChange( idx )}>
                                <span className="flex items-center px-6 py-4 text-sm font-medium">
                                    <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300 group-hover:border-gray-400">
                                        <span className="text-gray-500 group-hover:text-gray-900">{Util.lpad( idx + 1, 2, '0' )}</span>
                                    </span>
                                    <span className="ml-4 text-sm font-medium text-gray-500 group-hover:text-gray-900">{name}</span>
                                </span>
                            </div>
                        }

                        {/* Arrow separator for lg screens and up */}
                        {(step_idx < step_names.length) &&
                            <div className="absolute right-0 top-0 hidden h-full w-5 md:block" aria-hidden="true">
                                <svg className="h-full w-full text-gray-300"
                                     viewBox="0 0 22 80"
                                     fill="none"
                                     preserveAspectRatio="none">
                                    <path
                                        d="M0 -2L20 40L0 82"
                                        vectorEffect="non-scaling-stroke"
                                        stroke="currentcolor"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </div>
                        }
                    </li>
                ))}
            </ol>
        </nav>
    )
}
