import Language from "../../helpers/language";
import Util from "../../helpers/util";

export const QueueActiveWidget = props => {
    const { active, start_day, end_day } = props.queue;

    const today = Util.dateToDay(new Date().getTime());

    //We're off, simple
    if (!active) {
        return <h5 className='management__table-text'>{Language.getSentenceCase('off')}</h5>;
    }

    //Return the date this is valid for
    if (start_day > 0 || end_day > 0) {
        const style =
            start_day <= today && end_day >= today ? { color: "green" } : {};
        return (
            <h5 className='management__table-text' style={style}>
                {Util.formatDate(Util.dayToDate(start_day))} -{" "}
                {Util.formatDate(Util.dayToDate(end_day))}
            </h5>
        );
    }

    //Again, Just provide the most basic "Yes" we are doing this
    return (
        <h5 className='management__table-text' style={{ color: "green" }}>
            {Language.getSentenceCase('active')}
        </h5>
    );
};
