import React from "react";
import { sha256 } from "js-sha256";
import Util from "../helpers/util";
import { useStore } from "../store";
import InputWithIcon from "../components/input_with_Icon";
import Logo from "../assets/images/shared/drip7-logo.svg";
import { useNavigate } from "react-router-dom";
import Language from "../helpers/language";
import {Drip7Input} from "../components/drip7_input";
import {EnvelopeIcon, EyeIcon, LockClosedIcon, LockOpenIcon, PhoneIcon, UserIcon} from "@heroicons/react/24/outline";
import {Drip7Button} from "../components/drip7_button";

//State wrapper
const withStore = BaseComponent => props => {
    const { setCsrfIfEmpty, setUsrInfo } = useStore(state => state)

    return (
        <BaseComponent
            {...props}
            setCsrfIfEmpty={setCsrfIfEmpty}
            setUsrInfo={setUsrInfo}
            navigate={useNavigate()}
        />
    );
};

class Klass extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            errored: "",

            name: "",
            email: "",
            phone: "",
            password: "",
            password_repeat: "",
            show_password: false,
        };

        this.handleKeypress = this.handleKeypress.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleRegister = this.handleRegister.bind(this);
    }

    handleKeypress(event) {
        // Enter pressed? register
        if (event.key === 'Enter') {
            this.handleRegister();
        }
    }

    handleChange(event) {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({ [name]: value });
    }

    handleRegister() {
        const { email, name, phone, password, password_repeat } = this.state;

        if (password != password_repeat) {
            this.setState({ errored: Language.getString("Passwords don't match") });
            return;
        }

        if (password.length < 8) {
            this.setState({
                errored: Language.getString("Password needs to be 8 or more characters")
            });
            return;
        }

        const payload = { email, name, phone, pwd_hash: sha256(password) };
        Util.fetch_js(
            "/human/register/",
            payload,
            js => {
                const my_data = {
                    ...js.usr_info,
                    tenant: js.tenant
                };

                this.props.setUsrInfo(my_data);
                this.props.navigate(js.usr_info.portal)

                //If we're in a react-native app, send a message that the user is logged in
                if (window.ReactNativeWebView)
                    window.ReactNativeWebView.postMessage(js.usr_info);
            },
            (reason, code) => {
                this.setState({ errored: reason });
                console.log(reason);
            }
        );
    }

    render() {
        const { navigate, initial_tenant } = this.props
        const { name, email, phone, password, password_repeat, errored, show_password } = this.state

        const logo = initial_tenant.full_logo || Logo

        return (
            <div className="isolate bg-white px-6 py-20 sm:py-32 lg:px-8">
                <div aria-hidden="true"
                    className="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden sm:top-[-20rem] bg-drip7">
                    <div
                        style={{
                            clipPath:
                                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                        }}
                        className="relative left-1/2 -z-10 aspect-[1155/678] w-[36.125rem] max-w-none -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-40rem)] sm:w-[72.1875rem]"
                    />
                </div>
                <div className="mx-auto max-w-xl">
                    <h2 className="text-white text-3xl font-bold tracking-tight text-gray-700 sm:text-white sm:text-4xl">
                        Try the {initial_tenant.name} Training
                    </h2>
                    <p className="text-white mt-1 ml-1.5">Register and try our platform for free —&nbsp;
                        <span className="font-semibold">no credit card required.</span>
                    </p>
                    <p className="sm:text-white mt-3 ml-1.5">
                        <span className="text-body-emphasis">Register below for a free user experience trial – and experience the {initial_tenant.name} difference. If you would like a platform administration trial, please contact sales.</span>
                    </p>

                    <div className="w-full inline-flex justify-center mt-4">
                        <iframe loading="lazy"
                                title="Install Missing Plugin"
                                src="https://player.vimeo.com/video/883430807?dnt=1&amp;app_id=122963"
                                frameBorder="0" allow="autoplay; fullscreen; picture-in-picture"
                                data-player_id="883430807"
                                data-player_url="https://player.vimeo.com/video/883430807"
                                name="fitvid0"
                                data-ready="true"
                                data-player_title="Install Missing Plugin"
                                data-player_duration="27">
                        </iframe>
                    </div>
                </div>
                <form action="#" method="POST" className="mx-auto mt-16 max-w-xl sm:mt-20">
                    <div className="flex flex-col gap-6">
                    {errored != "" && (
                        <div className='register-error' role='alert' aria-atomic='true'>
                            <div className='register-error-wrap'>
                                <p>{Language.getString('error')}. {errored}.</p>
                            </div>
                        </div>
                    )}

                        <Drip7Input
                            id='name'
                            name='name'
                            type='text'
                                    placeholder={Language.getString("name")}
                                    value={name}
                                    onKeyDown={this.handleKeypress}
                                    onChange={this.handleChange}>
                            <UserIcon className='h-6 w-6 text-gray-600 stroke-2'/>
                        </Drip7Input>
                        {/*}
                        <InputWithIcon
                            inputProps={{
                                type: "input",
                                placeholder: Language.getString('name') + ' *',
                                name: "name",
                                autoCapitalize: "off",
                                autoComplete: "off",
                                autoCorrect: "off",
                                spellCheck: "false",
                                onKeyPress: this.handleKeypress,
                                onChange: this.handleChange
                            }}
                            icon={"icon user-icon"}
                        />
                        {*/}

                        <Drip7Input
                            id='email'
                            name='email'
                            type='email'
                            placeholder={Language.getString("email")}
                            value={email}
                            onKeyDown={this.handleKeypress}
                            onChange={this.handleChange}>
                            <EnvelopeIcon className='h-6 w-6 text-gray-600 stroke-2'/>
                        </Drip7Input>
                        {/*}
                        <InputWithIcon
                            inputProps={{
                                "aria-label":"Email",
                                type: "email",
                                placeholder: Language.getString('email') + ' *',
                                name: "email",
                                autoCapitalize: "off",
                                autoComplete: "off",
                                autoCorrect: "off",
                                spellCheck: "false",
                                onKeyPress: this.handleKeypress,
                                onChange: this.handleChange
                            }}
                            icon={"email-icon"}
                        />
                        {*/}

                        <Drip7Input
                            id='phone'
                            name='phone'
                            type='phone'
                            placeholder={Language.getString("phone")}
                            value={phone}
                            onKeyDown={this.handleKeypress}
                            onChange={this.handleChange}>
                            <PhoneIcon className='h-6 w-6 text-gray-600 stroke-2'/>
                        </Drip7Input>

                        <Drip7Input
                            id='password'
                            name='password'
                            type={show_password ? 'text': 'password'}
                            view={show_password}
                            placeholder={Language.getString("password")}
                            value={password}
                            onKeyDown={this.handleKeypress}
                            onChange={this.handleChange}>
                            <div>
                                {show_password &&
                                    <LockOpenIcon className='h-6 w-6 text-gray-600 stroke-2'/> }
                                {!show_password &&
                                    <LockClosedIcon className='h-6 w-6 text-gray-600 stroke-2'/> }
                            </div>
                            <EyeIcon
                                className='z-50 h-6 w-6 text-gray-600 stroke-2 cursor-pointer'
                                onClick={() => this.setState({show_password: !this.state.show_password})}
                            />
                        </Drip7Input>
                        {/*}
                        <InputWithIcon
                            inputProps={{
                                "aria-label":"Password",
                                type: "password",
                                placeholder: Language.getString('password') + ' *',
                                name: "password",
                                autoCapitalize: "off",
                                autoComplete: "off",
                                autoCorrect: "off",
                                spellCheck: "false",
                                onKeyPress: this.handleKeypress,
                                onChange: this.handleChange
                            }}
                            icon={"icon lock-icon"}
                            secondIcon={"icon eye-icon"}
                        />
                        {*/}

                        <Drip7Input
                            id='password_repeat'
                            name='password_repeat'
                            type={show_password ? 'text': 'password'}
                            view={show_password}
                            placeholder={Language.getString("verify password") + ' *'}
                            value={password_repeat}
                            onKeyDown={this.handleKeypress}
                            onChange={this.handleChange}>
                            <div>
                                {show_password &&
                                    <LockOpenIcon className='h-6 w-6 text-gray-600 stroke-2'/> }
                                {!show_password &&
                                    <LockClosedIcon className='h-6 w-6 text-gray-600 stroke-2'/> }
                            </div>
                            <EyeIcon
                                className='z-50 h-6 w-6 text-gray-600 stroke-2 cursor-pointer'
                                onClick={() => this.setState({show_password: !this.state.show_password})}
                            />
                        </Drip7Input>
                        {/*}
                        <InputWithIcon
                            inputProps={{
                                "aria-label": "Repeat Password",
                                type: "password",
                                placeholder: Language.getString('verify password') + ' *',
                                name: "password_repeat",
                                autoCapitalize: "off",
                                autoComplete: "off",
                                autoCorrect: "off",
                                spellCheck: "false",
                                onKeyPress: this.handleKeypress,
                                onChange: this.handleChange
                            }}
                            icon={"icon lock-icon"}
                            secondIcon={"icon eye-icon"}
                        />
                        {*/}

                        <Drip7Button
                            type='submit'
                            className='px-10 w-min text-lg'
                            onClick={this.handleRegister}>
                            {Language.getTitleCase('register')}
                        </Drip7Button>

                        <a href='#' className="text-drip7 font-semibold" onClick={() => navigate("/login")}>
                            {Language.getTitleCase('Back to Login')}
                        </a>

                        <div>
                            <p>{Language.getTitleCase('Need Help Logging In? Contact')}</p>
                            <a href='https://drip7.com/contact-us/' className="text-drip7 font-semibold">{Language.getTitleCase('Support Center')}</a>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

export const Register = withStore(Klass);
