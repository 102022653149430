import { useStore } from "../store";
import axios from "axios";
import Language from "./language";

export default class Util {
    // call this to setup a websocket connection
    // url: the url to connect to
    // setSocket: is a state hook to set the socket
    // routes: is a dictionary of routes to call when a message is received
    // failure: is a callback to call when a message is received but the server
    static connectWS(url, setSocket, routes={}, failure=null) {
        const socket = new WebSocket( url );

        //Setup a default failure callback if none is given
        if ( failure == null ) {
            failure = (ep, reason) => { console.log(`EP [ ${ep} ]: ${reason}`) }
        }

        //Handle routes from the server
        socket.onmessage = (event) => {
            const { ep, succ, resp } = JSON.parse( event.data )

            //Failure, quit
            if ( !succ ) {
                return failure( ep, resp )
            }

            //Invalid callback?
            if ( !(ep in routes) ||
                routes[ep] == undefined ||
                routes[ep] == null ) {
                return failure( ep, "Missing route" )
            }

            //Success, call the callback
            routes[ep]( resp )
        };

        //Default routes
        /*
        socket.onopen = () => {
            console.log('WebSocket connected');
        };
         */
        socket.onclose = () => {
            //console.log('WebSocket disconnected');
            setSocket(null)
        };

        //Callback!
        setSocket( socket )
        return socket
    };

    static sendWS( socket, ep, params ) {
        if ( socket == null || socket.readyState != WebSocket.OPEN ) {
            console.log("Socket is invalide")
            return null
        }

        return socket.send( JSON.stringify({ ep, params }) )
    }

    static sendFileWS( socket, file, callback=null ) {
        const reader = new FileReader();

        //Default callback
        if ( callback == null ) {
            callback = (prog) => { console.log("Progress: " + prog) }
        }

        reader.onload = function(event) {
            console.log("Sending file...")

            const buffer = event.target.result
            const chunkSize = 1024 * 1024 // 1 MB

            //Send out chunks
            for (let offset = 0; offset < buffer.byteLength; offset += chunkSize) {
                const size = Math.min(chunkSize, buffer.byteLength - offset)
                const chunk = new Uint8Array(buffer, offset, size)
                socket.send(chunk)

                callback( (offset / buffer.byteLength) * 100 )
            }

            callback( 100 )
        }

        /*
        reader.onprogress = function(event) {
            console.log("Prog...")
            if (callback != null && event.lengthComputable) {
                callback( (event.loaded / event.total) * 100 )
            }
        };
        */

        console.log("Read as binary....")
        reader.readAsArrayBuffer(file)
    }

    static closeWS( socket ) {
        if ( socket ) {
            socket.close()
        }
    }

    static fetch_js(url, js, succ, err) {
        const { csrf_token } = useStore.getState();

        //Defaults?
        if (succ == undefined || succ == null) {
            succ = js => { };
        }
        if (err == undefined || err == null) {
            err = (reason, code) => {
                console.log(reason + " Code: " + code);
            };
        }

        //Load in the data
        const form_data = new FormData();
        Object.keys(js).forEach(key => {
            if (js[key] == null) {
                return;
            }

            let content = js[key];
            if (content instanceof File) {
            }
            else if (Array.isArray(content) || typeof content === "object") {
                console.log(key, content)
                content = JSON.stringify(content);
            }

            form_data.append(key, content);
        });

        //Build the header
        let options = { method: "GET" };
        if (js != null) {
            options = {
                method: "POST",
                headers: {
                    //'Content-Type': 'application/json',
                    "X-CSRFToken": csrf_token
                },
                body: form_data
                //body: JSON.stringify( js )
            };
        }

        try {
            //Query
            fetch(url, options)
                .then(resp => {
                    //We had a sever related error, can't do anything useful
                    if (!resp.ok) {
                        return {
                            successful: false,
                            reason: Language.getTitleCase("server communication error"),
                            code: resp.status
                        };
                    }

                    //Server had a valid response, pass it off to deal with the data
                    return resp.json();
                })
                .then(js => {
                    if (js.successful) {
                        succ(js);
                    }
                    else {
                        err(js.reason, js.code);
                    }
                });
        }
        catch (e) {
            err( 'Network communication error', 0 )
        }
    }

    static fetch_ex(url, js, succ, err, prog) {
        const { csrf_token } = useStore.getState();
        //console.log( csrf_token )

        //Defaults?
        if (succ == undefined || succ == null) {
            succ = js => { };
        }
        if (err == undefined || err == null) {
            err = (reason, code) => {
                console.log(reason + " Code: " + code);
            };
        }
        if (prog == undefined || prog == null) {
            prog = perc => { };
        }

        //Load in the data
        const form_data = new FormData();
        Object.keys(js).forEach(key => {
            if (js[key] == null) {
                return;
            }

            let content = js[key];
            if (content instanceof File) {
            }
            else if (Array.isArray(js[key]) || typeof content === "object") {
                content = JSON.stringify(content);
            }

            form_data.append(key, content);
        });

        //Setup the options
        const config = {
            onUploadProgress: p => {
                if (p.total <= 0) {
                    prog(100);
                }

                prog(Math.round((p.loaded * 100) / p.total));
            },
            headers: {
                "X-CSRFToken": csrf_token
            }
        };

        //Axios post
        axios
            .post(url, form_data, config)
            .then(function (res) {
                succ(res.data);
            })
            .catch(function (err) {
                err(err.reason, err.code);
            });
    }

    static postDataAndOpenNewTab(url, data) {
        // Create a form
        const form = document.createElement('form');
        form.method = 'POST';
        form.action = url
        form.target = '_blank'; // To open in a new tab

        // Add data to the form
        Object.keys(data).forEach(key => {
            const input = document.createElement('input');
            input.type = 'hidden';
            input.name = key;
            if (Array.isArray(data[key]) || typeof data[key] === "object") {
                console.log(data[key], "ARray")
                input.value = JSON.stringify(data[key]);
            }
            else {
                console.log(data[key])
                input.value = data[key];
            }
            form.appendChild(input);
        });

        // Append the form to the body
        document.body.appendChild(form);

        // Submit the form
        form.submit();

        // Remove the form from the body
        document.body.removeChild(form);
    };

    /*
  static fetch_raw(url, js) {
      const { csrf_token } = useStore.getState();
      let header = { method: 'GET' };
      if ( js != null ) {
          header = {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
                  'X-CSRFToken': csrf_token,
              },
              body: JSON.stringify(js)
          };
      }

      //Query
      return fetch(url, header).then(resp => resp.json())
  }
  */

    //Helper to take the json blob, and break the logic paths into success and erro
    static response(js, succ, err) {
        if (js.successful) {
            succ(js);
        }
        else if (err != undefined) {
            err(js.reason, js.code);
        }
        else {
            console.error(js.reason);
        }
    }

    static handleLogout( current, msal, auth_type) {
        // Don't log out fully of SSO, just log out of Drip7
        // if ( auth_type == AuthenticationType.AZURE_SSO ) {
        //     //Log out of Azure SSO
        //     msal.instance.logoutRedirect().catch(e => {
        //         console.error(e.errorMessage)
        //     });
        // }

        //Reset language to browser default
        Language.setBrowserDefaultLanguage();

        //Logout
        Util.fetch_js(
            "/human/logout/",
            {},
            js => {
                current("/login", { auth_type: auth_type });
            },
            (reason, code) => {
                current("/login", { auth_type: auth_type });
            }
        );
    }

    static isbool(raw, allow_str=false) {
        if ( raw == undefined || raw == null ) {
            return false
        }

        return (typeof raw === "boolean" || raw instanceof Boolean)
    }

    static xbool(raw, def=false) {
        if ( raw == null || raw == undefined ) {
            return def
        }
        //Main path
        else if ( typeof raw === "boolean" || raw instanceof Boolean ) {
            return raw
        }
        else if ( typeof raw === "object" && !(raw instanceof String) ) {
            return def
        }

        return this.xstr(raw, 'false').toLowerCase() == "true"
    }

    static xint(raw, def=0) {
        if ( raw == null || raw == undefined ) {
            return def
        }
        else if ( typeof raw === "boolean" || raw instanceof Boolean ) {
            return (raw == true)? 1: 0
        }
        else if ( typeof raw === "object" && !(raw instanceof String) ) {
            return def
        }

        let num = parseInt(raw)
        return !isNaN(num) ? num : def;
    }

    static xfloat(raw, def=0) {
        if ( raw == null || raw == undefined ) {
            return def
        }
        else if ( typeof raw === "boolean" || raw instanceof Boolean ) {
            return (raw == true)? 1: 0
        }
        else if ( typeof raw === "object" && !(raw instanceof String) ) {
            return def
        }

        let num = parseFloat(raw)
        return !isNaN(num) ? num : def;
    }

    static xstr(raw, def='') {
        if ( raw == null || raw == undefined ) {
            return def
        }
        else if ( typeof raw === "boolean" || raw instanceof Boolean ) {
            return raw? "true": "false"
        }
        else if ( typeof raw === "object" && !(raw instanceof String) ) {
            return def
        }

        return `${raw}`
    }

    static capitalize(string) {
        if ( string == undefined || string == null || string == '' ) {
            return ''
        }

        if ( string.length <= 1 ) {
            return string.charAt(0).toLocaleUpperCase(Language.getLanguage())
        }

        return string.charAt(0).toLocaleUpperCase(Language.getLanguage()) + string.slice(1).toLocaleLowerCase();
    }

    static lowercase(string) {
        if ( string == undefined || string == null || string == '' ) {
            return ''
        }

        if ( string.length <= 1 ) {
            return string.charAt(0).toLocaleLowerCase(Language.getLanguage())
        }

        return string.charAt(0).toLocaleLowerCase(Language.getLanguage()) + string.slice(1).toLocaleLowerCase();
    }

    static titleCase(s) {
        let words = s.split(' ').map(w => { return w.charAt(0).toLocaleUpperCase(Language.getLanguage()) + w.slice(1)})
        return words.join(' ')
    }

    static namify(string) {
        if ( string == undefined || string == null || string == '' ) {
            return ''
        }

        const ary = string
            .replace("/", "_")
            .replace(" ", "_")
            .replace("-", "_")
            .split("_");

        let result = [];
        for (let i = 0; i < ary.length; i++) {
            result.push(Util.capitalize(ary[i]));
        }

        return result.join(" ");
    }

    static roundNumber(number, digits) {
        var multiple = Math.pow(10, digits);
        return Math.round(number * multiple) / multiple;
    }

    static dateToUtc( localDate ) {
        // Get the time zone offset in minutes
        const timeZoneOffsetMinutes = localDate.getTimezoneOffset();

        // Calculate the UTC time by subtracting the offset
        const utcMilliseconds = localDate.getTime() + timeZoneOffsetMinutes * 60000;

        // Create a new Date object for the UTC time
        return new Date(utcMilliseconds);
    }

    static unixToDate(ms) {
        if (ms !== null && ms !== undefined) {
            var d = new Date(0);
            d.setUTCSeconds(Math.floor(ms / 1000));
            return d;
        }

        return null
    }

    static epoch(ms) {
        if (ms !== null && ms !== undefined) {
            var d = new Date(0);
            d.setUTCSeconds(Math.floor(ms / 1000));
            return d;
        }

        return new Date().getTime();
    }

    static epochToDate(ms, include_year=true) {
        let d = new Date(0);
        d.setUTCSeconds(Math.floor(ms / 1000));

        return Util.formatDate(d, include_year);
    }

    static epochToTime(ms, short) {
        var d = new Date(0);
        d.setUTCSeconds(Math.floor(ms / 1000));

        if (short === true) {
            var min = d.getMinutes();
            var hour = d.getHours();
            var am = "am";
            if (hour >= 12) {
                hour -= 12;
                am = "pm";
            }

            if (hour === 0) hour = 12;

            return hour + ":" + lpad((min % 60).toString(), 2, "0") + am;
        }
        else return d.toLocaleTimeString();
    }

    static epochToDateTime(ms) {
        return epochToDate(ms) + " " + epochToTime(ms, true);
    }

    static epochExpanded(ms) {
        var date = new Date(0);
        date.setUTCSeconds(Math.floor(ms / 1000));

        //Setup the 12 hour format
        var hour = date.getHours();
        var hour_12 = hour % 12;
        if (hour_12 === 0) {
            hour_12 = 12;
        }

        return {
            month: date.getMonth() + 1,
            day: date.getDate(),
            year: date.getFullYear(),

            hour: hour,
            hour_12: hour_12,
            minute: date.getMinutes(),
            am_pm: hour < 12 ? "AM" : "PM"
        };
    }

    static lpad(str, count, char) {
        str = str.toString();
        if (char === undefined || char === null) char = " ";
        while (str.length < count) str = char + str;

        return str;
    }

    static rpad(str, count, char) {
        str = str.toString();
        if (char === undefined || char === null) char = " ";
        while (str.length < count) str = str + char;

        return str;
    }

    static formatDate(date, include_year=true) {
        var year = date.getFullYear();
        var month = date.getMonth();
        var day = date.getDate();

        if ( include_year ) {
            return month + 1 + "/" + day + "/" + year
        }
        else {
            return month + 1 + "/" + day
        }
    }

    static formatTime(ms, show_label, am_pm) {
        var sec = Math.floor(ms / 1000);
        if (show_label === undefined || show_label === null) show_label = true;
        if (am_pm === undefined || am_pm === null) am_pm = false;

        //Raw data!
        if (show_label === false) {
            var min = Math.round(sec / 60);
            if (min < 60) return min;

            return (
                Math.floor(min / 60) + ":" + lpad((min % 60).toString(), 2, "0")
            );
        }

        var minutes = Math.floor(sec / 60) % 60;
        var hours = Math.floor(sec / 3600);

        if (!am_pm)
            return (
                hours +
                ":" +
                lpad(minutes.toString(), 2, "0") +
                "." +
                lpad(Math.floor(sec % 60).toString(), 2, "0")
            );

        var am = "am";
        if (hours >= 12) {
            hours -= 12;
            am = "pm";
        }

        if (hours === 0) hours = 12;
        return hours + ":" + lpad(minutes.toString(), 2, "0") + am;
    }

    static simpleTimestamp(ms) {
        var sec = Math.floor((epoch() - ms) / 1000);
        if (sec < 60) {
            sec = 60;
        }

        //Years
        if (sec >= 12 * 30 * 24 * 3600) {
            return Math.floor(sec / (12 * 30 * 24 * 3600)) + "yr";
        }
        else if (sec >= 30 * 24 * 3600) {
            return Math.floor(sec / (30 * 24 * 3600)) + "mo";
        }
        else if (sec >= 24 * 3600) {
            return Math.floor(sec / (24 * 3600)) + "d";
        }
        else if (sec >= 3600) {
            return Math.floor(sec / 3600) + "h";
        }
        else {
            return Math.floor(sec / 60) + "min";
        }
    }

    static durationTuple(ms) {
        if (ms < 0) {
            ms = 0;
        }

        var seconds = ms / 1000;

        //Pad zeros
        var min = Math.floor(seconds / 60) % 60;
        var sec = Math.floor(seconds) % 60;
        if (min < 10) {
            min = "0" + min;
        }
        if (sec < 10) {
            sec = "0" + sec;
        }

        //Write the time
        return [Math.floor(seconds / 3600) + ":" + min, sec];
    }

    static humanDuration(ms, zero_time) {
        if (zero_time === undefined || zero_time === null) {
            zero_time = Language.getSentenceCase('now');
        }

        var sec = Math.floor(ms / 1000);
        if (sec <= 0) return zero_time;
        if (sec === 1) return "1 " + Language.getString('second');
        if (sec < 60) return Math.floor(sec) + " " + Language.getString('seconds');

        var hour = Math.floor(sec / 3600);
        var min = Math.floor(sec / 60) % 60;
        if (hour === 0) {
            if (min === 1) return "1 " + Language.getString('minute');
            else return min + " " + Language.getString('minutes');
        }

        if (hour === 1) {
            if (min === 0) return "1 " + Language.getString('hour');
            else if (min === 1) return "1 " + Language.getString('hour') + " " + Language.getString('and') + " 1 " + Language.getString('minute');
            else return "1 " + Language.getString('hour') + " " + Language.getString('and') + " " + min + " " + Language.getString('minutes');
        }

        if (min === 0) return hour + " " + Language.getString('hours');
        else if (min === 1) return hour + " " + Language.getString('hours') + " " + Language.getString('and') + " 1 " + Language.getString('minute');
        else return hour + " " + Language.getString('hours') + " and " + min + " " + Language.getString('minutes');
    }

    static isLeapYear(date) {
        if ( date == undefined || date == null ) {
            date = new Date()
        }

        let year = date.getFullYear();
        if ((year & 3) != 0) return false;

        return year % 100 != 0 || year % 400 == 0;
    }

    static dayToDate(days) {
        return new Date(days * 8.64e7 - new Date().getTimezoneOffset() * 60000);
    }

    static dateToDay(date, skip_utc) {
        const offset =
            skip_utc != true ? new Date().getTimezoneOffset() * 60000 : 0;
        return Math.round((date + offset) / 8.64e7);
    }

    // Get Day of Year
    static getDayName(dow) {
        return ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"][dow];
    }

    static getDayNameFull(dow) {
        const days = [
            Language.getSentenceCase('monday'),
            Language.getSentenceCase('tuesday'),
            Language.getSentenceCase('wednesday'),
            Language.getSentenceCase('thursday'),
            Language.getSentenceCase('friday'),
            Language.getSentenceCase('saturday'),
            Language.getSentenceCase('sunday')
        ]

        return ( dow != undefined )? days[dow]: days
    }

    static toLongMonth(idx) {
        if (idx < 0 || idx > 11) {
            return "---";
        }

        return [
            Language.getSentenceCase("january"),
            Language.getSentenceCase("february"),
            Language.getSentenceCase("march"),
            Language.getSentenceCase("april"),
            Language.getSentenceCase("may"),
            Language.getSentenceCase("june"),
            Language.getSentenceCase("july"),
            Language.getSentenceCase("august"),
            Language.getSentenceCase("september"),
            Language.getSentenceCase("october"),
            Language.getSentenceCase("november"),
            Language.getSentenceCase("december")
        ][idx];
    }

    static shortMonths() {
        return [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec"
        ]
    }

    static toShortMonth(idx) {
        if (idx < 0 || idx > 11) {
            return "";
        }

        return Util.shortMonths()[idx]
    }

    static friendlyDate(d) {
        if ( d === null || d === undefined ) {
            return "-"
        }
        var date = d.getDate();
        var month = d.getMonth();
        var year = d.getFullYear();

        return `${date} ${Util.toShortMonth(month)} ${year}`;
    }

    static getMonthDoy( month ) {
        if ( month < 0 || month > 11 ) {
            return 0
        }
        return [0, 31, 59, 90, 120, 151, 181, 212, 243, 273, 304, 334][month]
    }

    static getDoy(date) {
        if ( date == undefined || date == null ) {
            date = new Date()
        }

        const mn = date.getMonth();
        const dn = date.getDate();

        let day_of_year = Util.getMonthDoy( mn ) + dn;
        if (mn > 1 && Util.isLeapYear(date)) {
            day_of_year++;
        }

        return day_of_year;
    }

    static doyToMonthDay(doy) {
        if ( doy >= 400 ) {
            doy = Util.getDoy( Util.dayToDate(doy) ) - 1
        }

        for ( let month = 11; month >= 0; month-- ) {
            if ( Util.getMonthDoy( month ) > doy ) {
                continue
            }

            return {
                month: month + 1,
                day: doy - Util.getMonthDoy( month ) + 1,
                short_mon: Util.toShortMonth( month ),
                large_mon: Util.toLongMonth( month ),
            }
        }

        return {
            month: 1,
            day: 1,
            short_mon: Util.toShortMonth( 0 ),
            large_mon: Util.toLongMonth( 0 ),
        }
    }

    static daysInYear(date) {
        return 365 + (Util.isLeapYear( date )? 1: 0)
    }

    static addIst( num ) {
        if ( num <= 0 ) {
            return `${num}`
        }

        //Any number 10 -- 19 is an 'th'
        if ( Math.floor(num / 10) == 1 ) {
            return `${num}th`
        }

        //Add 1st, 2nd, 3rd, or Xth
        switch ( num % 10 ) {
            case 1:
                return `${num}st`

            case 2:
                return `${num}nd`

            case 3:
                return `${num}rd`

            default:
                return `${num}th`
        }
    }

    static isObject(obj) {
        return (
            typeof obj === "object" &&
            obj !== null &&
            Object.keys(obj).length > 0
        );
    }

    static binaryObjSearch(ary, key, search) {
        var min = 0;
        var max = ary.length - 1;
        var mid;

        while (min <= max) {
            mid = (min + max) >>> 1;
            if (ary[mid][key] === search) {
                return mid;
            }
            else if (ary[mid][key] < search) {
                min = mid + 1;
            }
            else {
                max = mid - 1;
            }
        }

        //Return the mid
        mid = (min + max) >>> 1;
        if (mid < 0) {
            return 0;
        }
        else if (mid >= ary.length) {
            return ary.length - 1;
        }
        else {
            return mid;
        }
    }

    static hyperlink( _text ) {
        let text = _text || ''
        const go_here = `<a href="$1" target="_blank">${Language.getSentenceCase("Go Here")}</a>`
        text = text.replace(/(http:\/\/[^\s]+)/g, go_here)
        text = text.replace(/(https:\/\/[^\s]+)/g, go_here)

        return text
    }

    static hyperlinkMD( _text ) {
        let text = _text || ''
        const go_here = `[${Language.getSentenceCase("Go Here")}]($1)`
        text = text.replace(/(http:\/\/[^\s]+)/g, go_here)
        text = text.replace(/(https:\/\/[^\s]+)/g, go_here)

        return text
    }

    static truncate( str, len ) {
        if ( str.length <= len ) {
            return str
        }
        else {
            return str.substring( 0, len - 3 ) + "..."
        }
    }

    static setFavicon( href ) {
        let link = document.querySelector("link[rel*='icon']") || document.createElement('link')
        link.type = 'image/x-icon'
        link.rel = 'shortcut icon'
        link.href = href
        document.getElementsByTagName('head')[0].appendChild(link)
    }

    static classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    static toCurrency( pennies, include_cents=true ) {
        if (typeof pennies !== 'number' || isNaN(pennies)) {
            return 'Invalid input';
        }

        const dollars = pennies / 100;
        if (pennies % 100 == 0 && !include_cents ) {
            //return `$${Math.floor(dollars).toFixed(0)}`
            return `$${dollars.toLocaleString('en-US')}`
        } else {
            //return `$${dollars.toFixed(2)}`
            return dollars.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD'
            });
        }
    }

    static condenseNumber( num ) {
        if ( num < 10 ) {
            return num
        }
        else if ( num < 100 ) {
            return Math.floor( num / 10 ) * 10 + '+'
        }
        else if ( num < 1000 ) {
            return Math.round( num / 100 ) * 100 + '+'
        }
        else {
            return (Math.round( num / 100 ) / 10).toFixed(1) + 'k'
        }
    }

    static boundedPercentage( percentage ) {
        const total_percentage = Math.min( Math.max( percentage, 0.1 ), 99 )
        const ready_percentage = (total_percentage >= 2)? Math.round( total_percentage ): total_percentage.toFixed(1)

        return { total_percentage, ready_percentage }
    }

    //Convert a number into a string with commas
    static numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
}
