import React, {useEffect} from "react";
import {v4 as uuidv4} from 'uuid';
import QRCode from "react-qr-code";
import {
    Circle,
    Document,
    Ellipse,
    G,
    Line,
    Page,
    Path,
    PDFDownloadLink,
    Polygon,
    Polyline,
    Rect,
    StyleSheet,
    Svg,
    View
} from '@react-pdf/renderer';
import Language from "../../helpers/language";
import {Drip7Button} from "../drip7_button";
import Util from "../../helpers/util";


export const AnswerQRCodeWidget = props => {
    const { access, name, onChange } = props;
    const pdf_filename = (name && name.length > 0 ? `${name}.pdf` : 'qrcode.pdf' )
    const ref_qrcode = React.createRef();

    let correct = ('correct' in props.answer) ? props.answer.correct : uuidv4()
    if ( Array.isArray(correct) ) {
        correct = correct.join(',')
    }

    useEffect(() => {
        onChange('answer', {}, { correct });
    }, [])

    const svgToReactPDF = (obj, idx) => {
        // https://abulasar.com/creating-a-react-component-to-render-normal-svg-in-react-pdf

        let name = obj.tagName !== undefined ? obj.tagName : obj.type;
        let props = Object.assign({},
            ...Array.from(obj.attributes, ({name, value}) => ({[name]: value}))
        );
        let children =
            obj.children !== undefined ? (
            [...obj.children].map((c, i) => {
                return svgToReactPDF(c, i);
            })
        ) : (
            <></>
        );



        if (name === "svg") {
            return <Svg key={idx} {...props}>{children}</Svg>;
        }
        else if (name === "path") {
            return <Path key={idx} {...props}>{children}</Path>;
        }
        else if (name === "line") {
            return <Line key={idx} {...props}>{children}</Line>;
        }
        else if (name === "polyline") {
            return <Polyline key={idx} {...props}>{children}</Polyline>;
        }
        else if (name === "polygon") {
            return <Polygon key={idx} {...props}>{children}</Polygon>;
        }
        else if (name === "rect") {
            return <Rect key={idx} {...props}>{children}</Rect>;
        }
        else if (name === "circle") {
            return <Circle key={idx} {...props}>{children}</Circle>;
        }
        else if (name === "ellipse") {
            return <Ellipse key={idx} {...props}>{children}</Ellipse>;
        }
        else if (name === "g") {
            return <G key={idx} {...props}>{children}</G>;
        }
        else {
            console.log(`oops: ${name}`)
            return <></>
        }
    }

    const GetPDF = () => {
        const qrcode = ref_qrcode.current;
        const pdf = svgToReactPDF(qrcode)

        const styles = StyleSheet.create({
            page: {
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center'
            },
            view: {
                width: '256px',
                height: '256px'
            }
        });
        
          
        return (
            <Document>
                <Page size="A4" style={styles.page}>
                    <View style={styles.view}>
                        {pdf}
                    </View>
                </Page>
            </Document>
        )
    }

    return (
        <>
            <div className='basic-modal__add-image-container width-100'>
                <QRCode ref={ref_qrcode} size={256}
                    viewBox={`0 0 256 256`}
                    value={correct}/>
            </div>
            <div className='basic-modal__add-image-container width-100'>
                <PDFDownloadLink document={<GetPDF />} fileName={pdf_filename}>
                    {({ blob, url, loading, error }) => (<Drip7Button
                            disabled={loading}
                            nameBtn='get-qrcode'>
                            {Language.getTitleCase('Download')}
                        </Drip7Button>
                    )}
                </PDFDownloadLink>
            </div>
        </>
    );
};
