import React, { useState } from "react";

/*
  This example requires some changes to your config:

  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
export default function Example() {
    return (
        <div>
            <label htmlFor="price" className="block text-sm font-medium leading-6 text-gray-900">
                Price
            </label>
            <div className="relative mt-2 rounded-md shadow-sm">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <span className="text-gray-500 sm:text-sm">$</span>
                </div>
                <input
                    id="price"
                    name="price"
                    type="text"
                    placeholder="0.00"
                    aria-describedby="price-currency"
                    className="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
          <span id="price-currency" className="text-gray-500 sm:text-sm">
            USD
          </span>
                </div>
            </div>
        </div>
    )
}
*/

/**
 * @param {{label?:string,inputProps:React.InputHTMLAttributes<HTMLInputElement>,icon:"email-icon" |"user-icon" |"search-icon" |"lock-icon" |"eye-icon" ,secondIcon,fullWidth:boolean}}
 * @returns {JSX.Element}
 */
const InputWithIcon = ({ label, inputProps, icon, secondIcon, fullWidth }) => {
    const [viewText, setViewText] = useState();
    const [addicionalStyle, setAddicionalStyle] = useState();

    const handleViewPass = () => {
        setAddicionalStyle("view");
        setTimeout(() => setAddicionalStyle(undefined), [150]);
        setTimeout(() => setViewText(prev => !prev), [200]);
    };

    return (
        <div className={`input-whit-icon-container ${fullWidth ? "fullwidth" : ""}`}>
            {label && <label htmlFor={label}>{label}</label>}
            <div className={`input-with-icon`}>
                {icon && <i className={icon} />}
                <input
                    className={`${icon ? "icon" : ""} ${addicionalStyle ?? ""}`}
                    {...inputProps}
                    {...(secondIcon && viewText ? { type: "text" } : "")}
                    {...(label ? { id: label } : "")}
                />
                {secondIcon && (
                    <i className={secondIcon} onClick={handleViewPass} />
                )}
            </div></div>
    );
};

export default InputWithIcon;
