import React, { useEffect, useState} from 'react'
import { RadioGroup } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/20/solid'
import Language from "../../helpers/language";
import Util from "../../helpers/util";


const frequencies = [
    //{ value: 'price_1month', label: 'Monthly', priceSuffix: '/month', mult: 1 },
    { value: 'price_12month', label: '1 Year', priceSuffix: '/month', mult: 12 },
    { value: 'price_36month', label: '3 Years', priceSuffix: '/month', mult: 36 },
]

export const TenantSubscriptionTab = (props) => {
    const {tenant_uid, tenants, showToast, onChanged} = props

    const tenant = tenants.find( x => x.uid === tenant_uid )

    const [frequency, setFrequency] = useState(tenant.pricing)

    const [content_module_uids, setContentModuleUids] = useState(
        tenant.content_module_uids
    )
    const [package_uid, setPackageUid] = useState(
        tenant.package_uid
    )

    const [state, setState] = useState({
        modules: [],
        packages: [],
    })
    const { modules, packages } = state

    const selected = { borderColor: 'var(--drip7-primary-color)', borderWidth: '1.5px'}
    const unselected = { borderWidth: '1px'}

    useEffect(() => {
        Util.fetch_js( "/subscription/list/", {},
            js => {
                setState(prev => ({ ...prev,
                    modules: js.modules,
                    packages: js.packages,
                }))
            }, showToast )

    }, []);

    const handleToggleContentModule = ( content_module_uid ) => {
        const url = content_module_uids.indexOf( content_module_uid ) >= 0 ? "/subscription/unsubscribe/" : "/subscription/subscribe/"

        Util.fetch_js( url, { tenant_uid: tenant.uid, content_module_uid },
            js => {
                setContentModuleUids( js.content_module_uids )
            }, showToast )
    }

    const handlePackage = ( package_uid ) => {
        Util.fetch_js( "/subscription/package/", { tenant_uid: tenant.uid, package_uid },
            js => {
                setPackageUid( js.package.uid )
            }, showToast )
    }

    //This really doesn't work without being 3 columns
    const cols = 3 //Math.max(packages.length, 3)

    const cur_frequency = frequencies.find( f => f.value == frequency )
    const discount = packages.map( p =>
        Math.round( ((p['price_1month'] > 0)? Math.abs((p[frequency] - p.price_1month) / p.price_1month): 0) * 100 )
    )

    const buyButtonText = (pkg_uid) => {
        const cur_idx = packages.findIndex( p => p.uid === package_uid )
        const pkg_idx = packages.findIndex( p => p.uid === pkg_uid )

        if ( tenant.pricing === frequency ) {
            if (cur_idx < pkg_idx) {
                return Language.getTitleCase("Upgrade Plan")
            }
            else if (cur_idx > pkg_idx) {
                return Language.getTitleCase("Extend and Downgrade Plan")
            }
            else {
                return Language.getTitleCase("Current Plan")
            }
        }
        else {
            if (cur_idx < pkg_idx) {
                return Language.getTitleCase("Extend and Upgrade Plan")
            }
            else if (cur_idx > pkg_idx) {
                return Language.getTitleCase("Extend and Downgrade Plan")
            }
            else {
                return Language.getTitleCase("Extend Plan")
            }
        }
    }

    const freq_count = frequencies.length === 3 ? 'grid-cols-3' : 'grid-cols-2'

    return (
        <div className="m-5">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-4xl text-center">
                    <h2 className="text-base font-semibold leading-7 text-drip7">Pricing</h2>
                    <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                        Pricing plans for companies of&nbsp;all&nbsp;sizes
                    </p>
                </div>
                <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
                    Choose an affordable plan that’s packed with the best
                    features for engaging your audience, creating customer
                    loyalty, and driving sales.
                </p>
                <div className="mt-16 flex justify-center">
                    <RadioGroup
                        value={cur_frequency}
                        onChange={f => setFrequency( f.value )}
                        className={Util.classNames("grid gap-x-1 rounded-full p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-gray-200",
                            freq_count
                        )}>
                        <RadioGroup.Label className="sr-only">Payment
                            frequency</RadioGroup.Label>
                        {frequencies.map( ( option ) => (<RadioGroup.Option
                                key={option.value}
                                value={option}
                                className={( { checked } ) => Util.classNames( checked ? 'bg-drip7 text-white' : 'text-gray-500', 'cursor-pointer rounded-full px-2.5 py-1' )}>
                                <span>{option.label}</span>
                            </RadioGroup.Option>) )}
                    </RadioGroup>
                </div>
                <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                    {packages.map( ( pkg, idx ) => (<div key={`packages_${idx}`}
                                                         onClick={() => setPackageUid( pkg.uid )}
                                                         className={Util.classNames(
                                                             (pkg.uid === package_uid) ? 'ring-2' : 'ring-1 ring-gray-200',
                                                             'rounded-3xl p-8', `w-1/${cols}` )}
                                                         style={pkg.most_popular ? { borderColor: 'var(--drip7-primary-color)' } : {}}>
                            <div className="flex items-center justify-between gap-x-4">
                                <h3 className={Util.classNames( (pkg.most_popular) ? 'text-drip7' : 'text-gray-900', 'text-lg font-semibold leading-8' )}>
                                    {pkg.name}
                                </h3>
                                {discount[idx] > 0 &&
                                    <p className="rounded-full bg-green-600/10 px-2.5 py-1 text-xs font-semibold leading-5 text-green-600">
                                        {`Save ${discount[idx]}%`}
                                    </p>}
                            </div>
                            <p className="mt-4 text-sm leading-6 text-gray-600">{pkg.description}</p>
                            <p className="mt-6 flex items-baseline gap-x-1">
                                <span className="text-4xl font-bold tracking-tight text-gray-900">
                                    {Util.toCurrency( pkg[cur_frequency.value], false )}
                                </span>
                                <span className="text-sm font-semibold leading-6 text-gray-600">
                                    {cur_frequency.priceSuffix}
                                </span>
                            </p>
                            {cur_frequency.value !== 'price_1month' &&
                                <p className="flex items-baseline gap-x-1">
                                    <span
                                        className="text-sm font-semibold line-through leading-6 text-gray-600">{Util.toCurrency( pkg['price_1month'] * cur_frequency.mult )}</span>
                                    <span
                                        className="text-sm font-bold leading-6 text-gray-900">{Util.toCurrency( pkg[cur_frequency.value] * cur_frequency.mult )}</span>
                                    <span
                                        className="text-sm leading-6 text-gray-600">
                                        for {cur_frequency.label.toLowerCase()} /per seat
                                    </span>
                                </p>}
                            <div aria-describedby={pkg.id}
                                 onClick={() => onPackage( pkg.uid )}
                                 className={Util.classNames( pkg.most_popular ? 'bg-drip7 text-white shadow-sm hover:bg-drip7' : 'text-drip7 ring-1 ring-inset ring-drip7 hover:ring-drip7', 'mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-drip7' )}>
                                {buyButtonText(pkg.uid)}
                            </div>
                            <ul role="list"
                                className="mt-8 space-y-3 text-sm leading-6 text-gray-600 xl:mt-10">
                                {pkg.features.map( ( feature ) => (
                                    <li key={feature} className="flex gap-x-3">
                                        <CheckIcon
                                            className="h-6 w-5 flex-none text-drip7"
                                            aria-hidden="true"/>
                                        {feature}
                                    </li>) )}
                            </ul>
                        </div>)
                    )}
                </div>
            </div>

            <h2 className="text-3xl font-bold tracking-tight text-gray-900 mt-lg-5">
                {Language.getTitleCase( "Content Modules" )}
            </h2>
            <ul role="list"
                className="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-1 xl:gap-x-8 mt-3">
                {modules.map( ( module, idx ) => (<li key={module.uid}
                                                      className={Util.classNames( "overflow-hidden rounded-xl", content_module_uids.indexOf( module.uid ) >= 0 ? "ring-2" : "ring-1 ring-gray-200", )}
                                                      style={content_module_uids.indexOf( module.uid ) >= 0 ? { borderColor: 'var(--drip7-primary-color)' } : {}}
                                                      onClick={() => handleToggleContentModule( module.uid )}>
                        <div
                            className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
                            <img src={module.compact_logo}
                                 alt={module.name}
                                 className="h-12 w-12 flex-none rounded-lg bg-white object-cover ring-1 ring-gray-900/10"/>
                            <div
                                className="text-sm font-medium leading-6 text-gray-900">{module.name}</div>
                        </div>
                        <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
                            {module.info.map( ( x, idx ) => (
                                <div key={`${module.uid}_${idx}`}
                                     className="flex justify-between gap-x-4 py-3">
                                    <dt className="text-gray-500">{x.key}</dt>
                                    <dd className="text-gray-700">
                                        <div
                                            className="font-medium text-gray-900">{x.value}</div>
                                    </dd>
                                </div>) )}
                        </dl>
                    </li>) )}
            </ul>
        </div>)
}