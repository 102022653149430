import React, { useEffect, useState } from "react";

import Util from "../../helpers/util";
import Language from "../../helpers/language";

import { QuestionsTab } from "./questions_tab";
import { CategoriesTab } from "./categories_tab";
import { PhishTemplatesTab } from "./phish_templates_tab";
import {Access} from "../../helpers/consts";
import {ReportGenerateTab} from "./report_generate_tab";
import {AdminLogTab} from "./admin_log_tab";
import { ReportProgressTab } from "./report_progress_tab";

export const Reports = (props) => {
    const { tenant_uid, tenants, showToast } = props;

    const [state, setState] = useState({
        selected_idx: 0
    })
    const { selected_idx } = state

    useEffect(() => {
        props.onPathChange("reports")
    }, [])

    return (
        <div className='questions main-modal'>
            <nav className='central__nav'>
                <ul className='central__nav-list'>
                    <li className={ "central__nav-item central__nav-item--small " + (selected_idx == 0 ? "central__nav-item--selected" : "") }
                        onClick={() => setState(prev => ({...prev, selected_idx: 0 }))}>
                        <svg width="15" height="15" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill='var(--drip7-primary-color)' d="M15.4707 9.3125H8.52734L13.1562 13.9707C13.332 14.1465 13.625 14.1465 13.8008 13.9707C14.9434 12.916 15.7344 11.4805 15.9395 9.83984C15.998 9.57617 15.7637 9.3125 15.4707 9.3125ZM15.002 7.4375C14.7676 3.92188 11.9844 1.13867 8.46875 0.904297C8.20508 0.875 8 1.10938 8 1.37305V7.90625H14.5332C14.7969 7.90625 15.0312 7.70117 15.002 7.4375ZM6.59375 9.3125V2.36914C6.59375 2.10547 6.33008 1.87109 6.06641 1.90039C2.55078 2.39844 -0.115234 5.44531 0.03125 9.10742C0.148438 12.8574 3.37109 15.9336 7.15039 15.875C8.61523 15.875 9.99219 15.4062 11.1055 14.5859C11.3398 14.4395 11.3398 14.0879 11.1348 13.8828L6.59375 9.3125Z" />
                        </svg>
                        {Language.getTitleCase('Progress')}
                    </li>
                    <li className={ "central__nav-item central__nav-item--small " + (selected_idx == 1 ? "central__nav-item--selected" : "") }
                        onClick={() => setState(prev => ({...prev, selected_idx: 1 }))}>
                        <svg width='15' height='12' viewBox='0 0 15 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
                            <path
                                d='M0.46875 2.625H12.6562C12.8906 2.625 13.125 2.41992 13.125 2.15625V1.21875C13.125 0.984375 12.8906 0.75 12.6562 0.75H0.46875C0.205078 0.75 0 0.984375 0 1.21875V2.15625C0 2.41992 0.205078 2.625 0.46875 2.625ZM14.5312 5.4375H2.34375C2.08008 5.4375 1.875 5.67188 1.875 5.90625V6.84375C1.875 7.10742 2.08008 7.3125 2.34375 7.3125H14.5312C14.7656 7.3125 15 7.10742 15 6.84375V5.90625C15 5.67188 14.7656 5.4375 14.5312 5.4375ZM12.6562 10.125H0.46875C0.205078 10.125 0 10.3594 0 10.5938V11.5312C0 11.7949 0.205078 12 0.46875 12H12.6562C12.8906 12 13.125 11.7949 13.125 11.5312V10.5938C13.125 10.3594 12.8906 10.125 12.6562 10.125Z'
                                fill='currentColor'></path>
                        </svg>
                        {Language.getTitleCase('Generate Report')}
                    </li>
                    <li className={ "central__nav-item central__nav-item--wide " + (selected_idx == 2 ? "central__nav-item--selected" : "") }
                        onClick={() => setState(prev => ({...prev, selected_idx: 2 }))}>
                        <svg width="15" height="15" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5 10.4286C5 7.45982 7.375 5 10.4286 5H37.5714C40.5402 5 43 7.45982 43 10.4286V37.5714C43 40.625 40.5402 43 37.5714 43H10.4286C7.375 43 5 40.625 5 37.5714V10.4286ZM27.2232 16.5357C26.4598 17.3839 26.5446 18.6562 27.308 19.4196L32.4821 24L27.308 28.6652C26.5446 29.3437 26.4598 30.7009 27.2232 31.5491C27.9866 32.3125 29.2589 32.3973 30.1071 31.6339L36.8929 25.5268C37.317 25.1875 37.5714 24.5937 37.5714 24C37.5714 23.4911 37.317 22.8973 36.8929 22.558L30.1071 16.4509C29.2589 15.6875 27.9866 15.7723 27.2232 16.5357ZM20.6071 19.4196C21.3705 18.6562 21.4554 17.3839 20.692 16.5357C19.9286 15.7723 18.6562 15.6875 17.808 16.4509L11.0223 22.558C10.5982 22.8973 10.4286 23.4911 10.4286 24C10.4286 24.5937 10.5982 25.1875 11.0223 25.5268L17.808 31.6339C18.6562 32.3973 19.9286 32.3125 20.692 31.5491C21.4554 30.7009 21.3705 29.3437 20.6071 28.6652L15.433 24L20.6071 19.4196Z" fill='currentColor' />
                        </svg>
                        {Language.getTitleCase('Admin Log')}
                    </li>
                </ul>
            </nav>

            {selected_idx == 0 && (
                <ReportProgressTab
                    tenant_uid={tenant_uid}
                    tenants={tenants}
                    showToast={showToast}
                />
            )}

            {selected_idx == 1 && (
                <ReportGenerateTab
                    tenant_uid={tenant_uid}
                    tenants={tenants}
                    showToast={showToast}
                />
            )}

            {selected_idx == 2 && (
                <AdminLogTab
                    tenant_uid={tenant_uid}
                    tenants={tenants}
                    showToast={showToast}
                />
            )}
        </div>
    );
}
