import {create} from "zustand";

export const useStore = create(set => ({
    csrf_token: "",
    setCsrfIfEmpty: csrf =>
        set(state =>
            state.csrf_token == "" && csrf != "" ? { csrf_token: csrf } : {}
        ),

    stripePromise: null,

    //No need for a set function, these are only loaded on start
    initial_args: "",
    initial_path: "",
    public_mode: false,

    usr_info: {},
    setUsrInfo: usr =>
        set(state =>
            typeof usr != "undefined" && usr != null && "email" in usr
                ? { usr_info: usr }
                : state
        ),

    msg: "",
    setMsg: m => set(state => ({ msg: m })),

    //Page cache
    page_cache: {},
    setPageCache: (k, v) => set(s => ({ page_cache: { [k]: v, ...s.page_cache}})),
}));
