import React, {useEffect, useRef, useState} from "react";
import Categories from "../components/user/categories";

//SSO
import { useMsal } from "@azure/msal-react";

import { useStore } from "../store";
import Util from "../helpers/util";
import Language from "../helpers/language"
import { LanguageFlag } from "./language_flag";
import {useNavigate} from "react-router-dom";

export const ProfileWidget = React.forwardRef((props, ref) => {
    const { switch_to, showToast } = props;
    const { usr_info } = useStore(state => ({ usr_info: state.usr_info }));
    const [animateOpen, setAninateOpen] = useState(null);

    const tenant = ('tenant' in usr_info)? usr_info.tenant: {support_url: null, auth_type: 'Local'}
    const role = ('role' in usr_info && usr_info.role)? usr_info.role: "normal"

    const navs = props.navs || []

    const [state, setState] = useState({
        show_menu: false,
        msal: useMsal()
    });
    const { show_menu, msal } = state;

    const navigate = useRef( useNavigate() )

    const is_admin =
        role.toLowerCase() == "admin" || role.toLowerCase() == "super admin";

    const support_url = tenant.support_url || "https://drip7.com/contact-us/"

    React.useImperativeHandle(ref, () => ({
        close: () => {
            setState({ ...state, show_menu: false });
        },
        toggle: () => {
            //Don't close the menu if we're selecting a language
            setState({ ...state, show_menu: !show_menu });
        }
    }));

    const handleLogout = () => {
        setState({ ...state, show_menu: false });

        Util.handleLogout(navigate.current, msal, tenant.auth_type);
    };

    const handleProfileSettings = () => {
        setState({ ...state, show_menu: false });
        navigate.current("/profile-settings");
    };

    const handleSwitchTo = () => {
        const stl = switch_to.toLowerCase();

        setState({ ...state, show_menu: false });

        if (stl == "admin") {
            navigate.current("/portal");
        }
        else if (stl == "client") {
            navigate.current("/dashboard");
        }
        else {
            showToast("ProfileWidget missing switch to prop", "error");
        }
    };

    const handleNav = nav => {
        setState({ ...state, show_menu: false });
        navigate.current(nav.path);
    };

    useEffect(() => {
        if (show_menu) {
            setTimeout(() => {
                setAninateOpen("open");
            }, 100);
        }
        else {
            setTimeout(() => {
                setAninateOpen();
            }, 100);
        }
    }, [show_menu]);

    return (
        <>
            {show_menu && (
                <div className={`admin-user-menu ${animateOpen}`}>
                    <ul className='user-commands'>
                        {is_admin && (
                            <li onClick={handleSwitchTo}>
                                <svg
                                    width='15'
                                    height='15'
                                    viewBox='0 0 15 15'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'>
                                    <path
                                        d='M14.2676 9.13281L13.0078 8.42969C13.1543 7.72656 13.1543 7.05273 13.0078 6.34961L14.2676 5.64648C14.4141 5.55859 14.4727 5.38281 14.4141 5.23633C14.0918 4.18164 13.5352 3.24414 12.832 2.45312C12.7148 2.33594 12.5391 2.30664 12.3926 2.39453L11.1328 3.09766C10.6055 2.6582 10.0195 2.30664 9.375 2.07227V0.636719C9.375 0.490234 9.25781 0.34375 9.08203 0.314453C7.99805 0.0507812 6.91406 0.0800781 5.88867 0.314453C5.71289 0.34375 5.625 0.490234 5.625 0.636719V2.07227C4.95117 2.30664 4.36523 2.6582 3.83789 3.12695L2.57812 2.39453C2.43164 2.30664 2.25586 2.33594 2.13867 2.45312C1.43555 3.24414 0.878906 4.18164 0.556641 5.23633C0.498047 5.38281 0.556641 5.55859 0.703125 5.64648L1.96289 6.34961C1.8457 7.05273 1.8457 7.72656 1.96289 8.42969L0.703125 9.13281C0.556641 9.2207 0.498047 9.39648 0.556641 9.54297C0.878906 10.5977 1.43555 11.5352 2.13867 12.3262C2.25586 12.4434 2.43164 12.4727 2.57812 12.3848L3.83789 11.6816C4.36523 12.1211 4.95117 12.4727 5.625 12.707V14.1426C5.625 14.2891 5.74219 14.4355 5.88867 14.4941C6.97266 14.7285 8.05664 14.6992 9.08203 14.4941C9.25781 14.4355 9.375 14.2891 9.375 14.1426V12.707C10.0195 12.4727 10.6055 12.1211 11.1328 11.6816L12.3926 12.3848C12.5391 12.4727 12.7148 12.4434 12.832 12.3262C13.5645 11.5352 14.0918 10.5977 14.4434 9.54297C14.4727 9.39648 14.4141 9.2207 14.2676 9.13281ZM7.5 9.71875C6.18164 9.71875 5.15625 8.69336 5.15625 7.375C5.15625 6.08594 6.18164 5.03125 7.5 5.03125C8.78906 5.03125 9.84375 6.08594 9.84375 7.375C9.84375 8.69336 8.78906 9.71875 7.5 9.71875Z'
                                        fill='currentColor'
                                    />
                                </svg>
                                {Language.getTitleCase('Switch to')} {Language.getTitleCase(switch_to)}
                            </li>
                        )}

                        {Object.entries(navs).map( ([slot, nav]) =>
                            <li key={`nav_desktop_${slot}`}
                                onClick={() => handleNav(nav)}>
                                {nav.icon}
                                {Language.getTitleCase(nav.name, true)}
                            </li>
                        )}

                        <li onClick={handleProfileSettings}>
                            <svg
                                width='15'
                                height='15'
                                viewBox='0 0 15 15'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'>
                                <path
                                    d='M14.2676 9.13281L13.0078 8.42969C13.1543 7.72656 13.1543 7.05273 13.0078 6.34961L14.2676 5.64648C14.4141 5.55859 14.4727 5.38281 14.4141 5.23633C14.0918 4.18164 13.5352 3.24414 12.832 2.45312C12.7148 2.33594 12.5391 2.30664 12.3926 2.39453L11.1328 3.09766C10.6055 2.6582 10.0195 2.30664 9.375 2.07227V0.636719C9.375 0.490234 9.25781 0.34375 9.08203 0.314453C7.99805 0.0507812 6.91406 0.0800781 5.88867 0.314453C5.71289 0.34375 5.625 0.490234 5.625 0.636719V2.07227C4.95117 2.30664 4.36523 2.6582 3.83789 3.12695L2.57812 2.39453C2.43164 2.30664 2.25586 2.33594 2.13867 2.45312C1.43555 3.24414 0.878906 4.18164 0.556641 5.23633C0.498047 5.38281 0.556641 5.55859 0.703125 5.64648L1.96289 6.34961C1.8457 7.05273 1.8457 7.72656 1.96289 8.42969L0.703125 9.13281C0.556641 9.2207 0.498047 9.39648 0.556641 9.54297C0.878906 10.5977 1.43555 11.5352 2.13867 12.3262C2.25586 12.4434 2.43164 12.4727 2.57812 12.3848L3.83789 11.6816C4.36523 12.1211 4.95117 12.4727 5.625 12.707V14.1426C5.625 14.2891 5.74219 14.4355 5.88867 14.4941C6.97266 14.7285 8.05664 14.6992 9.08203 14.4941C9.25781 14.4355 9.375 14.2891 9.375 14.1426V12.707C10.0195 12.4727 10.6055 12.1211 11.1328 11.6816L12.3926 12.3848C12.5391 12.4727 12.7148 12.4434 12.832 12.3262C13.5645 11.5352 14.0918 10.5977 14.4434 9.54297C14.4727 9.39648 14.4141 9.2207 14.2676 9.13281ZM7.5 9.71875C6.18164 9.71875 5.15625 8.69336 5.15625 7.375C5.15625 6.08594 6.18164 5.03125 7.5 5.03125C8.78906 5.03125 9.84375 6.08594 9.84375 7.375C9.84375 8.69336 8.78906 9.71875 7.5 9.71875Z'
                                    fill='currentColor'
                                />
                            </svg>
                            {Language.getTitleCase('Profile Settings')}
                        </li>
                        <li onClick={handleProfileSettings}>
                            <LanguageFlag />
                            {Language.getTitleCase('__name__')}
                        </li>
                    </ul>

                    <ul className='policies'>
                        <li>
                            <a href='https://drip7.com/terms-of-service/'
                               className="inline-flex"
                               target='_blank'>
                                <svg
                                    width='16'
                                    height='19'
                                    viewBox='0 0 16 19'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'>
                                    <path
                                        d='M15.875 12.9062V1.09375C15.875 0.636719 15.4883 0.25 15.0312 0.25H3.5C1.63672 0.25 0.125 1.76172 0.125 3.625V14.875C0.125 16.7383 1.63672 18.25 3.5 18.25H15.0312C15.4883 18.25 15.875 17.8984 15.875 17.4062V16.8438C15.875 16.5977 15.7344 16.3516 15.5586 16.2109C15.3828 15.6484 15.3828 14.1016 15.5586 13.5742C15.7344 13.4336 15.875 13.1875 15.875 12.9062ZM4.625 4.96094C4.625 4.85547 4.69531 4.75 4.83594 4.75H12.2891C12.3945 4.75 12.5 4.85547 12.5 4.96094V5.66406C12.5 5.80469 12.3945 5.875 12.2891 5.875H4.83594C4.69531 5.875 4.625 5.80469 4.625 5.66406V4.96094ZM4.625 7.21094C4.625 7.10547 4.69531 7 4.83594 7H12.2891C12.3945 7 12.5 7.10547 12.5 7.21094V7.91406C12.5 8.05469 12.3945 8.125 12.2891 8.125H4.83594C4.69531 8.125 4.625 8.05469 4.625 7.91406V7.21094ZM13.5195 16H3.5C2.86719 16 2.375 15.5078 2.375 14.875C2.375 14.2773 2.86719 13.75 3.5 13.75H13.5195C13.4492 14.3828 13.4492 15.4023 13.5195 16Z'
                                        fill='currentColor'
                                    />
                                </svg>
                                {Language.getTitleCase('Terms and Conditions')}
                            </a>
                        </li>

                        <li>
                            <a href='https://drip7.com/privacy-policy/'
                               className="inline-flex"
                               target='_blank'>
                                <svg
                                    width='16'
                                    height='19'
                                    viewBox='0 0 16 19'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'>
                                    <path
                                        d='M14.1875 9.25H5.46875V5.62891C5.46875 4.25781 6.55859 3.09766 7.96484 3.0625C9.37109 3.0625 10.5312 4.22266 10.5312 5.59375V6.15625C10.5312 6.64844 10.8828 7 11.375 7H12.5C12.957 7 13.3438 6.64844 13.3438 6.15625V5.59375C13.3438 2.64062 10.918 0.25 7.96484 0.25C5.01172 0.285156 2.65625 2.71094 2.65625 5.66406V9.25H1.8125C0.863281 9.25 0.125 10.0234 0.125 10.9375V16.5625C0.125 17.5117 0.863281 18.25 1.8125 18.25H14.1875C15.1016 18.25 15.875 17.5117 15.875 16.5625V10.9375C15.875 10.0234 15.1016 9.25 14.1875 9.25ZM9.40625 14.5938C9.40625 15.4023 8.77344 16 8 16C7.19141 16 6.59375 15.4023 6.59375 14.5938V12.9062C6.59375 12.1328 7.19141 11.5 8 11.5C8.77344 11.5 9.40625 12.1328 9.40625 12.9062V14.5938Z'
                                        fill='currentColor'
                                    />
                                </svg>
                                {Language.getTitleCase('Privacy Policy')}
                            </a>
                        </li>

                        <li>
                            <a href='https://drip7.com/cookie-policy/'
                               className="inline-flex"
                               target='_blank'>
                                <svg
                                    width='19'
                                    height='19'
                                    viewBox='0 0 19 19'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'>
                                    <path
                                        d='M18.9297 9.21484L18.5078 6.54297C18.3672 5.73438 17.7695 4.57422 17.207 3.97656L15.2734 2.04297C14.5703 1.375 13.6914 0.917969 12.707 0.742188L10 0.320312C9.05078 0.179688 8.06641 0.320312 7.1875 0.777344L4.76172 2.00781C4.02344 2.39453 3.10938 3.30859 2.72266 4.04688L1.49219 6.47266C1.21094 7 0.964844 7.94922 0.964844 8.58203C0.964844 8.79297 1 9.10938 1.03516 9.32031L1.45703 11.9922C1.59766 12.8008 2.19531 13.9609 2.75781 14.5586L4.69141 16.4922C5.28906 17.0547 6.41406 17.6523 7.25781 17.793L9.96484 18.2148C10.9141 18.3555 11.8984 18.2148 12.7773 17.7578L15.2031 16.5273C15.9414 16.1406 16.8555 15.2266 17.2422 14.4883L18.4727 12.0625C18.9297 11.1836 19.0703 10.1992 18.9297 9.21484ZM7.1875 13.1875C6.55469 13.1875 6.0625 12.6953 6.0625 12.0625C6.0625 11.4648 6.55469 10.9375 7.1875 10.9375C7.78516 10.9375 8.3125 11.4648 8.3125 12.0625C8.3125 12.6953 7.78516 13.1875 7.1875 13.1875ZM8.3125 7.5625C7.67969 7.5625 7.1875 7.07031 7.1875 6.4375C7.1875 5.83984 7.67969 5.3125 8.3125 5.3125C8.91016 5.3125 9.4375 5.83984 9.4375 6.4375C9.4375 7.07031 8.91016 7.5625 8.3125 7.5625ZM13.9375 12.0625C13.3047 12.0625 12.8125 11.5703 12.8125 10.9375C12.8125 10.3398 13.3047 9.8125 13.9375 9.8125C14.5352 9.8125 15.0625 10.3398 15.0625 10.9375C15.0625 11.5703 14.5352 12.0625 13.9375 12.0625Z'
                                        fill='currentColor'
                                    />
                                </svg>
                                {Language.getTitleCase('Cookies Policy')}
                            </a>
                        </li>

                        <li>
                            <a href={support_url} target='_blank'>
                                {Language.getTitleCase("Support Center")}
                            </a>
                        </li>
                    </ul>

                    <ul className='user-commands'>
                        <li className='logout' onClick={handleLogout}>
                            <svg
                                width='18'
                                height='15'
                                viewBox='0 0 18 15'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'>
                                <path
                                    d='M17.4727 7.84766C17.7891 7.53125 17.7891 7.00391 17.4727 6.65234L11.5664 0.746094C11.0039 0.21875 10.125 0.605469 10.125 1.34375V4.71875H5.34375C4.85156 4.71875 4.5 5.10547 4.5 5.5625V8.9375C4.5 9.42969 4.85156 9.78125 5.34375 9.78125H10.125V13.1562C10.125 13.9297 11.0391 14.2812 11.5664 13.7539L17.4727 7.84766ZM6.75 13.5781V12.1719C6.75 11.9609 6.53906 11.75 6.32812 11.75H3.375C2.74219 11.75 2.25 11.2578 2.25 10.625V3.875C2.25 3.27734 2.74219 2.75 3.375 2.75H6.32812C6.53906 2.75 6.75 2.57422 6.75 2.32812V0.921875C6.75 0.710938 6.53906 0.5 6.32812 0.5H3.375C1.51172 0.5 0 2.01172 0 3.875V10.625C0 12.4883 1.51172 14 3.375 14H6.32812C6.53906 14 6.75 13.8242 6.75 13.5781Z'
                                    fill='#FD6E6E'
                                />
                            </svg>
                            {Language.getTitleCase('Log Out')}
                        </li>
                    </ul>
                </div>
                )}
        </>
    );
});
