import React, { useEffect, useState } from "react";

import Util from "../../helpers/util";

import { RadioGroup } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/20/solid'

const frequencies = [
    //{ value: 'price_1month', label: 'Monthly', priceSuffix: '/month', mult: 1 },
    { value: 'price_12month', label: '1 Year', priceSuffix: '/month', mult: 12 },
    { value: 'price_36month', label: '3 Years', priceSuffix: '/month', mult: 36 },
]

//State wrapper
export const OnboardingPackage = React.forwardRef((props, ref) => {
    const { packages, frequency, onPackage, onFrequency, showToast } = props

    React.useImperativeHandle(ref, () => ({
        attemptContinue: () => { showToast('Please select a package', 'info') },
    }));

    useEffect(() => {
        window.scrollTo({ top: 0 });
    }, []);

    const cols = Math.max(packages.length, 4)

    const cur_frequency = frequencies.find( f => f.value == frequency )
    const discount = packages.map( p =>
        Math.round( ((p['price_1month'] > 0)? Math.abs((p[frequency] - p.price_1month) / p.price_1month): 0) * 100 )
    )

    const freq_count = frequencies.length === 3 ? 'grid-cols-3' : 'grid-cols-2'

    return (
        <div className="mt-10 sm:mt-14 bg-white">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-4xl text-center">
                    <h2 className="text-base font-semibold leading-7 text-drip7">Pricing</h2>
                    <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                        Pricing plans for companies of&nbsp;all&nbsp;sizes
                    </p>
                </div>
                <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
                    Choose an affordable plan that fits your team's needs to secure, train, and engage your organization.
                </p>
                <div className="mt-16 flex justify-center">
                    <RadioGroup
                        value={cur_frequency}
                        onChange={f => onFrequency( f.value )}
                        className={Util.classNames(
                            "grid gap-x-1 rounded-full p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-gray-200",
                            freq_count
                        )}>
                        <RadioGroup.Label className="sr-only">Payment frequency</RadioGroup.Label>
                        {frequencies.map((option) => (
                            <RadioGroup.Option
                                key={option.value}
                                value={option}
                                className={({ checked }) =>
                                    Util.classNames(
                                        checked ? 'bg-drip7 text-white' : 'text-gray-500',
                                        'cursor-pointer rounded-full px-2.5 py-1'
                                    )
                                }>
                                <span>{option.label}</span>
                            </RadioGroup.Option>
                        ))}
                    </RadioGroup>
                </div>
                <div className="isolate mx-auto mt-10 pb-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                    {packages.map((pkg, idx) => (
                        <div key={`packages_${idx}`}
                             onClick={() => onPackage(pkg.uid)}
                             className={Util.classNames(
                                 (pkg.most_popular) ? 'ring-2 ring-drip7' : 'ring-1 ring-gray-200',
                                 'cursor-pointer rounded-3xl p-8',
                                 `w-1/${cols}`
                             )}>
                            <div className="flex items-center justify-between gap-x-4">
                                <h3 className={Util.classNames(
                                    (pkg.most_popular) ? 'text-drip7' : 'text-gray-900',
                                    'text-lg font-semibold leading-8'
                                )}>
                                    {pkg.name}
                                </h3>
                                {discount[idx] > 0 &&
                                    <p className="rounded-full bg-green-600/10 px-2.5 py-1 text-xs font-semibold leading-5 text-green-600">
                                        {`Save ${discount[idx]}%`}
                                    </p>
                                }
                            </div>
                            <p className="mt-4 text-sm leading-6 text-gray-600">{pkg.description}</p>
                            <p className="mt-6 flex items-baseline gap-x-1">
                                <span className="text-4xl font-bold tracking-tight text-gray-900">{Util.toCurrency( pkg[cur_frequency.value], false )}</span>
                                <span className="text-sm font-semibold leading-6 text-gray-600">{cur_frequency.priceSuffix}</span>
                            </p>
                            {cur_frequency.value !== 'price_1month' &&
                                <p className="flex items-baseline gap-x-1">
                                    <span className="text-sm font-semibold line-through leading-6 text-gray-600">{Util.toCurrency( pkg['price_1month'] * cur_frequency.mult)}</span>
                                    <span className="text-sm font-bold leading-6 text-gray-900">{Util.toCurrency( pkg[cur_frequency.value] * cur_frequency.mult)}</span>
                                    <span className="text-sm leading-6 text-gray-600">
                                        for {cur_frequency.label.toLowerCase()} /per seat
                                    </span>
                                </p>
                            }
                            <div aria-describedby={pkg.id}
                                 onClick={() => onPackage(pkg.uid)}
                                className={Util.classNames(
                                    pkg.most_popular
                                        ? 'bg-drip7 text-white shadow-sm hover:bg-drip7'
                                        : 'text-drip7 ring-1 ring-inset ring-drip7',
                                    'mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-drip7'
                                )}>
                                Buy plan
                            </div>
                            <ul role="list" className="mt-8 space-y-3 text-sm leading-6 text-gray-600 xl:mt-10">
                                {pkg.features.map((feature) => (
                                    <li key={feature} className="flex gap-x-3">
                                        <CheckIcon className="h-6 w-5 flex-none text-drip7" aria-hidden="true" />
                                        {feature}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
})