import React, { useEffect, useRef, useState } from "react";
import { useAnimation } from "framer-motion";
import Util from "../../helpers/util";
import Language from "../../helpers/language";
import parse from "html-react-parser";

export const CorrectAnswerPopup = (props) => {
    const { explanation, points_earned, has_answer, onClose } = props;

    const is_empty =
        explanation == undefined ||
        explanation == null ||
        !("info" in explanation) ||
        (explanation.info || "").trim() == "";

    useEffect(() => {
        if (is_empty) {
            onClose();
        }
    }, []);

    const close_ref = useRef(null);
    const scrollableContainerRef = useRef(null);
    useEffect(() => {
        if (close_ref.current != null) {
            close_ref.current.focus();
        }
        if ( scrollableContainerRef.current != null ) {
            scrollableContainerRef.current.scrollTop = 0;
        }
    });

    const titles = [
        Language.getSentenceCase("Awesome, you're right!"),
        Language.getSentenceCase("Great job, you nailed it"),
    ];
    const [title_idx, setTitleIdx] = useState(
        Math.round(Math.random() * (titles.length - 1))
    );

    let points_text = null;
    //console.log( has_answer, points_earned )
    if (has_answer && !Util.isbool(points_earned) && points_earned > 0) {
        //console.log(points_earned);
        points_text =
            Language.getSentenceCase("earned") +
            " " +
            points_earned +
            " " +
            (points_earned != 1
                ? Language.getString("points")
                : Language.getString("point"));
    }

    const empty = () => {};

    const title = points_text ? titles[title_idx] : "";

    //Render nothing
    if (is_empty) {
        return <></>;
    }

    const controls = useAnimation();

    useEffect(() => {
        const startAnimation = async () => {
            await controls.start({ delay: -3 });
            await controls.start({ y: 50 });
            await controls.start({ scaleY: 0.2, scaleX: 1.5 });
        };

        startAnimation();
    }, [controls]);

    return (
        <div className="up-popup__container">
            <div className="up-popup up-popup--correct">
                <div className="up-popup__icon-wrapper">
                    <div className="up-popup__icon-wrapper--cropped"></div>
                </div>

                <div className="up-popup__icon">
                    <svg
                        width="37"
                        height="27.61"
                        viewBox="0 0 13 9"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M5.05469 8.8125C5.28906 9.04688 5.6875 9.04688 5.92188 8.8125L12.8125 1.92188C13.0469 1.6875 13.0469 1.28906 12.8125 1.05469L11.9688 0.210938C11.7344 -0.0234375 11.3594 -0.0234375 11.125 0.210938L5.5 5.83594L2.85156 3.21094C2.61719 2.97656 2.24219 2.97656 2.00781 3.21094L1.16406 4.05469C0.929688 4.28906 0.929688 4.6875 1.16406 4.92188L5.05469 8.8125Z"
                            fill="currentColor"
                        ></path>
                    </svg>
                    <svg
                        className="up-popup__icon--shadow"
                        width="37"
                        height="27.61"
                        viewBox="0 0 13 9"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M5.05469 8.8125C5.28906 9.04688 5.6875 9.04688 5.92188 8.8125L12.8125 1.92188C13.0469 1.6875 13.0469 1.28906 12.8125 1.05469L11.9688 0.210938C11.7344 -0.0234375 11.3594 -0.0234375 11.125 0.210938L5.5 5.83594L2.85156 3.21094C2.61719 2.97656 2.24219 2.97656 2.00781 3.21094L1.16406 4.05469C0.929688 4.28906 0.929688 4.6875 1.16406 4.92188L5.05469 8.8125Z"
                            fill="currentColor"
                        ></path>
                    </svg>
                </div>
                <div
                    className="up-popup__scrollable-container"
                    ref={scrollableContainerRef}
                >
                    <h3 className="up-popup__heading up-popup__heading__correct" >
                        {title}
                    </h3>

                    <p className="up-popup__p">
                        {parse(
                            Util.hyperlink(explanation.info).replace(
                                /([a-zA-Z]{2})\.(?=\s)/gi,
                                "$1.\n"
                            )
                        )}
                        {points_text ? (
                            <span>
                                <br />
                                <br />
                                {points_text}
                            </span>
                        ) : null}
                    </p>
                </div>

                    <button
                        ref={close_ref}
                        className="h-10 rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-500 ring-1 ring-inset ring-drip7 hover:bg-gray-50 hover:brightness-105 focus:outline-none"
                        disabled={points_earned == false}
                        onClick={!Util.isbool(points_earned) ? onClose : empty}
                    >
                        {Language.getSentenceCase("close")}
                    </button>
            </div>
        </div>
    );
};
