import '../assets/scss/main.scss'

import React from "react";
import { Routes, Route } from "react-router-dom";
import { Header } from "../components/user/header";
import { Toast } from "../components/toast";

import { Drip } from "./user/drip";
import { Dashboard } from "./user/dashboard";
import { V3Dashboard } from "./user/v3_dashboard";
import { Tier } from "./user/v3_tier";
import { WhatsNext } from './user/v3_whatsnext';
import { Leaderboard } from "./user/leaderboard";
import { BadgeStatus } from "./user/badge_status";
import { QuestionArchive } from "./user/question_archive";
import { Curriculum } from "./user/curriculum";

import Util from "../helpers/util";
import { useStore } from "../store";
import { ProfileSettings } from "./user/profile_settings";
import Language from "../helpers/language";
import {ReviewDrip} from "./user/review_drip";

//State wrapper
const withStore = BaseComponent => props => {
    const { usr_info } = useStore(state => ({ usr_info: state.usr_info }));
    return <BaseComponent {...props} usr_info={usr_info} />;
};

class Klass extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            toast: { msg: "", status: "" },
            path: "home"
        };

        this.handleToast = this.handleToast.bind(this);
        this.handleToastComplete = this.handleToastComplete.bind(this);
    }

    componentDidMount() { }

    componentDidUpdate(prevProps, prevState, snapshot) { }

    handleToast(msg, status) {
        if ( status == null || status == undefined || status == "" ) {
            status = "failure"
        }

        const toast = { msg, status };
        this.setState({ toast });
    }

    handleToastComplete() {
        const toast = { msg: "", status: "" };
        this.setState({ toast });
    }

    render() {
        const { path, toast } = this.state;

        const main_klass = "user-portal__main user-portal__main--" + path;

        document.title = `Drip7 | ${Util.namify(path)}`;

        return (
            <>
                <Header
                    path={path}
                    showToast={this.handleToast}
                />
                <main className={main_klass} id='main'>
                    <Routes>
                        <Route path='/drip' element={
                            <Drip
                                showToast={this.handleToast}
                                title={Language.getTitleCase('daily questions')}
                                onPathChange={path => this.setState({ path })}
                            />
                        }/>
                        <Route path='/review-drip/:queue_uid' element={
                            <ReviewDrip
                                showToast={this.handleToast}
                                title={Language.getTitleCase('review drip')}
                                onPathChange={path => this.setState({ path })}
                            />
                        }/>
                        <Route path='/leaderboard' element={
                            <Leaderboard
                                showToast={this.handleToast}
                                onPathChange={path => this.setState({path})}
                            />
                        }/>
                        <Route path='/badge-status' element={
                            <BadgeStatus
                                showToast={this.handleToast}
                                onPathChange={path => this.setState({ path })}
                            />
                        }/>
                        <Route path='/profile-settings' element={
                            <ProfileSettings
                                showToast={this.handleToast}
                                onPathChange={path => this.setState({ path })}
                            />
                        }/>
                        <Route path='/question-archive' element={
                            <QuestionArchive
                                showToast={this.handleToast}
                                onPathChange={path => this.setState({ path })}
                            />
                        }/>
                        <Route path='/curriculum' element={
                            <Curriculum
                                showToast={this.handleToast}
                                onPathChange={path => this.setState({ path })}
                            />
                        }/>
                        <Route path='/tier' element={
                            <Tier
                                showToast={this.handleToast}
                                onPathChange={path => this.setState({ path })}
                            />
                        }/>
                        <Route path='/whats-next' element={
                            <WhatsNext
                                showToast={this.handleToast}
                                onPathChange={path => this.setState({ path })}
                            />
                        }/>
                        <Route path='*' element={
                            <V3Dashboard
                                showToast={this.handleToast}
                                onPathChange={path => this.setState({ path })}
                            />
                        }/>
                    </Routes>
                </main>

                <Toast
                    msg={toast.msg}
                    status={toast.status}
                    timeout='5000'
                    onComplete={this.handleToastComplete}
                />
            </>
        );
    }
}

export const UserMain = withStore(Klass);
