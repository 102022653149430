import React from "react";
import { sha256 } from "js-sha256";

// SSO
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { EventType } from "@azure/msal-browser";
import { loginRequest } from "../authConfig";

import Util from "../helpers/util";
import Language from "../helpers/language";
import SSO from "../helpers/sso"
import { AuthenticationType } from "../helpers/consts";
import { useStore } from "../store";
import InputWithIcon from "../components/input_with_Icon";
import Logo from '../assets/images/shared/drip7-logo.svg'
import {Link, useNavigate} from "react-router-dom";
import {Drip7Input} from "../components/drip7_input";
import {EnvelopeIcon, EyeIcon, LockClosedIcon, LockOpenIcon, GlobeAmericasIcon} from "@heroicons/react/24/outline";
import {Drip7Dropdown} from "../components/drip7_dropdown";

//State wrapper
const withStore = BaseComponent => props => {
    const { setCsrfIfEmpty, setUsrInfo, setMsg } = useStore(state => state )

    return (
        <BaseComponent
            {...props}
            setCsrfIfEmpty={setCsrfIfEmpty}
            setUsrInfo={setUsrInfo}
            setMsg={setMsg}
            navigate={useNavigate()}
            msal={useMsal()}
            isAuthenticated={useIsAuthenticated()}
        />
    );
};

class Klass extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            errored: "",
            show_password: false,
            loading: false,
            email: "",
            password: "",
            language: null,
            auth_type: AuthenticationType.USER_PWD,
            logo_click: 0,      //Track logo clicks for SSO Backdoor (Apple/Android reviewers)
            supported_langs: Language.getSupportedLanguages(),
        };

        this.handleKeypress = this.handleKeypress.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSSOBypass = this.handleSSOBypass.bind(this);
        this.handleLogin = this.handleLogin.bind(this);
        this.completeLogin = this.completeLogin.bind(this);
        this.handleResetpwd = this.handleResetpwd.bind(this);
        this.handleRegister = this.handleRegister.bind(this);
    }

    handleChangeLanguage(loc) {
        this.setState({ language: loc });
        Language.setLanguage(loc);
    }

    componentDidUpdate(prevProps) {
        const { accounts } = this.props.msal;
        const { isAuthenticated } = this.props;

        if (prevProps.isAuthenticated != this.props.isAuthenticated) {
            if (isAuthenticated && accounts && accounts.length > 0) {
                this.validateSSOLogin();
            }
        }
    }

    componentDidMount() {
        const { auth_type } = this.props;
        this.setState({...this.state, auth_type: auth_type})
    }

    handleKeypress(event) {
        // Enter pressed? login
        if (event.key === 'Enter') { this.handleLogin(); }
    }

    handleChange(event) {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({ [name]: value });
    }

    // To support app store reviewers, we need to bypass SSO login
    //  Click on the logo 10 times to switch to user/password
    handleSSOBypass() {
        var { auth_type, logo_click } = this.state;
        if (auth_type == AuthenticationType.AZURE_SSO) {
            logo_click = logo_click + 1;
            if (logo_click >= 10)
                auth_type = AuthenticationType.USER_PWD;
            this.setState({...this.state, auth_type: auth_type, logo_click: logo_click});
        }
    }

    handleSSOLogin() {
        const { instance } = this.props.msal;
        const { isAuthenticated } = this.props;

        instance.addEventCallback(message => {
            if (message.eventType == EventType.LOGIN_SUCCESS) {
                this.validateSSOLogin(message.payload.accessToken ? message.payload.accessToken : message.payload.idToken);
            }
            else if (message.eventType == EventType.ACQUIRE_TOKEN_FAILURE) {
                instance.loginRedirect(loginRequest).catch(e => {
                    this.setState({ ...this.state, errored: e.errorMessage });
                });
            }
            else {
                console.log(message.eventType);
            }
        });
        if (!isAuthenticated) {
            instance.loginRedirect(loginRequest).catch(e => {
                this.setState({ ...this.state, errored: e.errorMessage });
            });
        }
        else {
            this.validateSSOLogin();
        }
    }

    validateSSOLogin(token) {
        const { instance, accounts } = this.props.msal;
        const acct = instance.getActiveAccount() || accounts[0];

        //Get the user's full name before sending it to Drip7
        SSO.getUserDetails(instance, accounts, (details) => {
            // update the user info based on SSO details
            const payload = {
                tenant_uid: this.props.tenant_uid,
                email: details['email'],
                name: details['name'],
                nickname: details['nickname']
            };
            if (token) payload['token'] = token

            Util.fetch_js(
                "/human/login_sso/",
                payload,
                this.completeLogin,
                (reason, code) => {
                    this.setState({ loaded: true, err: true, errored: reason });
                }
            );
        })
    }

    handleSAMLLogin() {
        this.setState(prev => ({ ...prev, loading: true }));
        this.props.setMsg("");

        let { tenant_uid } = this.props;

        Util.fetch_js( "/human/login_saml/", { tenant_uid },
            this.completeLogin,
            (reason, code) => {
                this.setState({ errored: reason, loading: false });
                console.log(reason);
            }
        );
    }

    handleLogin() {
        let { auth_type, email, password } = this.state;
        let { tenant_uid } = this.props;

        if (auth_type == AuthenticationType.AZURE_SSO)
            this.handleSSOLogin();
        else if (auth_type == AuthenticationType.SAML)
            this.handleSAMLLogin();
        else {
            this.setState(prev => ({ ...prev, loading: true }));
            this.props.setMsg("");

            password = sha256(password);
            //console.log( email +" "+ password );

            Util.fetch_js( "/human/login/", { email, password, tenant_uid },
                this.completeLogin,
                (reason, code) => {
                    this.setState({ errored: reason, loading: false });
                    console.log(reason);
                }
            );
        }
    }

    completeLogin(js) {
        const my_data = {
            ...js.usr_info,
            msp: js.msp,
            tenant: js.tenant
        };
        const { language } = this.state
        this.setState(prev => ({ ...prev, loading: false }));

        //Set primary and secondary color CSS variables
        const primary_color = my_data.tenant
            ? my_data.tenant.primary_color
            : "#41C8FF";
        const secondary_color = my_data.tenant
            ? my_data.tenant.secondary_color
            : "#FFFFFF";
        document.documentElement.style.setProperty(
            "--drip7-primary-color",
            primary_color
        );
        document.documentElement.style.setProperty(
            "--drip7-secondary-color",
            secondary_color
        );

        //Set the user's preferred language
        if (language && language !== js.usr_info.language) {
            Language.setLanguage(language)
            Util.fetch_js(
                "/human/modify/",
                {
                    user_uid: js.usr_info.uid,
                    language
                },
                js => {
                    console.log('success');
                },
                (err, code) => {
                    console.log(err, 'failure');
                }
            );
        } else {
            Language.setLanguage(js.usr_info.language)
        }


        this.props.setUsrInfo(my_data);
        this.props.navigate(js.usr_info.portal);

        //If we're in a react-native app, send a message that the user is logged in
        if (window.ReactNativeWebView)
            window.ReactNativeWebView.postMessage(js.usr_info);

        //Causes a reload
        this.setState({ loaded: true });
    }

    handleResetpwd() {
        this.props.navigate("/resetpwd");
    }

    handleRegister() {
        const {initial_tenant} = this.props

        if ( initial_tenant.can_register ) {
            this.props.navigate("/register");
        }
        else if ( initial_tenant.demo_tenant_url != "" ) {
            window.location.assign(`https://${initial_tenant.demo_tenant_url}/register`)
        }
    }

    render() {
        const { errored, loading, language, auth_type, supported_langs } = this.state;
        const { msg } = useStore.getState();
        const { initial_tenant, tenant_uid } = this.props;

        const logo = initial_tenant.full_logo || Logo
        const curr_language = language ?? Language.getLanguage()

        //Do we need our default support url or is it provided?
        const default_support = !(initial_tenant.support_url || initial_tenant.support_email || initial_tenant.support_phone || initial_tenant.support_sms)

        const saml_login = (window.location.href.indexOf('localhost:') < 0) ? '/human/login_saml/' : 'http://localhost:8000/human/login_saml/';

        return (
            <div className='login-page'>
                <div className='login-wrap'>
                    <figure className='logo'>
                        <img src={logo} alt='Drip7 logo' onClick={this.handleSSOBypass} />
                    </figure>

                    <div className='login-page__language-select flex flex-row items-center justify-content-center'>
                        <GlobeAmericasIcon className='h-6 w-6 text-drip7'/>
                        <Drip7Dropdown
                            id='language'
                            entries={supported_langs}
                            value={curr_language}
                            onChange={e => this.handleChangeLanguage(e.target.value)}
                        />
                    </div>

                    <div className='login-menu'>
                        {errored != "" && (
                            <div className='login-error' role="alert" aria-atomic="true">
                                <div className='login-error-wrap'>
                                    <p>{Language.getString("error")}. {errored}.</p>
                                </div>
                            </div>
                        )}

                        {msg != "" && (
                            <div className='login-error' role='alert' aria-atomic='true'>
                                <div className='login-error-wrap'>
                                    <p>{msg}.</p>
                                </div>
                            </div>
                        )}

                        <div className='login-fields'>
                            {auth_type == AuthenticationType.USER_PWD && (
                                <div className='user-password-container'>
                                    <Drip7Input
                                        id='email'
                                        name='email'
                                        type='email'
                                        placeholder={Language.getString("email")}
                                        value={this.state.email}
                                        onKeyDown={this.handleKeypress}
                                        onChange={this.handleChange}>
                                        <EnvelopeIcon className='h-6 w-6 text-gray-600 stroke-2'/>
                                    </Drip7Input>
                                    {/*}
                                    <InputWithIcon
                                        inputProps={{
                                            "aria-label": "Email",
                                            type: "email",
                                            placeholder: Language.getString("email"),
                                            name: "email",
                                            autoCapitalize: "off",
                                            autoComplete: "off",
                                            autoCorrect: "off",
                                            spellCheck: "false",
                                            onKeyPress: this.handleKeypress,
                                            onChange: this.handleChange
                                        }}
                                        icon={"icon user-icon"}
                                    />
                                    {*/}

                                    <Drip7Input
                                        id='password'
                                        name='password'
                                        type={this.state.show_password ? 'text': 'password'}
                                        view={this.state.show_password}
                                        placeholder={Language.getString("password")}
                                        value={this.state.password}
                                        onKeyDown={this.handleKeypress}
                                        onChange={this.handleChange}>
                                        <div>
                                            {this.state.show_password &&
                                            <LockOpenIcon className='h-6 w-6 text-gray-600 stroke-2'/> }
                                            {!this.state.show_password &&
                                            <LockClosedIcon className='h-6 w-6 text-gray-600 stroke-2'/> }
                                        </div>
                                        <EyeIcon
                                            className='z-50 h-6 w-6 text-gray-600 stroke-2 cursor-pointer'
                                            onClick={() => this.setState({show_password: !this.state.show_password})}
                                        />
                                    </Drip7Input>
                                    {/*}
                                    <InputWithIcon
                                        inputProps={{
                                            "aria-label": "Password",
                                            type: "password",
                                            placeholder: Language.getString("password"),
                                            name: "password",
                                            autoCapitalize: "off",
                                            autoComplete: "off",
                                            autoCorrect: "off",
                                            spellCheck: "false",
                                            onKeyPress: this.handleKeypress,
                                            onChange: this.handleChange
                                        }}
                                        icon={"icon lock-icon"}
                                        secondIcon={"icon eye-icon"}
                                    />
                                    {*/}
                                </div>
                            )}
                            {auth_type == AuthenticationType.SAML ?
                                    <a href={saml_login}>
                                        <button
                                            type='submit'
                                            className='btn'
                                            disabled={loading}>
                                            {Language.getSentenceCase("login")}
                                        </button>
                                    </a>
                                :
                                    <button
                                        type='submit'
                                        className='btn'
                                        disabled={loading}
                                        onClick={this.handleLogin}>
                                        {Language.getSentenceCase("login")}
                                    </button>
                            }
                            {auth_type == AuthenticationType.USER_PWD && (
                                <>
                                    {(initial_tenant.can_register || initial_tenant.demo_tenant_uid != "") &&
                                        <button
                                            type='button'
                                            className='btn'
                                            onClick={this.handleRegister}>
                                            {Language.getSentenceCase( "register" )}
                                        </button>
                                    }

                                    <div className='login-help'>
                                        <Link to='/resetpwd'>
                                            {Language.getSentenceCase("reset password")}
                                        </Link>
                                    </div>
                                </>
                            )}

                            <div className='login-help'>
                                <p>{Language.getString("Need Help Logging In?")}</p>
                                {initial_tenant.support_url && <>{Language.getString("visit")}: <a href={initial_tenant.support_url}>{Language.getString("Support Center")}</a><br/></>}
                                {initial_tenant.support_email && <>{Language.getString("email")}: <a href={"mailto:"+initial_tenant.support_email}>{initial_tenant.support_email}</a><br/></>}
                                {initial_tenant.support_phone && <>{Language.getString("call us")}: <a href={"tel:"+initial_tenant.support_phone}>{initial_tenant.support_phone}</a><br/></>}
                                {initial_tenant.support_sms && <>{Language.getString("text us")}: <a href={"sms:"+initial_tenant.support_sms}>{initial_tenant.support_sms}</a><br/></>}
                                {default_support && <a href='https://drip7.com/contact-us/'>{Language.getString("Support Center")}</a>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export const Login = withStore(Klass);
