import React, { useState, useEffect } from "react";

export const ModalTabsWidget = props => {
    const { tabs, selected_idx, onChange } = props;

    return (
        <div className='basic-modal__field-container'>
            <nav className='central__nav'>
                <ul className='central__nav-list'>
                    {Object.entries(tabs).map(([idx, tab]) => (
                        <li key={`tab_item_${idx}`}
                            className={ "central__nav-item central__nav-item--small " + (selected_idx == idx ? "central__nav-item--selected" : "") }
                            onClick={() => onChange( idx )}>
                            {tab.icon}
                            {tab.name}
                        </li>
                    ))}
                </ul>
            </nav>
        </div>
    );
};
