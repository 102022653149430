import React, { useState } from "react";
import Language from "../helpers/language";

export const SelectWidget = (props) => {
    const { entries, selected, title, onChange } = props
    const [search, setSearch] = useState("")

    const filterSearch = (search, entries) => {
        //Do nothing
        if (search == "") {
            return entries
        }

        //Only keep
        let result = [];
        const search_lc = search.toLowerCase();
        for (let i = 0; i < entries.length; i++) {
            const entry = entries[i];
            if (entry.name.toLowerCase().indexOf(search_lc) >= 0) {
                result.push(entry)
            }
        }

        return result
    }

    const unselectionGrouping = (selected, entries) => {
        let unselected_group = [];
        for (let i = 0; i < entries.length; i++) {
            if (!(entries[i].uid in selected)) {
                unselected_group.push(entries[i]);
            }
        }

        return unselected_group
    }

    const handleAddSelection = (value) => {
        let sel = selected
        sel[value.uid] = value

        onChange( sel )
    }

    const handleRemoveSelection = (value) => {
        let sel = selected
        delete sel[value.uid]

        onChange( sel )
    }

    const handleSelectAll = () => {
        const sel = selected

        const filtered = filterSearch(search, entries)
        filtered.forEach(x => { sel[x.uid] = x })

        onChange( sel )
    }

    const handleSelectClear = () => {
        let sel = {}
        onChange( sel )
    }

    const filtered = filterSearch(search, entries)
    const unselected = unselectionGrouping( selected, filtered )

    return (
        <div className='create-group'>
            <div className='search-bar-container'>
                <i className='icon search-icon'></i>
                <input
                    className='search-input'
                    type='search'
                    placeholder={Language.getString('Search by name')}
                    name='search_user'
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                />
            </div>

            <div className='create-group__users-container'>
                <div className='create-group__users'>
                    <h5>
                        {Language.getSentenceCase('selected')} {title} {Object.keys(selected).length}
                        &nbsp;( <a onClick={handleSelectClear} href="#">All</a> )
                    </h5>
                    <ul 
                    className='create-group__users-list'
                     tabIndex='0'
                     >
                        {Object.entries(selected).map(
                            ([key, value]) => (
                                <li
                                    tabIndex='0'
                                    className='create-group__users-item'
                                    key={"selected_" + key}
                                    onClick={() => handleRemoveSelection(value)}>
                                    {value.name}
                                </li>
                            )
                        )}
                    </ul>
                </div>

                <svg
                    aria-hidden='true'
                    focusable='false'
                    data-prefix='fas'
                    data-icon='exchange-alt'
                    className='svg-inline--fa fa-exchange-alt fa-w-16'
                    role='img'
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 512 512'>
                    <path
                        fill='currentColor'
                        d='M0 168v-16c0-13.255 10.745-24 24-24h360V80c0-21.367 25.899-32.042 40.971-16.971l80 80c9.372 9.373 9.372 24.569 0 33.941l-80 80C409.956 271.982 384 261.456 384 240v-48H24c-13.255 0-24-10.745-24-24zm488 152H128v-48c0-21.314-25.862-32.08-40.971-16.971l-80 80c-9.372 9.373-9.372 24.569 0 33.941l80 80C102.057 463.997 128 453.437 128 432v-48h360c13.255 0 24-10.745 24-24v-16c0-13.255-10.745-24-24-24z'></path>
                </svg>

                <div className='create-group__users'>
                    <h5>
                        {Language.getSentenceCase('unselected')} {title}
                        &nbsp;( <a onClick={handleSelectAll} href="#">All</a> )
                    </h5>
                    <ul 
                    className='create-group__users-list' 
                    tabIndex='0'
                    >
                        {Object.entries(unselected).map(
                            ([key, value]) => (
                                <li
                                    tabIndex='0'
                                    className='create-group__users-item'
                                    key={"selected_" + key}
                                    onClick={() => handleAddSelection( value )}>
                                    {value.name}
                                </li>
                            )
                        )}
                    </ul>
                </div>
            </div>
        </div>
    );
};
