import React, { useState } from "react";

import { useStore } from "../../store";
import Util from "../../helpers/util";
import Language from "../../helpers/language";
import { Drip7OriginalModal } from "../../modals/drip7_original_modal";
import { Drip7Button } from "../../components/drip7_button";
import { TenantModal } from "../../modals/admin/tenant_model";
import { TenantSettingsTab } from "./tenant_settings_tab";
import { TenantCustomizeTab } from "./tenant_customize_tab";
import { TenantNavigationTab } from "./tenant_navigation_tab";
import {TenantSubscriptionTab} from "./tenant_subscription_tab";
import {PresentationChartLineIcon} from "@heroicons/react/24/outline";
import {TenantPresentationTab} from "./tenant_presentation_tab";

//State wrapper
const withStore = BaseComponent => props => {
    const { setUsrInfo, usr_info } = useStore(state => state)
    return <BaseComponent {...props}
                          setUsrInfo={setUsrInfo}
                          usr_info={usr_info}
    />;
};

class Klass extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            changed: false,
            info: {},
            reload: 0,
            show_create: false
        };

        this.handleChanged = this.handleChanged.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleRevert = this.handleRevert.bind(this);
        this.handleCreate = this.handleCreate.bind(this);
        this.handleRemove = this.handleRemove.bind(this);
        this.handlePresentation = this.handlePresentation.bind(this);
    }

    componentDidMount() {
        this.props.onPathChange("styles");
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.tenant_uid != this.props.tenant_uid) {
        }
    }

    handleChanged(info) {
        this.setState({ changed: true, info });
    }

    handleRevert() {
        const reload = this.state.reload + 1;
        //On revert, go back to settings tab so we can reload the customize tab easily
        this.setState({ changed: false, reload });

        //revert to original colors
        document.documentElement.style.setProperty(
            "--drip7-primary-color",
            this.props.usr_info.tenant.primary_color
        );
        document.documentElement.style.setProperty(
            "--drip7-secondary-color",
            this.props.usr_info.tenant.secondary_color
        );
    }

    handlePresentation() {
        const { tenant_uid, showToast } = this.props

        showToast(Language.getTitleCase("Starting update"), "info" )
        Util.fetch_js('/tenant/update_presentation/', { tenant_uid },
            js => {
                showToast(Language.getTitleCase("Successfully updated"), "successful" )
            },
            (err, code) => {
                showToast( err, "failure" )
            })
    }

    handleSave( tenant ) {
        const { usr_info, tenant_uid, tenants, setUsrInfo, showToast, onRefreshMspsTenants } = this.props;
        const { reload } = this.state;

        // Set the demo property based on trial_remaining
        const info = {...tenant}
        info['demo'] = info['trial_remaining'] != null;

        //Write out the changes
        Util.fetch_js( "/tenant/modify/", { ...info, tenant_uid },
            js => {
                showToast(Language.getTitleCase('update successful'), 'successful');
                onRefreshMspsTenants(() => {
                    this.setState({ changed: false, reload: reload + 1, info: js });

                    //Force refresh of tenant data
                    if ( usr_info.tenant_uid == tenant_uid ) {
                        Util.fetch_js( "/human/is_valid/", {},
                            js => {
                                //Store some user info
                                if (js.valid) {
                                    const my_data = {
                                        ...js.usr_info,
                                        msp: js.msp,
                                        tenant: js.tenant
                                    };
                                    setUsrInfo(my_data);
                                }
                            },
                            (err, code) => {
                            })
                    }
                })
            },
            (reason, code) => {
                showToast(reason, 'failure');
            }
        );
    }

    handleCreate() {
        this.setState({...this.state, show_create: true})
    }

    handleRemove() {
        const { tenant_uid, tenants, showToast, onRefreshMspsTenants } =
            this.props;

        //Find the tenant
        const info = tenants.find(x => x.uid == tenant_uid) || null;
        if (info == null) {
            showToast(Language.getSentenceCase('Couldn\'t find currently selected tenant'), 'error');
            return;
        }

        //Request the name of the tenant
        const name = prompt(Language.getSentenceCase('enter tenant name'));

        if (name == info.name) {
            Util.fetch_js( "/tenant/remove/", { tenant_uid },
                js => {
                    showToast(Language.getSentenceCase('successfully removed') + " " + name, 'successful');
                    onRefreshMspsTenants();
                },
                (reason, code) => {
                    showToast(reason, 'failure');
                }
            );
        }
        else if (name != "" && name != null) {
            showToast(name + " != " + info.name, 'failure');
        }
    }

    handleCloseModal(js) {
        const { onContext } = this.props;
        const { onRefreshMspsTenants } = this.props;

        if (js) {
            onRefreshMspsTenants()
            onContext( js.uid )
            this.setState({...this.state, changed: false, show_create: false, info: js });
        }
        else
            this.setState({...this.state, show_create: false})
    }

    render() {
        const { usr_info, tenant_uid, tenants, showToast } = this.props;
        const { changed, reload, show_create } = this.state;

        const initial_info = tenants.find(x => x.uid == tenant_uid) || {};

        return (
            <div className='main-modal customization-menu'>
                <TenantCustomizeTab
                    initial_info={initial_info}
                    tenant_uid={tenant_uid}
                    reload={reload}
                    changed={changed}
                    showToast={showToast}
                    onChanged={this.handleChanged}
                    onSave={this.handleSave}
                    onRevert={this.handleRevert}
                />
                <Drip7OriginalModal>
                    {show_create && (
                        <TenantModal 
                            showToast={showToast}
                            tenant_uid={tenant_uid}
                            super_admin={usr_info.role.toLowerCase() == 'super admin'}
                            onClose={this.handleCloseModal.bind(this)} />
                    )}
                </Drip7OriginalModal>
            </div>
        );
    }
}

export const Styles = withStore(Klass);
