import '../../assets/css/tailwind.css'

import React, {useEffect, useRef, useState} from "react";

import Util from "../../helpers/util";
import {Behavior, PackageFrequency} from "../../helpers/consts";

import { Toast } from "../../components/toast";
import { Steps } from "../../components/onboarding/steps";
import { Header } from "../../components/onboarding/header";
import { OnboardingPackage } from "./onboarding_package";
import { OnboardingInformation } from "./onboarding_information";
import { OnboardingModules } from "./onboarding_modules";
import {OnboardingPayment} from "./onboarding_payment";
import {setTenantColors} from "../../helpers/content";
import {useStore} from "../../store";
import {useNavigate} from "react-router-dom";
import Drip7 from "../../assets/images/shared/drip7-logo.svg";


//State wrapper
export const Onboarding = props => {
    const [state, setState] = useState({
        packages: [],
        package_uid: "",
        modules: [],
        module_uids: [],
        seats: 10,
        step_idx: 0,
        max_step_idx: 0,
        frequency: PackageFrequency.YEARLY,
        init_info: null,
        init_modules: null,
        init_payment: null,
        tenant_info: {},
        user_info: {},
    })
    const { packages, package_uid, modules, module_uids, seats, step_idx, max_step_idx, frequency, init_info, init_modules, init_payment, tenant_info, user_info } = state

    const { setUsrInfo } = useStore(state => state)

    const [toast, setToast] = useState({ msg: "", status: "" })

    const navigate = useRef( useNavigate() )

    const packageRef = React.useRef(null);
    const informationRef = React.useRef(null);
    const moduleRef = React.useRef(null);
    const paymentRef = React.useRef(null);

    useEffect( () => {
        //Load up the data!
        Util.fetch_js('/subscription/list/', {},
            js => {
                const pkg = js.packages.find( p => p.most_popular )
                setState( prev => ({ ...prev,
                    packages: js.packages,
                    modules: js.modules,
                    package_uid: (pkg)? pkg.uid : "",
                }) )
            }, handleToast );
    }, [] );

    const handleToast = (msg, status) => {
        if ( status === null || status === undefined || status === "" ) {
            status = "failure"
        }

        setToast({ msg, status })
    }

    const handleToastComplete = () => {
        setToast({ msg: "", status: "" })
    }

    const handleStep = (step_idx) => {
        if ( step_idx <= max_step_idx ) {
            setState(prev => ({ ...prev,
                step_idx,
            }))
        }
        else if ( state.step_idx === 0 && packageRef.current != null ) {
            packageRef.current.attemptContinue()
        }
        else if ( state.step_idx === 1 && moduleRef.current != null ) {
            moduleRef.current.attemptContinue()
        }
        else if ( state.step_idx === 2 && informationRef.current != null ) {
            informationRef.current.attemptContinue()
        }
    }

    const handlePackage = (package_uid) => {
        setState(prev => ({...prev,
            package_uid,
            step_idx: 1,
            max_step_idx: (max_step_idx < 1)? 1 : max_step_idx,
        }))
    }

    const handleModules = info => {
        setState(prev => ({...prev,
            init_modules: info.state,
            module_uids: info.module_uids,
            step_idx: 2,
            max_step_idx: (max_step_idx < 2)? 2 : max_step_idx,
        }))
    }

    const handleInformation = info => {
        setState(prev => ({...prev,
            tenant_info: info.tenant,
            user_info: info.user,
            init_info: info.state,
            step_idx: 3,
            max_step_idx: (max_step_idx < 3)? 3 : max_step_idx,
        }))
    }

    const handlePayment = info => {
        const payload = {
            package_uid,
            module_uids,
            seats,
            frequency,
            tenant_info,
            user_info,
        }
        Util.fetch_js('/client/onboarding/onboard/', payload,
            js => {
                //Setup the login creds
                const my_data = {
                    ...js.usr_info,
                    msp: js.msp,
                    tenant: js.tenant
                };
                setUsrInfo(my_data);
                setTenantColors( js.tenant )
                Util.setFavicon( js.tenant.favicon )

                //Goto the Whats Next page
                const nextpage = js.usr_info.portal.substring(0,js.usr_info.portal.lastIndexOf('/')) + '/whats-next'
                navigate.current(nextpage);
                //window.location.href = '/'
            }, handleToast )

        setState(prev => ({...prev,
            init_payment: info.state,
            seats: info.seats,
            step_idx: 4,
            max_step_idx: (max_step_idx < 4)? 4 : max_step_idx,
        }))
    }

    const pkg = packages.find( p => p.uid === package_uid ) || {name: "", price_1month: 0, price_12month: 0, price_36month: 0, content_modules: []}

    //Build the products
    const products = [{
        uid: pkg.uid,
        title: pkg.name,
        subtitle: 'Package',
        price: pkg[frequency],
        setup_fee: pkg.setup_fee,
        img_src: Drip7,
    }]
    modules.forEach( module => {
        if ( module_uids.indexOf(module.uid) < 0 ) {
            return
        }

        products.push({
            uid: module.uid,
            title: module.name,
            subtitle: (module.behavior === Behavior.PHISHING_MODULE)? "Phishing": "Content Module",
            price: module[frequency],
            img_src: (module.compact_logo !== '')? module.compact_logo : Drip7,
        })
    })

    return (
        <div className={Util.classNames("h-100", (step_idx === 0)? "bg-white": "")}>
            <Header
                />

            <Steps
                step_names={['Select Package', 'Content Modules', 'Information', 'Payment']}
                step_idx={step_idx}
                onChange={handleStep}
                />

            {step_idx === 0 &&
                <OnboardingPackage
                    ref={packageRef}
                    packages={packages}
                    frequency={frequency}
                    onFrequency={frequency => setState(prev => ({...prev, frequency }))}
                    onPackage={handlePackage}
                    showToast={handleToast}
                    />
            }
            {step_idx === 1 &&
                <OnboardingModules
                    ref={moduleRef}
                    init={init_modules}
                    frequency={frequency}
                    module_uids={pkg.content_modules.map( m => m.uid)}
                    modules={modules}
                    onContinue={handleModules}
                    showToast={handleToast}
                />
            }
            {step_idx === 2 &&
                <OnboardingInformation
                    ref={informationRef}
                    init={init_info}
                    tenant_info={tenant_info}
                    user_info={user_info}
                    onContinue={handleInformation}
                    showToast={handleToast}
                    />
            }

            {step_idx === 3 &&
                <OnboardingPayment
                    ref={paymentRef}
                    init={init_payment}
                    frequency={frequency}
                    products={products}
                    tenant_info={tenant_info}
                    user_info={user_info}
                    onContinue={handlePayment}
                    showToast={handleToast}
                />
            }

            <Toast
                msg={toast.msg}
                status={toast.status}
                timeout='5000'
                onComplete={handleToastComplete}
            />
        </div>
    )
}
