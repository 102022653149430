import React, { useEffect, useState } from "react";
import Util from "../../helpers/util";
import Language from "../../helpers/language";
import { Progress } from "../../components/progress";
import { WS_URL } from "../../settings";


export const QuestionBulkWsModal = props => {
    const { onClose, showToast } = props;
    const { tenant_uid } = props;

    //Setup my state
    const [state, setState] = useState({
        filename: "",
        raw_file: null,
        questions: [],
        connected: null,
    });
    const { filename, raw_file, questions, connected } = state;

    const [prog, setProg] = useState({
        progress: 0,
        errored: false,
        status: "",
    });
    const { status, errored, progress } = prog;

    const [socket, setSocket] = useState(null);

    //File ref required to access the file browser
    const fileRef = React.useRef();

    useEffect(() => {
        const _socket = Util.connectWS( `${WS_URL}/ws/bulk_questions/`, setSocket, {
            'file_processing': fileProcessing,
            'question_processing': questionProcessing,
            'question_uploaded': questionUploaded,
            'save_questions': saveQuestions,
            'error': processingError,
        })

        //Track the state of the socket
        _socket.onopen = () => {
            console.log("Socket connected")
            setState(prev => ({ ...prev, connected: true }))
        }
        _socket.onclose = () => {
            console.log("Socket closed")
            setState(prev => ({ ...prev, connected: false }))
        }

        return Util.closeWS( socket )
    }, []);

    const questionProcessing = (payload ) => {
        setState(prev => ({ ...prev,
            questions: prev.questions.concat([payload]),
        }))
    }

    const questionUploaded = (payload ) => {
        const index = Util.xint(payload.index)
        setState(prev => {
            const questions = [...prev.questions]
            questions[index].uploaded = true

            return ({...prev, questions})
        })
    }

    const fileProcessing = ( payload ) => {
        let { status } = payload
        let { errored } = prog
        switch ( status ) {
            case 'EXTRACTED':
                status = Language.getSentenceCase('Successfully extracted questions')
                break

            case 'PARSED':
                status = Language.getSentenceCase('Successfully parsed questions')
                break

            case 'LOADING':
                status = Language.getSentenceCase('Loading file') +' '+ payload.msg
                break

            case 'NO_SPREADSHEET':
                status = Language.getSentenceCase('No spreadsheet found')
                errored = true
                break

            case 'ERROR':
                status = payload.msg
                errored = true
                break

            default: break
        }

        setProg(prev => ({ ...prev,
            status,
            errored,
            progress: 0,
        }))
    }

    const processingError = ( error ) => {
        setProg(prev => ({ ...prev,
            status: error,
            errored: true,
            progress: 0,
        }))
        showToast( error, 'failure' )
    }

    const saveQuestions = ( payload ) => {
        const msg = Language.getSentenceCase('Successfully saved') +` ${payload.count} `+ Language.getLowerCase('questions')
        showToast( msg, 'successful' )

        onClose()
    }

    //Funky pass through to open the file browse
    const handleFileClick = () => {
        if ( !connected ) {
            showToast(Language.getSentenceCase('not connected'), 'failure');
            return;
        }

        fileRef.current.value = null;
        fileRef.current.click();
    };

    //We have a selected file
    const handleFileChange = e => {
        if ( !connected ) {
            showToast(Language.getSentenceCase('not connected'), 'failure');
            return;
        }

        const raw_file = e.target.files[0];

        // Check file size
        const file_mb = raw_file.size / (1024 * 1024)
        console.log( file_mb )
        if ( file_mb > 150 ) {
            showToast(Language.getSentenceCase('files need to be less than 150MB'), 'failure');
            return
        }
        else if ( file_mb > 100 ) {
            showToast(Language.getSentenceCase('files larger than 100MB impacts performance'), 'failure');
        }

        console.log(tenant_uid)

        //Select the tenant so we can prepare to load
        Util.sendWS( socket, 'select_tenant', { tenant_uid })

        //Start the upload
        handleUpload( raw_file )

        //update the state
        setState(prev => ({...prev,
            filename: raw_file.name,
            raw_file,
            questions: [],
        }))
    };

    const handleUpload = ( raw_file ) => {
        if ( !connected ) {
            showToast(Language.getSentenceCase('not connected'), 'failure');
            return;
        }

        // Check file size
        const file_mb = raw_file.size / (1024 * 1024)
        if ( file_mb > 150 ) {
            return
        }

        //Open the file
        Util.sendWS( socket, 'open_file', { file_size: raw_file.size })

        const callback = (perc) => {
            setProg({ status: Language.getSentenceCase('uploading'), progress: perc });
        }
        Util.sendFileWS( socket, raw_file, callback )
    };

    const handleSaveQuestions = () => {
        Util.sendWS( socket, 'save_questions', {})
    }

    const question_count = questions.filter(q => q.err == null).length

    return (
        <div className='add-users-modal overflow-auto' tabIndex={-1}>
            <p className='hidden-heading'>{Language.getTitleCase('dialog content starts')}</p>
            <div className='add-users-wrap'>
                <div className='add-users-heading'>
                    <h2>{Language.getTitleCase('Add questions from file')}</h2>
                    <svg
                        className='close-button'
                        width='12'
                        height='11'
                        viewBox='0 0 12 11'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                        onClick={onClose}>
                        <path
                            d='M7.93359 5.375L10.8633 2.44531C11.2441 2.09375 11.2441 1.50781 10.8633 1.15625L10.2188 0.511719C9.86719 0.130859 9.28125 0.130859 8.92969 0.511719L6 3.44141L3.04102 0.511719C2.68945 0.130859 2.10352 0.130859 1.75195 0.511719L1.10742 1.15625C0.726562 1.50781 0.726562 2.09375 1.10742 2.44531L4.03711 5.375L1.10742 8.33398C0.726562 8.68555 0.726562 9.27148 1.10742 9.62305L1.75195 10.2676C2.10352 10.6484 2.68945 10.6484 3.04102 10.2676L6 7.33789L8.92969 10.2676C9.28125 10.6484 9.86719 10.6484 10.2188 10.2676L10.8633 9.62305C11.2441 9.27148 11.2441 8.68555 10.8633 8.33398L7.93359 5.375Z'
                            fill='currentColor'></path>
                    </svg>
                </div>
                {connected &&
                    <h5>{Language.getTitleCase('Zip / xlsx / csv file')}</h5>
                }
                {connected == false &&
                    <h5 style={{color: "red"}}>{Language.getTitleCase('Not connected')}</h5>
                }
                {connected == null &&
                    <h5>{Language.getTitleCase('Connecting')}</h5>
                }
                <div className='add-users-file-select'>
                    <input
                        type='file'
                        ref={fileRef}
                        onChange={handleFileChange}
                        style={{ display: "none" }}
                    />
                    <input
                        className='add-users-input'
                        type='text'
                        readOnly
                        name='user-file'
                        placeholder={Language.getSentenceCase('No File Selected')}
                        value={filename}
                    />
                    <button
                        className='add-users-choose-file solid-button'
                        type='button'
                        name='choose-file'
                        onClick={handleFileClick}>
                        {Language.getTitleCase('Choose File')}
                    </button>
                </div>

                <p>
                    {Language.getSentenceCase('supported file formats')}: 7z, zip &nbsp;
                    <a href='/static/example_question_upload.zip'>{Language.getSentenceCase('example')}</a>
                </p>

                {progress <= 0 && questions.length <= 0 && (
                    <div>
                        <p style={errored? {'color': 'red'}: {}}>{status}</p>
                    </div>
                )}

                {progress > 0 && questions.length <= 0 && (
                    <div>
                        <Progress
                            style={{ width: "280px" }}
                            label={status}
                            label_only={true}
                            percentage={progress}
                        />
                    </div>
                )}

                {questions.length > 0 && (
                    <table className='questions__table mt-5 mb-5'>
                        <tbody>
                        <tr className='questions__table-row questions__table-row--head'>
                            <th className='questions__table-head questions__table-head--subject'>
                                <h5 className='questions__table-heading-no-sort'>
                                    {Language.getTitleCase('Name')}
                                </h5>
                            </th>
                            <th className='questions__table-head questions__table-head--subject'>
                                <h5 className='questions__table-heading-no-sort'>
                                    {Language.getTitleCase('Category')}
                                </h5>
                            </th>
                            <th className='questions__table-head questions__table-head--subject' style={{minWidth: '72px'}}>
                                <h5 className='questions__table-heading-no-sort' style={{textAlign: 'center'}}>
                                    {Language.getTitleCase( 'Content' )}
                                </h5>
                            </th>
                            <th className='questions__table-head questions__table-head--subject'>
                                <h5 className='questions__table-heading-no-sort'>
                                    {Language.getTitleCase( 'Status' )}
                                </h5>
                            </th>
                        </tr>

                        {questions.map((question, index) => (
                        <tr key={index} className='questions__table-row questions__table-row--body'>
                            <td style={{ maxWidth: '250px' }}>
                                {question.uploaded && <div>✅ {question.name}</div>}
                                {question.uploaded != true && <div>question.name</div>}
                            </td>
                            <td>{question.category}</td>
                            <td>{question.contents.join(', ')}</td>
                            {question.err == null && <td>✅</td>}
                            {question.err && (
                                <td>
                                    <p className="capitalize-text" style={{color:'red'}}>❌ {question.err}</p>
                                </td>
                            )}
                        </tr>
                        ))}
                        </tbody>
                    </table>
                )}

                {question_count > 0 && (
                <div className='upload-buttons pb-5'>
                    <button
                        className='clear-button'
                        type='button'
                        name='cancel'
                        onClick={onClose}>
                        {Language.getTitleCase('cancel')}
                    </button>
                    <button
                        className='solid-button'
                        type='submit'
                        name='submit'
                        disabled={!filename || progress > 0}
                        onClick={handleSaveQuestions}>
                        {Language.getTitleCase('upload', true) +` ${question_count} `+ Language.getLowerCase('question(s)', true)}
                    </button>
                </div>
                )}
            </div>
            <p className='hidden-heading'>{Language.getTitleCase('dialog content ends')}</p>
        </div>
    );
};
