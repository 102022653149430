import React, { useEffect, useState } from "react";

import DatePicker from "react-multi-date-picker";
import Util from "../../helpers/util";
import Language from "../../helpers/language";
import { InputWithArrows } from "../../components/input_with_arrows";
import { ManualPointActions } from "../../helpers/consts";
import {Drip7Dropdown} from "../../components/drip7_dropdown";

export const ManualPointModal = (props) => {
    const { tenant_uid, onClose, showToast } = props

    const safe_mp = !Util.isbool(props.manual_point)? props.manual_point: { uid: null, action: ManualPointActions.ASSIGN, points: 6, desc: '', assigned_on: Util.epoch() }

    const [state, setState] = useState({
        manual_point_uid: safe_mp.uid,
        action: safe_mp.action,
        points: safe_mp.points,
        desc: safe_mp.desc,
        assigned_on: safe_mp.assigned_on,
        search: "",
        selected: {},
        users: [],
        disable_click: false,
    })
    const { manual_point_uid, action, points, desc, assigned_on, selected, search, users, disable_click } = state

    useEffect(() => {
        if ( manual_point_uid != null ) {
            Util.fetch_js('/manual_points/detail/', { manual_point_uid },
                js => {
                    const selected = {}
                    js.user_manual_points.forEach( x => {
                        selected[x.user_uid] = true
                    })

                    const data = { manual_point_uid: js.uid }
                    for ( const x of ['action', 'points', 'desc', 'assigned_on'] ) {
                        data[x] = js[x]
                    }

                    setState(prev => ({...prev,
                        ...data,
                        selected,
                    }))
                },
                (err, code) => {
                    showToast(err, "failure")
                })
        }

        Util.fetch_js('/human/list/', { tenant_uid },
            js => {
                setState(prev => ({...prev,
                    users: js.users,
                }))
            },
            (err, code) => {
                showToast(err, "failure")
            })
    }, [])

    const filterSearch = (search, entries) => {
        //Do nothing
        if (search == "") {
            return entries;
        }

        //Only keep
        let result = [];
        const search_lc = search.toLowerCase();
        for (let i = 0; i < entries.length; i++) {
            const entry = entries[i];
            if (entry.name.toLowerCase().indexOf(search_lc) >= 0) {
                result.push(entry);
            }
        }

        return result;
    }

    const selection = (selected, entries) => {
        let selected_users = [];
        let unselected_users = [];
        for (let i = 0; i < entries.length; i++) {
            if (entries[i].uid in selected) {
                selected_users.push(entries[i]);
            }
            else {
                unselected_users.push(entries[i]);
            }
        }

        return [selected_users, unselected_users];
    }

    const handleSave = () => {
        const payload = {
            tenant_uid,
            manual_point_uid,
            action,
            points,
            desc,
            assigned_on,
            user_uids: Object.keys(selected),
        }
        const url = (manual_point_uid != null)? '/manual_points/modify/': '/manual_points/create/'
        Util.fetch_js( url, payload,
            js => {
                showToast("Updated points to users", "successful")
                onClose();
            },
            (err, code) => {
                showToast(err, 'failure');
            }
        );

        setState(prev => ({...prev,
            disable_click: true,
        }))
    }

    const handleAddSelected = (uid) => {
        let { selected } = state;
        selected[uid] = true;

        setState( prev => ({ ...prev, selected }))
    }

    const handleRemoveSelected = (uid) => {
        let { selected } = state;
        delete selected[uid];

        setState(prev => ({ ...prev, selected }))
    }

    const handleSelectAll = () => {
        let selected = {}
        users.forEach( x => {
            selected[x.uid] = true
        })

        setState(prev => ({ ...prev, selected }))
    }

    const handleUnselectAll = () => {
        setState(prev => ({ ...prev, selected: {} }))
    }

    const handleChange = (event) => {
        setState(prev => ({ ...prev,
            [event.target.name]: event.target.value
        }))
    }

    const handleDateChange = (e) => {
        //when non-integers are entered the site crashes
        if(e === null){return}
        
        setState(prev => ({...prev,
            assigned_on: e.unix * 1000
        }))
    }

    const handleDateNow = () => {
        setState(prev => ({...prev,
            assigned_on: Util.epoch()
        }))
    }

    let [sel_users, _unsel_users] = selection( selected, users )
    const unsel_users = filterSearch(search, _unsel_users)

    return (
        <div className='basic-modal basic-modal--add-group'>
            <div className='basic-modal__head'>
                <h4>{Language.getTitleCase('Bulk adjust points')}</h4>
                <svg
                    className='basic-modal__close-button'
                    width='12'
                    height='11'
                    viewBox='0 0 12 11'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                    onClick={onClose}>
                    <path
                        d='M7.93359 5.375L10.8633 2.44531C11.2441 2.09375 11.2441 1.50781 10.8633 1.15625L10.2188 0.511719C9.86719 0.130859 9.28125 0.130859 8.92969 0.511719L6 3.44141L3.04102 0.511719C2.68945 0.130859 2.10352 0.130859 1.75195 0.511719L1.10742 1.15625C0.726562 1.50781 0.726562 2.09375 1.10742 2.44531L4.03711 5.375L1.10742 8.33398C0.726562 8.68555 0.726562 9.27148 1.10742 9.62305L1.75195 10.2676C2.10352 10.6484 2.68945 10.6484 3.04102 10.2676L6 7.33789L8.92969 10.2676C9.28125 10.6484 9.86719 10.6484 10.2188 10.2676L10.8633 9.62305C11.2441 9.27148 11.2441 8.68555 10.8633 8.33398L7.93359 5.375Z'
                        fill='currentColor'></path>
                </svg>
            </div>

            <div className="queue-grid">
                <div className='basic-modal__field basic-modal__field--add-question'>
                    <label
                        className='basic-modal__label'>
                        {Language.getTitleCase('Action')}
                    </label>
                    <div className='dropdown dropdown--attached dropdown--add-question'>
                        <Drip7Dropdown
                            id='action'
                            name='action'
                            entries={Object.values(ManualPointActions)}
                            value={action}
                            onChange={handleChange}
                            />
                    </div>
                </div>

                <div className='basic-modal__field basic-modal__field--add-question'>
                    <label
                        className='basic-modal__label'
                        htmlFor='newQuestionCategory'>
                        {Language.getTitleCase('points')}
                    </label><div style={{paddingTop:'6px'}}>
                    <InputWithArrows
                        name="points"
                        value={points}
                        min={-1000}
                        max={1000}
                        onChange={handleChange}
                    /></div>
                </div>
            </div>

            <div className="queue-grid">
                <div className='basic-modal__field basic-modal__field--add-question'>
                    <label
                        className='basic-modal__label'
                        htmlFor='newQuestionCategory'>
                        {Language.getTitleCase('description')}
                    </label>
                    <input
                        type='text'
                        name='desc'
                        aria-label="description"
                        className='mt-1 border-1 border-gray-300 rounded'
                        onChange={handleChange}
                        value={desc}
                    />
                </div>

                <div className='basic-modal__field basic-modal__field--add-question grid-item'>
                    <label
                        className='basic-modal__label'
                        htmlFor='newQuestionCategory'>
                        {Language.getSentenceCase('Apply on date')} (
                        <a href='#' onClick={handleDateNow} >
                            {Language.getTitleCase('now')}
                        </a>
                        )
                    </label>
                    <div className='dropdown dropdown--attached'>
                        <DatePicker
                            inputClass='mt-1 border-1 border-gray-300 rounded'
                            value={Util.epoch(assigned_on)}
                            onChange={handleDateChange}
                            
                        />
                    </div>
                </div>
            </div>

            <div className='create-group'>
                <div className='search-bar-container'>
                    <i className='icon search-icon'></i>
                    <input
                        className='search-input'
                        type='search'
                        placeholder={Language.getSentenceCase('Search users by name')}
                        name='search'
                        value={search}
                        onChange={handleChange}
                    />
                </div>

                <div className='create-group__users-container'>
                    <div className='create-group__users'>
                        <h5>
                            {Language.getTitleCase('selected users')} {Object.keys(sel_users).length}&nbsp;
                            ( <a onClick={handleUnselectAll} href="#">{Language.getSentenceCase('all')}</a> )
                        </h5>
                        <ul className='create-group__users-list' tabIndex='0'>
                            {Object.entries(sel_users).map(
                                ([key, user]) => (
                                    <li
                                    tabIndex='0'
                                        className='create-group__users-item'
                                        key={"selected_users_" + key}
                                        onClick={() => handleRemoveSelected( user.uid )}>
                                        {user.name}
                                    </li>
                                )
                            )}
                        </ul>
                    </div>

                    <svg
                        aria-hidden='true'
                        focusable='false'
                        data-prefix='fas'
                        data-icon='exchange-alt'
                        className='svg-inline--fa fa-exchange-alt fa-w-16'
                        role='img'
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 512 512'>
                        <path
                            fill='currentColor'
                            d='M0 168v-16c0-13.255 10.745-24 24-24h360V80c0-21.367 25.899-32.042 40.971-16.971l80 80c9.372 9.373 9.372 24.569 0 33.941l-80 80C409.956 271.982 384 261.456 384 240v-48H24c-13.255 0-24-10.745-24-24zm488 152H128v-48c0-21.314-25.862-32.08-40.971-16.971l-80 80c-9.372 9.373-9.372 24.569 0 33.941l80 80C102.057 463.997 128 453.437 128 432v-48h360c13.255 0 24-10.745 24-24v-16c0-13.255-10.745-24-24-24z'></path>
                    </svg>

                    <div className='create-group__users'>
                        <h5>
                            {Language.getTitleCase('unselected users')}
                            ( <a onClick={handleSelectAll} href="#">All</a> )
                        </h5>
                        <ul className='create-group__users-list' tabIndex='0'>
                            {Object.entries(unsel_users).map(
                                ([key, user]) => (
                                    <li
                                        tabIndex='0'
                                        className='create-group__users-item'
                                        key={"selected_users_" + key}
                                        onClick={() => handleAddSelected( user.uid ) }>
                                        {user.name}
                                    </li>
                                )
                            )}
                        </ul>
                    </div>
                </div>
            </div>

            <div className='basic-modal__button-container'>
                <button
                    className='basic-modal__button basic-modal__button--clear'
                    disabled={disable_click}
                    onClick={onClose}>
                    {Language.getTitleCase('cancel')}
                </button>

                <button
                    className='basic-modal__button basic-modal__button--solid'
                    disabled={disable_click}
                    onClick={handleSave}>
                    {Language.getTitleCase("save")}
                </button>
            </div>
        </div>
    );
}
