import React from "react";
import Language from "../helpers/language";

export const BadgeTable = props => {
    const { uid, lock, name, logo, percentage, points, owned_perc, message } = props;
    const { onClick } = props;

    let perc = parseInt(percentage) || 0;
    if (perc < 0) {
        perc = 0
    }
    if (perc > 100) {
        perc = 100;
    }

    let op = Math.round(owned_perc * 100.0)

    const t = perc / 100.0
    const prog_first = "rotate(" + (t <= 0.5 ? t * 360 : 180) + "deg)";
    const prog_second = "rotate(" + t * 360 + "deg)";

    return (
        <tr className='badge-table__badge' onClick={() => onClick(uid)}>
            <td className='badge-table__image'>
                <div className='badge-table__icon-wrapper'>
                    <div className='badge-table__progress-meter'>
                        <div
                            style={{ transform: prog_first }}
                            className='radial-progress__half-circle radial-progress__half-circle--first-50'></div>
                        {perc > 50 && (
                            <div
                                style={{ transform: prog_second }}
                                className='radial-progress__half-circle radial-progress__half-circle--second-50'></div>
                        )}
                        <div className='radial-progress__half-circle-cover'></div>
                        <figure className='badge-table__icon'>
                            <img src={logo} alt='logo'/>
                        </figure>
                    </div>
                </div>
            </td>
            <td className='badge-table__name'>
                <div className='badge-table__name-text'>{name}</div>
                <div className='badge-table__name-percent'>{op}% {Language.getTitleCase('players earned')}</div>
            </td>
            <td className='badge-table__message'>
                <p className="text-[1.1em]">{message}</p>
            </td>
            <td className='badge-table__points'>
                {points > 0 &&
                <div className='badge-table__points-text'>{`${points} Points`}</div>
                }
            </td>
        </tr>
    );
};
