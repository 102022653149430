import React, { useEffect, useState } from "react";
import { useStore } from "../../store";
import Util from "../../helpers/util";
import Language from "../../helpers/language";
import { DataRange, ReportDelivery, ReportType } from "../../helpers/consts";
import { SelectWidget } from "../../components/select_widget";
import {Drip7Dropdown} from "../../components/drip7_dropdown";

export const ReportGenerateModal = (props) => {
    const { usr_info } = useStore(state => state)

    const { report, tenant_uid, generate_only, showToast, onClose } = props;
    const [state, setState] = useState({
        report_uid: (report == true)? null: report.uid,
        name: (report == true)? '': report.name,
        report_type: (report == true)? ReportType.REPORT_OVERVIEW: report.report,
        delivery: (report == true)? ReportDelivery.MONTHLY: report.delivery,
        data_range: (report ==  true)? 'all': report.data_range,
        categories: [],
        selected_categories: {},
        queues: [],
        selected_queues: {},
        users: [],
        selected_users: { [usr_info.uid]: usr_info },
    })
    const { report_uid, name, report_type, delivery, data_range,
            categories, selected_categories,
            queues, selected_queues,
            users, selected_users } = state

    useEffect(() => {
        //Get some info!
        if ( !generate_only ) {
            Util.fetch_js('/human/list/', {tenant_uid},
                js => {
                    setState(prev => ({ ...prev,
                        users: js.users
                    }))
                },
                (err, code) => {
                    showToast(err, 'failure')
                })
        }

        //Get all possible categories
        Util.fetch_js('/category/list/', { tenant_uid },
            js => {
                let selected_categories = {}
                js.categories.forEach(x => {
                    selected_categories[x.uid] = x
                })

                setState(prev => ({ ...prev,
                    categories: js.categories,
                    selected_categories,
                }))
            },
            (err, code) => {
                showToast(err, "failure")
            })

        //Is there report details?
        if ( report_uid ) {
            Util.fetch_js('/report/detail/', { report_uid },
                js => {
                    let selected_users = {}
                    js.users.forEach(x => {
                        selected_users[x.uid] = x
                    })

                    let selected_queues = {}
                    js.queues.forEach(x => {
                        selected_queues[x.uid] = x
                    })

                    let selected_categories = {}
                    js.categories.forEach(x => {
                        selected_categories[x.uid] = x
                    })

                    //Store the selected users
                    setState(prev => ({ ...prev,
                        selected_users,
                        selected_queues,
                        selected_categories,
                    }))
                },
                (err, code) => {
                    showToast(err, 'failure')
                })
        }

        //Pull a list of all the queues
        Util.fetch_js('/queue/list/', { tenant_uid },
            js => {
                let selected_queues = {}
                js.queues.forEach(x => {
                    selected_queues[x.uid] = x
                })

                setState(prev => ({...prev,
                    queues: js.queues,
                    selected_queues,
                }))
            },
            (err, code) => {
                showToast(err, 'failure')
            })
    }, [])

    //Basic change op
    const handleChange = (e) => {
        setState(prev => ({...prev, [e.target.name]: e.target.value }))
    }

    const handleSelectedChange = (name, value) => {
        setState(prev => ({...prev, [name]: value }))
    }

    const handleSaveReport = () => {
        const queue_uids = Object.keys(selected_queues)
        const user_uids = Object.keys(selected_users)
        const category_uids = Object.keys(selected_categories)
        if ( user_uids.length <= 0 ) {
            showToast(Language.getSentenceCase('Please select at least one user to receive the report'), 'failure')
            return
        }

        if ( name == "" ) {
            showToast(Language.getSentenceCase('The report requires a name'), 'failure')
            return
        }

        const url = (report_uid)? '/report/modify/': '/report/create/'
        let payload = {
            name,
            report: report_type,
            delivery,
            data_range,
            user_uids,
            queue_uids,
            category_uids,
        }

        if ( report_uid ) {
            payload['report_uid'] = report_uid
        }
        else {
            payload['tenant_uid'] = tenant_uid
        }

        Util.fetch_js(url, payload,
            js => {
                showToast(Language.getSentenceCase('saved report'), 'successful')
                onClose()
            },
            (err, code) => {
                showToast(err, 'failure')
            })
    }

    //Generate a report and exit
    const handleGenerateReport = () => {
        let payload = {
            tenant_uid,
            data_range,
            categories,
        }

        let url = ''
        switch ( report_type ) {
            case ReportType.REPORT_USERS:
                url = '/report/user_queue_report/'
                payload['queue_uids'] = Object.keys(selected_queues)
                break
            case ReportType.REPORT_USER_ACTIVITY:
                url = '/report/user_activity_report/'
                break
            case ReportType.REPORT_CURRICULUM:
                url = '/report/curriculum_report/'
                payload['queue_uids'] = Object.keys(selected_queues)
                break
            case ReportType.REPORT_BADGE:
                url = '/report/badge_report/'
                break
            case ReportType.REPORT_QUESTION:
                url = '/report/question_report/'
                payload['category_uids'] = Object.keys(selected_categories)
                break
            case ReportType.REPORT_POINTS:
                url = '/report/points_report/'
                payload['queue_uids'] = Object.keys(selected_queues)
                break
            case ReportType.REPORT_OVERVIEW:
                url = '/report/overview2_pdf/'
                break

            default:
                showToast(Language.getSentenceCase('Invalid Report Type'), 'failure')
                return
        }

        console.log(payload)
        Util.postDataAndOpenNewTab(url, payload)
        onClose()
    }

    let show_dates = false
    let show_queue = false
    let show_categories = false
    switch ( report_type ) {
        case ReportType.REPORT_USERS:
        case ReportType.REPORT_POINTS:
            show_dates = show_queue = true
            break

        case ReportType.REPORT_CURRICULUM:
            show_queue = true
            break

        case ReportType.REPORT_USER_ACTIVITY:
        case ReportType.REPORT_BADGE:
            show_dates = true
            break

        case ReportType.REPORT_QUESTION:
            show_categories = true
            break

        default:
            break
    }

    return (
        <div className='basic-modal basic-modal--add-group'>
            <div className='basic-modal__head'>
                <h4>{Language.getTitleCase('generate report')}</h4>
                <svg
                    className='basic-modal__close-button'
                    width='12'
                    height='11'
                    viewBox='0 0 12 11'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                    onClick={onClose}>
                    <path
                        d='M7.93359 5.375L10.8633 2.44531C11.2441 2.09375 11.2441 1.50781 10.8633 1.15625L10.2188 0.511719C9.86719 0.130859 9.28125 0.130859 8.92969 0.511719L6 3.44141L3.04102 0.511719C2.68945 0.130859 2.10352 0.130859 1.75195 0.511719L1.10742 1.15625C0.726562 1.50781 0.726562 2.09375 1.10742 2.44531L4.03711 5.375L1.10742 8.33398C0.726562 8.68555 0.726562 9.27148 1.10742 9.62305L1.75195 10.2676C2.10352 10.6484 2.68945 10.6484 3.04102 10.2676L6 7.33789L8.92969 10.2676C9.28125 10.6484 9.86719 10.6484 10.2188 10.2676L10.8633 9.62305C11.2441 9.27148 11.2441 8.68555 10.8633 8.33398L7.93359 5.375Z'
                        fill='currentColor'></path>
                </svg>
            </div>

            <div className="fields">
                {!generate_only &&
                    <div className='basic-modal__field basic-modal__field--add-question'>
                        <label
                            className='basic-modal__label'
                            htmlFor='newQuestionCategory'>
                            {Language.getTitleCase('name')}
                        </label>
                        <input
                            type='text'
                            className='mt-1 border-1 border-gray-300 rounded'
                            id='newQuestionCategory'
                            placeholder={Language.getSentenceCase('report name')}
                            name='name'
                            value={name}
                            onChange={handleChange}
                        />
                    </div>
                }

                <div className='basic-modal__field basic-modal__field--add-question'>
                    <label
                        className='basic-modal__label'
                        htmlFor='newQuestionCategory'>
                        {Language.getTitleCase('reports')}
                    </label>
                    <div className='dropdown dropdown--attached dropdown--add-question'>
                        <Drip7Dropdown
                            id='report_type'
                            name='report_type'
                            entries={Object.values(ReportType)}
                            value={report_type}
                            onChange={handleChange}
                            display={x => Language.getTitleCase(x)}
                            overflow='max-h-60'
                            />
                    </div>
                </div>

                {!generate_only &&
                <div className='basic-modal__field basic-modal__field--add-question'>
                    <label
                        className='basic-modal__label'
                        htmlFor='newQuestionCategory'>
                        {Language.getTitleCase('delivery')}
                    </label>
                    <div className='dropdown dropdown--attached dropdown--add-question'>
                        <Drip7Dropdown
                            id='delivery'
                            name='delivery'
                            entries={Object.values(ReportDelivery)}
                            value={delivery}
                            onChange={handleChange}
                            display={x => Language.getTitleCase(x)}
                            overflow='max-h-60'
                            />
                    </div>
                </div>
                }

                {show_dates &&
                    <div className='basic-modal__field basic-modal__field--add-question'>
                        <label
                            className='basic-modal__label'
                            htmlFor='newQuestionCategory'>
                            {Language.getTitleCase('data range')}
                        </label>
                        <div className='dropdown dropdown--attached dropdown--add-question'>
                            <Drip7Dropdown
                                id='data_range'
                                name='data_range'
                                entries={Object.values(DataRange)}
                                value={data_range}
                                onChange={handleChange}
                                display={x => Language.getTitleCase(x)}
                                overflow='max-h-60'
                                />
                        </div>
                    </div>
                }
            </div>

            {show_queue &&
                <SelectWidget
                    entries={queues}
                    selected={selected_queues}
                    title={Language.getTitleCase('queues')}
                    onChange={x => handleSelectedChange('selected_queues', x)}
                />}

            {show_categories &&
                    <SelectWidget
                        entries={categories}
                        selected={selected_categories}
                        title="Categories"
                        onChange={x => handleSelectedChange('selected_categories', x)}
                    />}

            {!generate_only &&
                    <SelectWidget
                        entries={users}
                        selected={selected_users}
                        title={Language.getTitleCase('users')}
                        onChange={x => handleSelectedChange('selected_users', x)}
                    />}

            <div className='basic-modal__button-container'>
                <button
                    className='basic-modal__button basic-modal__button--clear'
                    onClick={onClose}>
                    {Language.getTitleCase('cancel')}
                </button>
                {generate_only &&
                    <button
                        className='basic-modal__button basic-modal__button--solid'
                        onClick={handleGenerateReport}>
                        {Language.getTitleCase('generate')}
                    </button>
                }
                {!generate_only &&
                    <button
                        className='basic-modal__button basic-modal__button--solid'
                        onClick={handleSaveReport}>
                        {Language.getTitleCase('save')}
                    </button>
                }
            </div>
        </div>
    );
};
