import React, { useEffect, useState } from "react";
import Util from "../../helpers/util";
import Language from "../../helpers/language";
import {
    UserQuestionTableWidget
} from "../../components/admin/user_question_table_widget";


export const UserPointsModal = (props) => {
    const { info, showToast, onClose } = props

    const [state, setState] = useState({
        user_questions: [],
        user_question_updates: {},
    })
    const { user_questions, user_question_updates } = state

    useEffect(() => {
        //Get a list of all members for this tenant
        Util.fetch_js( "/user_question/list/", { user_uid: info.uid },
            js => {
                setState(prev => ({ ...prev,
                    user_questions: js.user_questions,
                }))
            },
            (err, code) => {
                showToast( err.reason, "successful")
            }
        );
    }, [])

    const handleChange = (uid, points) => {
        let uqu = { ...user_question_updates }

        //If this "change" matches the current there is actually no change
        if ( user_questions.find(x => x.uid == uid ).points == points ) {
            //Only remove the key if we already have the uid, else do nothing
            if ( uid in uqu ) {
                delete uqu[uid]
            }
        }
        //Normal behavior, add in a change to points
        else {
            uqu[uid] = points
        }

        setState(prev => ({ ...prev, user_question_updates: uqu }))
    }

    const handleSave = () => {
        let user_questions = []

        Object.keys(user_question_updates).forEach( uid => {
            user_questions.push({
                uid,
                points: user_question_updates[uid],
                //correct
                //desc
            })
        })

        Util.fetch_js('/user_question/modify/', { user_uid: info.uid, user_questions },
            js => {
                showToast("Successfully updated points", "successful")

                setState(prev => ({...prev,
                    user_questions: js.user_questions,
                    user_question_updates: {},
                }))
            },
            (err,code) => {
                showToast( err, "failure")
            })
    }

    const handleDiscard = () => {
        setState(prev => ({...prev,
            user_questions: user_questions,
            user_question_updates: {}
        }))
    }

    return (
        <div className='basic-modal basic-modal--add-question basic-modal--wide-modal'>
            <div className='basic-modal__head'>
                <h4>
                    {Language.getTitleCase('user points')}
                </h4>
                <>
                    {Object.keys(user_question_updates).length > 0 &&
                        <div className='basic-modal__button-container--save'>
                            <button
                                className='basic-modal__button basic-modal__button--clear'
                                onClick={handleDiscard}>
                                {Language.getTitleCase('discard changes')}
                            </button>
                            <button
                                className='basic-modal__button basic-modal__button--solid'
                                onClick={handleSave}>
                                {Language.getTitleCase('save changes')}
                            </button>
                        </div>
                    }
                    {Object.keys(user_question_updates).length == 0 &&
                        <svg
                        className='basic-modal__close-button'
                        width='12'
                        height='11'
                        viewBox='0 0 12 11'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                        onClick={onClose}>
                        <path
                            d='M7.93359 5.375L10.8633 2.44531C11.2441 2.09375 11.2441 1.50781 10.8633 1.15625L10.2188 0.511719C9.86719 0.130859 9.28125 0.130859 8.92969 0.511719L6 3.44141L3.04102 0.511719C2.68945 0.130859 2.10352 0.130859 1.75195 0.511719L1.10742 1.15625C0.726562 1.50781 0.726562 2.09375 1.10742 2.44531L4.03711 5.375L1.10742 8.33398C0.726562 8.68555 0.726562 9.27148 1.10742 9.62305L1.75195 10.2676C2.10352 10.6484 2.68945 10.6484 3.04102 10.2676L6 7.33789L8.92969 10.2676C9.28125 10.6484 9.86719 10.6484 10.2188 10.2676L10.8633 9.62305C11.2441 9.27148 11.2441 8.68555 10.8633 8.33398L7.93359 5.375Z'
                            fill='currentColor'></path>
                    </svg>
                    }
                </>
            </div>

            <div className='user-photo-and-edit'>
                <UserQuestionTableWidget
                    user_questions={user_questions}
                    user_question_updates={user_question_updates}
                    selected={[]}
                    onChange={handleChange}
                    />
            </div>
        </div>
    );
}
