import React, {useState} from "react";
import Util from "../../helpers/util";
import Language from "../../helpers/language";
import { Drip7Button } from "../../components/drip7_button";
import {Drip7Input} from "../../components/drip7_input";


export const TenantPresentationTab = props => {
    const { tenant_uid, showToast } = props;

    const [state, setState] = useState({
        updating: false,
        user_count: 30,
    });
    const { updating, user_count } = state;

    const handleReset = () => {
        if (updating) {
            return
        }

        if ( user_count > 500 ) {
            showToast("Please enter a number less than 500", "failure")
            return
        }

        //Let the user know we're running
        showToast("This might take up to 1 minute", "Resetting presentation")
        setState(prev => ({...prev, updating: true }))

        Util.fetch_js('/tenant/update_presentation/', { tenant_uid, user_count },
            js => {
                showToast(Language.getSentenceCase("Successful"))
                setState(prev => ({...prev, updating: false }))
            },
            (err, code) => {
                showToast(err, "failure")
            })
    }

    const handleChange = (e) => {
        setState(prev => ({...prev, [e.target.name]: e.target.value }))
    }

    return (
        <div className="field-container">
            <div className='customization-fields' role='tabpanel'
                 id='tab_panel_main_information'>
                <div>
                    <div className='grid-item'>
                        <Drip7Input
                            type="text"
                            name="user_count"
                            id="user_count"
                            label={Language.getSentenceCase("Presentation user count")}
                            placeholder={Language.getSentenceCase("Enter the number of users to be presented")}
                            value={user_count}
                            onChange={handleChange}
                        />
                        <Drip7Button
                            className={Util.classNames(updating? "cursor-not-allowed" : "")}
                            onClick={handleReset}>
                            <div className="inline-flex text-md font-semibold">
                                {updating &&
                                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12"
                                                cy="12" r="10" stroke="currentColor"
                                                stroke-width="4"></circle>
                                        <path className="opacity-75"
                                              fill="currentColor"
                                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                }
                                {Language.getSentenceCase("Reset presentation")}
                            </div>
                        </Drip7Button>
                    </div>
                </div>
            </div>
        </div>
    );
}
