import React, { useState } from 'react'
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react'
import { ExclamationTriangleIcon, XMarkIcon } from '@heroicons/react/24/outline'
import Language from "../../helpers/language";


export const ReviewPdf = props => {
    const {open, onClose, content_url} = props

    return (
        <Dialog className="relative z-50" open={open} onClose={onClose}>
            <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            <div className="fixed inset-0 z-50 w-screen screen-h overflow-y-auto">
                <div className="flex min-h-full h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <DialogPanel
                        transition
                        className="relative transform h-full overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 w-full sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95 sm:max-w-5xl min-h-full"
                    >
                        <div className="absolute right-0 top-0 pr-4 pt-4">
                            <button
                                type="button"
                                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                onClick={onClose}
                            >
                                <span className="sr-only">Close</span>
                                <XMarkIcon className="h-6 w-6" aria-hidden="true"/>
                            </button>
                        </div>
                        <div className="sm:hidden absolute left-0 top-0 pl-4 pt-4">
                            <DialogTitle as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                {Language.getTitleCase("Policy Review")}
                            </DialogTitle>
                        </div>
                        <DialogTitle as="h3" className="hidden sm:block text-base font-semibold leading-6 text-gray-900">
                            {Language.getTitleCase("Policy Review")}
                        </DialogTitle>
                        <div className="mt-2 w-full h-full sm:pb-6 inline-flex justify-content-center">
                            <embed className="w-full" src={content_url} referrerPolicy="no-referrer"/>
                        </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    )
}
