import Util from "../helpers/util";

export const Drip7Button = props => {
    const { children } = props
    const idBtn = props.idBtn || ""
    const nameBtn = props.nameBtn || ""
    const disabled = props.disabled || false
    const mode = props.mode || "filled"
    const color = props.color || "drip7"
    const className = props.className || ""
    const onClick = props.onClick || (() => {})
    const style = props.style || {}

    if (mode === "outlined") {
        return (
            <button
                id={idBtn}
                name={nameBtn}
                disabled={disabled}
                style={style}
                type="button"
                onClick={onClick}
                className={Util.classNames(`h-10 rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-${color} ring-1 ring-inset ring-${color} hover:bg-gray-50 hover:brightness-105`, className)}>
                {children}
            </button>
        )
    }
    else if (mode === "pilled") {
        return (
            <button
                id={idBtn}
                name={nameBtn}
                disabled={disabled}
                style={style}
                type="button"
                onClick={onClick}
                className={Util.classNames("h-10 rounded-full bg-${drip7} px-4 py-2.5 text-sm font-semibold text-white hover:bg-${drip7} focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-${drip7} hover:brightness-105", className)}>
                {children}
            </button>
        )

    }
    else if (mode === "dropdown") {
        return (
            <button
                id={idBtn}
                name={nameBtn}
                disabled={disabled}
                style={style}
                type="button"
                onClick={onClick}
                className={Util.classNames(`h-10 rounded-md bg-${color} drop-button px-2 py-2.5 text-sm font-semibold text-white hover:bg-${color} focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-${color} hover:brightness-105`, className)}>
                {children}
            </button>
        )
    }
    else { //Filled and catch all
        return (
            <button
                id={idBtn}
                name={nameBtn}
                disabled={disabled}
                style={style}
                type="button"
                onClick={onClick}
                className={Util.classNames(`h-10 rounded-md bg-${color} px-3.5 py-2.5 text-sm font-semibold text-white hover:bg-${color} focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-${color} hover:brightness-105`, className)}>
                {children}
            </button>
        )
    }
}

