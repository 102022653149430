import Util from "../helpers/util";

import { ExclamationCircleIcon } from '@heroicons/react/20/solid'

export const Drip7TextArea = props => {
    const id = props.id || ""
    const name = props.name || ""
    const label = props.label || props.title || ""
    const children = props.children
    const type = props.type || ""
    const placeholder = props.placeholder || ""
    const disabled = props.disabled || false
    const rows = props.rows || null
    const readOnly = props.readOnly || false
    const value = props.value || ""
    const onChange = props.onChange || (() => {})
    const className = props.className || ""
    const styleTextarea = props.styleTextarea || {}

    const isComponent = obj => {
        if (obj === undefined || obj === null) {
            return false
        }

        //Check if string
        if (typeof obj === 'string' || obj instanceof String) {
            return obj.toString().replace(/\s/g, '').length > 0
        }
        else if (Array.isArray(obj) && obj.length > 0) {
            return isComponent(obj[0])
        }
        else {
            //Return true if its an object
            return obj.constructor === Object
        }
    }

    return (
        <div className={className}>
            {(label || children) && 
                <label
                    className='basic-modal__label'
                    htmlFor={name}>
                    {label}{children}
                </label>
            }
            <div className="relative rounded-md shadow-sm">
                <textarea
                    id={id}
                    name={name}
                    className={Util.classNames(
                        "block w-full rounded-md border-0 py-2.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-inset focus:ring-drip7 sm:leading-6 disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200"
                    )}
                    style={styleTextarea}
                    type={type}
                    rows={rows}
                    placeholder={placeholder}
                    value={value}
                    readOnly={readOnly}
                    disabled={disabled}
                    onChange={onChange}
                />
            </div>
        </div>
    )
}

