import React, { useEffect, useState } from "react";

export const AnswerTypeInlineInput = (props) => {
    const { name, value, onChanged } = props;

    return (
        <div className='daily-questions__fitb'>
            <input
                type='text'
                name={name}
                value={value}
                className={"daily-questions__fitb-answer"}
                placeholder='Type Answer Here'
                onChange={onChanged}
            />
        </div>
    );
}
