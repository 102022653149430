import React, { Fragment, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ArrowsRightLeftIcon, XMarkIcon } from '@heroicons/react/24/outline'
import Util from "../../helpers/util";
import Language from "../../helpers/language";
import { Drip7Combobox } from "../../components/drip7_combobox";


export const SwapTenantModal = props => {
    const { open, tenant_uid, onClose, showToast } = props

    const [state, setState] = useState({
        tenants: [],
        content_modules: [],
    })
    const { tenants, content_modules } = state

    useEffect(() => {
        Util.fetch_js('/tenant/super_admin/', {},
            js => {
                setState(prev => ({ ...prev,
                    tenants: js.tenants,
                    content_modules: js.content_modules,
                }))
            },
            (err, code) => {
                showToast(err)
            })
    }, [])

    const handleContext = (idx) => {
        if (idx >= 0 && idx < tenants.length) {
            Util.fetch_js('/tenant/swap/', { tenant_uid: tenants[idx].uid },
                js => {
                    window.location.reload(true);
                },
                (err, code) => {
                    showToast(err)
                })
        }
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-40" onClose={onClose}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-40 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel
                                className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                <div
                                    className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                    <button
                                        type="button"
                                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                                        onClick={onClose}>
                                        <span className="sr-only">Close</span>
                                        <XMarkIcon className="h-6 w-6"
                                                   aria-hidden="true"/>
                                    </button>
                                </div>
                                <div className="sm:flex sm:items-start h-96 mb-8">
                                    <div
                                        className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-drip7 sm:mx-0 sm:h-10 sm:w-10">
                                        <ArrowsRightLeftIcon
                                            className="h-6 w-6 text-white flex-shrink-0"
                                            aria-hidden="true"/>
                                    </div>
                                    <div className="mt-3 sm:mx-4 sm:mt-0 sm:text-left w-full">
                                        <Dialog.Title as="h3"
                                                      className="text-base font-semibold leading-6 text-gray-900">
                                            {Language.getSentenceCase('Select a tenant to transfer into')}
                                        </Dialog.Title>
                                        <div className="mt-3 w-full">
                                            <Drip7Combobox
                                                entries={tenants.map(t => ({
                                                    name: t.name,
                                                    uid: t.uid,
                                                    image_url: t.compact_logo
                                                }))}
                                                selected_idx={tenants.findIndex(t => t.uid === tenant_uid)}
                                                onSelected={handleContext}/>
                                        </div>

                                        <Dialog.Title as="h3"
                                                      className="mt-8 text-base font-semibold leading-6 text-gray-900">
                                            {Language.getSentenceCase('Select a content module to transfer into')}
                                        </Dialog.Title>
                                        <div className="mt-3 w-full">
                                            <Drip7Combobox
                                                entries={content_modules.map(t => ({
                                                    name: t.name,
                                                    uid: t.uid,
                                                    image_url: t.compact_logo
                                                }))}
                                                selected_idx={content_modules.findIndex(t => t.uid === tenant_uid)}
                                                onSelected={handleContext}/>
                                        </div>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
