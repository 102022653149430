import React, { useState } from "react";
import { Toast } from "../components/toast";
import Util from "../helpers/util";
import { useStore } from "../store";
import InputWithIcon from "../components/input_with_Icon";
import Logo from "../assets/images/shared/drip7-logo.svg";
import Language from "../helpers/language";
import {Link, useNavigate} from "react-router-dom";
import {Drip7Input} from "../components/drip7_input";
import {EnvelopeIcon, UserIcon} from "@heroicons/react/24/outline";

//State wrapper
export const Resetpwd = props => {
    const {usr_info} = useStore(state => state)
    const { initial_tenant } = props

    const [state, setState] = useState({
        errored: "",
        toast: { msg: "", status: "" },
        email: usr_info.email
    });
    const { errored, toast, email } = state;

    const logo = initial_tenant.full_logo || Logo

    const navigate = useNavigate()

    const showToast = (msg, status) => {
        const toast = { msg, status };
        setState({ ...state, toast });
    };

    const handleForgot = () => {
        Util.fetch_js( "/human/forgot/", { email },
            js => {
                showToast(Language.getSentenceCase('Please check your email'), "successful");
            },
            (reason, code) => {
                showToast(reason, "failure");
            }
        );
    };

    const handleKeypress = event => {
        // Enter pressed? login
        if (event.key === "Enter") {
            handleForgot();
        }
    };

    const handleNav = path => {
        navigate(path);
    };

    const handleChange = event => {
        const name = event.target.name;
        const value = event.target.value;
        setState({ ...state, [name]: value });
    };

    return (
        <div className='login-page'>
            <div className='login-wrap'>
                <figure className='logo'>
                    <img src={logo} alt='Drip7 logo' />
                </figure>

                <div className='login-menu'>
                    {errored != "" && (
                        <div className='login-error'>
                            <div className='login-error-wrap'>
                                <p>{Language.getString('error')}. {errored}.</p>
                            </div>
                        </div>
                    )}

                    <div className='login-fields'>
                        <Drip7Input
                            id='email'
                            name='email'
                            type='email'
                            placeholder={Language.getString("email")}
                            value={email}
                            onKeyDown={handleKeypress}
                            onChange={handleChange}>
                            <EnvelopeIcon className='h-6 w-6 text-gray-600 stroke-2'/>
                        </Drip7Input>
                        {/*}
                        <InputWithIcon
                            inputProps={{
                                "aria-label": "Email",
                                type: "email",
                                placeholder: Language.getString('email'),
                                name: "email",
                                autoCapitalize: "off",
                                autoComplete: "off",
                                autoCorrect: "off",
                                spellCheck: "false",
                                onKeyPress: handleKeypress,
                                onChange: handleChange
                            }}
                            icon={"icon user-icon"}
                        />
                        {*/}

                        <button className='btn' onClick={handleForgot}>
                            {Language.getString('Email password reset')}
                        </button>
                        <div className='login-help'>
                            <Link to="/login">
                                {Language.getString('Back to Login')}
                            </Link>
                        </div>
                    </div>
                </div>

                <Toast
                    msg={toast.msg}
                    status={toast.status}
                    timeout='5000'
                    onComplete={() => showToast("", "")}
                />
            </div>
        </div>
    );
};
