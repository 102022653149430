import React, { useEffect, useRef } from "react";
import  FullRed  from "../../assets/images/user-portal/drip-full-red.svg";
import  FullBlue  from "../../assets/images/user-portal/drip-full-blue.svg";
import  FullGray  from "../../assets/images/user-portal/drip-full-gray.svg";
import  EmptyBlue  from "../../assets/images/user-portal/drip-empty-blue.svg";
import  EmptyRed  from "../../assets/images/user-portal/drip-empty-red.svg";
import Language from "../../helpers/language";
import Util from "../../helpers/util";


export const WeeklySchedule = (props) => {
    const { questions, selected_idx } = props;

    // translating the day then getting first letter of day
    const days = [
        Language.getSentenceCase('sunday')[0],
        Language.getSentenceCase('monday')[0],
        Language.getSentenceCase('tuesday')[0],
        Language.getSentenceCase('wednesday')[0],
        Language.getSentenceCase('thursday')[0],
        Language.getSentenceCase('friday')[0],
        Language.getSentenceCase('saturday')[0],
    ]

    let today = new Date();
    today.setHours(0,0,0,0);
    today = Util.dateToUtc( today )

    //Build out the progress
    const progress = [6,5,4,3,2,1,0].map((d) => {
        const date = new Date(today.getTime())
        date.setDate(date.getDate()-d);

        //The result object for the day
        const result = {
            idxs: [],
            letter: days[date.getDay()],
            on_time: false,
            answered: false,
        }

        //Build out the result
        questions.forEach((q, idx) => {
            const d_on = Math.floor((date - q.assigned_on)/(1000*60*60))
            if ( d_on < 0 || d_on > 24 ) {
                return;
            }

            const c_on = Math.floor((today - q.assigned_on)/(1000*60*60))
            const a_on = (q.answered_on != null && q.answered_on > 0)? Math.floor((q.answered_on - q.assigned_on)/(1000*60*60)): null

            //Update the result
            result.idxs.push(idx)
            if ( a_on !== null ) {
                result.answered = true
            }
            if ( c_on >= 0 && c_on <= 24 ||
                 a_on !== null && a_on >= 0 && a_on <= 24 ) {
                result.on_time = true;
            }
        })
        return result;
    });

    //Calculate the selected based on the question
    const selected = progress.findIndex( p => p.idxs.indexOf(selected_idx) >= 0 )

    return (
      <div className="weekly-schedule userdash-component">
        {progress.map((p, i) => {
            let img = p.answered ? FullRed : EmptyRed;
            if (p.idxs.length <= 0) {
                img = FullGray;
            }
            else if (p.on_time) {
                img = p.answered ? FullBlue : EmptyBlue;
            }

            return (
                <span key={i} className="weekly-schedule-day">
                    <img src={img}/>
                    <span className={(i === 6 && selected < 0 || i === selected) ? "day-letter-current" : "day-letter"}>{p.letter}</span>
                </span>
            )
        })}
      </div>
    );
  };
  