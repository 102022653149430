import Language from "../../helpers/language";
import { Drip7TextArea } from "../drip7_textarea";

export const ContentTextWidget = props => {
    const { access, content, onChange } = props;
    const text = content.text || ""

    const handleChange = e => {
        if ( !access.write ) {
            return
        }

        onChange('content', { ...content, [e.target.id]: e.target.value });
    };

    return (
        <Drip7TextArea
            className='basic-modal__field--add-question width-100 px-1'
            label={Language.getTitleCase('Question')}
            placeholder={Language.getSentenceCase('Type question text here') + '...'}
            id='text'
            readOnly={!access.write}
            onChange={handleChange}
            value={text}
        />
    );
};
