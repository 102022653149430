import React, {useEffect, useState} from "react";
import { PhotoIcon, UserCircleIcon } from '@heroicons/react/24/solid'
import {SketchPicker} from "react-color";
import Language from "../../helpers/language";
import {Menu} from "@headlessui/react";
import {ChevronDownIcon, ExclamationCircleIcon} from "@heroicons/react/20/solid";
import Util from "../../helpers/util";
import {CheckCircleIcon} from "@heroicons/react/24/outline";
import { sha256 } from "js-sha256";
import {Spinner} from "../../components/spinner";

export const OnboardingInformation = React.forwardRef((props, ref) => {
    React.useImperativeHandle(ref, () => ({
        attemptContinue: handleContinue,
    }));

    const { onContinue, showToast } = props

    const [state, setState] = useState(
        ('init' in props && props.init != null && 'tenant_name' in props.init )? props.init: {
            website: "",
            tenant_name: "",
            subdomain: "",
            subdomain_sync: true,

            safe_color: null,
            primary_color: "#41C8FF",
            show_primary_color: false,
            secondary_color: "#1482E6",
            show_secondary_color: false,

            tenant_name_valid: null,
            subdomain_valid: null,

            name: "",
            nickname: "",
            email: "",
            phone: "",

            password: "",
            password_repeat: "",

            email_valid: null,
            password_valid: null,

            spinner: false,
    })
    const { website, tenant_name, subdomain, subdomain_sync, primary_color, show_primary_color, secondary_color, show_secondary_color, tenant_name_valid, subdomain_valid, name, nickname, email, phone, password, password_repeat, password_valid, email_valid, spinner } = state

    useEffect(() => {
        window.scrollTo({ top: 0 });
    }, []);

    const subdomainSafe = value => {
        return value.toLowerCase().replace(/^[0-9]*/g, '').replace(/[^a-zA-Z0-9]+/g, '-')
    }

    //Not needed, but I didn't want all this code in the HTML
    const handleContinue = () => {
        //Provide some bitchen information about what went wrong
        if ( !tenant_name_valid ) {
            return showToast( "Please enter a valid tenant name", 'failure' )
        }
        else if ( !subdomain_valid ) {
            return showToast( "Please enter a valid subdomain", 'failure' )
        }
        else if ( !email_valid ) {
            return showToast( "Please enter a valid email", 'failure' )
        }
        else if ( !password_valid ) {
            return showToast( "Please enter a valid password", 'failure' )
        }

        onContinue( {
            tenant: {
                website,
                name: tenant_name,
                subdomain,
                primary_color,
                secondary_color,
            },
            user: {
                name,
                nickname,
                email,
                phone,
                pwd_hash: sha256( password ),
            },
            state
        } )
    }

    const handleShowColor = name => {
        const shower = { show_primary_color: false, show_secondary_color: false }
        setState(prev => ({ ...prev, ...shower,
            [`show_${name}`]: true,
            safe_color: prev[name],
        }))
    }

    const handleSaveColor = (name, revert) => {
        //Save?
        if ( revert !== true ) {
            setState(prev => ({ ...prev,
                [`show_${name}`]: false,
                safe_color: null,
            }))
        }

        //Revert the change
        else {
            setState(prev => ({ ...prev,
                [`show_${name}`]: false,
                [name]: prev.safe_color,
                safe_color: null,
            }))
        }
    }

    const handleChangeColor = (name, color) => {
        setState(prev => ({ ...prev,
            [name]: color.hex,
        }))

        //Set primary and secondary color CSS variables
        if ( name == 'primary_color' ) {
            document.documentElement.style.setProperty(
                "--drip7-primary-color", color.hex
            )
        }

        if ( name == 'secondary_color' ) {
            document.documentElement.style.setProperty(
                "--drip7-secondary-color", color.hex
            )
        }
    }

    const handleChange = (e) => {
        const {name, value } = e.target

        if ( name == 'subdomain' ) {
            setState(prev => ({ ...prev,
                [name]: subdomainSafe( value ),
                subdomain_sync: false,
            }))
        }
        else if ( name == 'tenant_name' && subdomain_sync ) {
            setState(prev => ({ ...prev,
                [name]: value,
                subdomain: subdomainSafe( value ),
            }))
        }
        else if ( name === 'password_repeat' ) {
            const password_valid = ( password.length >= 8 && value.length >= 8 && password === value )? true: state.password_valid
            setState(prev => ({ ...prev,
                [name]: value,
                password_valid,
            }))
        }
        else {
            setState(prev => ({ ...prev, [name]: value }))
        }
    }

    const handleTenantNameBlur = (tenant_name, subdomain) => {
        if ( tenant_name === undefined ) {
            tenant_name = state.tenant_name
        }
        if ( subdomain === undefined ) {
            subdomain = state.subdomain
        }

        Util.fetch_js('/client/onboarding/check_valid/', { name: tenant_name, subdomain },
            js => {
                //Set the error status
                setState(prev => ({ ...prev,
                    tenant_name_valid: js.name_valid,
                    subdomain_valid: js.subdomain_valid,
                    spinner: false,
                }))

                //Show the error toast
                if ( !js.name_valid || !js.subdomain_valid ) {
                    showToast( js.err, 'failure' )
                }
            },
            showToast )
    };

    const handleEmailBlur = () => {
        Util.fetch_js('/human/check_valid/', { email },
            js => {
                //Set the error status
                setState(prev => ({ ...prev,
                    email_valid: js.email_valid,
                }))

                //Show the error toast
                if ( !js.email_valid ) {
                    showToast( js.err, 'failure' )
                }
            },
            showToast )
    };

    const handleEnter = (e) => {
        if ( e.key === 'Enter' ) {
            if ( handlePasswordBlur() ) {
                handleContinue()
            }
        }
    }

    const handlePasswordBlur = () => {
        if ( !email_valid ) {
            handleEmailBlur()
        }

        let password_valid = true
        if ( password.length == 0 || password_repeat.length == 0 ) {
            password_valid = null
        }
        else if ( password.length < 8 || password_repeat.length < 8 ) {
            password_valid = false
            showToast( "Passwords must be at least 8 characters", 'failure' )
        }
        else if ( password != password_repeat ) {
            password_valid = false
            showToast( "Passwords do not match", 'failure' )
        }
        setState(prev => ({ ...prev,
            password_valid
        }))

        return password_valid
    };

    const handleScrape = () => {
        if ( website.length === 0 ) {
            return
        }

        if ( website === 'localhost' ) {
            setState(prev => ({ ...prev,
                tenant_name: "Localhost",
                subdomain: "localhost",
                name: "John Doe",
                email: "johndoe@drip7.com",
                phone: "651-555-1212",
                password: "password123",
                password_repeat: "password123",

                tenant_name_valid: true,
                subdomain_valid: true,
                email_valid: true,
                password_valid: true,
            }))
            return
        }

        setState(prev => ({ ...prev,
            spinner: true,
        }))

        Util.fetch_js('/client/onboarding/scrape_website/', { url: `https://${website}` },
            js => {
                const {name, logo_url, primary_color, secondary_color} = js

                const payload = {}
                if ( name && name.length > 0 ) {
                    payload.tenant_name = name
                    payload.subdomain = subdomainSafe( name )
                }
                if ( primary_color && primary_color.length > 0 ) {
                    payload.primary_color = primary_color
                }
                if ( secondary_color && secondary_color.length > 0 ) {
                    payload.secondary_color = secondary_color
                }
                if ( logo_url && logo_url.length > 0 ) {
                    payload.logo_url = logo_url
                }

                setState(prev => ({ ...prev,
                    ...payload,
                    spinner: false,
                }))
                showToast("Successfully scraped website", 'success')

                //Run the tenant name checks
                handleTenantNameBlur( name, subdomainSafe( name ) )
            },
            (err, code) => {
                setState(prev => ({ ...prev,
                    spinner: false,
                }))
                showToast(err, "failure")
            } )
    }

    const ringWrapper = (valid, null_klass, success_klass, failure_klass) => {
        if ( valid == null ) {
            return null_klass
        }
        else if ( valid ) {
            return success_klass
        }
        else {
            return failure_klass
        }
    }

    return (
        <div className="p-10 space-y-10 divide-y divide-gray-900/10 bg-white">
            <div className="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3">
                <div className="px-4 sm:px-0">
                    <h2 className="text-base font-semibold leading-7 text-gray-900">
                        Organization’s Information
                    </h2>
                    <p className="mt-1 text-sm leading-6 text-gray-600">
                        Enter your organization’s basic information to help with Drip7 onboarding. This information can be changed later if needed.
                    </p>
                </div>

                <div className="bg-white shadow-sm border-1 ring-1 ring-gray-300 sm:rounded-xl md:col-span-2">
                    <div className="px-4 py-6 sm:p-8">
                        <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="sm:col-span-4">
                                <label htmlFor="website" className="block text-sm font-medium leading-6 text-gray-900">
                                    Website (optional)
                                </label>
                                <div className="mt-2 w-full flex flex-row">
                                    <div className="w-full flex rounded-md ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-drip7 sm:max-w-md">
                                        <span className="flex select-none items-center pl-3 text-gray-500 sm:text-sm">https://</span>
                                        <input
                                            type="text"
                                            name="website"
                                            id="website"
                                            className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                            placeholder="www.example.com"
                                            value={website}
                                            onChange={handleChange}
                                            onBlur={handleScrape}
                                        />
                                    </div>
                                {spinner &&
                                    <Spinner className="ml-4"/>
                                }
                                </div>
                            </div>

                            <div className="sm:col-span-3">
                                <label htmlFor="tenant_name" className="block text-sm font-medium leading-6 text-gray-900">
                                    Tenant name
                                </label>
                                <div className="mt-2">
                                    <div className="flex align-items-center rounded-md ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-drip7 sm:max-w-md">
                                        <input
                                            type="text"
                                            name="tenant_name"
                                            id="tenant_name"
                                            className="block flex-1 border-0 bg-transparent py-1.5 pl-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                            placeholder="Name"
                                            value={tenant_name}
                                            onBlur={handleTenantNameBlur}
                                            onChange={handleChange}
                                        />
                                        {false && tenant_name_valid != null && tenant_name_valid &&
                                            <CheckCircleIcon className="flex select-none items-center pr-3 h-8 w-8 text-green-400"/>
                                        }
                                        {tenant_name_valid != null && !tenant_name_valid &&
                                            <ExclamationCircleIcon className="flex select-none items-center pr-3 h-8 w-8 text-red-400"/>
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="sm:col-span-3">
                                <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
                                    Subdomain
                                </label>
                                <div className="mt-2">
                                    <div className="flex align-items-center rounded-md ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-drip7 sm:max-w-md">
                                        <input
                                            type="text"
                                            name="subdomain"
                                            id="subdomain"
                                            className="block flex-1 border-0 bg-transparent py-1.5 pl-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                            placeholder="Subdomain"
                                            value={subdomain}
                                            onBlur={handleTenantNameBlur}
                                            onChange={handleChange}
                                        />
                                        {subdomain_valid != null && subdomain_valid &&
                                            <CheckCircleIcon className="flex select-none items-center pr-3 h-8 w-8 text-green-400"/>
                                        }
                                        {subdomain_valid != null && !subdomain_valid &&
                                            <ExclamationCircleIcon className="flex select-none items-center pr-3 h-8 w-8 text-red-400"/>
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="sm:col-span-3">
                                <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900">
                                    Primary Color
                                </label>

                                {show_primary_color && <>
                                    <SketchPicker
                                        color={primary_color}
                                        onChange={e => handleChangeColor( 'primary_color', e )}
                                        onChangeComplete={e => handleChangeColor( 'primary_color', e )}
                                    />
                                    <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
                                        <button
                                            className="text-sm font-semibold leading-6 text-gray-900"
                                            onClick={() => handleSaveColor( 'primary_color', true )}>
                                            Cancel
                                        </button>
                                        <button
                                            className="rounded-md bg-drip7 px-3 py-2 text-sm font-semibold text-white hover:brightness-110 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-drip7"
                                            onClick={() => handleSaveColor( 'primary_color' )}>
                                            Confirm
                                        </button>
                                    </div>
                                </>}

                                {!show_primary_color && <>
                                    <Menu as="div" className="relative inline-block text-left">
                                        <div>
                                            <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                                onClick={() => handleShowColor( 'primary_color' )}>
                                                <div className="w-5 h-5 rounded-full" style={{backgroundColor: primary_color}}>
                                                </div>
                                                Primary Color
                                                <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                                            </Menu.Button>
                                        </div>
                                    </Menu>
                                </>}
                            </div>

                            <div className="sm:col-span-3">
                                <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900">
                                    Secondary Color
                                </label>

                                {show_secondary_color && <>
                                    <SketchPicker
                                        color={secondary_color}
                                        onChange={e => handleChangeColor( 'secondary_color', e )}
                                        onChangeComplete={e => handleChangeColor( 'secondary_color', e )}
                                    />
                                    <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
                                        <button
                                            className="text-sm font-semibold leading-6 text-gray-900"
                                            onClick={() => handleSaveColor( 'secondary_color', true )}>
                                            Cancel
                                        </button>
                                        <button
                                            className="rounded-md bg-drip7 px-3 py-2 text-sm font-semibold text-white hover:brightness-110 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-drip7"
                                            onClick={() => handleSaveColor( 'secondary_color' )}>
                                            Confirm
                                        </button>
                                    </div>
                                </>}

                                {!show_secondary_color && <>
                                    <Menu as="div" className="relative inline-block text-left">
                                        <div>
                                            <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                                         onClick={() => handleShowColor( 'secondary_color' )}>
                                                <div className="w-5 h-5 rounded-full" style={{backgroundColor: secondary_color}}>
                                                </div>
                                                Secondary Color
                                                <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                                            </Menu.Button>
                                        </div>
                                    </Menu>
                                </>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 pb-24 md:grid-cols-3">
                <div className="px-4 sm:px-0">
                    <h2 className="text-base font-semibold leading-7 text-gray-900">
                        Main Contact Person
                    </h2>
                    <p className="mt-1 text-sm leading-6 text-gray-600">
                        Please provide the contact information for the primary person who will be administering the Drip7 account. This information can be changed later if needed.
                    </p>
                </div>

                <form className="bg-white shadow-sm border-1 ring-1 ring-gray-300 sm:rounded-xl md:col-span-2">
                    <div className="px-4 py-6 sm:p-8">
                        <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="sm:col-span-3">
                                <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                                    First and Last name
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="text"
                                        name="name"
                                        id="name"
                                        autoComplete="given-name"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-drip7 sm:text-sm sm:leading-6"
                                        placeholder="John Doe"
                                        value={name}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>

                            <div className="sm:col-span-3">
                                <label htmlFor="nickname" className="block text-sm font-medium leading-6 text-gray-900">
                                    Nickname (optional)
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="text"
                                        name="nickname"
                                        id="nickname"
                                        autoComplete="nickname"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-drip7 sm:text-sm sm:leading-6"
                                        value={nickname}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>

                            <div className="sm:col-span-4">
                                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                    Email address
                                </label>
                                <div className="mt-2">
                                    <div className="flex align-items-center rounded-md ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-drip7 sm:max-w-md">
                                        <input
                                            type="email"
                                            name="email"
                                            id="email"
                                            className="block flex-1 border-0 bg-transparent py-1.5 pl-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                            placeholder="Email"
                                            value={email}
                                            onBlur={handleEmailBlur}
                                            onChange={handleChange}
                                        />
                                        {false && email_valid != null && email_valid &&
                                            <CheckCircleIcon className="flex select-none items-center pr-3 h-8 w-8 text-green-400"/>
                                        }
                                        {email_valid != null && !email_valid &&
                                            <ExclamationCircleIcon className="flex select-none items-center pr-3 h-8 w-8 text-red-400"/>
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="sm:col-span-4">
                                <label htmlFor="phone" className="block text-sm font-medium leading-6 text-gray-900">
                                    Phone number
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="phone"
                                        name="phone"
                                        /*type="phone" If this exists, it puts ugly lines around the input*/
                                        placeholder="xxx.xxx.xxxx"
                                        autoComplete="phone"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-drip7 sm:text-sm sm:leading-6"
                                        value={phone}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>

                            <div className="sm:col-span-3">
                                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                                    Password
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="password"
                                        name="password"
                                        id="password"
                                        autoComplete="password"
                                        placeholder="••••••••••••••••"
                                        className={Util.classNames(
                                            "block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-drip7 sm:text-sm sm:leading-6",
                                            ringWrapper( password_valid, 'ring-gray-300', 'ring-green-500', 'ring-red-500' ),
                                        )}
                                        value={password}
                                        onChange={handleChange}
                                        onBlur={handlePasswordBlur}
                                    />
                                </div>
                            </div>

                            <div className="sm:col-span-3">
                                <label htmlFor="password_repeat" className="block text-sm font-medium leading-6 text-gray-900">
                                    Repeat Password
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="password"
                                        name="password_repeat"
                                        id="password_repeat"
                                        placeholder="••••••••••••••••"
                                        className={Util.classNames(
                                            "block w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-drip7 sm:text-sm sm:leading-6",
                                            ringWrapper( password_valid, 'ring-gray-300', 'ring-green-500', 'ring-red-500' ),
                                        )}
                                        value={password_repeat}
                                        onChange={handleChange}
                                        onKeyDown={handleEnter}
                                        onBlur={handlePasswordBlur}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
                        <button
                            type="button"
                            className={
                                (tenant_name_valid && subdomain_valid && email_valid && password_valid) ?
                                    "rounded-md bg-drip7 px-3 py-2 text-sm font-semibold text-white hover:bg-drip7 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-drip7":
                                    "rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                            }
                            onClick={handleContinue}>
                            Continue
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
})
