import React, {useState} from "react";

import {AnswerType} from "../../helpers/consts";
import {findContent} from "../../helpers/content";
import Language from "../../helpers/language";
import {QuestionFollowUpSingleWidget} from "./question_follow_up_single_widget";
import {Drip7Dropdown} from "../drip7_dropdown";

export const QuestionFollowUpWidget = props => {
    const { question, contents, language, access, follow_up_uids, categories, curriculums, onChange } = props;

    const [content, placeholder, _] = findContent( language, contents );

    //Setup the answer
    let answers = []
    let correct = 0
    if ( question.answer_type == AnswerType.Multiple ||
         question.answer_type == AnswerType.Buttons ) {
        answers = content.answer.answers
        correct = parseInt( content.answer.correct )
    }
    else if ( question.answer_type == AnswerType.Bool ||
              question.answer_type == AnswerType.Fill ) {
        answers = ['True', 'False']
        correct = ( content.answer.correct )? 0: 1
    }
    else if ( question.answer_type == AnswerType.Slider ||
              question.answer_type == AnswerType.Checkbox ) {
        if ( 'answer' in content && 'answers' in content.answer ) {
            answers = content.answer.answers.map( x => `Range ${x[0]} to ${x[1]}` )
        }
        correct = parseInt( (content.answer.correct || '-1') )
    }

    //Setup the state
    const [state, setState] = useState({
        answer: answers[0],
        answer_idx: 0,
    })
    const { answer, answer_idx } = state

    let uids = []
    let safe_follow_up_uids = follow_up_uids
    if ( Array.isArray(follow_up_uids) ) {
        const count = (answers.length >= 1)? answers.length: 1
        safe_follow_up_uids = { [(correct + 1) % count]: follow_up_uids }
    }

    if ( answer_idx in safe_follow_up_uids ) {
        uids = safe_follow_up_uids[answer_idx]
    }

    //Can we render anything
    if ( answers.length == 0 || answer == undefined || answer == null ) {
        return (
            <div className='basic-modal__field-container'>
                <div className='basic-modal__field basic-modal__field--add-question'>
                    <label
                        className='basic-modal__label'>
                        {Language.getTitleCase('No follow up answers found')}
                    </label>
                </div>
            </div>
        )
    }

    //Answer title
    const answerTitle = (idx, answer) => {
        if ( question.answer_type == AnswerType.Multiple ||
             question.answer_type == AnswerType.Buttons ||
             question.answer_type == AnswerType.Slider ||
             question.answer_type == AnswerType.Checkbox ) {
            if ( parseInt(content.answer.correct) == idx ) {
                return `[X] ${answer}`
            }
        }
        else if ( question.answer_type == AnswerType.Bool ||
                  question.answer_type == AnswerType.Fill ) {
        }

        return answer
    }

    const handleUids = (uids) => {
        if ( !access.write ) {
            return
        }

        safe_follow_up_uids[answer_idx] = uids
        onChange( safe_follow_up_uids )
    }

    const handleDropdown = (e) => {
        setState(prev => ({...prev,
            answer_idx: e.target.idx,
            answer: e.target.value,
        }))
    }

    return (
        <div className='basic-modal__field-container'>
            <div className='basic-modal__field basic-modal__field--add-question mt10'>
                <label
                    className='basic-modal__label'>
                    {Language.getTitleCase('answer')}
                </label>
                <div className='dropdown dropdown--attached dropdown--add-question '>
                    <Drip7Dropdown
                        id='answer'
                        entries={answers}
                        onChange={handleDropdown}
                        value={answerTitle(answer_idx, answer)}
                        />
                </div>
            </div>

            <QuestionFollowUpSingleWidget
                access={access}
                follow_up_uids={uids}
                categories={categories}
                curriculums={curriculums}
                onChange={handleUids}
            />
        </div>
    );
};
