import React, {useState, useEffect, useRef} from "react";
import Util from "../helpers/util";

import Chart from "react-apexcharts";
import Language from "../helpers/language";

export const QuestionActivityCard = props => {
    const { cascade, tenant_uid } = props;

    const chart = useRef(null)
    const [state, setState] = useState({
        local_tenant_uid: '',
        local_cascade: null,
    });
    const { local_tenant_uid, local_cascade } = state

    const loadGraph = (series, labels, max) => {
        if ( chart.current != null ) {
            chart.current.destroy()
        }

        const colors = [series[0].color, series[1].color, series[2].color]

        const options = {
            series,
            labels,

            chart: {
                height: 320,
                stacked: false,
                type: "line",
                zoom: {
                    enabled: false
                },
                toolbar: {
                    offsetY: -24,
                    tools: {
                        download: '<svg fill="#000000" height="20px" width="20px" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 212.973 212.973" xml:space="preserve"><g stroke-width="0"></g><g stroke-linecap="round" stroke-linejoin="round"></g><g> <g> <path d="M205.473,76.146c-4.143,0-7.5,3.358-7.5,7.5v103.32H15V83.646c0-4.142-3.357-7.5-7.5-7.5S0,79.503,0,83.646v110.82 c0,4.142,3.357,7.5,7.5,7.5h197.973c4.143,0,7.5-3.358,7.5-7.5V83.646C212.973,79.503,209.615,76.146,205.473,76.146z"></path> <path d="M101.171,154.746c1.407,1.407,3.314,2.197,5.304,2.197c1.989,0,3.896-0.79,5.304-2.197l32.373-32.374 c2.929-2.929,2.929-7.678,0-10.606c-2.93-2.93-7.678-2.929-10.607,0l-19.569,19.569l0.004-112.828c0-4.142-3.357-7.5-7.5-7.5 c-4.142,0-7.5,3.358-7.5,7.5l-0.004,112.829l-19.546-19.547c-2.929-2.929-7.677-2.93-10.607,0c-2.929,2.929-2.929,7.677,0,10.606 L101.171,154.746z"></path> </g> </g></svg>'
                      },
                    show: false, // Hide the toolbar
                }
            },
            stroke: {
                curve: "smooth",
                width: [1, 1, 1]
            },
            colors,
            fill: { colors },

            xaxis: {
                type: "datetime"
            },
            yaxis: [
                {
                    max,
                    min: 0,
                    opposite: true,
                    title: {
                        text: Language.getSentenceCase('question count'),
                    },
                    labels: {
                        formatter: function(val) { return parseInt(val) },
                    }
                },
                {
                    max,
                    min: 0,
                    show: false,
                },
                {
                    max,
                    min: 0,
                    show: false,
                }
            ],
            tooltip: {
                y: {
                    formatter: function(y) {
                        return parseInt( y )
                        //return y + '%'
                    }
                }
            }
        };

        chart.current = new ApexCharts(
            document.querySelector("#question-activity_chart"),
            options
        );
        chart.current.render();
    }

    useEffect(() => {
        //Bail
        if (tenant_uid == "" || (tenant_uid == local_tenant_uid && cascade == local_cascade)) {
            return;
        }

        //Get a count of tenant members
        Util.fetch_js("/dashboard/usage_span/", { tenant_uid, cascade },
            js => {
                let labels = [];
                const series = [
                    {
                        key: 'una',
                        name: Language.getTitleCase('unanswered'),
                        type: "area",
                        data: [],
                        largest: 0,
                        color: "#1482E6",
                    },
                    {
                        key: 'inc',
                        name: Language.getTitleCase('incorrect'),
                        type: "area",
                        data: [],
                        largest: 0,
                        color: "#FD6E6E",
                    },
                    {
                        key: 'cor',
                        name: Language.getTitleCase('correct'),
                        type: "area",
                        data: [],
                        largest: 0,
                        color: "#87DCAA",
                    },
                ]

                for (let i = 0; i < js.activity.length; i++) {
                    const act = js.activity[i];
                    if ( act.correct > act.incorrect && act.correct > act.unanswered ) {
                        series[0].largest++
                    }
                    if ( act.incorrect > act.correct && act.incorrect > act.unanswered ) {
                        series[1].largest++
                    }
                    if ( act.unanswered > act.correct && act.unanswered > act.incorrect ) {
                        series[2].largest++
                    }

                    if ( act.correct > act.incorrect || act.correct > act.unanswered ) {
                        series[0].largest++
                    }
                    if ( act.incorrect > act.correct || act.incorrect > act.unanswered ) {
                        series[1].largest++
                    }
                    if ( act.unanswered > act.correct || act.unanswered > act.incorrect ) {
                        series[2].largest++
                    }
                }

                // Sort my series by largest
                series.sort((a, b) => {
                    return a.largest - b.largest
                })

                const lookup = {}
                series.forEach( (s, idx) => {
                    lookup[s.key] = idx
                })

                //Load in the activity
                let max = 0
                for (let i = 0; i < js.activity.length; i++) {
                    const act = js.activity[i];

                    //Convert from 0 to 100
                    const cor = parseInt(act.correct)
                    const inc = parseInt(act.incorrect)
                    const una = parseInt(act.unanswered)

                    if ( max < Math.round(cor * 1.20) ) {
                        max = Math.round(cor * 1.20)
                    }
                    if ( max < Math.round(inc * 1.20) ) {
                        max = Math.round(inc * 1.20)
                    }
                    if ( max < Math.round(una * 1.20) ) {
                        max = Math.round(una * 1.20)
                    }

                    //Load in the data, stack it manually
                    series[lookup['cor']].data.push(cor);
                    series[lookup['inc']].data.push(inc);
                    series[lookup['una']].data.push(una);
                    labels.push( Util.friendlyDate(Util.dayToDate(act.timestamp_day)) );
                }

                loadGraph( series, labels, max )
            },
            (err, code) => {
                showToast(err, 'failure');
            }
        );

        //Force the tenant uid state update to prevent double loads
        setState(prev => ({...prev,
            local_tenant_uid: tenant_uid,
            local_cascade: cascade,
        }))
    }, [props]);

    //destroy card on unmounting - removes console errors
    useEffect(() => {
        return () => {
            if ( chart.current != null ) {
                chart.current.destroy()
            }
        }
    }, [])

    return (
        <div
            className='admin-card admin-card__large'
            style={{ marginLeft: "0px" }}>
            <h3>{Language.getTitleCase('Question Activity')}</h3>
            <div id='question-activity_chart'></div>
        </div>
    );
};
