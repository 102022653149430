import React, {useEffect, useRef, useState} from 'react'
import {useStore} from "../../store";
import {ChatQuery} from "../../components/admin/chat_query";
import Util from "../../helpers/util";
import {ChatConversation} from "../../components/admin/chat_conversation";
import {Drip7Combobox} from "../../components/drip7_combobox";
import Language from "../../helpers/language";
import {PencilSquareIcon} from "@heroicons/react/24/outline";
import {QuestionModal} from "../../modals/admin/question_modal";
import {Drip7OriginalModal} from "../../modals/drip7_original_modal";


export const ChatSandboxTab = (props) => {
    const {tenant_uid, showToast} = props

    const {usr_info} = useStore( x => x)

    const [state, setState] = useState({
        questions: [],
        question_categories: [],
        selected_idx: -1,
        conversation_uid: "",
        show_question: null,
    })
    const { questions, question_categories, selected_idx, conversation_uid, show_question } = state
    const [chat, setChat] = useState({payload: {
        conversation: []
        },
        human: usr_info
    })

    const [contentHeight, setContentHeight] = useState(0);
    const footerRef = useRef(null);

    useEffect(() => {
        Util.fetch_js('/question/list/', {tenant_uid},
            js => {
                setState(prev => ({...prev,
                    question_categories: js.categories,
                    questions: js.categories.map(c => c.questions ).flat(Infinity),
                }))
            }, showToast)
    }, []);

    useEffect(() => {
        const resizeContentDiv = () => {
            if ( !footerRef.current ) {
                return;
            }

            const screenHeight = window.innerHeight;
            const headerHeight = 328 //Imperical number
            const footerHeight = footerRef.current.offsetHeight;
            const newContentHeight = screenHeight - headerHeight - footerHeight;

            setContentHeight((newContentHeight < 520)? 520: newContentHeight);
        };

        resizeContentDiv(); // Call on initial render
        window.addEventListener('resize', resizeContentDiv); // Call on window resize

        return () => {
            window.removeEventListener('resize', resizeContentDiv); // Cleanup on component unmount
        };
    }, []);

    const handleChat = (message) => {
        Util.fetch_js('/ai_chat/chat/', {conversation_uid, message},
            js => {
                const { conversation} = js
                setChat( prev => ({...prev,
                    payload: { conversation }
                }))
            }, showToast)

        //Update the conversation and update the last index response to ai_response
        setChat( prev => ({...prev,
            human: usr_info,
            payload: {
                conversation: [ ...prev.payload.conversation,
                    {
                        uid: usr_info.uid,
                        question: message,
                        response: null,
                        created_on: Date.now(),
                    }]
            }
        }))
    }

    const handleSelected = ( selected_idx ) => {
        const question_uid = questions[selected_idx]?.uid

        Util.fetch_js('/ai_chat/create/', {question_uid},
            js => {
                const { conversation, conversation_uid } = js

                setState(prev => ({...prev,
                    conversation_uid
                }))

                setChat( prev => ({...prev,
                    payload: { conversation }
                }))
            }, showToast)

        setState(prev => ({...prev,
            selected_idx
        }))

        setChat( prev => ({...prev,
            payload: {
                conversation: [{
                    question: 'Intro',
                    response: null,
                }]
            }
        }))
    }

    const handleEditQuestion = () => {
        if ( selected_idx < 0 ) {
            showToast('Select a question to edit', 'failure')
            return
        }

        setState(prev => ({...prev,
            show_question: questions[selected_idx]
        }))
    }

    const handleModalClose = () => {
        setState(prev => ({...prev,
            show_question: null
        }))
    }

    return (
        <div>
            <div style={{height: `${contentHeight}px`}} className="py-0 flex flex-col md:flex-row">
                <div className={
                    Util.classNames(
                        "panel flex flex-col md:w-1/4 py-4 md:border-r px-3",
                        "overflow-y-auto",
                    )}>
                    <div className="inline-flex justify-between pb-4">
                        <label className="font-semibold text-gray-600">
                            {Language.getTitleCase("Questions")}
                        </label>
                        {selected_idx >= 0 &&
                        <PencilSquareIcon
                            className="h-6 w-6 text-drip7 cursor-pointer"
                            aria-hidden="true"
                            onClick={handleEditQuestion}
                        />
                        }
                    </div>
                    <Drip7Combobox
                        entries={questions}
                        selected_idx={selected_idx}
                        onSelected={handleSelected}
                        />
                    <div className="mt-4">
                        Avatars placeholder
                        <div className="text-sm">
                            (Show tutor prompts to test)
                        </div>
                    </div>
                </div>
                <div className="panel p-4 lg:pr-1 md:w-full">
                    <p className="font-semibold text-gray-600">
                        Chat
                    </p>
                    <ChatConversation
                        chat={chat}
                        showToast={showToast}
                    />
                </div>
            </div>
            <div ref={footerRef} className="px-4 py-4 sm:px-6">
                <ChatQuery
                    onQuestion={handleChat}
                    showToast={showToast}
                />
            </div>

            <Drip7OriginalModal>
                {show_question && (
                    <QuestionModal
                        tenant_uid={tenant_uid}
                        qrcode_allowed={false}
                        categories={question_categories}
                        question={show_question}
                        showToast={showToast}
                        onClose={handleModalClose}
                    />
                )}
            </Drip7OriginalModal>
        </div>
    )
}
