import React, {useEffect, useRef, useState} from "react";
import '@splidejs/react-splide/css';
import Language from "../../helpers/language";
import {TierBadge, TierNumber} from "../../helpers/consts";
import Util from "../../helpers/util";
import {useStore} from "../../store";


export const AvatarCard2 = (props) => {
    let {tier, showToast} = props

    const { page_cache, setPageCache } = useStore( x => x )
    const avatar_cache = (page_cache.avatar !== undefined)? page_cache.avatar: { my_place: null }

    const [state, setState] = useState({
        my_place: avatar_cache.my_place,
    })
    const { my_place } = state

    useEffect(() => {
        Util.fetch_js( "/client/leaderboard/my_place/", {},
            js => {
                const { my_place } = js
                console.log(js)

                setState(prev => ({ ...prev,
                    my_place,
                }))
                setPageCache( 'avatar', { my_place } )
            }, showToast)

    }, []);

    const place = my_place !== null? Util.addIst( my_place ): null

    return (
        <div className="avatar-card shadow-md">
            <span className="avatar-card-container">
                <img className="avatar-card-img" src={props.img}></img>
            </span>
            <div className="avatar-card-info">
                <h4 className="avatar-card-info-name">{props.name}</h4>
                {place !== null &&
                <h4 className="avatar-card-info-tenant">
                    {Language.getTitleCase("you are")} {place} {Language.getString("on the leaderboard")}
                </h4>
                }
            </div>
        </div>
    )
}
  
