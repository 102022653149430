import React, {useEffect, useState} from "react";

import Util from "../../helpers/util";
import Language from "../../helpers/language";

import { ConfirmModal } from "../../modals/confirm_modal";
import {ImportFileModal} from "../../modals/admin/import_file_modal";
import { ContentType, QueueDelivery } from "../../helpers/consts";
import { TrashIcon } from "@heroicons/react/20/solid";
import {DocumentAcceptanceModal} from "../../modals/admin/document_acceptance_modal";
import { PolicyScheduleModal } from "../../modals/admin/policy_schedule_modal";
import {
    ArrowDownOnSquareIcon,
    CheckCircleIcon, XMarkIcon
} from "@heroicons/react/24/outline";
import {Drip7Button} from "../../components/drip7_button";

export const DocumentAcceptanceTab = (props) => {
    const { tenant_uid, showToast } = props

    const [state, setState] = useState({
        search: "",
        documents: [],
        selected: {},
        confirm: null,
        upload_uid: null,
        upload_filename: '',
        upload_description: '',
        upload_document: false,
        schedule_policy: false,
    })
    const { search, documents, selected, confirm, upload_uid, upload_filename, upload_description, upload_document, schedule_policy } = state

    useEffect(() => {
        updateList()
    }, [])

    const updateList = () => {
        /*
        Util.fetch_js( "/question/list/", { tenant_uid, include_types: [ContentType.Document], exclude_empty: true, detailed: true },
            js => {
                const documents = js.categories.map(cat =>
                    cat.questions.filter(q => q.contents.length > 0).map(q =>
                        ({ ...q,
                           filesize: q.contents[0].content.filesize,
                           document_url: q.contents[0].content.document,
                        }))
                ).flat()
                setState(prev => ({ ...prev,
                    documents
                }))
            },
            (reason, code) => {
                showToast(reason, 'failure');
            }
        );
         */

        Util.fetch_js( "/question/policies/", { tenant_uid },
            js => {
                const documents = js.questions.filter(q => q.contents.length >= 1).map(q => ({
                    ...q.question,
                    contents: q.contents,
                    filesize: q.contents[0].content.filesize,
                    document_url: q.contents[0].content.document,
                }))
                setState(prev => ({ ...prev,
                    documents
                }))
            },
            (reason, code) => {
                showToast(reason, 'failure');
            }
        );
    }

    const updateFile = (question_uid, name, description) => {
        Util.fetch_js( "/question/update_document/", { question_uid, name, description },
            js => {
                showToast(Language.getTitleCase('updated document'), 'successful');
                updateList()

                setState(prev => ({...prev,
                    upload_document: false,
                    upload_uid: null,
                    upload_filename: null,
                    upload_description: null,
                }))
            },
            (reason, code) => {
                showToast(reason, 'failure');
                setState(prev => ({...prev,
                    upload_uid: null,
                    upload_document: false,
                    upload_filename: null,
                    upload_description: null,
                }))
            }
        );
    }

    const uploadFile = (text, file) => {
        Util.fetch_js( "/question/upload_document/", { tenant_uid, text, file },
            js => {
                showToast(Language.getTitleCase('uploaded document'), 'successful');
                updateList()

                setState(prev => ({...prev,
                    upload_document: false,
                    schedule_policy: (js.curriculum)? js.curriculum: false,
                }))
            },
            (reason, code) => {
                showToast(reason, 'failure');
                setState(prev => ({...prev,
                    upload_document: false
                }))
            }
        );
    }

    const schedulePolicy = (curriculum) => {
        handleModalClose()

        if (curriculum === null || curriculum === false || curriculum === undefined) {
            return
        }

        const payload = {
            curriculum_uid: curriculum.uid,
            name: curriculum.name,
            delivery: QueueDelivery.Splash
        }
        Util.fetch_js( "/queue/create/", payload,
            js => {
                showToast(Language.getTitleCase('scheduled document'), 'successful');
                updateList()
            }, showToast )
    }

    const filterDocuments = (search, documents) => {
        //Do nothing
        if (search === "") {
            return documents
        }

        //Only keep
        const search_lc = search.toLowerCase();
        return documents.filter(report =>
            report.name.toLowerCase().indexOf(search_lc) >= 0
        )
    }

    const handleSelect = (checked, uid) => {
        if (checked) {
            selected[uid] = true;
        }
        else if (uid in selected) {
            delete selected[uid];
        }

        setState(prev => ({ ...prev, selected }))
    }

    const handleSelectAll = (checked) => {
        let selected = {}

        //Check all?
        if (checked) {
            for (let i = 0; i < documents.length; i++) {
                selected[documents[i].uid] = true;
            }
        }

        setState(prev => ({ ...prev, selected }))
    }

    const handleChange = (e) => {
        setState(prev => ({...prev, [e.target.id]: e.target.value }))
    }

    const handleModalOpen = (upload_uid=null, upload_filename=null, upload_description=null) => {
        //upload_description = upload_description.replace(/\n\n(?:https?:\/\/|www\.)\S+/gi, '')

        setState(prev => ({...prev,
            upload_uid,
            upload_filename,
            upload_description,
            upload_document: true
        }))
    }

    const handleModalClose = () => {
        updateList()
        setState( prev => ({...prev,
            show_generate: null,
            generate_only: false,
            upload_document: false,
            schedule_policy: false,
        }))
    }

    const handleRemove = (uid) => {
        const question_uids = uid ? [uid] : Object.keys(selected)
        //console.log(question_uids)
        Util.fetch_js( "/question/remove/", { question_uids, clean_up: true },
            js => {
                showToast(Language.getTitleCase('deleted documents'), 'successful');
                updateList()
            },
            (err, code) => {
                showToast(err, 'failure');
            }
        );

        setState(prev => ({...prev, confirm: null, selected: {} }))
    }

    const confirmAction = (action) => {
        const new_confirm = {
            action,
            msg: Language.getTitleCase('remove documents'),
            yes: Language.getTitleCase('remove'),
            no: Language.getTitleCase('cancel')
        };

        setState(prev => ({...prev, confirm: new_confirm }))
    }

    const filtered_documents = filterDocuments(search, documents)

    return (
        <div className='curriculum main-modal'>
            <div className='central__main central__main--management'>
                <div className='central__head'>
                    <div className='central__head-search-wrapper'>
                        <svg
                            width='15'
                            height='16'
                            viewBox='0 0 15 16'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'>
                            <path
                                d='M14.8828 14.6152L11.3379 11.0703C11.25 11.0117 11.1621 10.9531 11.0742 10.9531H10.6934C11.6016 9.89844 12.1875 8.49219 12.1875 6.96875C12.1875 3.62891 9.43359 0.875 6.09375 0.875C2.72461 0.875 0 3.62891 0 6.96875C0 10.3379 2.72461 13.0625 6.09375 13.0625C7.61719 13.0625 8.99414 12.5059 10.0781 11.5977V11.9785C10.0781 12.0664 10.1074 12.1543 10.166 12.2422L13.7109 15.7871C13.8574 15.9336 14.0918 15.9336 14.209 15.7871L14.8828 15.1133C15.0293 14.9961 15.0293 14.7617 14.8828 14.6152ZM6.09375 11.6562C3.48633 11.6562 1.40625 9.57617 1.40625 6.96875C1.40625 4.39062 3.48633 2.28125 6.09375 2.28125C8.67188 2.28125 10.7812 4.39062 10.7812 6.96875C10.7812 9.57617 8.67188 11.6562 6.09375 11.6562Z'
                                fill='currentColor'></path>
                        </svg>
                        <input
                            type='text'
                            className='central__head-search rounded-md border-gray-200 bg-white text-gray-900 placeholder-text-gray-400 focus:border-drip7 focus:ring-0 sm:text-sm sm:leading-6'
                            placeholder={Language.getSentenceCase('Search by name') + '...'}
                            id='search'
                            value={search}
                            onChange={handleChange}
                        />
                    </div>

                    <div className='central__head-button-container'>
                        {Object.keys(selected).length > 0 && (
                            <Drip7Button
                                color='red-500'
                                onClick={() => confirmAction(yes => yes && handleRemove()) }>
                                {Language.getTitleCase('remove document')}
                            </Drip7Button>
                        )}
                        <Drip7Button
                          onClick={() => handleModalOpen()}>
                            {Language.getTitleCase('upload document')}
                        </Drip7Button>
                    </div>
                </div>

                <table className='management__table'>
                    <tbody>
                    <tr className='management__table-row management__table-row--head w-full'>
                        <th className='management__table-head management__table-head--input'>
                            <div className='d7__checkbox-container'>
                                <input
                                    className='d7__checkbox'
                                    aria-label="checkbox"
                                    type='checkbox'
                                    checked={documents.length > 0 && documents.length == Object.keys(selected).length}
                                    onChange={e => handleSelectAll(e.target.checked)}/>
                                <span className='dash'></span>
                            </div>
                        </th>
                        <th className='management__table-head management__table-head--name'>
                            <h5 className='management__table-heading'>
                                {Language.getTitleCase('name')}
                            </h5>
                        </th>
                        <th className='management__table-head ml-0'>
                            <h5 className='text-sm font-semibold text-gray-500'>
                                {Language.getTitleCase('Delivered')}
                            </h5>
                        </th>
                        <th className='management__table-head ml-0'>
                            <h5 className='text-sm font-semibold text-gray-500'>
                                {Language.getTitleCase('Accept / Deny / Total')}
                            </h5>
                        </th>
                        <th className='management__table-head ml-0'>
                            <h5 className='text-sm font-semibold text-gray-500'>
                                {Language.getTitleCase('Download')}
                            </h5>
                        </th>
                        <th className='w-12'>
                        </th>
                    </tr>
                    {Object.entries(filtered_documents).map(
                        ([key, policy]) => (
                            <tr className='management__table-row management__table-row--body'
                                key={"report_row_" + key}>
                                <td className='management__table-data management__table-data--input'>
                                    <div className='d7__checkbox-container'>
                                        <input
                                            className='d7__checkbox'
                                            aria-label="checkbox"
                                            type='checkbox'
                                            checked={policy.uid in selected}
                                            onChange={e => handleSelect(e.target.checked, policy.uid)}/>
                                        <span className='checkmark'></span>
                                    </div>
                                </td>
                                <td className='management__table-data management__table-data--name cursor-pointer'
                                    onClick={() => handleModalOpen(policy.uid, policy.name, policy.contents[0].content.text)}>
                                    <h5 className='management__table-text'>
                                        {policy.name}
                                        &nbsp;-&nbsp;
                                        {Util.numberWithCommas( Math.floor(policy.filesize / 1000))}K
                                    </h5>
                                </td>
                                <td className='management__table-data'>
                                    {policy.delivered &&
                                    <CheckCircleIcon className="w-5 h-5 text-green-500"/>
                                    }
                                    {!policy.delivered &&
                                        <XMarkIcon className="w-5 h-5 text-red-500"/>
                                    }
                                </td>
                                <td className='management__table-data'>
                                    <span className="text-green-600 font-semibold text-sm">{policy.ack}</span>
                                    <span className="mx-2 text-sm">/</span>
                                    <span className="text-red-600 font-semibold text-sm">{policy.deny}</span>
                                    <span className="mx-2 text-sm">/</span>
                                    <span className="text-gray-600 font-semibold text-sm">{policy.total}</span>
                                </td>
                                <td className='management__table-data'>
                                    <ArrowDownOnSquareIcon
                                        className='h-6 w-6 text-gray-400 hover:text-blue-500 cursor-pointer'
                                        onClick={() => window.open(policy.document_url, '_blank')}/>
                                </td>
                                <td className=''>
                                    <TrashIcon className='h-5 w-5 mr-5 text-gray-400 hover:text-red-500 cursor-pointer'
                                               onClick={() => confirmAction(() => handleRemove(policy.uid))}/>
                                </td>
                            </tr>
                        )
                    )}
                    </tbody>
                </table>

                {confirm != null && (
                    <ConfirmModal
                        msg={confirm.msg}
                        yesTxt={confirm.yes}
                        noTxt={confirm.no}
                        onYes={() => confirm.action(true)}
                        onNo={() => setState(prev => ({...prev, confirm: null }))}
                    />
                )}
            </div>

            <DocumentAcceptanceModal
                open={upload_document}
                existing_uid={upload_uid}
                filename={upload_filename}
                description={upload_description}
                load_on_start={true}
                title={Language.getTitleCase('upload policy')}
                accept=".pdf"
                onUpload={uploadFile}
                onUpdate={updateFile}
                onClose={handleModalClose}
                showToast={showToast}
            />

            <PolicyScheduleModal
                open={schedule_policy !== false}
                curriculum={schedule_policy}
                onSchedule={schedulePolicy}
                onClose={handleModalClose}
            />
        </div>
    );
};