import React, { useEffect, useState } from "react";
import Util from "../../helpers/util";
import Language from "../../helpers/language";

import { Access, BlockMode, CurriculumTypes, CurriculumUsage } from "../../helpers/consts";
import { CurriculumCollectionWidget } from "../../components/admin/curriculum_collection_widget";
import { CurriculumSpecificWidget } from "../../components/admin/curriculum_specific_widget";
import { ModalTabsWidget } from "../../components/modal_tabs_widget";
import { CurriculumBlockWidget } from "../../components/admin/curriculum_block_widget";
import { CurriculumSlotModal } from "./curriculum_slot_modal";
import { CurriculumPreviewWidget } from "../../components/admin/curriculum_preview_widget";
import { categoryQuestionsToOverview } from "../../helpers/content";
import ResultIMG from "../../assets/images/user-portal/result.svg";
import { QueueScheduleWidget } from "../../components/admin/queue_schedule_widget";
import { InputWithArrows } from "../../components/input_with_arrows";
import {Drip7Dropdown} from "../../components/drip7_dropdown";
import { Drip7TextArea } from "../../components/drip7_textarea";
import {
    CurriculumQuestionWidget
} from "../../components/admin/curriculum_question_widget";


export const CurriculumModal = (props) => {
    const { showToast, tenant_uid, onClose } = props
    let props_curriculum = props.curriculum

    if (!("uid" in props_curriculum)) {
        props_curriculum = {
            usage: props_curriculum.usage,
            curriculum_type: CurriculumTypes.NORMAL,
            name: "",
            cascade: true,
            limit: 0,
            est_time: 0,
        };
    }

    const [state, setState] = useState({
        category_questions: [],
        categories: [],
        tags: [],
        curriculum: props_curriculum,
        category_uids: [],
        tag_uids: [],
        question_uids: [],
        blocks: [],
        selected_idx: 0,
        edit_slot: null,
        curriculums: [],
        schedule: null,
    })
    const { category_questions, categories, tags, curriculum, category_uids, tag_uids, question_uids, blocks, selected_idx, edit_slot, curriculums, schedule } = state

    useEffect(() => {
        //Get the category info
        const cat_payload = { tenant_uid, access: Access.EXEC }
        Util.fetch_js( "/category/list/", cat_payload,
            js => {
                setState(prev => ({ ...prev,
                    categories: js.categories,
                }))
            },
            (err, code) => {
                showToast(err, 'failure');
            }
        )

        const tag_payload = { tenant_uid, access: Access.EXEC }
        Util.fetch_js( "/tag/list/", tag_payload,
            js => {
                setState(prev => ({ ...prev,
                    tags: js.tags,
                }))
            },
            (err, code) => {
                showToast(err, 'failure');
            }
        )

        //Get the category info
        let q_payload = { tenant_uid, access: Access.EXEC }
        if ( curriculum.usage == CurriculumUsage.CAMPAIGN ) {
            q_payload['include_types'] = ["phish"]
        }
        else {
            q_payload['exclude_types'] = ["phish"]
        }
        Util.fetch_js( "/question/list/", q_payload,
            js => {
                setState(prev => ({ ...prev,
                    category_questions: js.categories,
                }))
            },
            (err, code) => {
                showToast(err, 'failure');
            }
        )

        Util.fetch_js( "/curriculum/list/", { tenant_uid, access: Access.EXEC },
            js => {
                setState(prev => ({ ...prev,
                    curriculums: js.curriculums
                }))
            },
            (reason, code) => {
                showToast(reason, 'failure');
            }
        );

        //Is the uid in curriculum? Download details
        if ("uid" in curriculum) {
            Util.fetch_js( "/curriculum/detail/", { curriculum_uid: curriculum.uid },
                js => {
                    setState(prev => ({ ...prev,
                        access: js.access,
                        curriculum: js.curriculum,
                        question_uids: js.questions.map( x => x.uid ),
                        schedule: js.curriculum.schedule,
                    }))
                },
                (err, code) => {
                    showToast(err, 'failure');
                }
            )
        }
        else {
            setState(prev => ({ ...prev,
                schedule: [0,1,2,3,4,5,6].map(x => ({day_of_week: x, count: 1})),
            }))
        }
    }, [])

    const handleAddQuestion = (question_uid, index=-1) => {
        if ( index >= 0 ) {
            let uids = [...question_uids]
            uids.splice( index, 0, question_uid )
            setState( prev => ({ ...prev, question_uids: uids }) )
        }
        else {
            setState( prev => ({ ...prev,
                question_uids: question_uids.concat( [question_uid] )
            }) )
        }
    }

    const handleReorderQuestion = (question_uids) => {
        setState( prev => ({ ...prev,
            question_uids,
        }))
    }

    const handleRemoveQuestion = (question_uid) => {
        setState( prev => ({ ...prev,
            question_uids: question_uids.filter(x => x != question_uid)
        }))
    }

    const handleCurriculumChange = (e) => {
        curriculum[e.target.id] = e.target.value;
        setState( prev => ({ ...prev,
            curriculum
        }))
    }

    const handleCurriculumChecked = (e) => {
        curriculum[e.target.id] = e.target.checked;
        setState( prev => ({ ...prev,
            curriculum
        }))
    }

    const handleDropdown = e => {
        curriculum[e.target.id] = e.target.value
        setState( prev => ({ ...prev,
            curriculum
        }))
    };

    const handleSave = () => {
        const url = "uid" in curriculum ? "/curriculum/modify/" : "/curriculum/create/"
        curriculum["question_uids"] = question_uids

        //Convert the uid name to curriculum_uid
        if ("uid" in curriculum) {
            curriculum.curriculum_uid = curriculum.uid;
        }

        //Save the data
        Util.fetch_js( url, curriculum,
            js => {
                showToast(Language.getSentenceCase('saved curriculum'), 'successful');
                onClose();
            },
            (reason, code) => {
                showToast(reason, 'failure');
            }
        );
    }

    const containerStyle = () => {
            return {justifyContent: 'center'}
    }

    const handleScheduleChange = ( delivery, doy, count ) => {
        let { schedule } = state
        if ( schedule.length != 7 ) {
            schedule = [0,1,2,3,4,5,6].map(x => ({day_of_week: x, count: 1}))
        }

        if ( delivery.toLowerCase() == "every day" ) {
            for ( let i = 0; i < schedule.length; i++ ) {
                schedule[i].count = count
            }
        }
        else if ( delivery.toLowerCase() == "every weekday" ) {
            for ( let i = 0; i < schedule.length; i++ ) {
                schedule[i].count = (i < 5)? count: 0
            }
        }
        else if ( delivery.toLowerCase() == "custom" ) {
            if ( doy < 0 || doy >= schedule.length ) {
                return
            }

            schedule[doy].count = count
        }

        setState(prev => ({ ...prev,
            schedule
        }))
    }

    const category_lookup = {}
    category_uids.forEach(x => {
        category_lookup[x] = true
    })

    const tag_lookup = {}
    tag_uids.forEach(x => {
        tag_lookup[x] = true
    })

    //Calcualte question count. This doesn't account for overlap
    let question_count = question_uids.length
    categories.forEach(x => {
        if ( x.uid in category_lookup ) {
            question_count += x.question_count + x.phish_count
        }
    })
    tags.forEach( x => {
        if ( x.uid in tag_lookup ) {
            question_count += x.question_count + x.phish_count
        }
    })

    //Calculate my deliver count
    let delivery_count = 0
    blocks.forEach( b => {
        b.repeat

        let sc = 0
        b.slots.forEach(s => {
            sc += ('count' in s)? s.count: 1
        })

        delivery_count += b.repeat * sc
    })

    /*
    let action = ("uid" in curriculum)? Language.getTitleCase('modifying'): Language.getTitleCase('create')
    if ( !access.write ) {
        action = Language.getTitleCase("read only")
    }
     */
    const title = Language.getTitleCase( ( curriculum.usage == CurriculumUsage.CAMPAIGN )? 'phish campaign': 'curriculum')
    //const title = `${action} ${Language.getTitleCase(title_type)}`

    const category_summaries = categories.filter( x => x.uid in category_lookup ).concat(
                               categoryQuestionsToOverview( category_questions, category_uids, question_uids ))
    const tag_summaries = tags.filter( x => x.uid in tag_lookup )

    const qc_title = (question_count > 0)? `(${question_count})`: ""
    const dc_title = (delivery_count > 0)? `(${delivery_count})`: ""

    const tabs = [
        { name: `${Language.getTitleCase('Content Pool')} ${qc_title}`, icon: <svg width="15" height="19" viewBox="0 0 15 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.875 12.9062V1.09375C15.875 0.636719 15.4883 0.25 15.0312 0.25H3.5C1.63672 0.25 0.125 1.76172 0.125 3.625V14.875C0.125 16.7383 1.63672 18.25 3.5 18.25H15.0312C15.4883 18.25 15.875 17.8984 15.875 17.4062V16.8438C15.875 16.5977 15.7344 16.3516 15.5586 16.2109C15.3828 15.6484 15.3828 14.1016 15.5586 13.5742C15.7344 13.4336 15.875 13.1875 15.875 12.9062ZM4.625 4.96094C4.625 4.85547 4.69531 4.75 4.83594 4.75H12.2891C12.3945 4.75 12.5 4.85547 12.5 4.96094V5.66406C12.5 5.80469 12.3945 5.875 12.2891 5.875H4.83594C4.69531 5.875 4.625 5.80469 4.625 5.66406V4.96094ZM4.625 7.21094C4.625 7.10547 4.69531 7 4.83594 7H12.2891C12.3945 7 12.5 7.10547 12.5 7.21094V7.91406C12.5 8.05469 12.3945 8.125 12.2891 8.125H4.83594C4.69531 8.125 4.625 8.05469 4.625 7.91406V7.21094ZM13.5195 16H3.5C2.86719 16 2.375 15.5078 2.375 14.875C2.375 14.2773 2.86719 13.75 3.5 13.75H13.5195C13.4492 14.3828 13.4492 15.4023 13.5195 16Z" fill="var(--drip7-primary-color)"></path></svg> },
        //{ name: `${Language.getTitleCase('Delivery')} ${dc_title}`, icon: <svg width="19" height="19" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill="var(--drip7-primary-color)" d="M22.2187 5.39003C23.332 4.86999 24.5937 4.86999 25.707 5.39003L41.9609 12.8935C42.5547 13.1906 43 13.7849 43 14.4536C43 15.1965 42.5547 15.7908 41.9609 16.088L25.707 23.5914C24.5937 24.1114 23.332 24.1114 22.2187 23.5914L5.96484 16.088C5.37109 15.7908 5 15.1965 5 14.4536C5 13.7849 5.37109 13.1906 5.96484 12.8935L22.2187 5.39003ZM41.9609 22.4027C42.5547 22.6999 43 23.2942 43 23.9629C43 24.7058 42.5547 25.3001 41.9609 25.5973L25.707 33.1007C24.5937 33.6207 23.332 33.6207 22.2187 33.1007L5.96484 25.5973C5.37109 25.3001 5 24.7058 5 23.9629C5 23.2942 5.37109 22.6999 5.96484 22.4027L9.97266 20.5455L21.2539 25.7458C22.9609 26.563 24.9648 26.563 26.6719 25.7458L37.9531 20.5455L41.9609 22.4027ZM26.6719 35.2551L37.9531 30.0547L41.9609 31.912C42.5547 32.2092 43 32.8035 43 33.4721C43 34.2151 42.5547 34.8094 41.9609 35.1065L25.707 42.61C24.5937 43.13 23.332 43.13 22.2187 42.61L5.96484 35.1065C5.37109 34.8094 5 34.2151 5 33.4721C5 32.8035 5.37109 32.2092 5.96484 31.912L9.97266 30.0547L21.2539 35.2551C22.9609 36.0723 24.9648 36.0723 26.6719 35.2551Z"></path></svg> },
        { name: Language.getTitleCase('Preview'), icon: <svg width="15" height="15" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M5 10.4286C5 7.45982 7.375 5 10.4286 5H37.5714C40.5402 5 43 7.45982 43 10.4286V37.5714C43 40.625 40.5402 43 37.5714 43H10.4286C7.375 43 5 40.625 5 37.5714V10.4286ZM27.2232 16.5357C26.4598 17.3839 26.5446 18.6562 27.308 19.4196L32.4821 24L27.308 28.6652C26.5446 29.3437 26.4598 30.7009 27.2232 31.5491C27.9866 32.3125 29.2589 32.3973 30.1071 31.6339L36.8929 25.5268C37.317 25.1875 37.5714 24.5937 37.5714 24C37.5714 23.4911 37.317 22.8973 36.8929 22.558L30.1071 16.4509C29.2589 15.6875 27.9866 15.7723 27.2232 16.5357ZM20.6071 19.4196C21.3705 18.6562 21.4554 17.3839 20.692 16.5357C19.9286 15.7723 18.6562 15.6875 17.808 16.4509L11.0223 22.558C10.5982 22.8973 10.4286 23.4911 10.4286 24C10.4286 24.5937 10.5982 25.1875 11.0223 25.5268L17.808 31.6339C18.6562 32.3973 19.9286 32.3125 20.692 31.5491C21.4554 30.7009 21.3705 29.3437 20.6071 28.6652L15.433 24L20.6071 19.4196Z" fill="var(--drip7-primary-color)" ></path> </svg> },
    ]

    let next_curriculum = curriculums.find( x => x.uid == curriculum.next_curriculum_uid ) || { name: '---' }

    const show_desc = (curriculum.curriculum_type.toLowerCase() != CurriculumTypes.NORMAL.toLowerCase())
    const show_schedule = (schedule != null && curriculum.curriculum_type.toLowerCase() == CurriculumTypes.ASSIGNABLE_DRIP.toLowerCase())

    return (
        <div className="w-full h-full p-8">
            <div className='flex flex-row flex-wrap w-full h-full bg-white rounded-xl shadowed justify-center pb-8 overflow-y-auto'
                tabIndex={-1}>
                <p className='hidden-heading'>{Language.getTitleCase('dialog content starts')}</p>
                <div className='basic-modal__head'>
                    <h4>{title}</h4>
                    {edit_slot == null &&
                        <svg
                            className='basic-modal__close-button'
                            width='12'
                            height='11'
                            viewBox='0 0 12 11'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                            onClick={onClose}>
                            <path
                                d='M7.93359 5.375L10.8633 2.44531C11.2441 2.09375 11.2441 1.50781 10.8633 1.15625L10.2188 0.511719C9.86719 0.130859 9.28125 0.130859 8.92969 0.511719L6 3.44141L3.04102 0.511719C2.68945 0.130859 2.10352 0.130859 1.75195 0.511719L1.10742 1.15625C0.726562 1.50781 0.726562 2.09375 1.10742 2.44531L4.03711 5.375L1.10742 8.33398C0.726562 8.68555 0.726562 9.27148 1.10742 9.62305L1.75195 10.2676C2.10352 10.6484 2.68945 10.6484 3.04102 10.2676L6 7.33789L8.92969 10.2676C9.28125 10.6484 9.86719 10.6484 10.2188 10.2676L10.8633 9.62305C11.2441 9.27148 11.2441 8.68555 10.8633 8.33398L7.93359 5.375Z'
                                fill='currentColor'></path>
                        </svg>
                    }
                </div>

                <div className='queue-grid'>
                    <div className='basic-modal__field'>
                        <label
                            className='basic-modal__label'
                            htmlFor='newCurriculumName'>
                            {Language.getTitleCase('curriculum name')}
                        </label>
                        <input
                            type='text'
                            className='mt-1 border-1 border-gray-300 rounded'
                            placeholder={Language.getTitleCase('curriculum name')}
                            id='name'
                            value={curriculum.name}
                            onChange={handleCurriculumChange}
                        />
                    </div>

                    <div className='basic-modal__field'>
                        <label
                            className='basic-modal__label'
                            htmlFor='newCurriculumName'>
                            {Language.getTitleCase('Next year curriculum')}
                        </label>
                        <div className='dropdown dropdown--attached dropdown--add-question' style={{ marginTop: '12px' }}>
                            <Drip7Dropdown
                                id='next_curriculum_uid'
                                value={next_curriculum}
                                entries={curriculums}
                                onChange={c => handleDropdown({target: { id:c.target.id, value:c.target.value.uid}})}
                                empty={{name: '---', uid: ''}}
                                display={x => x.name}
                                overflow='max-h-40'
                                />
                        </div>
                    </div>
                </div>

                <div className="queue-grid">
                    <div className='basic-modal__field'>
                        <label className='basic-modal__label'
                            htmlFor='curriculum_type'>
                            {Language.getTitleCase( 'Type' )}
                        </label>

                        <div className='dropdown dropdown--attached dropdown--add-question' style={{ marginTop: '12px' }}>
                            <Drip7Dropdown
                                id='curriculum_type'
                                value={curriculum.curriculum_type}
                                entries={Object.values(CurriculumTypes)}
                                onChange={handleDropdown}
                                />
                        </div>
                    </div>
                    <div className='basic-modal__field basic-modal__field--add-question grid-item'>
                        <label
                            className='basic-modal__label'
                            htmlFor='est_time'>
                            {Language.getTitleCase('estimated time (minutes)')}
                        </label>
                        <InputWithArrows
                            id="est_time"
                            label="est_time"
                            value={curriculum.est_time}
                            min={0}
                            max={480}
                            onChange={handleCurriculumChange}
                        />
                    </div>

                    {show_desc && <>
                        <div className='basic-modal__field basic-modal__field--add-question grid-item-row'>
                            <div className='basic-modal__field'>
                                <label
                                    className='basic-modal__label'
                                    htmlFor='short_desc'>
                                    {Language.getTitleCase('Tab Name')}
                                </label>
                                <input
                                    type='text'
                                    className='basic-modal__input'
                                    placeholder={Language.getTitleCase('tab name')}
                                    id='tab_name'
                                    value={curriculum.tab_name}
                                    onChange={handleCurriculumChange}
                                />
                            </div>
                        </div>

                        <Drip7TextArea
                            className='basic-modal__field--add-question width-100'
                            label={Language.getTitleCase('full description')}
                            id='desc'
                            placeholder={Language.getSentenceCase('Type full description here') + '...'}
                            onChange={handleCurriculumChange}
                            value={curriculum.desc}
                        />

                        <div className='basic-modal__field basic-modal__field--add-question grid-item-row'>
                            <div className='basic-modal__field'>
                                <label
                                    className='basic-modal__label'
                                    htmlFor='short_desc'>
                                    {Language.getTitleCase('short description')}
                                </label>
                                <input
                                    type='text'
                                    className='basic-modal__input'
                                    placeholder={Language.getTitleCase('short description')}
                                    id='short_desc'
                                    value={curriculum.short_desc}
                                    onChange={handleCurriculumChange}
                                />
                            </div>
                        </div>
                    </>}
                </div>

                {show_schedule &&
                    <QueueScheduleWidget
                        schedules={schedule}
                        question_count={(delivery_count > 0)? delivery_count: question_count}
                        limit={(delivery_count > 0)? delivery_count: question_count}
                        onScheduleChange={handleScheduleChange}
                    />
                }

                <div className="queue-grid">
                    <div className='basic-modal__field basic-modal__field--add-question grid-item-row' style={{ maxHeight: 'unset' }}>
                        <label className='basic-modal__label'>
                            {Language.getTitleCase("settings")}
                        </label>

                        <div className='customization-checkbox grid-item queue-grid--settings'>
                            <input
                                type='checkbox'
                                aria-label="checkbox"
                                name='cascade'
                                readOnly
                                id='cascade'
                                checked={curriculum.cascade}
                                onClick={handleCurriculumChecked}
                            />
                            <span className='checkmark'></span>
                            <label htmlFor='cascade'
                                   onClick={x => handleCurriculumChecked({ target: { id: 'cascade', checked: !curriculum.cascade } })}>
                                {Language.getTitleCase('sub tenant access')}
                            </label>
                        </div>
                    </div>
                </div>

                {edit_slot == null && curriculum.usage == CurriculumUsage.CURRICULUM &&
                    <ModalTabsWidget
                        tabs={tabs}
                        selected_idx={selected_idx}
                        onChange={selected_idx => setState( prev => ({ ...prev, selected_idx }) )} />
                }

                <div className='basic-modal__field-container' style={containerStyle()}>
                    {selected_idx == 0 && <>
                        <CurriculumQuestionWidget
                            title={Language.getTitleCase((curriculum.usage == CurriculumUsage.CAMPAIGN)? "Templates": "Questions")}
                            categories={category_questions}
                            question_uids={question_uids}
                            show_hide={false}
                            onAdd={handleAddQuestion}
                            onReorder={handleReorderQuestion}
                            onRemove={handleRemoveQuestion}
                        />
                    </>}

                    {selected_idx == 1 &&
                        <CurriculumPreviewWidget
                            tenant_uid={tenant_uid}
                            curriculum={curriculum}
                            category_uids={category_uids}
                            tag_uids={tag_uids}
                            question_uids={question_uids}
                            blocks={blocks}
                            showToast={showToast}
                        />
                    }

                    {/* selected_idx == 0 && <>
                        <CurriculumSpecificWidget
                            title={Language.getTitleCase((curriculum.usage == CurriculumUsage.CAMPAIGN)? "Templates": "Questions")}
                            categories={category_questions}
                            question_uids={question_uids}
                            show_hide={false}
                            onAdd={handleAddQuestion}
                            onRemove={handleRemoveQuestion}
                        />
                        <CurriculumCollectionWidget
                            categories={categories}
                            category_uids={category_uids}
                            tags={tags}
                            tag_uids={tag_uids}
                            phishing={curriculum.usage == CurriculumUsage.CAMPAIGN}
                            onChange={handleCollectionChange}
                        />

                    </>*/}

                    {/*selected_idx == 1 && <>
                        {edit_slot == null &&
                            <div className='curriculum_follow_up'>
                                {blocks.length <= 0 &&
                                    <div className='no-result'>
                                        <div className="delivery-top">
                                            <img src={ResultIMG} alt='Result' />
                                        </div>
                                        <h2>
                                            {Language.getSentenceCase('Generic delivery')}
                                        </h2>
                                    </div>
                                }

                                {Object.entries(blocks).map(([idx, block]) => (
                                    <CurriculumBlockWidget
                                        key={`curriculum_block_${idx}`}
                                        block={block}
                                        slots={block.slots}
                                        category_questions={category_questions}
                                        category_summaries={category_summaries}
                                        category_uids={category_uids}
                                        question_uids={question_uids}
                                        tag_summaries={tag_summaries}
                                        onEditSlot={sidx => handleEditSlot( idx, sidx )}
                                        onChange={x => handleBlockChange( idx, x )}
                                        onRemove={() => handleBlockRemove( idx )}
                                        showToast={showToast}
                                        />
                                ))}

                                <div className="delivery-bottom">
                                    <button
                                        className='basic-modal__button basic-modal__button--solid'
                                        onClick={handleBlockAdd}>
                                        {Language.getTitleCase('Add Block')}
                                    </button>
                                </div>
                            </div>
                        }
                        {edit_slot != null &&
                            <CurriculumSlotModal
                                slot={blocks[edit_slot.b_idx].slots[edit_slot.s_idx]}
                                category_summaries={category_summaries}
                                tag_summaries={tag_summaries}
                                onChange={handleSlotUpdate}
                                onClose={() => setState(prev => ({...prev, edit_slot: null}))}
                                />
                        }
                    </>*/}
                </div>

                {edit_slot == null &&
                    <div className='basic-modal__button-container-centered'>
                        <button
                            className='basic-modal__button basic-modal__button--clear'
                            onClick={onClose}>
                            {Language.getTitleCase( 'cancel' )}
                        </button>
                        <button
                            className='basic-modal__button basic-modal__button--solid'
                            disabled={curriculum.name.length == 0}
                            onClick={handleSave}>
                            {Language.getTitleCase( 'Save Changes' )}
                        </button>
                    </div>
                }
                <p className='hidden-heading'>{Language.getTitleCase('dialog content ends')}</p>
            </div>
        </div>
    );
}
