import { Switch } from '@headlessui/react'
import {CheckIcon, XMarkIcon} from "@heroicons/react/24/outline";

export const Drip7Checkbox = props => {
    const label = props.label || ""
    const id = props.id || ""
    const name = props.name || ""
    const checked = props.checked || false
    const onChange = props.onChange || (() => {})

    return (
        <Switch
            id={id}
            name={name}
            checked={checked}
            onChange={x => onChange({ target: { id, name, checked: x, value: x } })}
            className="group relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-drip7 focus:ring-offset-2 data-[checked]:bg-drip7">
            <span className="sr-only">
                {label}
            </span>
            <span className="pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5">
                <span
                    aria-hidden="true"
                    className="absolute inset-0 flex h-full w-full items-center justify-center transition-opacity duration-200 ease-in group-data-[checked]:opacity-0 group-data-[checked]:duration-100 group-data-[checked]:ease-out">
                    <XMarkIcon className="h-3 w-3 font-bold text-gray-400" strokeWidth={2}/>
                </span>
                <span
                    aria-hidden="true"
                    className="absolute inset-0 flex h-full w-full items-center justify-center opacity-0 transition-opacity duration-100 ease-out group-data-[checked]:opacity-100 group-data-[checked]:duration-200 group-data-[checked]:ease-in">
                    <CheckIcon className="h-3 w-3 font-bold text-gray-600" strokeWidth={2}/>
                </span>
            </span>
        </Switch>
    )
}