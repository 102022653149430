import React, {useEffect, useState} from "react";
import Util from "../../helpers/util";
import Language from "../../helpers/language";

import {QueueDeliveryUi} from "../../helpers/consts";
import {InputWithArrows} from "../input_with_arrows";
import {Drip7Dropdown} from "../drip7_dropdown";

export const QueueScheduleWidget = props => {
    const { onScheduleChange, question_count, limit, schedules } = props;

    //Setup my state data
    const [state, setState] = useState({
        delivery: '',
        deliver_pre_day: 1,
    })
    const { delivery, deliver_pre_day } = state

    useEffect(() => {
        let dc = (schedules.length > 0)? schedules[0].count: 1
        let d = QueueDeliveryUi.EVERY_DAY

        //Figure out what the delivery schedule is based on the numbers
        for (let i = 0; i < schedules.length; i++) {
            if ( schedules[i].count != dc ) {
                if ( i >= 5 && schedules[i].count <= 0 ) {
                    d = QueueDeliveryUi.WEEKDAY
                }
                else {
                    d = QueueDeliveryUi.CUSTOM
                    break
                }
            }
        }

        //Set my values
        setState(prev => ({...prev,
            delivery: d,
            deliver_pre_day: dc,
        }))
    }, [])

    let schedule_count = 0;
    schedules.forEach( schedule => {
        schedule_count += parseInt(schedule.count);
    })

    const qc = ( limit > 0 && limit < question_count )? limit: question_count
    const weeks = Math.ceil( qc / schedule_count )


    const handleChange = (e) => {
        let d = (e.target.id != 'delivery')? delivery: e.target.value
        let dpr = (e.target.id != 'deliver_pre_day')? deliver_pre_day: e.target.value

        //Are we updating the pre day
        if ( e.target.id == 'deliver_pre_day' || e.target.id == 'delivery' ) {
            onScheduleChange( d, -1, dpr )
        }

        setState(prev => ({ ...prev,
            [e.target.id]: e.target.value
        }))
    }

    return (
        <div className='queue-grid'>
            <div className='basic-modal__field basic-modal__field--add-question'>
                <div className='dropdown dropdown--attached dropdown--add-question number-input'>
                    <label className='basic-modal__label w-100'>
                        &nbsp;
                        {Language.getSentenceCase('for')}
                        &nbsp;
                        {weeks}
                        &nbsp;
                        {Language.getSentenceCase('weeks')}
                    </label>

                    <Drip7Dropdown
                        id='delivery'
                        value={delivery}
                        entries={Object.values(QueueDeliveryUi)}
                        onChange={handleChange}
                        />
                </div>
            </div>

            <div className='basic-modal__field basic-modal__field--add-question'>
                <div className='basic-modal__field basic-modal__field--add-question number-input'>
                    {delivery != QueueDeliveryUi.CUSTOM && <>
                        <label
                            className='basic-modal__label'>
                            &nbsp;
                            {Language.getSentenceCase('questions')}
                            &nbsp;
                        </label>
                        <div className='relative inline-block text-left'>
                            <InputWithArrows
                                id={'deliver_pre_day'}
                                value={deliver_pre_day}
                                onChange={handleChange}
                            />
                        </div>
                    </>}
                </div>
            </div>

            {delivery == QueueDeliveryUi.CUSTOM &&
                <table className="grid-item--large" style={{width: "100%"}}>
                    <tbody>
                    <tr>
                        {Object.entries(schedules).map(([doy, _]) =>
                            <th key={`doy_header_${doy}`}>{Util.getDayName(doy)}</th>
                        )}
                    </tr>
                    <tr>
                        {Object.entries(schedules).map(([doy, schedule]) => (
                            <td key={`doy_data_${doy}`}>
                                <InputWithArrows
                                    value={schedule.count}
                                    onChange={e => onScheduleChange( delivery, doy, e.target.value )}
                                />
                            </td>
                        ))}
                    </tr>
                    </tbody>
                </table>
            }
        </div>
    );
};
