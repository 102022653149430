import React, {useEffect, useRef, useState} from "react";
import ResultIMG from "../../assets/images/user-portal/result.svg";
import InputWithIcon from "../../components/input_with_Icon";
import Util from "../../helpers/util";
import Language from "../../helpers/language";
import ContentLoader from "react-content-loader";
import {useNavigate} from "react-router-dom";

export const Curriculum = props => {
    const { showToast } = props;

    const [state, setState] = useState({
        loaded: false,
        assigned_uids: {},
        unfav: {},
        tabs: ['Completed'],
        curriculum_view: '',
        curriculum_list: {},
        expand: null,
        search: ''
    });
    const { loaded, unfav, assigned_uids, tabs, curriculum_view, curriculum_list, expand, search } = state;

    const navigate = useRef( useNavigate() )

    //Load all the avatars
    useEffect(() => {
        props.onPathChange("curriculum");

        //Get the user's curriculum lists
        Util.fetch_js( "/client/curriculum/available/", {},
            js => {
                const assigned_uids = {}
                js.curriculums.Completed.forEach( c => {
                    assigned_uids[c.uid] = c.is_completed
                })

                setState(prev => ({...prev,
                    loaded: true,
                    assigned_uids,
                    tabs: js.tabs,
                    curriculum_view: js.tabs[0],
                    curriculum_list: js.curriculums,
                }))
            },
            (err, code) => {
                showToast(err, 'failure');
            }
        );
    }, []);

    const handleCurriculumView = v => {
        setState({...state, curriculum_view: v, expand: null})
    }

    const handleExpand = curriculum => {
        const {expand} = state
        var exp = (expand != null && expand.uid == curriculum.uid) ? null : curriculum
       setState({ ...state, expand: exp });
    };

    const handleReview = (cur) => {
        if ( !('queue_assigned' in cur)) {
            return
        }

        navigate.current(`/review-drip/${cur.queue_assigned.uid}`)
        console.log(cur)
    }

    const assignCurriculum = (name, curriculum_uid) => {
        if ( curriculum_uid in assigned_uids && !assigned_uids[curriculum_uid] ) {
            showToast(`You have already assigned ${name}`, 'successful')
            navigate.current("/drip")
            return
        }

        Util.fetch_js( "/client/curriculum/assign/", {curriculum_uid, clear_stale: true},
            js => {
                showToast(`Beginning ${name}`, 'successful')
                navigate.current("/drip")
            },
            (err, code) => {
                showToast(err, 'failure');
            }
        );
    }

    const toggleFav = (queue_assigned_uid) => {
        let { unfav } = state

        const active = (queue_assigned_uid in unfav)
        if ( active ) {
            delete unfav[queue_assigned_uid]
        }
        else {
            unfav[queue_assigned_uid] = true
        }

        //Post to the server our action
        Util.fetch_js('/client/curriculum/active/', { queue_assigned_uid, active },
            js => {
                showToast(Language.getSentenceCase("Toggled favorite"), "successful")
            }, showToast )

        setState(prev => ({...prev, unfav }))
    }

    const klassCurriculumView = v => {
        if (curriculum_view == v) {
            return "central-container__filter-button central-container__filter-button--curriculum-list central-container__filter-button--selected";
        }
        else {
            return "central-container__filter-button central-container__filter-button--curriculum-list";
        }
    };

    const tab_icons = [
        (<svg
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M9 10.375C11.7773 10.375 14.0625 8.125 14.0625 5.3125C14.0625 2.53516 11.7773 0.25 9 0.25C6.1875 0.25 3.9375 2.53516 3.9375 5.3125C3.9375 8.125 6.1875 10.375 9 10.375ZM13.5 11.5H11.5312C10.7578 11.8867 9.91406 12.0625 9 12.0625C8.08594 12.0625 7.20703 11.8867 6.43359 11.5H4.5C2.00391 11.5 0 13.5391 0 16V16.5625C0 17.5117 0.738281 18.25 1.6875 18.25H16.3125C17.2266 18.25 18 17.5117 18 16.5625V16C18 13.5391 15.9609 11.5 13.5 11.5Z" fill="#41C8FF"/>
        </svg>),
        (<svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M7.91016 8.47656C7.45312 8.89844 7.45312 9.63672 7.91016 10.0586C8.33203 10.5156 9.07031 10.5156 9.49219 10.0586C9.94922 9.63672 9.94922 8.89844 9.49219 8.47656C9.07031 8.01953 8.33203 8.01953 7.91016 8.47656ZM8.71875 0.53125C3.90234 0.53125 0 4.46875 0 9.25C0 14.0664 3.90234 17.9688 8.71875 17.9688C13.5 17.9688 17.4375 14.0664 17.4375 9.25C17.4375 4.46875 13.5 0.53125 8.71875 0.53125ZM13.1484 5.76953L10.8281 10.832C10.7227 11.043 10.4766 11.2891 10.2656 11.3945L5.20312 13.7148C4.60547 13.9609 4.00781 13.3633 4.25391 12.7656L6.57422 7.70312C6.67969 7.49219 6.92578 7.24609 7.13672 7.14062L12.1992 4.82031C12.7969 4.57422 13.3945 5.17188 13.1484 5.76953Z" fill="#41C8FF"/>
        </svg>),
        (<svg
            width="20"
            height="19"
            viewBox="0 0 20 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M9.10547 0.882812L6.82031 5.55859L1.65234 6.29688C0.738281 6.4375 0.386719 7.5625 1.05469 8.23047L4.74609 11.8516L3.86719 16.9492C3.72656 17.8633 4.71094 18.5664 5.51953 18.1445L10.125 15.7188L14.6953 18.1445C15.5039 18.5664 16.4883 17.8633 16.3477 16.9492L15.4688 11.8516L19.1602 8.23047C19.8281 7.5625 19.4766 6.4375 18.5625 6.29688L13.4297 5.55859L11.1094 0.882812C10.7227 0.0742188 9.52734 0.0390625 9.10547 0.882812Z" fill="#41C8FF"/>
        </svg>)
    ]
    const getTabIcon = ( idx ) => {
        return ( idx < tab_icons.length )? tab_icons[idx]: tab_icons[tab_icons.length - 1]
    }

    return (
        <div className='user-portal__main-container user-portal__main-container--curriculum-list'>
            <div className='badge-status__heading curriculum-heading mobile-heading'>
                <h1>{Language.getTitleCase('Curriculum')}</h1>
            </div>

            <div className='central-container__filter-button-container central-container__filter-button-container--mobile'>
                {tabs.map((tab, i) =>
                    <div key={`tab_${i}`}
                        className={klassCurriculumView(tab)}
                        onClick={() => handleCurriculumView(tab)}>
                        <h3 className='central-container__filter-button-name'>
                            {tab}
                        </h3>
                    </div>
                )}
            </div>

            <div className='central-container curriculum-list'>
                <div className='search-wrapper search-wrapper--mobile'>
                    <InputWithIcon
                        fullWidth
                        inputProps={{
                            type: "text",
                            placeholder: Language.getSentenceCase('search')+"...",
                            value: search,
                            onChange: e =>
                                setState({
                                    ...state,
                                    search: e.target.value.toLowerCase()
                                })
                        }}
                        icon='search-icon'
                    />
                </div>
                <div className='central-container__filter-button-container'>
                    {tabs.map((tab, i) => (
                    <div key={`tab_${i}`}
                        className={klassCurriculumView(tab)}
                        onClick={() => handleCurriculumView(tab)}>
                        {getTabIcon(i)}
                        <h3 className='central-container__filter-button-name'>
                            {tab}
                        </h3>
                    </div>
                    ))}
                </div>

                <div className='curriculum-list__body'>
                    {false && !loaded && <div className='curriculum-list__loader'>
                        {[...Array(3)].map((x, i) =>
                            <div className='curriculum-list__loader--item' key={'loading' + i}>
                                <ContentLoader width="550" height="114" >
                                    <rect x="0" y="16" width="80" height="13" />
                                    <rect className="mobile-row" x="0" y="45" width="550" height="14" />
                                    <rect x="0" y="90" rx="12" ry="12" width="168" height="24" />
                                </ContentLoader>
                            </div>
                        )}
                        </div>
                    }
                    {curriculum_view in curriculum_list && curriculum_list[curriculum_view].filter((c) => expand == null || c.uid != expand.uid).map( (c, i) => (
                        <div key={`cur_list_${i}`} className='curriculum-list__item'>
                            <div className='curriculum-list__text'
                                 onClick={() => handleReview(c)}>
                                <div className='curriculum-list__title'>
                                    <div className='curriculum-list__title-text'>
                                        {c.name}
                                    </div>
                                </div>

                                <div className='curriculum-list__information'>
                                    <div className='curriculum-list__short-description'>
                                        {('queue_assigned' in c)? `${Util.epochToDate( c.queue_assigned.created_on, true )} `: ''}
                                        {c.desc}
                                    </div>
                                </div>
                            </div>
                            <div className='curriculum-list__buttons'>
                                {!('queue_assigned' in c) && <>
                                    {(c.uid in assigned_uids) ? (
                                        <button className='curriculum-list__start curriculum-list__start--assigned user-portal__btn'
                                                onClick={() => assignCurriculum(c.name, c.uid)}>
                                            {Language.getTitleCase('assigned')}
                                        </button>
                                        ) : (
                                        <button
                                            className='curriculum-list__start user-portal__btn blue-button'
                                            onClick={() => assignCurriculum(c.name, c.uid)}>
                                            {Language.getTitleCase('assign to me')}
                                        </button>
                                    )}
                                </>}

                                {('queue_assigned' in c) && <>
                                    {(!(c.queue_assigned.uid in unfav)) ? (
                                        <button
                                            className={'curriculum-list__start user-portal__btn ' + (c.is_completed? 'curriculum-list__favorite--true': 'curriculum-list__start--assigned')}
                                            disabled={c.user_count > 1 || !c.is_completed}
                                            onClick={() => toggleFav(c.queue_assigned.uid)}>
                                            <svg
                                                width="20"
                                                height="19"
                                                viewBox="0 0 20 19"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path d="M9.09961 1.72266L6.94141 6.13867L2.06055 6.83594C1.19727 6.96875 0.865234 8.03125 1.49609 8.66211L4.98242 12.082L4.15234 16.8965C4.01953 17.7598 4.94922 18.4238 5.71289 18.0254L10.0625 15.7344L14.3789 18.0254C15.1426 18.4238 16.0723 17.7598 15.9395 16.8965L15.1094 12.082L18.5957 8.66211C19.2266 8.03125 18.8945 6.96875 18.0312 6.83594L13.1836 6.13867L10.9922 1.72266C10.627 0.958984 9.49805 0.925781 9.09961 1.72266Z" fill="white"/>
                                            </svg>
                                            {c.is_completed ? Language.getTitleCase('Completed') : Language.getTitleCase('In Progress')}
                                        </button>  ) : (
                                        <button
                                            className='curriculum-list__start user-portal__btn curriculum-list__favorite'
                                            disabled={c.user_count > 1 || !c.is_completed}
                                            onClick={() => toggleFav(c.queue_assigned.uid)}>
                                            <svg
                                                width="20"
                                                height="19"
                                                viewBox="0 0 20 19"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path d="M18.0312 6.83594L13.1836 6.13867L10.9922 1.72266C10.627 0.958984 9.49805 0.925781 9.09961 1.72266L6.94141 6.13867L2.06055 6.83594C1.19727 6.96875 0.865234 8.03125 1.49609 8.66211L4.98242 12.082L4.15234 16.8965C4.01953 17.7598 4.94922 18.4238 5.71289 18.0254L10.0625 15.7344L14.3789 18.0254C15.1426 18.4238 16.0723 17.7598 15.9395 16.8965L15.1094 12.082L18.5957 8.66211C19.2266 8.03125 18.8945 6.96875 18.0312 6.83594ZM13.3828 11.5176L14.1797 16.0996L10.0625 13.9414L5.91211 16.0996L6.70898 11.5176L3.35547 8.26367L7.9707 7.59961L10.0625 3.41602L12.1211 7.59961L16.7363 8.26367L13.3828 11.5176Z"/>
                                            </svg>
                                            {Language.getTitleCase('add to Completed')}
                                        </button>)
                                    }
                                </>}

                            </div>
                        </div>
                    ))}
                    {!(curriculum_view in curriculum_list) || curriculum_list[curriculum_view].length <= 0 ? (
                        <div className='no-result'>
                            <img src={ResultIMG} alt='Result' />
                            <h2>
                                {'You have not assigned'}
                                <br />
                                {'any curriculum yet.'}
                            </h2>
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
};
