import React, { useEffect, useRef, useState } from 'react'
import { RadioGroup } from '@headlessui/react'
import { CheckCircleIcon, TrashIcon } from '@heroicons/react/20/solid'
import Util from "../../helpers/util";
import {PackageFrequency} from "../../helpers/consts";
import {useStore} from "../../store";
import {
    Elements,
    PaymentElement, useElements,
    useStripe
} from '@stripe/react-stripe-js';
import {Spinner} from "../../components/spinner";


const paymentMethods = [
    { id: 'credit-card', title: 'Credit card' },
    { id: 'paypal', title: 'PayPal' },
    { id: 'etransfer', title: 'eTransfer' },
]


export const Payment = props => {
    const { client_secret, onStripe } = props

    const stripe = useStripe()
    const elements = useElements()

    useEffect(() => {
        if (stripe && elements) {
            onStripe(stripe, elements)
        }
    }, [stripe, elements]);

    return (
        <div className="mt-10 border-t border-gray-200 pt-10">
            <h2 className="text-lg font-medium text-gray-900">Payment</h2>

            {client_secret &&
                <PaymentElement
                />
            }
            {!client_secret &&
                <Spinner/>
            }
        </div>
    )
}