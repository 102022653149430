import { Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions, Label } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { useState } from 'react'
import Util from "../helpers/util";

/*
const entries = [
    {
        id: 1,
        name: 'Leslie Alexander',
        image_url:
            'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
    // More users...
]
 */

export const Drip7Combobox = props => {
    const { id, name, entries, selected_idx } = props
    const title = props.title || null
    const onSelected = props.onSelected || (() => {})
    const onChange = props.onChange || (() => {})
    const onEntry = props.onEntry || (() => {})

    const [query, setQuery] = useState('')

    const handleSelect = (entry) => {
        setQuery('')
        onSelected( entries.findIndex(x => entry === x) )
        onChange( { target: { id, name, value: entry.uid } } )
        onEntry( entry )
    }

    const selected = entries[selected_idx] || {}

    const filteredEntries =
        (query === ''
            ? entries
            : entries.filter((entry) => {
                return entry.name.toLowerCase().includes(query.toLowerCase())
            })).slice(0, 50)

    return (
        <Combobox
            as="div"
            value={selected}
            onChange={handleSelect}>
            {title &&
                <Label className="block text-sm font-medium leading-6 text-gray-900">
                    {title}
                </Label>
            }
            <div className="relative">
                <ComboboxInput
                    className="w-full rounded-md border-0 bg-white py-2 pl-3 pr-12 text-gray-900 ring-1 ring-inset ring-gray-200 focus:ring-inset focus:ring-drip7 sm:leading-6"
                    onChange={(event) => setQuery(event.target.value)}
                    onBlur={() => setQuery('')}
                    displayValue={(entry) => entry?.name}
                />
                <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                    <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </ComboboxButton>

                {filteredEntries.length > 0 && (
                    <ComboboxOptions className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {filteredEntries.map((entry, idx) => (
                            <ComboboxOption
                                key={`combobox_${idx}`}
                                value={entry}
                                className={({ focus }) =>
                                    Util.classNames(
                                        'relative cursor-default select-none py-2 pl-3 pr-9',
                                        focus ? 'bg-drip7 text-white' : 'text-gray-900',
                                    )
                                }>
                                {({ focus, selected }) => (
                                    <>
                                        <div className="flex items-center">
                                            {entry.image_url &&
                                                <img src={entry.image_url} alt="" className="h-6 w-6 flex-shrink-0 rounded-full ring-1 ring-gray-300" />
                                            }
                                            <span className={Util.classNames('ml-3 truncate', selected && 'font-semibold')}>
                                                {entry.name}
                                            </span>
                                        </div>

                                        {selected && (
                                            <span
                                                className={Util.classNames(
                                                    'absolute inset-y-0 right-0 flex items-center pr-4',
                                                    focus ? 'text-white' : 'text-drip7',
                                                )}>
                                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                            </span>
                                        )}
                                    </>
                                )}
                            </ComboboxOption>
                        ))}
                    </ComboboxOptions>
                )}
            </div>
        </Combobox>
    )
}
