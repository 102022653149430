export const InputWithArrows = props => {
    const { onChange, value, id, name, min, max, readOnly } = props;
    let { onBlur } = props

    if ( onBlur == null || onBlur == undefined ) {
        onBlur = () => {}
    }

    //Make sure the values are safe
    const cap = (val) => {
        if ( max != null && max != undefined && val > max ) {
            return max
        }
        if ( min == null && min == undefined && val < 0 ) {
            return 0
        }
        if ( min != null && min != undefined && val < min ) {
            return min
        }

        return val
    }

    const handleInc = e => {
        if ( readOnly ) {
            return
        }

        onChange({ target: { id, name, value: cap( value + 1 )}})
    };

    const handleDec = e => {
        if ( readOnly ) {
            return
        }

        onChange({ target: { id, name, value: cap( value - 1 )}})
    };

    const handleChange = e => {
        if ( readOnly ) {
            return
        }

        let { value } = e.target
        if ( isNaN( value ) ) {
            value = "0"
        }
        else if ( value == "-" || value == "" || !value ) {
            value = "-0"
        }

        onChange({ target: { id, name, value: parseInt( value )}}) //No cap
    };

    const handleBlur = e => {
        onChange({ target: { id, name, value: cap( parseInt( value ))}})
        onBlur()
    }

    return (
        <div className='basic-modal__input-wrapper'
            style={{ width: "76px" }}>
            <input
                className='basic-modal__input basic-modal__input--number'
                id='count'
                placeholder='00'
                value={value}
                style={{ width: "48px" }}
                readOnly={readOnly}
                onBlur={handleBlur}
                onChange={handleChange}
            />
            {!readOnly && 
            <div className='number-button__container'>
                <svg
                    className='number-button number-button--up'
                    width='10'
                    height='6'
                    viewBox='0 0 10 6'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                    onClick={handleInc}>
                    <path
                        d='M1.5332 0.5C1.00586 0.5 0.742188 1.14453 1.12305 1.52539L4.87305 5.27539C5.10742 5.50977 5.48828 5.50977 5.72266 5.27539L9.47266 1.52539C9.85352 1.14453 9.58984 0.5 9.0625 0.5H1.5332Z'
                        fill='#1F4760'></path>
                </svg>
                <svg
                    className='number-button number-button--down'
                    width='10'
                    height='6'
                    viewBox='0 0 10 6'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                    onClick={handleDec}>
                    <path
                        d='M1.5332 0.5C1.00586 0.5 0.742188 1.14453 1.12305 1.52539L4.87305 5.27539C5.10742 5.50977 5.48828 5.50977 5.72266 5.27539L9.47266 1.52539C9.85352 1.14453 9.58984 0.5 9.0625 0.5H1.5332Z'
                        fill='#1F4760'></path>
                </svg>
            </div> 
            }
        </div>
    );
};
