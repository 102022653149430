import React, { useEffect, useState } from "react";

import Util from "../../helpers/util";
import Language from "../../helpers/language";

import { QuestionsTab } from "./questions_tab";
import { CategoriesTab } from "./categories_tab";
// import { PhishTemplatesTab } from "./phish_templates_tab";
import {Access} from "../../helpers/consts";
import {SparklesIcon} from "@heroicons/react/24/outline";
import {ChatSandboxTab} from "./chat_sandbox_tab";

export const Content = (props) => {
    const { tenant_uid, tenants, showToast } = props;

    const [state, setState] = useState({
        tenant_uid: "",
        show_answers: false,
        question_categories: [],
        categories: [],
        selected_idx: 0
    })
    const { show_answers, question_categories, categories, selected_idx } = state

    const updateContent = (show_answers) => {
        //Get a list of all members for this tenant
        const payload = {
            tenant_uid,
            show_answers,
            access: Access.EXEC
        };
        Util.fetch_js( "/question/list/", payload,
            js => {
                //Seems wierd, but call another download that is much slower with more detailed information
                if ( !show_answers ) {
                    updateContent( true )
                }

                //Store the categories!
                setState(prev => ({...prev,
                    show_answers: true,
                    question_categories: js.categories
                }))
            },
            (reason, code) => {
                showToast(reason, 'failure');
            }
        );

        if ( show_answers ) {
            //Get all the categories
            Util.fetch_js( "/category/list/", { tenant_uid, access: Access.EXEC },
                js => {
                    //Store the categories!
                    setState(prev => ({...prev,
                        categories: js.categories
                    }))
                },
                (reason, code) => {
                    showToast(reason, 'failure');
                }
            );
        }

        //Setup the tenant_uid to block multiloads
        setState(prev => ({
            ...prev,
            tenant_uid,
        }))
    }

    useEffect(() => {
        props.onPathChange("content")
    }, [])

    useEffect(() => {
        if ( props.tenant_uid != state.tenant_uid ) {
            updateContent( show_answers )
        }
    }, [props])

    // get my current tenant
    const tenant = tenants.find(t => t.uid === tenant_uid) || {}

    return (
        <div className='questions main-modal'>
            <nav className='central__nav'>
                <ul className='central__nav-list'>
                    <li className={"central__nav-item central__nav-item--small " + (selected_idx == 0 ? "central__nav-item--selected" : "")}
                        onClick={() => setState(prev => ({
                            ...prev,
                            selected_idx: 0
                        }))}>
                        <svg
                            width='15'
                            height='12'
                            viewBox='0 0 15 12'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'>
                            <path
                                d='M0.46875 2.625H12.6562C12.8906 2.625 13.125 2.41992 13.125 2.15625V1.21875C13.125 0.984375 12.8906 0.75 12.6562 0.75H0.46875C0.205078 0.75 0 0.984375 0 1.21875V2.15625C0 2.41992 0.205078 2.625 0.46875 2.625ZM14.5312 5.4375H2.34375C2.08008 5.4375 1.875 5.67188 1.875 5.90625V6.84375C1.875 7.10742 2.08008 7.3125 2.34375 7.3125H14.5312C14.7656 7.3125 15 7.10742 15 6.84375V5.90625C15 5.67188 14.7656 5.4375 14.5312 5.4375ZM12.6562 10.125H0.46875C0.205078 10.125 0 10.3594 0 10.5938V11.5312C0 11.7949 0.205078 12 0.46875 12H12.6562C12.8906 12 13.125 11.7949 13.125 11.5312V10.5938C13.125 10.3594 12.8906 10.125 12.6562 10.125Z'
                                fill='currentColor'></path>
                        </svg>
                        {Language.getTitleCase('questions')}
                    </li>
                    <li className={"central__nav-item central__nav-item--wide " + (selected_idx == 1 ? "central__nav-item--selected" : "")}
                        onClick={() => setState(prev => ({ ...prev, selected_idx: 1 }))}>
                        <svg
                            width='16'
                            height='16'
                            viewBox='0 0 16 16'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'>
                            <path
                                d='M6.5625 3.7168V5.12305L7.41211 6.00195C7.20703 4.97656 7.5293 3.95117 8.26172 3.21875C8.84766 2.60352 9.63867 2.31055 10.459 2.31055H10.4883L8.81836 3.98047L9.25781 6.61719L11.8945 7.05664L13.5938 5.38672C13.5938 6.20703 13.2715 7.02734 12.6562 7.61328C12.3926 7.90625 12.0703 8.11133 11.748 8.25781C11.7773 8.28711 11.8359 8.3457 11.8945 8.375L12.8027 9.2832C13.0957 9.10742 13.3887 8.87305 13.6523 8.60938C14.7656 7.49609 15.2344 5.85547 14.8535 4.33203C14.7656 3.95117 14.502 3.6875 14.1504 3.57031C13.7695 3.48242 13.3887 3.59961 13.1543 3.83398L11.4258 5.5625L10.459 5.41602L10.3125 4.44922L12.041 2.75C12.3047 2.48633 12.3926 2.10547 12.3047 1.75391C12.1875 1.40234 11.9238 1.10938 11.543 1.02148C9.99023 0.640625 8.37891 1.08008 7.26562 2.22266C6.97266 2.51562 6.70898 2.83789 6.50391 3.21875H6.5625V3.7168ZM3.10547 14.1758C2.72461 14.5566 2.05078 14.5566 1.66992 14.1758C1.49414 14 1.37695 13.7363 1.37695 13.4727C1.37695 13.209 1.49414 12.9746 1.66992 12.7695L5.625 8.84375L4.62891 7.84766L0.703125 11.7734C0.234375 12.2422 0 12.8574 0 13.4727C0 14.1172 0.234375 14.7324 0.703125 15.1719C1.14258 15.6406 1.75781 15.875 2.40234 15.875C3.01758 15.875 3.63281 15.6406 4.10156 15.1719L7.03125 12.2422C6.76758 11.7734 6.5918 11.2461 6.5918 10.6895L3.10547 14.1758ZM14.6777 12.4766L11.25 9.04883C10.5469 8.375 9.55078 8.22852 8.73047 8.63867L5.625 5.5332V3.6875L1.875 0.875L0 2.75L2.8125 6.5H4.62891L7.73438 9.63477C7.35352 10.4551 7.4707 11.4512 8.14453 12.125L11.5723 15.582C12.0117 15.9922 12.6855 15.9922 13.125 15.582L14.6777 14.0293C15.0879 13.5898 15.0879 12.916 14.6777 12.4766Z'
                                fill='currentColor'></path>
                        </svg>
                        {Language.getTitleCase('categories')}
                    </li>
                    {tenant.ai_chat_hack &&
                    <li className={"central__nav-item central__nav-item--wide " + (selected_idx == 2 ? "central__nav-item--selected" : "")}
                        onClick={() => setState(prev => ({ ...prev, selected_idx: 2 }))}>
                        <SparklesIcon
                            className='h-6 w-6 mr-2'
                            aria-hidden='true'
                            />
                        {Language.getTitleCase('chat sandbox')}
                    </li>
                    }
                </ul>
            </nav>

            {selected_idx == 0 && (
                <QuestionsTab
                    tenant_uid={tenant_uid}
                    tenants={tenants}
                    categories={categories}
                    question_categories={question_categories}
                    showToast={showToast}
                    onRefreshCategories={() => updateContent(show_answers)}
                />
            )}

            {selected_idx == 1 && (
                <CategoriesTab
                    tenant_uid={tenant_uid}
                    categories={categories}
                    showToast={showToast}
                    onRefreshCategories={() => updateContent(show_answers)}
                />
            )}

            {selected_idx == 2 && (
                <ChatSandboxTab
                    tenant_uid={tenant_uid}
                    showToast={showToast}
                />
            )}
        </div>
    );
}
