import React, {useState} from "react";
import Language from "../../helpers/language";


export const AnswerMultipleWidget = props => {
    const { access, onChange } = props;
    const [state, setState] = useState({});

    const safe_placeholder = props.placeholder || {};
    let placeholders = safe_placeholder.answers || [];
    let answers = props.answer.answers || ["", "", "", ""];

    //Pad the answers with empty to match placeholder text
    while (answers.length < placeholders.length) {
        answers.push("");
    }

    //Pad Placeholders
    while (placeholders.length < answers.length) {
        placeholders.push(Language.getSentenceCase('Type answer here') + "...");
    }

    const correct = props.answer.correct || safe_placeholder.correct || -1;

    const checkedKlass = checked => {
        return (
            "basic-modal__answer-checkmark" +
            (checked ? " basic-modal__answer-checkmark--correct-answer" : "")
        );
    };

    const handleCorrect = correct => {
        if ( !access.write ) {
            return
        }

        if ( parseInt( props.answer.correct ) == correct ) {
            correct = -1
        }

        onChange('answer', { answers }, { correct });
    };

    const handleChange = (idx, answer) => {
        if ( !access.write ) {
            return
        }

        let update_answers = answers;
        update_answers[idx] = answer;

        setState({ ...state, answers: update_answers });
        onChange('answer', { answers: update_answers }, { correct });
    };

    const handleRemove = idx => {
        if ( !access.write ) {
            return
        }

        if (idx < 0 || idx >= answers.length) {
            return;
        }

        let update_answers = answers;
        update_answers.splice(idx, 1);
        let update_correct = correct;

        //Make sure the correct answer value stays with the correct choice
        if (idx <= parseInt(update_correct) && parseInt(update_correct) > 0)
            update_correct = (parseInt(update_correct) - 1).toString();

        onChange('answer', { answers: update_answers }, { correct: update_correct } )
    };

    const addAnswer = () => {
        if ( !access.write ) {
            return
        }

        let update_answers = answers;

        update_answers.push("");

        onChange('answer', { answers: update_answers }, { correct });
    };

    return (
        <div className='basic-modal__add-answer-container'>
            {Object.entries(answers).map(([key, answer]) => (
                <div
                    className='basic-modal__input-wrapper basic-modal__input-wrapper--add-answer width-100'
                    key={"answer_key_" + key}>
                    <input
                        className='basic-modal__input basic-modal__input--add-answer basic-modal__input--add-multichoice width-100'
                        type='text'
                        placeholder={placeholders[key]}
                        value={answer}
                        readOnly={!access.write}
                        onChange={e => handleChange(key, e.target.value)}
                    />
                    <svg
                        className={checkedKlass(key == correct)}
                        width='16'
                        height='13'
                        viewBox='0 0 16 13'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                        onClick={() => handleCorrect(key)}>
                        <path
                            d='M5.56836 11.7656C5.86133 12.0586 6.35938 12.0586 6.65234 11.7656L15.2656 3.15234C15.5586 2.85938 15.5586 2.36133 15.2656 2.06836L14.2109 1.01367C13.918 0.720703 13.4492 0.720703 13.1562 1.01367L6.125 8.04492L2.81445 4.76367C2.52148 4.4707 2.05273 4.4707 1.75977 4.76367L0.705078 5.81836C0.412109 6.11133 0.412109 6.60938 0.705078 6.90234L5.56836 11.7656Z'
                            fill='white'></path>
                    </svg>
                    &nbsp;
                    <svg
                        className='basic-modal__delete'
                        width='14'
                        height='16'
                        viewBox='0 0 14 16'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                        onClick={() => handleRemove(key)}>
                        <path
                            d='M1.375 14.4688C1.375 15.2598 1.99023 15.875 2.78125 15.875H11.2188C11.9805 15.875 12.625 15.2598 12.625 14.4688V4.625H1.375V14.4688ZM9.34375 6.96875C9.34375 6.73438 9.54883 6.5 9.8125 6.5C10.0469 6.5 10.2812 6.73438 10.2812 6.96875V13.5312C10.2812 13.7949 10.0469 14 9.8125 14C9.54883 14 9.34375 13.7949 9.34375 13.5312V6.96875ZM6.53125 6.96875C6.53125 6.73438 6.73633 6.5 7 6.5C7.23438 6.5 7.46875 6.73438 7.46875 6.96875V13.5312C7.46875 13.7949 7.23438 14 7 14C6.73633 14 6.53125 13.7949 6.53125 13.5312V6.96875ZM3.71875 6.96875C3.71875 6.73438 3.92383 6.5 4.1875 6.5C4.42188 6.5 4.65625 6.73438 4.65625 6.96875V13.5312C4.65625 13.7949 4.42188 14 4.1875 14C3.92383 14 3.71875 13.7949 3.71875 13.5312V6.96875ZM13.0938 1.8125H9.57812L9.28516 1.28516C9.16797 1.05078 8.9043 0.875 8.66992 0.875H5.30078C5.06641 0.875 4.77344 1.05078 4.68555 1.28516L4.42188 1.8125H0.90625C0.642578 1.8125 0.4375 2.04688 0.4375 2.28125V3.21875C0.4375 3.48242 0.642578 3.6875 0.90625 3.6875H13.0938C13.3281 3.6875 13.5625 3.48242 13.5625 3.21875V2.28125C13.5625 2.04688 13.3281 1.8125 13.0938 1.8125Z'
                            fill='currentColor'></path>
                    </svg>
                </div>
            ))}
            {access.write && (
                <div
                    className='questions__table-data questions__table-data--add-subject'
                    onClick={() => addAnswer()}
                    style={{zIndex:20}}>
                    <svg
                        width='14'
                        height='14'
                        viewBox='0 0 14 14'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'>
                        <path
                            d='M13.0625 5.96875H8.84375V1.75C8.84375 1.25195 8.4043 0.8125 7.90625 0.8125H6.96875C6.44141 0.8125 6.03125 1.25195 6.03125 1.75V5.96875H1.8125C1.28516 5.96875 0.875 6.4082 0.875 6.90625V7.84375C0.875 8.37109 1.28516 8.78125 1.8125 8.78125H6.03125V13C6.03125 13.5273 6.44141 13.9375 6.96875 13.9375H7.90625C8.4043 13.9375 8.84375 13.5273 8.84375 13V8.78125H13.0625C13.5605 8.78125 14 8.37109 14 7.84375V6.90625C14 6.4082 13.5605 5.96875 13.0625 5.96875Z'
                            fill='currentColor'
                        />
                    </svg>
                    <span className='questions__add-subject'>{Language.getTitleCase('Add Answer')}</span>
                </div>
            )}
        </div>
    );
};
