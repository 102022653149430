import React, { useEffect, useRef } from "react";
import  QuestionCountImg  from "../../assets/images/user-portal/user-question-count.svg";
import  TotalTimeImg  from "../../assets/images/user-portal/user-total-time.svg";
import  Arrow  from "../../assets/images/user-portal/arrow.svg";
import Language from "../../helpers/language";
import {useNavigate} from "react-router-dom";


export const TrainingSection = (props) => {
    const navigate = useNavigate()

    const handleClick = () => {
        if (props.questionCount === 0) {
            return
        }

        navigate('/drip')
    }

    return (
        <div className={"training-section shadowed" + (props.questionCount > 0? "cursor-pointer": "")}
             onClick={handleClick}>
            <div className="training-content">
                {props.questionCount === 0 &&
                    <div className="training-title">
                        {Language.getSentenceCase('No new questions')}
                    </div>
                }
                {props.questionCount > 0 &&
                <div className="training-title">
                    {Language.getSentenceCase('Start Training')}
                </div>
                }
                <div className="training-summary">
                    <img className="training-img" src={TotalTimeImg}></img>
                    <div className="training-time">{props.questionCount > 0? props.totalTime: ''} {Language.getSentenceCase('min')}</div>
                    <img className="training-img" src={QuestionCountImg}></img>
                    <div className="training-question">{props.questionCount > 0? props.questionCount: ''} {Language.getSentenceCase('Questions')}</div>
                </div>
            </div>
            <div className="training-button">
                <div className="training-button-icon">
                    <img src={Arrow}></img>
                </div>
            </div>
        </div>
    );
  };
  