import React, {useEffect, useState} from "react"
import {Drip7Dropdown} from "../drip7_dropdown"
import Language from "../../helpers/language"

import {buildQuestions, filterQuestions} from "../../helpers/content"
import {AnswerType, ContentType} from "../../helpers/consts"
import {QuestionTableWidget} from "./question_table_widget"
import { Drip7Input } from "../drip7_input"
import {QuestionPicker} from "./question_picker"
import {ChevronUpDownIcon} from "@heroicons/react/20/solid";


export const CurriculumQuestionWidget = props => {
    const { title, onAdd, onReorder, onRemove, categories, question_uids, show_hide } = props

    const [state, setState] = useState({
        search: "",
        category_uid: "",
        content_type: "",
        answer_type: "",
        apply_filter: false,
        show_questions: question_uids.length > 0 || show_hide || show_hide == null || show_hide == undefined,
    })
    const { search, category_uid, content_type, answer_type } = state

    const [selected_questions, setSelectedQuestions] = useState([])
    const [possible_questions, setPossibleQuestions] = useState([])

    const [dragging, setDragging] = useState(null)

    useEffect(() => {
        const questions = buildQuestions( categories )

        const lookup = {}
        questions.forEach( x => { lookup[x.uid] = x })

        setSelectedQuestions( question_uids.map( x => lookup[x] ).filter(x => x !== undefined ))

    }, [categories, question_uids]);

    useEffect(() => {
        const questions = buildQuestions( categories )
        const filtered_questions = filterQuestions(
            questions,
            search,
            category_uid,
            content_type,
            answer_type,
        )

        const skip = {}
        question_uids.forEach( x => { skip[x] = true })

        setPossibleQuestions( filtered_questions.filter( x => !skip[x.uid] ))

    }, [categories, question_uids, search, category_uid, content_type, answer_type]);


    const cat_title = (categories.find( x => x.uid === category_uid ) || { name: "Categories", uid: ''})
    const cat_entries = [{uid: '', name: '---'}].concat(
        Object.entries(categories).sort((c1, c2) => { return c1[1].name.localeCompare(c2[1].name) }).map(x => x[1]))

    //Give friendly names to content and answers that can be anything
    const ctt = (content_type == null || content_type == undefined || content_type == "")? {name: "Content", value: ""}: {name: Language.getTitleCase(content_type), value: content_type}
    const att = (answer_type == null || answer_type == undefined || answer_type == "")? {name: 'Answer', value: ''}: {name: Language.getTitleCase(answer_type), value: answer_type}

    const handleChange = e => {
        setState( prev => ({ ...prev, [e.target.id]: e.target.value }))
    }

    const handleDragStart = (e, start_id, index) => {
        const item = (start_id === 'selected_questions')? selected_questions[index]: possible_questions[index]
        setDragging(prev => ({...prev,
            item,
            start_id,
            current_id: start_id,
            index: -1,
        }))
        e.dataTransfer.effectAllowed = 'move'
        setTimeout(() => {
            e.target.style.opacity = '0.5'
        }, 0)
    }

    const handleDragEnd = (e) => {
        //e.preventDefault()
        e.target.style.opacity = '1'
        if (dragging === null) {
            return
        }

        const {start_id, current_id, item, index} = dragging
        if (start_id !== current_id) {
            if ( start_id === 'selected_questions' ) {
                onRemove( item.uid )
            }
            else {
                onAdd( item.uid, index )
            }
        }
        else if (start_id === 'selected_questions') {
            onReorder( selected_questions.map( x => x.uid ) )
        }

        setDragging(null)
    }

    const handleDragOver = (e, current_id, index) => {
        //Tell the world I am hovering
        if (dragging === null ) {
            return
        }

        //Tell the world I am hovering
        if ( index < 0 ) {
            setDragging(prev => ({...prev, current_id}))
            return
        }

        //Tell the world I am hovering
        if ( dragging.start_id === 'possible_questions' ||
            dragging.current_id === 'possible_questions' ) {
            setDragging(prev => ({...prev, current_id, index}))
            return
        }

        e.preventDefault()
        const newItems = [...selected_questions]
        const itemIndex = selected_questions.indexOf(dragging.item)
        newItems.splice(itemIndex, 1)
        newItems.splice(index, 0, dragging.item)

        setSelectedQuestions(newItems)
        setDragging(prev => ({...prev, current_id, index}))
    }

    // Calculate height based on 10 items
    const itemHeight = 48 // height of each item in pixels
    const visibleItems = 10
    const containerHeight = itemHeight * visibleItems

    return (
        <div className='w-full'>
            <div className="mt-6 ml-8 inline-flex gap-x-2">
                <Drip7Dropdown
                    id='category_uid'
                    name='category_uid'
                    value={cat_title}
                    entries={cat_entries}
                    display={x => x.name}
                    overflow={'max-h-60'}
                    onChange={e => handleChange({
                        target: {
                            id: 'category_uid',
                            value: e.target.value.uid
                        }
                    })}
                />

                <Drip7Dropdown
                    id='content_type'
                    name='content_type'
                    value={ctt}
                    entries={[{
                        name: '---',
                        value: ''
                    }].concat(Object.values(ContentType).map(ct => ({
                        name: ct,
                        value: Language.getTitleCase(ct)
                    })))}
                    display={x => x.name}
                    onChange={x => handleChange({
                        target: {
                            id: 'content_type',
                            value: x.target.value.value
                        }
                    })}
                    overflow={'max-h-60'}
                />

                <Drip7Dropdown
                    id='answer_type'
                    name='answer_type'
                    value={att}
                    entries={[{
                        name: '---',
                        value: ''
                    }].concat(Object.values(AnswerType).map(at => ({
                        name: at,
                        value: Language.getTitleCase(at)
                    })))}
                    display={x => x.name}
                    onChange={x => handleChange({
                        target: {
                            id: 'answer_type',
                            value: x.target.value.value
                        }
                    })}
                    overflow={'max-h-60'}
                />

                <Drip7Input
                    placeholder={Language.getSentenceCase('search for questions')}
                    id='search'
                    value={search}
                    onChange={handleChange}>
                    <i className='icon search-icon'></i>
                </Drip7Input>
            </div>

            <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                <div className='col-span-1'>
                    <div className="w-full max-w-2xl mx-auto p-4">
                        <h3 className="text-lg">
                            {Language.getSentenceCase('possible questions')} ({possible_questions.length})
                        </h3>
                        <div
                            className="overflow-y-auto border border-gray-200 rounded-lg"
                            style={{height: `${containerHeight}px`}}
                            id="container"
                            onDragOver={e => handleDragOver(e, 'possible_questions', -1)}
                            onDragEnd={handleDragEnd}>
                            <ul className="divide-y divide-gray-200">
                                {possible_questions.map((item, index) => (
                                    <li key={`possible_questions_${item.uid}_${index}`}
                                        id="possible_questions"
                                        draggable
                                        onDragStart={e => handleDragStart(e, 'possible_questions', index)}
                                        onDragOver={e => handleDragOver(e, 'possible_questions', index)}
                                        onDragEnd={handleDragEnd}
                                        className="flex items-center gap-3 p-3 bg-white hover:bg-gray-50 cursor-move transition-colors">
                                        <div
                                            className="flex items-center gap-2">
                                            <ChevronUpDownIcon
                                                className="h-5 w-5 text-gray-400 flex-shrink-0"/>
                                            <span
                                                className="text-gray-700">{item.name}</span>
                                        </div>
                                    </li>))}
                            </ul>
                        </div>
                    </div>
                </div>

                <div className='col-span-1'>
                    <div className="w-full max-w-2xl mx-auto p-4">
                        <h3 className="text-lg">
                            {Language.getSentenceCase('selected questions')} ({selected_questions.length})
                        </h3>
                        <div className="overflow-y-auto border border-gray-200 rounded-lg"
                            style={{height: `${containerHeight}px`}}
                            id="container"
                            onDragOver={e => handleDragOver(e, 'selected_questions', -1)}
                            onDragEnd={handleDragEnd}>
                            <ul className="divide-y divide-gray-200">
                                {selected_questions.map((item, index) => (
                                    <li key={`selected_questions_${item.uid}`}
                                        id="selected_questions"
                                        draggable
                                        onDragStart={e => handleDragStart(e, 'selected_questions', index)}
                                        onDragOver={e => handleDragOver(e, 'selected_questions', index)}
                                        onDragEnd={handleDragEnd}
                                        className="flex items-center gap-3 p-3 bg-white hover:bg-gray-50 cursor-move transition-colors">
                                        <div
                                            className="flex items-center gap-2">
                                            <ChevronUpDownIcon
                                                className="h-5 w-5 text-gray-400 flex-shrink-0"/>
                                            <span
                                                className="text-gray-700">{item.name}</span>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
