import React, { useState } from "react"
import Util from "../../helpers/util"

import ReactMarkdown from 'react-markdown'


export const ContentTypeText = props => {
    const { content } = props

    let text = "text" in content ? content.text : ""

    //Split all the '\n' into an array and then join together with '&nbsp;' on text
    //text = text.split('\n').join('\n\n&nbsp;\n')
    //console.log(text)

    text = Util.hyperlinkMD( text )

    //{parse(text)}
    return (
        <div className='daily-questions__question flex flex-col items-center'>
            <ReactMarkdown className="prose">
                {text}
            </ReactMarkdown>
        </div>
    )
}
