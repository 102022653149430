import React, { useEffect, useRef } from "react";
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import  droplet  from "../../assets/images/shared/droplet.svg";
import Language from "../../helpers/language";


export const Slider = (props) => {
    const colors = ['#FA5C3C','#1482E6','#87DCAA','#41C8FF','#1F4760']

    const items = (props.items.length > 0)? props.items: [{name:'Curriculum', date:' '}]

    return (
        <span className="slider--container overflow-auto">
            <Splide
                hasTrack={ false }
                options={ {
                    drag:'free',
                    rewind: true,
                    arrows:false,
                    pagination:false,
                    gap:'0px',
                    snap:false,
                    // width : '80vw',
                    start:1,
                  } }>
                <SplideTrack>
                    {items.map((elem,idx) =>
                        <SplideSlide className="slide-curric" key={idx}>
                            <span className={`slider--card bg-white ${idx === 0 ? 'first-slider-card' : ''} ${idx === items.length - 1 ? 'last-slider-card' : ''}`} style={{ borderLeft:` 1px solid ${colors[idx%5]}`}} >
                                <span style={{ backgroundColor:`${colors[idx%5]}` }} className='slider--card-top'></span>
                                {elem.progress !== undefined && elem.progress > 0 &&
                                    <img style={{ left: elem.progress }} src={ droplet }/>
                                }
                                <span className='slider--card-info'>
                                    <span className='slider--card-info-date'>{ elem.date }</span>
                                    <span className='slider--card-info-curric'>{ elem.name }</span>
                                </span>
                            </span>
                        </SplideSlide>
                    )}
                </SplideTrack>
            </Splide>
        </span>
    );
};
  