import React, { useState } from "react";
import Util from "../../helpers/util";
import Language from "../../helpers/language";

export const AddUsersFromFileModal = props => {
    const { onClose, onEdit, showToast } = props;
    const { info, uid, tenant_uid } = props;

    //Setup my state
    const [state, setState] = useState({
        filename: "",
        raw_file: null,
        add_users: true
    });
    const { filename, raw_file, add_users } = state;

    //File ref required to access the file browser
    const fileRef = React.useRef();

    //Funky pass through to open the file browse
    const handleFileClick = () => {
        fileRef.current.click();
    };

    //We have a selected file
    const handleFileChange = e => {
        const raw_file = e.target.files[0];
        setState({ ...state, filename: raw_file.name, raw_file });
    };

    const handleChecked = e => {
        const add_users = e == "add-users";
        setState({ ...state, add_users });
    };

    const handleUpload = () => {
        const payload = {
            hybrid_uid: uid,
            filename,
            replace_users: !add_users,
            tenant_uid,
            email_password_reset: false,
            content: raw_file //This is a file
        };
        Util.fetch_js( "/human/upload_members/", payload,
            js => {
                showToast(`${Language.getSentenceCase('successfully loaded users')} ${js.count}`, 'successful');
                onClose()
            },
            (reason, code) => {
                showToast(reason, 'failure');
            }
        );
    };

    return (
        <div className='add-users-modal'>
            <div className='add-users-wrap'>
                <div className='add-users-heading'>
                    <h2>{Language.getTitleCase('add users from file')}</h2>
                    <svg
                        className='close-button'
                        width='12'
                        height='11'
                        viewBox='0 0 12 11'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                        onClick={onClose}>
                        <path
                            d='M7.93359 5.375L10.8633 2.44531C11.2441 2.09375 11.2441 1.50781 10.8633 1.15625L10.2188 0.511719C9.86719 0.130859 9.28125 0.130859 8.92969 0.511719L6 3.44141L3.04102 0.511719C2.68945 0.130859 2.10352 0.130859 1.75195 0.511719L1.10742 1.15625C0.726562 1.50781 0.726562 2.09375 1.10742 2.44531L4.03711 5.375L1.10742 8.33398C0.726562 8.68555 0.726562 9.27148 1.10742 9.62305L1.75195 10.2676C2.10352 10.6484 2.68945 10.6484 3.04102 10.2676L6 7.33789L8.92969 10.2676C9.28125 10.6484 9.86719 10.6484 10.2188 10.2676L10.8633 9.62305C11.2441 9.27148 11.2441 8.68555 10.8633 8.33398L7.93359 5.375Z'
                            fill='currentColor'></path>
                    </svg>
                </div>
                <p className='modal-field-heading'>{Language.getSentenceCase('file with user list')}</p>
                <div className='add-users-file-select'>
                    <input
                        type='file'
                        ref={node => (fileRef.current = node)}
                        onChange={handleFileChange}
                        style={{ display: "none" }}
                    />
                    <input
                        className='add-users-input'
                        type='text'
                        readOnly
                        name='user-file'
                        placeholder={Language.getSentenceCase('No File Selected')}
                        value={filename}
                    />
                    <button
                        className='add-users-choose-file solid-button'
                        type='button'
                        name='choose-file'
                        onClick={handleFileClick}>
                        {Language.getTitleCase('Choose File')}
                    </button>
                </div>

                <p>{Language.getSentenceCase('supported file formats')}: csv, xls, xlsx</p>
                <div className='add-users-radios'>
                    <div
                        className='radio-choice'
                        onClick={() => handleChecked("add-users")}>
                        <input
                            type='radio'
                            name='add-users'
                            value='add-users'
                            readOnly
                            aria-label="radio-button"

                            checked={add_users}
                        />
                        <label htmlFor='add-users'>
                            {Language.getSentenceCase('Add users to current list')}
                        </label>
                    </div>
                    <div
                        className='radio-choice'
                        onClick={() => handleChecked("replace-users")}>
                        <input
                            type='radio'
                            name='add-users'
                            value='replace-users'
                            aria-label="radio-button"
                            readOnly
                            checked={!add_users}
                        />
                        <label htmlFor='replace-users'>
                            {Language.getSentenceCase('Replace users in current list')}
                        </label>
                    </div>
                </div>

                <div className='upload-buttons'>
                    <button
                        className='clear-button'
                        type='button'
                        name='cancel'
                        onClick={onClose}>
                        {Language.getTitleCase('cancel')}
                    </button>
                    <button
                        className='solid-button'
                        type='submit'
                        name='submit'
                        disabled={!filename}
                        onClick={handleUpload}>
                        {Language.getTitleCase('Upload The List')}
                    </button>
                </div>
            </div>
        </div>
    );
};
