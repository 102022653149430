import React, { useState } from 'react'

export const ChatQuery = props => {
    const { readOnly, onQuestion, showToast } = props

    const [state, setState] = useState( {
        question: '',
    } )
    const { question } = state

    const handleChange = (e) => {
        setState(prev => ({ ...prev,
            question: e.target.value
        }))
    }

    const handleChatQuestion = () => {
        onQuestion(question)

        setState(prev => ({ ...prev,
            question: ""
        }))
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleChatQuestion()
        }
    }

    return (
        <div className="flex items-start space-x-4">
            <div className="min-w-0 flex-1">
                <div
                    className="min-w-0 flex flex-row border-b border-gray-200 focus-within:border-indigo-600">
                    <label htmlFor="comment"
                           className="sr-only">
                        Add your comment
                    </label>
                    <input
                        name="comment"
                        id="comment"
                        className="mt-2 mb-3 block w-full resize-none border-0 border-transparent p-0 pb-2 text-gray-900 placeholder:text-gray-400 focus:border-indigo-600 focus:ring-0 sm:text-sm sm:leading-6"
                        placeholder="Ask a question..."
                        autoComplete="off"
                        readOnly={readOnly}
                        value={question}
                        onKeyDown={handleKeyDown}
                        onChange={handleChange}
                    />
                    <div className="mt-1">
                        <button
                            disabled={readOnly}
                            className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-indigo-100"
                            onClick={handleChatQuestion}>
                            Query
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}